import {VariablesType} from "../../../../../../../../services/payments/IPaymentsResponse";
import {ChangeEvent, FormEvent} from "react";
import {Box, TextField, Typography} from "@mui/material";


interface Props {
  formData:VariablesType
  handleChange:(e: ChangeEvent<HTMLInputElement>)=>void
}

export const PaymentPayRequestForm = (props:Props)=>{

  const {formData, handleChange} = props

  const onSubmitHandler = (e:FormEvent<HTMLDivElement>  )=>{
    e.preventDefault()
  }

  return (
    <Box component="form" onSubmit={onSubmitHandler}>
      <Box mb={2} display="flex" flexWrap="wrap" alignItems="center" gap={1}>
        <Typography>Здравствуйте! Вам была произведена оплата на сумму</Typography>
        <TextField
          sx={{maxWidth: '100px'}}
          size={'small'}
          variant="outlined"
          name={'a'}
          value={formData['a'] || ''}
          onChange={handleChange}
        />
        <Typography>рублей, просьба направить ссылку на чек из приложения `Мой налог`.</Typography>
      </Box>
    </Box>
  )
}