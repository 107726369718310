import {FC} from 'react'
import { IStatusIconList } from './IStatusIconList'
import { IStatusAgreementItem } from '../Statuses/models/ISettingsFilters'
import { Avatar, Button, Menu, MenuItem } from '@mui/material'

export const StatusIconList:FC<IStatusIconList> = (props) => {
  const {
    list,
    status,
    anchorEl,
    onCloseList,
    onClickList,
    onChangeStatus,
    disabled = false,
    readOnly = false,
    btnSx
  } = props

  const open = Boolean(anchorEl)

  const currentStatusItem:IStatusAgreementItem = list[status]

  return (
    <>
      <Button
        endIcon={<Avatar sx={{height:20, width:20}} src={currentStatusItem?.iconUrl}/>}
        onClick={readOnly === false ? onClickList : undefined}
        variant='outlined'
        disabled={disabled}
        sx={{cursor:readOnly ? 'auto' : 'pointer', ...btnSx}}
      >
        {currentStatusItem?.name}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onCloseList}
      >
        {currentStatusItem?.allowed_moves?.map(item=>
          <MenuItem
            key={item} 
            sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} 
            onClick={()=>onChangeStatus(item)}
            disabled={disabled}
          >
            {list[item].name}
            <Avatar sx={{height:20, width:20, ml:1}} src={list[item]?.iconUrl}/>
          </MenuItem>)
        }
      </Menu>
    </>
  )
}