import {IIntegrationTableList} from "./PrePaymentTableList";
import React, {FC, useCallback, useState} from "react";
import {observer} from "mobx-react-lite";
import IntegrationsService from "../../../../../../services/integration/integrations.service";
import userStore from "../../../../../../store/user.store";
import {ALL_ROLES} from "../../../../../../shared/rules/interface/EnumRights";
import {RULES} from "../../../../../../shared/rules/rules";
import {IBurgerItem} from "../../../../../../components/Table/models/ITableItem";
import {OpenInNew} from "@mui/icons-material";
import {IIntegrationsFullResponse} from "../../../../../../services/integration/IIntegrationsResponse";
import prePaymentStore from "../../../../../../store/prePayment.store";
import {WrapperRouteAgree} from "../../../AgreementPage/ui/AgreementTableItem/ui/WrapperRouteAgree/WrapperRouteAgree";
import {INTEGRATION_CARD_OPEN} from "../../../../../../shared/constants/searchParams";
import {TableItem} from "../../../../../../components/Table/ui/TableItem";
import statusesStore from "../../../../../../store/statuses.store";

interface IPrePaymentTableListArr extends IIntegrationTableList {
  navigateAside:(id:string | number)=>void;
}

export const PrePaymentTableListArr:FC<IPrePaymentTableListArr> = observer((props) => {

  const checkUserOtherAndSelfEdit = useCallback((integrationId:number):boolean => {
    const acsess = userStore.user?.role === ALL_ROLES.trainee
      ? [RULES.integrations.edit_others]
      : [RULES.integrations.edit_others, RULES.integrations.edit_self]

    return acsess.map(x=>{
      if(userStore?.user?.rights?.find(right=>right?.code === x) || userStore.user?.id === integrationId){
        return true
      } else return false
    }).filter(x=>x===true)[0]
  }, [])

  const checkUserEdit = useCallback((integrationId:number):boolean => {
    if(userStore.user?.role == ALL_ROLES.ADMIN){
      return true
    } else if(checkUserOtherAndSelfEdit(integrationId)) {
      return true
    } else return false
  }, [])


  const [controlButtonPanel] = useState<IBurgerItem[]>([
    {
      title: 'Открыть и посмотреть',
      onNavigate: props.navigateAside,
      isShow: true,
      icon: <OpenInNew/>
    },
  ])

  const onChangePriceAndStatus = useCallback((id:number, status:IIntegrationsFullResponse['status'], update_id:number) => {
    prePaymentStore.changeUpdateIdItem(id, update_id, status?.code)
  },[])

  const handleOnChangeStatus = (id: number, update_id: number, value: string)=>{
    prePaymentStore.changeUpdateIdItem(id, update_id, value)
    prePaymentStore.deleteOnStatusClick(id,value)
  }

  return (
    <>
      {prePaymentStore.tableItems.map(item=> {
          const integrationProps={
            ...item,
            integration_theme:'integration_theme',
          }
          return <WrapperRouteAgree searchParamsKey={INTEGRATION_CARD_OPEN} id={item.id} key={item.id}>
            <TableItem
              {...integrationProps}
              changePaymentStatusInIntegrationTable={prePaymentStore?.changePaymentStatusPrePaymentTable}
              controlButtonPanel={controlButtonPanel}
              cells={props.tableHead}
              onChangeStatus={handleOnChangeStatus}
              onUpdateStatus={IntegrationsService.updateIntegrationStatuses}
              showSettingMenu
              user={userStore.user}
              isPriceEdit
              statusStore={statusesStore.statuses}
              hover
              readonly={!checkUserEdit(item?.releaseUser?.id)}
              showCheckboxCell
              isBanned={item?.channel?.isBanned}
              onChangeType={prePaymentStore.changeUpdateIdItem}
              backgroundColor={'#fff'}
              onChangePriceAndStatus={onChangePriceAndStatus}
            />
          </WrapperRouteAgree>;
        }
      )
      }
    </>
  )
})