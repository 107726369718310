import {FC, useState, useEffect, memo} from 'react'
import { Autocomplete, Avatar, Box, CircularProgress, FormControl, TextField, Typography } from '@mui/material'
import { IFIlterFetchSelectChip } from './IFIlterFetchSelectChip'
import { IFilterSelectItem } from '../FilterSelectChip/IFilterSelectChip'

export const FIlterFetchSelectChip:FC<IFIlterFetchSelectChip> = memo((props) => {
  const {
    label,
    content,
    searchParamName,
    placeholder,
    limitTags,
    loading,
    onSearch,
    error,
    onResetError,
    initValue,
    changeUrlValue,
    filterOptions
  } = props

  const [items, setItems] = useState<IFilterSelectItem[]>(initValue)
  const [inputValue, setInputValue] = useState<string>('')

  useEffect(()=>{
    if(inputValue.trim()!==''){
      const delay = setTimeout(()=> onSearch(inputValue), 500)
      return ()=>clearTimeout(delay)
    }
    if(inputValue.trim()===''){
      onResetError()
    }
  },[inputValue])

  useEffect(()=>{
    changeUrlValue(items)
  },[items])
  
  return (
    <FormControl size='small'>
      <Typography
        variant='body2' 
        sx={{height:27, display:'inline-flex'}} 
        alignItems={'center'} 
        justifyContent={'start'}
      >
        {label}
      </Typography>
      <Autocomplete
        value={items}
        onChange={(event: any, newValue: IFilterSelectItem[]) => {
          setItems(newValue)
        }}
        filterOptions={filterOptions}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue)
        }}
        multiple
        limitTags={limitTags}
        options={content}
        getOptionLabel={(option) => (!isNaN(+inputValue) === true && inputValue !== '') ? option.value : option.name}
        ChipProps={{variant:'outlined'}}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.value}>
            {option.iconUrl ? <Avatar src={option.iconUrl} sx={{height:20, width:20, mr:0.5}}/> : null}
            {option.name}
          </Box>
        )}
        noOptionsText='Ничего не найдено'
        loading={loading}
        loadingText={'Загрузка...'}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            size='small'
            error={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        size='small'
      />
    </FormControl>
  )
})