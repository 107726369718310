import { FC, memo, useState } from "react";
import { IRepeatTable } from "./IRepeatTable";
import { TableDefault } from "../../../../../Table/TableDefault";
import { TableItem } from "../../../../../Table/ui/TableItem";
import { IStrategyField } from "../../../../../../strategy/interface/IStrategyField";
import { WrapperRouteAgree } from "../../../../../../pages/DashboardPage/pages/AgreementPage/ui/AgreementTableItem/ui/WrapperRouteAgree/WrapperRouteAgree";
import { INTEGRATION_CARD_OPEN_REPEAT } from "../../../../../../shared/constants/searchParams";
import { IBurgerItem } from "../../../../../Table/models/ITableItem";
import repeatIntegrationStore from "../../../../../../store/repeatIntegration.store";
import { OpenInNew } from "@mui/icons-material";

export const RepeatTable: FC<IRepeatTable> = memo((props) => {
  const { integrations, navigateAside } = props;

  const [currentSortType, setCurrentSortType] = useState("");

  const [tableCol] = useState<IStrategyField[]>([
    { code: "id", title: "ID", immutable: false, isSimple: true },
    {
      code: "channel",
      title: "Канал",
      immutable: false,
      isSimple: false,
      isChannel: true,
      maxWidth: "200px",
    },
    { code: "promokod", title: "Промокод", immutable: false, isSimple: true },
    { code: "price", title: "Стоиомость", immutable: false, isSimple: true, isMoneySimple: true },
    {
      code: "goodsCategoryCode",
      title: "Категория товаров",
      immutable: false,
      isSimple: false,
      isGoodCategoryCode: true,
      isSort: true,
    },
    {
      code: "integration_date",
      title: "Дата выхода интеграции",
      immutable: false,
      isSimple: false,
      isDate: true,
      isDateEdit: false,
      isSort: true,
    },
    { code: "tz", title: "ТЗ", immutable: false, isSimple: false, isTz: true },
    { code: "roi", title: "ROI", immutable: false, isSimple: true, isPercent: true, isSort: true },
    {
      code: "validOrders",
      title: "Кол-во заказов",
      immutable: false,
      isSimple: true,
      maxWidth: "70px",
    },
    {
      code: "repeatIntegration",
      title: "На повтор",
      immutable: false,
      isSimple: false,
      isRepeatIntegration: true,
    },
  ]);

  const [controlButtonPanel] = useState<IBurgerItem[]>([
    {
      title: "Открыть и посмотреть",
      onNavigate: navigateAside,
      isShow: true,
      icon: <OpenInNew />,
    },
  ]);

  const sortHandler = (type: string, sortType: "asc" | "desc") =>
    repeatIntegrationStore.sortRepeatItem(type, sortType);

  return (
    <TableDefault
      tableRow={tableCol}
      showBorderRadius={false}
      maxWidth="100vw"
      maxHeight="100%"
      showBurgerCell
      talbeHeaderColor="#f2f3f5"
      isShowSort
      sortHandler={sortHandler}
      currentSortType={currentSortType}
      onChangeCurrentSortType={setCurrentSortType}
      hideUrlSortCode>
      {integrations.map((item) => {
        const itemProps = {
          ...item,
          promokod: item.promokod?.code,
          repeatIntegration: item?.id,
          channelType: item?.channel,
          showCopy: true,
          showIcon: true,
        };
        return (
          <WrapperRouteAgree
            key={item.id}
            id={`${item.id}`}
            searchParamsKey={INTEGRATION_CARD_OPEN_REPEAT}>
            <TableItem
              readonly
              backgroundColor="#fff"
              controlButtonPanel={controlButtonPanel}
              cells={tableCol}
              {...itemProps}
            />
          </WrapperRouteAgree>
        );
      })}
    </TableDefault>
  );
});
