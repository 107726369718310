import { makeAutoObservable } from "mobx";
import UsersService from "../services/users/users.service";
import { AxiosError } from "axios";
import { IUser } from "../entities/user/models/IUser";

class AllUsersStore {
  users:IUser[] | null = null

  constructor(){
    makeAutoObservable(this)
  }

  setItems(users:IUser[]) {
    this.users = users
  }

  getAllUsers(){
    UsersService.getAllUsers()
      .then(res=>{
        // console.log(res, 'res all users')
        this.setItems(res.data)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err all users')
        }
      })
  }

}

export default new AllUsersStore()