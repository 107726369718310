import {FC, useState} from 'react'
import { IContractIntegration } from './IContractIntegration'
import integrationPageStore from '../../../../../../../../store/integrationPage.store'
import { ContractBlock } from '../../../../../../../FinanceCardPage/ui/ContractBlock/ContractBlock'
import { AxiosError } from 'axios'
import { IContractInsertOrUpdateRequest } from '../../../../../../../../services/payments/IPaymentsRequest'
import PaymentsService from '../../../../../../../../services/payments/payments.service'
import { observer } from 'mobx-react-lite'
import { checkNumberBoolean } from '../../../../../../../../shared/functions/functions'

export const ContractIntegration:FC<IContractIntegration> = observer((props) => {
  const {readOnly} = props

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [isShowError, setShowError] = useState(false)

  const [isEdit, setEdit] = useState(false)

  const [isAddAttach, setAddAttach] = useState(false)
  
  const startEdit = () => {
    integrationPageStore.syncContractWithEditContract()
    setEdit(true)
  }

  const closeEdit = () => {
    integrationPageStore.closeContarctEdit()
    setEdit(false)
    setAddAttach(false)
  }

  const removeAttachment = () => {
    integrationPageStore.removeAttachment()
    setAddAttach(false)
  }

  const changeCheckbox = (checked:boolean, field:string) => {
    integrationPageStore.changeSimpleEditContractBlock(checked, field)
  }

  const submit = () => {
    if(integrationPageStore.editContractBlock && integrationPageStore.oplataId && checkNumberBoolean(integrationPageStore.paymentUpdateId)){

      setLoading(true)
      setError(null)
      setShowError(false)

      const option: IContractInsertOrUpdateRequest = {
        update_id:integrationPageStore.paymentUpdateId!,
        payment:{id:integrationPageStore.oplataId},
        summ:integrationPageStore.editContractBlock?.summ || null as any,
        contract:{...integrationPageStore.editContractBlock?.contract, posted_comments:integrationPageStore?.editContractBlock?.contract?.posted_comments as any,  counteragent:{id:integrationPageStore?.counteragent?.id as any}},
        attachment:integrationPageStore.editContractBlock?.attachment || null,
        isOriginalsReceived:Boolean(integrationPageStore.editContractBlock?.isOriginalsReceived),
        isNDS:Boolean(integrationPageStore.editContractBlock?.isNDS),
        trackNumber:integrationPageStore.editContractBlock?.trackNumber || null as any,
        id:integrationPageStore.editContractBlock?.id || undefined,
        summAttachment:integrationPageStore.editContractBlock?.summAttachment || null as any,
      }
      PaymentsService.contractInserOrUpdate(option)
        .then(res=>{
          integrationPageStore.setContractBlock(res.data?.contractBlock || null)
          // console.log(res, 'res contract')
          integrationPageStore.setPaymentUpdateId(res.data?.update_id || null)
          closeEdit()
          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            // console.log(err, 'err')
            setLoading(false)
            setError(err?.response?.data?.message)
            setShowError(true)
          }
        })

    }
  }

  return (
    <ContractBlock
      readOnly={readOnly}
      changePostedCommentsWithEditContractBlock={integrationPageStore?.changePostedCommentsWithEditContractBlock}
      editContractBlock={integrationPageStore.editContractBlock}
      changeSimpleEditContractBlock={integrationPageStore.changeSimpleEditContractBlock}
      contractBlock={integrationPageStore.contractBlock}
      editCheckbox={integrationPageStore.editContractBlock?.isOriginalsReceived || false}
      editCheckboxNDS={integrationPageStore.editContractBlock?.isNDS || false}
      changeCheckbox={changeCheckbox}
      closeEdit={closeEdit}
      error={error}
      isAddAttach={isAddAttach}
      isEdit={isEdit}
      isLoading={isLoading}
      isShowError={isShowError}
      onCloseError={()=>setShowError(false)}
      onOpenAddAttach={()=>setAddAttach(true)}
      removeAttachment={removeAttachment}
      startEdit={startEdit}
      submit={submit}
    />
  )
})