import {FC} from 'react'
import { ILinkField } from './ILinkField'
import { Link, Stack, Typography } from '@mui/material'
import { CopyIconButton } from '../../../../UI/Buttons/CopyIconButton/CopyIconButton'

export const LinkField:FC<ILinkField> = (props) => {
  const {link, maxWidth} = props

  return (
    <>
      {link
        ? <Stack direction={'row'} alignItems={'center'}>
            <Link
              href={link}
              target='_blank'
              sx={{textOverflow:'ellipsis', whiteSpace:'nowrap',overflow:'hidden', maxWidth:maxWidth ? maxWidth : "180px", display:'block'}}
            >
              {link}
            </Link>
            <CopyIconButton copyText={link}/>
          </Stack>
        : <Typography>—</Typography>
      }
    </>
  )
}