import { AxiosResponse } from "axios";
import $gateway_api from "../../shared/api/gatewayAxios";
import { IGoodItem } from "../../components/Table/ui/Fields/GoodsField/ui/GoodItem/IGoodItem";
import {IGoodCategoriesItem, IReferencesBudgetItem, IReferencesHintsChannel, IReferencesHintsIntegrationResponse, IReferencesIntegrationPlacesResponse, IReferencesPaymentTypesResponse, IReferencesPlanGetResponse, IReferencesPlanResonse, IReferencesPresetResponse, IReferencesPromokodRulesResponse, IReferencesRequisiteResponse, IReferencesTzResponse, IRequisteAccount, ISalaryEditOrUpdate, IReferencesStatsByAutoUpdatesResponse, IReferencesCreateThemeResponse, IReferencesRecipientNameResponse, IReferencesPositioningResponse} from "./IReferencesResponse";
import { IReferencesBudgetParams, IReferencesPaymentRequest, IReferencesPlanGetRequest, IReferencesPlanSetRequest, IReferencesPresetPropsRequest, IReferencesPresetRequest, IReferencesSetBudgetUserRequest } from "./IReferencesRequest";
import { IChannelCreateRequestSelect } from "../channel/IChannelRequest";
import { ITzSimpleObjResponse } from "../tz/ITzResponse";
import {IContrActorSimpleObjResponse, IContractorCreateResponse} from "../contractor/IContractorIResponse";
import {IMotivationCalcAll} from "../motivation/IMotivationResponse";
import { IordTextInsertResponse } from "../payments/IPaymentsResponse";
import { IIntegrationsFullResponse } from "../integration/IIntegrationsResponse";

export default class ReferencesService {

  static getAllGoods():Promise<AxiosResponse<IGoodItem[]>>{
    return $gateway_api.get('/api/references/goods')
  }

  static getRequisite():Promise<AxiosResponse<IReferencesRequisiteResponse>>{
    return $gateway_api.get('/api/references/accounts-info')
  }

  static createPresetFilter(option:IReferencesPresetRequest):Promise<AxiosResponse<IReferencesPresetResponse>>{
    return $gateway_api.post('/api/references/presets/create', option)
  }

  static deletePresetFilter(option:{presetId:number}):Promise<AxiosResponse>{
    return $gateway_api.post('/api/references/presets/delete', option)
  }

  static getPresetWithProps(option:IReferencesPresetPropsRequest):Promise<AxiosResponse<IReferencesPresetResponse[]>>{
    return $gateway_api.post('/api/references/presets/get-with-props', option)
  }

  static getAllPreset():Promise<AxiosResponse<IReferencesPresetResponse[]>>{
    return $gateway_api.get('/api/references/presets/get-with-props')
  }

  static getHintsChannelName(option:{target:string | number}):Promise<AxiosResponse<IReferencesHintsChannel[]>>{
    return $gateway_api.post('/api/references/hints/channel-name', option)
  }

  static getRecipientName(option:{target:string | number,paymentType:string[]}):Promise<AxiosResponse<IReferencesRecipientNameResponse[]>>{
    return $gateway_api.post('/api/references/hints/recipient-name', option)
  }

  static getHintsAgencylName(option:{target:string}):Promise<AxiosResponse<string[]>>{
    return $gateway_api.post('/api/references/hints/agency-name', option)
  }

  static getIntegrationReleasesPlaces():Promise<AxiosResponse<IReferencesIntegrationPlacesResponse[]>>{
    return $gateway_api.get('/api/references/integration-places')
  }

  static getAllTz():Promise<AxiosResponse<IReferencesTzResponse[]>>{
    return $gateway_api.get('/api/references/tz-short')
  }

  static getSearchSystems():Promise<AxiosResponse<IChannelCreateRequestSelect[]>>{
    return $gateway_api.get('/api/references/searchSystems')
  }

  static getAdvertisers():Promise<AxiosResponse<IChannelCreateRequestSelect[]>>{
    return $gateway_api.get('/api/references/advertisers')
  }

  static getGoodCategories():Promise<AxiosResponse<IGoodCategoriesItem[]>>{
    return $gateway_api.get('/api/references/goodCategories')
  }

  static getPromokodRules():Promise<AxiosResponse<IReferencesPromokodRulesResponse[]>>{
    return $gateway_api.get('/api/references/promokod-rules')
  }

  static budgetSetToUser(option:IReferencesSetBudgetUserRequest):Promise<AxiosResponse<IReferencesBudgetItem[]>>{
    return $gateway_api.post('/api/references/budget/setToUser', option)
  }

  static budgetGet(params:IReferencesBudgetParams):Promise<AxiosResponse<IReferencesBudgetItem[]>>{
    return $gateway_api.get('/api/references/budget/get', {
      params
    })
  }

  static planGet(params:IReferencesPlanGetRequest):Promise<AxiosResponse<IReferencesPlanGetResponse[]>>{
    return $gateway_api.get('/api/references/plan/get', {
      params
    })
  }

  static planSetGeneral(option:IReferencesPlanSetRequest):Promise<AxiosResponse<IReferencesPlanResonse>>{
    return $gateway_api.post('/api/references/plan/setGeneral', option)
  }

  static planSetToUser(option:IReferencesPlanSetRequest):Promise<AxiosResponse<IReferencesPlanResonse>>{
    return $gateway_api.post('/api/references/plan/setToUser', option)
  }

  static referencesTz(option:{field:string}):Promise<AxiosResponse<ITzSimpleObjResponse[]>>{
    return $gateway_api.post('/api/references/tz', option)
  }

  static referencesContrActor(option: {field: string}): Promise<AxiosResponse<IContrActorSimpleObjResponse[]>>{
    return $gateway_api.post('/api/references/counteragent', option)
  }

  static editFixCell(option:ISalaryEditOrUpdate): Promise<AxiosResponse<IMotivationCalcAll>>{
    return $gateway_api.post('/api/references/salary/updateOrCreate', option)
  }

  static referencesPayment(option:IReferencesPaymentRequest): Promise<AxiosResponse<IReferencesPaymentTypesResponse[]>>{
    return $gateway_api.post('/api/references/payment', option)
  }

  static hintsCounteragent(option:{target:string}): Promise<AxiosResponse<IContractorCreateResponse[]>>{
    return $gateway_api.post('/api/references/hints/counteragent-name', option)
  }

  static hintsIntegrations(option:{target:string}): Promise<AxiosResponse<IReferencesHintsIntegrationResponse[]>>{
    return $gateway_api.post('/api/references/hints/integrations', option)
  }

  static goodsCategoryForMotivation(option:IReferencesPlanGetRequest): Promise<AxiosResponse<IGoodCategoriesItem[]>>{
    return $gateway_api.post('/api/references/goodsCategoryForMotivation', option)
  }

  static advertisingFormat(): Promise<AxiosResponse<IRequisteAccount[]>>{
    return $gateway_api.get('/api/references/advertisingFormat')
  }

  static statsByAutoUpdates(): Promise<AxiosResponse<IReferencesStatsByAutoUpdatesResponse[]>>{
    return $gateway_api.get('/api/references/statsByAutoUpdates')
  }

  static getOrdTexts(): Promise<AxiosResponse<IordTextInsertResponse[]>>{
    return $gateway_api.get('/api/references/ordTexts')
  }
  
  static createChannelTheme(option:{name:string}): Promise<AxiosResponse<IReferencesCreateThemeResponse>>{
    return $gateway_api.post('/api/references/channelTheme',option)
  }

  static getChannelSizes(): Promise<AxiosResponse<IRequisteAccount[]>>{
    return $gateway_api.get('/api/references/sizes')
  }
 
  static getOrdEridTexts(): Promise<AxiosResponse<Omit<IordTextInsertResponse, 'goodsCategory'>[]>>{
    return $gateway_api.get('/api/references/eridTexts')
  }

  static createPositioning(option:{name:string}):Promise<AxiosResponse<IReferencesPositioningResponse>>{
    return $gateway_api.post('/api/references/positioning',option)
  }

  static hintsAgreements(option:{target:string}): Promise<AxiosResponse<IIntegrationsFullResponse[]>>{
    return $gateway_api.post('/api/references/hints/agreements', option)
  }

  static hintsAllIntegrations(option:{target:string}): Promise<AxiosResponse<IIntegrationsFullResponse[]>>{
    return $gateway_api.post('/api/references/hints/allIntegrations', option)
  }

  static hintsProductSampleInfo(option:{target:string}): Promise<AxiosResponse<IIntegrationsFullResponse[]>>{
    return $gateway_api.post('/api/references/hints/productSampleInfo', option)
  }

  static getSourceCities(): Promise<AxiosResponse<IRequisteAccount[]>>{
    return $gateway_api.get('/api/references/sourceCities')
  }

}