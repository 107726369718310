import { FC, useState, useEffect, memo } from "react";
import { IFilterSelectChip, IFilterSelectItem } from "./IFilterSelectChip";
import { Autocomplete, Avatar, Box, FormControl, Stack, TextField, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

export const FilterSelectChip: FC<IFilterSelectChip> = memo((props) => {
  const {
    showOptionLabelValue,
    label,
    content,
    searchParamName,
    placeholder,
    limitTags,
    isGoodsSelect,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const queryValue: string[] = searchParams.get(searchParamName)?.split(",") || [];

  const queryItems: any[] = queryValue
    .map((val) => {
      return content.find((item) => item.value === val);
    })
    .filter((x) => x !== undefined);

  const [items, setItems] = useState<IFilterSelectItem[]>(
    searchParams.get(searchParamName) ? queryItems : []
  );
  const [inputValue, setInputValue] = useState<string>("");

  const changeUrlValue = () => {
    if (items.length === 0) {
      searchParams.delete(searchParamName);
      setSearchParams(searchParams);
    } else {
      searchParams.set(searchParamName, `${items.map((x) => x.value)}`);
      setSearchParams(searchParams);
    }
  };

  const sortedOptions = [...content].sort((a: IFilterSelectItem, b: IFilterSelectItem) => {
    if (a.filterCode === b.filterCode) {
      const isALatin = /^[A-Za-z]/.test(a.name);
      const isBLatin = /^[A-Za-z]/.test(b.name);

      if (isALatin && !isBLatin) return -1;
      if (!isALatin && isBLatin) return 1;

      return a.name.localeCompare(b.name);
    }

    if (a.filterCode && b.filterCode) {
      return a.filterCode.localeCompare(b.filterCode);
    }

    return 0;
  });

  useEffect(() => {
    changeUrlValue();
  }, [items]);

  return (
    <FormControl size="small">
      <Typography
        variant="body2"
        sx={{ height: 27, display: "inline-flex" }}
        alignItems={"center"}
        justifyContent={"start"}>
        {label}
      </Typography>
      <Autocomplete
        value={items}
        onChange={(event: any, newValue: IFilterSelectItem[]) => {
          setItems(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        multiple
        limitTags={limitTags}
        options={isGoodsSelect ? sortedOptions : content}
        getOptionLabel={(option) =>
          showOptionLabelValue ? `${option.value} ${option.name}` : option.name
        }
        groupBy={(option) => (isGoodsSelect ? (option?.filterCode ? option.filterCode : "") : '')}
        ChipProps={{ variant: "outlined" }}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.value === value.value}
        noOptionsText="Ничего не найдено"
        renderInput={(params) => <TextField {...params} placeholder={placeholder} size="small" />}
        renderOption={(props, option) => (
          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
            {option.iconUrl ? (
              <Avatar src={option.iconUrl} sx={{ height: 20, width: 20, mr: 0.5 }} />
            ) : null}
            {showOptionLabelValue ? `${option.value} ${option.name}` : option.name}
          </Box>
        )}
        renderGroup={(params) => (
          <>
          <Typography fontWeight={"bold"} pl={2}>
            {params.group}
          </Typography>
          <Stack>{params.children}</Stack>
        </>
        )}
        size="small"
      />
    </FormControl>
  );
});
