import {FC} from 'react'
import { IAddPlanInput } from './IAddPlanInput'
import {  Stack, Typography } from '@mui/material'

export const AddPlanInput:FC<IAddPlanInput> = (props) => {
  const {value} = props

  return (
    <>
        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
          <Typography>{value}</Typography>
        </Stack>
    </>
  )
}
