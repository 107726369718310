import { useState, useEffect } from "react";
import { Box, Drawer, Stack, Typography } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import userStore from "../../store/user.store";
import { ROUTES } from "../../shared/routes/routes";
import { GoodSampleBlock } from "./ui/GoodSampleBlock/GoodSampleBlock";
import { GoodComments } from "./ui/GoodComments/GoodComments";
import productSampleCreateStore from "../../store/productSampleCreate.store";
import { observer } from "mobx-react-lite";
import ProductSample from "../../services/productSample/productSample.service";
import { IProductSampleInsertOrUpdate } from "../../services/productSample/IProductSampleRequest";
import { AxiosError } from "axios";
import goodsTableStore from "../../store/goodsTable.store";
import { NotificationAlerts } from "../../components/NotificationAlerts/NotificationAlerts";
import { CustomIconButton } from "../../components/UI/Buttons/CustomIconButton/CustomIconButton";
import { GOODS_CREATE_PAGE_OPEN, INTEGRATION_CARD_OPEN } from "../../shared/constants/searchParams";
import { toJS } from "mobx";
import integrationPageStore from "../../store/integrationPage.store";
import { IProductSampleItemResponse } from "../../services/productSample/IProductSampleResponse";

export const GoodsCreatePage = observer(() => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState<string | null>(null);

  const createProductSample = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const form = e.target as any;

    const options: IProductSampleInsertOrUpdate = {
      integration: (productSampleCreateStore.integration as any) || undefined,
      productSamples:
        productSampleCreateStore.productSamples
          ?.map((x) => {
            return { ...x, id: undefined } as any;
          })
          ?.map((x) => (Object.keys(x).length === 2 && Object.keys(x).includes("color") ? null : x))
          ?.filter((x) => x !== null) || [],
      comment: productSampleCreateStore.comment || undefined,
      deliveryCost: productSampleCreateStore.deliveryCost || undefined,
      fio: form.fio.value || undefined,
      hasRefund: Boolean(productSampleCreateStore.hasRefund),
      phone: form.phone.value || undefined,
      sendAt: productSampleCreateStore.sendAt || undefined,
      sendFrom: productSampleCreateStore.sendFrom || undefined,
      sendTo: form.sendTo.value || undefined,
      trackNumberCG: form.trackNumberCG.value || undefined,
      trackNumberTC: form.trackNumberTC.value || undefined,
      userId: productSampleCreateStore.userId || undefined,
      isSampleRefund: Boolean(productSampleCreateStore.isSampleRefund),
      refundCost: productSampleCreateStore.refundCost || undefined,
      trackNumberRefund: form.trackNumberRefund?.value || undefined,
    };

    ProductSample.insertOrUpdate(options)
      .then((res) => {
        // console.log(res, 'res')
        goodsTableStore.addTableItem(res.data);

        if (searchParams.has(INTEGRATION_CARD_OPEN)) {
          integrationPageStore.setProductSampleInfo(res.data);
        }

        closeModal();
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          setErrorText(err?.response?.data?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const closeModal = () => {
    setOpen(false);

    setTimeout(() => {
      if (searchParams.has(INTEGRATION_CARD_OPEN)) {
        searchParams.delete(GOODS_CREATE_PAGE_OPEN);
        return setSearchParams(searchParams);
      }

      productSampleCreateStore.clearStore();
      navigate("/" + userStore.currentCompany + "/" + ROUTES.GOODS);
    }, 200);
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={closeModal}
        sx={{ position: "relative" }}
        PaperProps={{ sx: { backgroundColor: "#F2F3F4" } }}
        slotProps={{ backdrop: { sx: { left: 70 } } }}>
        <Box
          sx={{
            p: 2,
            pt: 1,
            width: {
              xl: "85vw",
              lg: "calc(100vw - 70px - 100px)",
              md: "calc(100vw - 70px - 50px)",
              sm: "calc(100vw - 70px)",
              xs: "100vw",
            },
          }}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <CustomIconButton onClick={closeModal} sx={{ ml: -1 }}>
              <KeyboardArrowLeft fontSize="large" />
            </CustomIconButton>
            <Typography variant="h5" noWrap>
              Добавление образца товаров
            </Typography>
          </Stack>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "2fr 1fr" },
              columnGap: 2,
              rowGap: 2,
              mt: 1,
              width: { xs: "100%", lg: "75%" },
            }}>
            <GoodSampleBlock
              productSampleList={productSampleCreateStore.productSamples}
              onChangeGood={productSampleCreateStore.onChangeGood}
              onAddedGood={productSampleCreateStore.onAddedGood}
              onDeleteGood={productSampleCreateStore.onDeleteGood}
              onSubmitForm={createProductSample}
              disabled={isLoading}
              loading={isLoading}
              userId={productSampleCreateStore.userId}
              onManagerChange={productSampleCreateStore.onManagerChange}
              hasRefundValue={Boolean(productSampleCreateStore.hasRefund)}
              onChangeCheckbox={productSampleCreateStore.onChangeCheckbox}
              sendFrom={productSampleCreateStore.sendFrom}
              onChangeCity={productSampleCreateStore.onChangeSendFrom}
              sendAtValue={productSampleCreateStore.sendAt}
              onChangeDate={productSampleCreateStore.onChangeDate}
              showRefundContent={Boolean(productSampleCreateStore.hasRefund)}
              integration={productSampleCreateStore.integration}
              onChangeIntegration={productSampleCreateStore.onChangeIntegration}
              isEdit={true}
              showAddedBtn
              isSampleRefundValue={Boolean(productSampleCreateStore.isSampleRefund)}
              onChangeSampleRefund={productSampleCreateStore.onChangeIsSampleRefund}
              setDeliveryCostValue={productSampleCreateStore.setDeliveryCostValue}
              setRefundCostValue={productSampleCreateStore.setRefundCostValue}
            />
            <GoodComments disabled={isLoading} />
          </Box>
        </Box>
      </Drawer>
      <NotificationAlerts
        error={{
          text: errorText || "",
          open: Boolean(errorText),
          onClose: () => setErrorText(null),
        }}
      />
    </>
  );
});
