import {FC, useState} from 'react'
import { IChannelAsideStatus } from './IChannelAsideStatus'
import { StatusesList } from '../../../../components/Statuses/StatusesList'
import statusesStore from '../../../../store/statuses.store'
import { IUpdateStatusesRequest } from '../../../../services/integration/IntegrationsRequest'
import channelTableStore from '../../../../store/channelTable.store'
import ChannelService from '../../../../services/channel/channel.service'
import { AxiosError } from 'axios'
import { NotificationAlerts } from '../../../../components/NotificationAlerts/NotificationAlerts'

export const ChannelAsideStatus:FC<IChannelAsideStatus> = (props) => {
  const {
    id, 
    type, 
    update_id, 
    setStatus, 
    onChangeUpdateId, 
    readOnly,
    allAllowedMoves
  } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [isShowAlert, setShowAlert] = useState(false)
  
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<null | string>(null)


  const changeStatus = (value:string) => {
    setLoading(true)
    setError(null)

    const option:IUpdateStatusesRequest = {
      id,
      field:'status',
      new_value:value,
      update_id
    }
    ChannelService.updateStatus(option)
      .then(res=>{
        // console.log(res, 'res change sttuas')
        channelTableStore.changeUpdateIdItem(id, res.data.update_id, value as any)
        onChangeUpdateId(res.data.update_id)
        setStatus(value)
        handleClose()
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err change stuts')
          setLoading(false)
          setError(err?.response?.data?.message)
        }
      })
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <StatusesList
        type={type}
        anchorEl={anchorEl}
        onOpenList={handleClick}
        onCloseList={handleClose}
        changeStatus={changeStatus}
        statusStore={statusesStore.channel}
        loading={isLoading}
        showAlertSuccess={isShowAlert}
        closeAlertSuccess={()=>setShowAlert(false)}
        isShowAlerError={isError}
        closeAlertError={()=>setError(null)}
        readOnly={readOnly}
        allAllowedMoves={allAllowedMoves}
      />
      <NotificationAlerts
        error={{text:isError ? isError : '', open:isError ? true : false, onClose:()=>setError(null)}}
      />
    </>
  )
}
