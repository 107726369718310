import {FC, useState} from 'react'
import { IStatusAgreeModal } from './IStatusAgreeModal'
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { IFilterSelectItem } from '../../../Filters/UI/FilterSelectChip/IFilterSelectChip'
import AllUsersStore from '../../../../store/AllUsers.store'
import { ALL_RULES } from '../../../../shared/rules/interface/EnumRights'
import AgreementsService from '../../../../services/agreements/agreements.service'
import { IAgreementsRequestCreate } from '../../../../services/agreements/IAgreementsRequest'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'

export const StatusAgreeModal:FC<IStatusAgreeModal> = observer((props) => {
  const {onChangeType, onClose, open, integrationId} = props

  const [loading, setLoading] = useState(false)

  const [usersId, setUsersId] = useState<string[]>([])
  
  const users:IFilterSelectItem[] | null = AllUsersStore.users
    ?.filter(user=>user.isActive === true)
    ?.filter(user=>user.rights.find(x=>x.code.includes(`${ALL_RULES.concord_}`)))
    .map(item=>{
      const newItem:IFilterSelectItem = {
        name:`${item.firstName} ${item.lastName}`,
        value:item.id?.toString()
      }
      return newItem
    }) || null


  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value

    setUsersId(typeof value === 'string' ? value.split(',') : value)
  }

  const onSubmit = () => {
    if(integrationId){
      setLoading(true)

      const option:IAgreementsRequestCreate = {
        integrationId,
        matchingUsers:usersId.map(x=>+x)
      }
      AgreementsService.create(option)
        .then(res=>{
          // console.log(res, 'res CONCORD status')
          if(onChangeType){
            onChangeType(res.data?.status?.code || '', res.data?.update_id)
          }
          onClose()
          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            // console.log(err, 'err CONCORD status')
            setLoading(false)
          }
        })
    }
  }

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle sx={{p:1,pl:3}}>
        При переходе в данный статус вы инициируете процесс согласования интеграции.
      </DialogTitle>
      <Divider/>
      <DialogContent sx={{pt:1,pb:2}}>
        <Stack spacing={1}>
          <Typography>Выберите пользователей для согласования</Typography>
          <FormControl fullWidth>
            <InputLabel id="multiple-checkbox-label" size='small'>Кто согласовывает</InputLabel>
            <Select
              labelId='multiple-checkbox-label'
              label='Кто согласовывает'
              multiple
              value={usersId as any}
              onChange={handleChange}
              input={<OutlinedInput size='small' label="Кто согласовывает" />}
              renderValue={(selected) => `${usersId.map(id=>users?.find(user=>user.value === id)?.name).join(', ')}`}
              MenuProps={MenuProps}
              size='small'
            >
              {users && users.map((user) => (
                <MenuItem key={user.value} value={user.value}>
                  <Checkbox checked={usersId.indexOf(user.value) > -1} />
                  <ListItemText primary={user.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <Divider/>
      <DialogActions>
        <Typography sx={{mr:"auto", ml:2}}>
          Постановка на согласование: <Typography component={'span'} fontWeight={'bold'}>{dayjs().format('DD.MM.YYYY')}</Typography>
        </Typography>
        <Button disabled={loading} variant='outlined' onClick={onClose}>Отмена</Button>
        <Button
          disabled={usersId.length===0 || loading}
          variant='contained'
          onClick={onSubmit}
          endIcon={loading && <CircularProgress sx={{maxHeight:20, maxWidth:20}} />}
        >
          согласовать
        </Button>
      </DialogActions>
    </Dialog>
  )
})