import { Skeleton, Stack } from "@mui/material"

export const FilterSkeleton = () => {
  return (
    <Stack spacing={1}>
      <Stack direction={'row'} justifyContent={"space-between"}>
        <Skeleton variant='rounded' sx={{minHeight:30, minWidth:'47%'}} />
        <Skeleton variant='rounded' sx={{minHeight:30, minWidth:'47%'}} />
      </Stack>
      <Skeleton variant='rounded' sx={{minHeight:10, maxHeight:10}}/>
    </Stack>
  )
}
