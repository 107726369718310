import {FC, useState, useEffect} from 'react'
import { IActivatePromoModal } from './IActivatePromoModal'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { IReferencesPromokodRulesResponse } from '../../services/references/IReferencesResponse'
import ReferencesService from '../../services/references/references.service'
import { AxiosError } from 'axios'

export const ActivatePromoModal:FC<IActivatePromoModal> = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    loading = false,
    goodsCategoryCode
  } = props

  const [isLoadingRules, setLoadingRules] = useState(false)
  const [promokodRules, setPromokodRules] = useState<null | IReferencesPromokodRulesResponse[]>(null)
  const [value, setPromokodRulesValue] = useState<undefined | number>(undefined)

  const handleChange = (event: SelectChangeEvent) => {
    setPromokodRulesValue(+event.target.value)
  }

  const submit = () => {
    if(value){
      onSubmit(value)
    }
  }

  const getAllPromokodRules = () => {
    setLoadingRules(true)
    ReferencesService.getPromokodRules()
      .then(res=>{
        // console.log(res,'res all promo rule')
        setPromokodRules(res.data?.filter(rule=>rule?.goodsCategory?.code === goodsCategoryCode))
        setLoadingRules(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err promo rules')
          setLoadingRules(false)
        }
      })
  }

  useEffect(()=>{
    getAllPromokodRules()
  },[])

  useEffect(()=>{
    if(promokodRules){
      setPromokodRulesValue(promokodRules?.find(x=>x?.goodsCategory?.code === goodsCategoryCode)?.id)
    }
  },[promokodRules])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle
        sx={{
          display:'flex', 
          alignItems:'center', 
          justifyContent:'space-between', 
          p:{xs:1, sm:2}, 
          pr:1, 
          fontSize:{xs:'14px', sm:'1.07rem'}
        }}
      >
        Форма заведения промокода
        <IconButton disabled={loading} onClick={onClose}>
          <Cancel/>
        </IconButton>
      </DialogTitle>
      <Divider/>
      <DialogContent sx={{p:{xs:1, sm:2},}}>
        <FormControl fullWidth>
          <InputLabel id="select-label">{isLoadingRules ? 'Идёт загрузка...' : 'Выберите правило'}</InputLabel>
          <Select
            labelId="select-label"
            label={'Выберите правило'}
            disabled={isLoadingRules || loading}
            value={value ? value?.toString() : ''}
            onChange={handleChange}
          >
            {promokodRules?.map(item=><MenuItem key={item.id} value={item.id}>{`${item.id} — ${item.name}`}</MenuItem>)}
          </Select>
        </FormControl>
      </DialogContent>
      <Divider/>
      <DialogActions sx={{p:{xs:1, sm:2},}}>
        <Button 
          variant='outlined' 
          disabled={loading}
          onClick={onClose}
        >
          отмена
        </Button>
        <Button 
          variant='contained'
          disabled={loading || value === undefined}
          onClick={submit}
          endIcon={loading && <CircularProgress sx={{maxHeight:20, maxWidth:20}}/>}
        >
          создать
        </Button>
      </DialogActions>
    </Dialog>
  )
}