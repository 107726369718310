import { Box, } from "@mui/material"
import { AsideTableItem } from "../AsideTableItem/AsideTableItem"
import { FC } from "react"
import { IAsideGeneralAnalytics } from "./IAsideGeneralAnalytics"
import { IAsideTableItem } from "../AsideTableItem/IAsideTableItem"
import { priceTransormFormat } from "../../../../../../shared/functions/functions" 

export const AsideGeneralAnalytics:FC<IAsideGeneralAnalytics> = (props) => {
  const {initValue, onChangeChartId, allDataStatistics} = props

  const analitics:IAsideTableItem[] = [
    {
      title:"Всего интеграций в графике" ,
      textData:allDataStatistics.toScheduleIntegrationsTotal.value,
      value:0,
      currentValue:initValue,
      onClick:onChangeChartId,
      keyName:'toScheduleIntegrationsTotal',
    },
    {
      title:"Всего выпущено интеграций" ,
      textData:allDataStatistics.integrationsTotal.value,
      value:1,
      currentValue:initValue,
      onClick:onChangeChartId,
      keyName:'integrationsTotal',
    },
    {
      title:"Всего выпущено релизов" ,
      textData:allDataStatistics.releasesTotal.value,
      value:2,
      currentValue:initValue,
      onClick:onChangeChartId,
      keyName:'releasesTotal',
    },
    {
      title:"Стоимость выпущенных интеграций" ,
      textData:priceTransormFormat(allDataStatistics.releasedIntegrationsSumm.value, true),
      value:3,
      currentValue:initValue,
      onClick:onChangeChartId,
      keyName:'releasedIntegrationsSumm',
    },
    {
      title:"Стоимость всех интеграций в графике" ,
      textData:priceTransormFormat(allDataStatistics.toScheduleIntegrationsSumm.value, true),
      value:4,
      currentValue:initValue,
      onClick:onChangeChartId,
      keyName:'toScheduleIntegrationsSumm',
    },
    {
      title:"Оплаченная сумма по интеграциям в графике" ,
      textData:priceTransormFormat(allDataStatistics.budget.value, true),
      value:5,
      currentValue:initValue,
      onClick:onChangeChartId,
      keyName:'budget',
    },
    {
      title: "Осталось оплатить по интеграциям в графике",
      textData: priceTransormFormat(allDataStatistics.remainsToPay.value, true),
      value:6,
      currentValue: initValue,
      onClick: onChangeChartId,
      keyName: "remainsToPay",
    },
    {
      title:"Оплаченная сумма по платежам",
      textData:priceTransormFormat(allDataStatistics.pays.value, true),
      value:7,
      currentValue:initValue,
      onClick:onChangeChartId,
      keyName:'pays',
    },
    {
      title:"ROI",
      textData:allDataStatistics.roi.value,
      value:8,
      currentValue:initValue,
      onClick:onChangeChartId,
      keyName:'roi',
      symbol:'%'
    },
    {
      title:"▼ С выбранной даты по сегодняшнее число",
      value:999,
      isOnlyText:true
    },
    {
      title:"Общая сумма продаж",
      textData:priceTransormFormat(allDataStatistics.totalOrdersSumm.value, true),
      value:9,
      currentValue:initValue,
      onClick:onChangeChartId,
      keyName:'totalOrdersSumm',
    },
    {
      title:"Всего валидных заказов",
      textData:allDataStatistics.totalOrders.value,
      value:10,
      currentValue:initValue,
      onClick:onChangeChartId,
      keyName:'totalOrders',
    },
    {
      title:"Всего отменных заказов",
      textData:allDataStatistics.totalCancel.value,
      value:11,
      currentValue:initValue,
      onClick:onChangeChartId,
      keyName:'totalCancel',
    },
    {
      title:"Сумма отмен",
      textData:priceTransormFormat(allDataStatistics.cancelSumm.value, true),
      value:12,
      currentValue:initValue,
      onClick:onChangeChartId,
      keyName:'cancelSumm',
    },
    {
      title:"Средний чек",
      textData:priceTransormFormat(allDataStatistics.averageCheck.value, true),
      value:13,
      currentValue:initValue,
      onClick:onChangeChartId,
      keyName:'averageCheck',
      borderBottom:false
    }
  ]

  return (
    <Box sx={{border:'1px solid #e0e0e0', height:'fit-content'}}>
      {analitics.map(item=><AsideTableItem key={item.value} {...item}/>)}
    </Box>
  )
}