import { IStrategyField } from "../../../../../strategy/interface/IStrategyField";

export const getFinancesIntegrationCols = ():IStrategyField[] => {
  return [
    {title:'ID', code:'id', immutable:false, isSimple:true},
    {title:'Канал', code:'channel', immutable:false, isSimple:false, isChannelType:true, isShowTypeText:true, maxWidth:'150px'},
    {title:'Промокод', code:'promo', immutable:false, isSimple:true},
    {title:'Дата выхода', code:'integration_date', immutable:false, isSimple:false, isDate:true, isDateEdit:false, isFullYears:true},
    {title:'Стоимость', code:'price', immutable:false, isSimple:true, isMoneySimple:true},
    {title:'Прямой контакт', code:'contact', immutable:false, isSimple:true},
  ]
}