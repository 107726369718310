import { useState} from 'react'
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import analyticsStore from '../../../../../../store/analytics.store';
import { useSearchParams } from 'react-router-dom';
import {AnalyticTextMultiplyDateSelect} from "./ui/AnalyticTextMultiplyDateSelect/AnalyticTextMultiplyDateSelect";

export const AnalyticDatePicker = observer(() => {

  const [searchParams, setSearchParams] = useSearchParams()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const searchDate = searchParams.get('date')

  const [valueFrom, setValueFrom] = useState<Dayjs | null>(searchDate ? dayjs(searchDate.split('_')[0]) : analyticsStore.dateFrom)
  const [valueTo, setValueTo] = useState<Dayjs | null>(searchDate ? dayjs(searchDate.split('_')[1]) : analyticsStore.dateTo)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  
  const handleClose = () => {
    setAnchorEl(null)
    setValueFrom(analyticsStore.dateFrom)
    setValueTo(analyticsStore.dateTo)
  }

  const submit = () => {
    analyticsStore.setDateFrom(valueFrom)
    analyticsStore.setDateTo(valueTo)

    searchParams.set('date', `${valueFrom?.format('YYYY-MM-DD')}_${valueTo?.format('YYYY-MM-DD')}`)
    setSearchParams(searchParams)

    handleClose()
  }
 
  return (
    <AnalyticTextMultiplyDateSelect
      title={
        searchDate
          ? `${dayjs(searchDate.split('_')[0]).format('DD.MM.YYYY')} - ${dayjs(searchDate.split('_')[1]).format('DD.MM.YYYY')}`
          : `${analyticsStore.dateFrom?.format('DD.MM.YYYY')} - ${analyticsStore.dateTo?.format('DD.MM.YYYY')}`
      }
      anchorEl={anchorEl}
      onOpen={handleClick}
      onClose={handleClose}
      valueFirst={valueFrom}
      changeValueFirst={setValueFrom}
      valueSecon={valueTo}
      changeValueSecond={setValueTo}
      disabled={valueFrom && valueTo ? valueFrom?.format('YYYY.MM.DD') > valueTo?.format('YYYY.MM.DD') : false}
      onSubmit={submit}
      sx={{flexWrap:{md:'nowrap', xs:'wrap'}, width:{xs:'310px', md:'100%'}}}
      contentDateSx={{maxWidth:{xs:'288px', sm:'334px'}}}
      headerDateSx={{maxWidth:{xs:'288px', sm:'334px'}, margin: {xs:0, sm:'16px 0 8px 0'}, pr: {xs:0, sm:'12px'}, pl:{xs:2, sm:'24px'}}}
      submitBtnSx={{right:{xs:'16px', sm:0}}}
    />
  )
})