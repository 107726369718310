import {FC, useState} from 'react'
import { TextMultiplyDateSelect } from '../../../../../TextMultiplyDateSelect/TextMultiplyDateSelect'
import dayjs, { Dayjs } from 'dayjs'
import { IUserAnalyticDatePicker } from './IUserAnalyticDatePicker'

export const UserAnalyticDatePicker:FC<IUserAnalyticDatePicker> = (props) => {
  const {
    totalTo,
    setTotalTo,
    totalFrom,
    setTotalFrom,
    sx,
    contentDateSx,
    headerDateSx,
    submitBtnSx
  } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [valueFrom, setValueFrom] = useState<Dayjs | null>(dayjs(dayjs().format('YYYY-MM-01')))
  const [valueTo, setValueTo] = useState<Dayjs | null>(dayjs(dayjs().format(`YYYY-MM-${dayjs(dayjs().format('YYYY-MM-DD')).daysInMonth()}`)))

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  
  const handleClose = () => {
    setAnchorEl(null)

    setValueFrom(totalFrom)
    setValueTo(totalTo)
  }

  const submit = () => {
    setTotalFrom(valueFrom)
    setTotalTo(valueTo)

    setAnchorEl(null)
  }

  return (
    <TextMultiplyDateSelect
      anchorEl={anchorEl}
      title={`${dayjs(totalFrom).format('DD.MM.YYYY')} — ${dayjs(totalTo).format('DD.MM.YYYY')}`}
      valueFirst={valueFrom}
      changeValueFirst={setValueFrom}
      valueSecon={valueTo}
      changeValueSecond={setValueTo}
      onOpen={handleClick}
      onClose={handleClose}
      onSubmit={submit}
      disabled={valueFrom && valueTo ? valueFrom?.format('YYYY.MM.DD') > valueTo?.format('YYYY.MM.DD') : false}
      sx={sx}
      contentDateSx={contentDateSx}
      headerDateSx={headerDateSx}
      submitBtnSx={submitBtnSx}
    />
  )
}
