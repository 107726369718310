import { Dayjs } from "dayjs";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { AnalyticTextMultiplyDateSelect } from "../../../../../../pages/AnalyticsPage/pages/GeneralAnalytics/ui/AnalyticDatePicker/ui/AnalyticTextMultiplyDateSelect/AnalyticTextMultiplyDateSelect";
import repeatIntegrationStore from "../../../../../../store/repeatIntegration.store";

export const UserRepeatDatePicker: FC<{ userId: number }> = observer(({ userId }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [valueFrom, setValueFrom] = useState<Dayjs | null>(repeatIntegrationStore.dateFrom);
  const [valueTo, setValueTo] = useState<Dayjs | null>(repeatIntegrationStore.dateTo);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setValueFrom(repeatIntegrationStore.dateFrom);
    setValueTo(repeatIntegrationStore.dateTo);
  };

  const submit = () => {
    repeatIntegrationStore.setDateFrom(valueFrom);
    repeatIntegrationStore.setDateTo(valueTo);
  
    repeatIntegrationStore.getCandidatesForRepeat(userId)    

    handleClose();
  };

  return (
    <AnalyticTextMultiplyDateSelect
      title={`${repeatIntegrationStore.dateFrom?.format(
        "DD.MM.YYYY"
      )} - ${repeatIntegrationStore.dateTo?.format("DD.MM.YYYY")}`}
      anchorEl={anchorEl}
      onOpen={handleClick}
      onClose={handleClose}
      valueFirst={valueFrom}
      changeValueFirst={setValueFrom}
      valueSecon={valueTo}
      changeValueSecond={setValueTo}
      disabled={
        valueFrom && valueTo
          ? valueFrom?.format("YYYY.MM.DD") > valueTo?.format("YYYY.MM.DD")
          : false
      }
      onSubmit={submit}
      sx={{ flexWrap: { md: "nowrap", xs: "wrap" }, width: { xs: "310px", md: "100%" } }}
      contentDateSx={{ maxWidth: { xs: "288px", sm: "334px" } }}
      headerDateSx={{
        maxWidth: { xs: "288px", sm: "334px" },
        margin: { xs: 0, sm: "16px 0 8px 0" },
        pr: { xs: 0, sm: "12px" },
        pl: { xs: 2, sm: "24px" },
      }}
      submitBtnSx={{ right: { xs: "16px", sm: 0 } }}
    />
  );
});
