import {FC} from 'react'
import { IContractDateInput } from './IContractDateInput'
import { DateCustomInput } from '../../../../../../components/UI/DateCustomInput/DateCustomInput'
import dayjs, { Dayjs } from 'dayjs'

export const ContractDateInput:FC<IContractDateInput> = (props) => {
  const {
    field,
    onChange,
    value,
    disabled,
    placeholder = 'дд.мм.год',
    sx,
    type,
    id,
  } = props

  const changeDate = (value:Dayjs | null) => {
    if(value !== null && value?.isValid()){
      onChange(value?.format('YYYY-MM-DDT00:00:00')+'.000Z', field, type, id)
    }
  }

  return (
    <>
      <DateCustomInput 
        value={value ? dayjs(value) : null} 
        onChange={changeDate} 
        placeholder={placeholder}
        sx={{width:'100%', ...sx}}
        disabled={disabled}
      />
    </>
  )
}