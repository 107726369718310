import {FC, useState} from 'react'
import { IModalsRepeat } from './IModalsRepeat'
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton, Stack, TextField, Typography } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { getAllGoodsCategories } from '../../../../../../IntegrationTable/shared/dataList'
import categoriesGoodsStore from '../../../../../../../store/categoriesGoods.store'
import { observer } from 'mobx-react-lite'
import IntegrationsService from '../../../../../../../services/integration/integrations.service'
import { IIntegrationApproveRepeatRequest } from '../../../../../../../services/integration/IntegrationsRequest'
import { AxiosError } from 'axios'
import { Dayjs } from 'dayjs'
import { NotificationAlerts } from '../../../../../../NotificationAlerts/NotificationAlerts'
import repeatIntegrationStore from '../../../../../../../store/repeatIntegration.store'
import { IIntegrationsFullResponse } from '../../../../../../../services/integration/IIntegrationsResponse'
import integrationTableStore from '../../../../../../../store/integrationTable.store'
import 'dayjs/locale/ru'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { CustomIconButton } from '../../../../../../UI/Buttons/CustomIconButton/CustomIconButton'
import { CustomButton } from '../../../../../../UI/Buttons/CustomButton/CustomButton'

export const ModalsRepeat:FC<IModalsRepeat> = observer((props) => {
  const {id, onClose, open} = props

  const [activeStep, setActiveStep] = useState(0)

  const [maxIntegrationsValue, setMaxIntegrationsVValue] = useState<number | null>(null)

  const [value, setValue] = useState<string>('')

  const [category, setCategory] = useState('')

  const [date, setDate] = useState<Dayjs | null>(null)

  const [isLoadingApprove, setLoadingApprove] = useState(false)
  const [isErrorApprove, setErrorApprove] = useState<string | null>(null)

  const [addedIntegrations, setAddedIntegrations] = useState<null | IIntegrationsFullResponse[]>(null)

  const [isShowFinalyCard, setShowFinalyCard] = useState(false)

  const confirmMaxIntegrations = () => {
    setMaxIntegrationsVValue(+value)

    setActiveStep(1)
  }

  const nextStep = (isLastStep?:boolean) => {

    if(date && category !== ''){
      setLoadingApprove(true)
      setErrorApprove(null)

      const options: IIntegrationApproveRepeatRequest = {
        goodsCategory:category,
        integration_date:date?.format('YYYY-MM-DDT00:00:00') + '.000Z',
        integrationId:id
      }
      IntegrationsService.integrationApproveRepeat(options)
        .then(res=>{
          // console.log(res,'res aprove')
          integrationTableStore.addItem(res.data)

          setAddedIntegrations(prev=>{
            if(prev){
              return [...prev, res.data]
            } else return [res.data]
          })

          if(isLastStep){
            setShowFinalyCard(true)
          } else {
            setActiveStep(prev=>prev+1)
          }

          setDate(null)
          setCategory('')

          setLoadingApprove(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError) {
            // console.log(err, 'err aprove')

            setErrorApprove(err?.response?.data?.message)
            setLoadingApprove(false)
          }
        })
    }
  }

  const closeModal = () => {
    onClose()
    repeatIntegrationStore.removeIntegrations(id)
  }
 
  const handleChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string)
  }
  
  return (
    <Dialog
      open={open}
      onClose={isLoadingApprove ? ()=>'' : isShowFinalyCard ? closeModal : onClose}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle
        sx={{
          display:'flex', 
          alignItems:'center', 
          justifyContent:'space-between', 
          p:{xs:1, sm:2}, 
          pr:1, 
          fontSize:{xs:'14px', sm:'1.07rem'}
        }}
      >
        Повтор интеграции №{id}
        <CustomIconButton 
          disabled={isLoadingApprove} 
          onClick={isLoadingApprove ? ()=>'' : isShowFinalyCard ? closeModal : onClose}
        >
          <Cancel/>
        </CustomIconButton>
      </DialogTitle>
      <Divider/>
      <DialogContent sx={{p:{xs:1, sm:2},}}>
        {activeStep === 0 &&
          <Stack spacing={1}>
            <Typography>Кол-во интеграций на повтор:</Typography>
            <TextField
              value={value}
              onChange={e=>setValue(e.target.value?.trim())}
              size='small' 
              autoFocus
              type='number'
              fullWidth
              placeholder='Введите кол-во интеграций'
            />
          </Stack>
        }
        {maxIntegrationsValue && !isShowFinalyCard &&
          <Stack spacing={2}>
            <Typography>
              Параметры интеграции <Typography component={'span'} fontWeight='bold'>{`${activeStep} / ${maxIntegrationsValue}`}</Typography>
            </Typography>
            {categoriesGoodsStore.categories
              ? <FormControl fullWidth>
                  <InputLabel id="simple-select" size='small'>Категория товаров</InputLabel>
                  <Select
                    size={'small'}
                    labelId="simple-select"
                    value={category}
                    label="Категория товаров"
                    onChange={handleChange}
                    disabled={isLoadingApprove}
                  >
                    {getAllGoodsCategories(categoriesGoodsStore.categories).map(item=>
                      <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              : <Skeleton variant='rounded' sx={{height:40, width:'100%'}}/>
            }
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
              <DatePicker
                label={'Запланированная дата'}
                value={date}
                onChange={setDate}
                slotProps={{
                  textField:{
                    size:'small',
                    placeholder:'дд.мм.год'
                  }
                }}
                sx={{width: '100%'}}
                disabled={isLoadingApprove}
              />
            </LocalizationProvider>
          </Stack>
        }
        {isShowFinalyCard && addedIntegrations &&
          <Stack>
            <Typography variant='h6'>Список созданных интеграций</Typography>
            {addedIntegrations.map(item=><Typography key={item.id}>Интеграция с ID: {item.id}</Typography>)}
          </Stack>
        }
      </DialogContent>
      <Divider/>
      <DialogActions sx={{p:{xs:1, sm:2},}}>
        {activeStep === 0 &&
          <CustomButton 
            variant='outlined' 
            disabled={value?.trim() === '' || +value?.trim() <= 0}
            onClick={confirmMaxIntegrations}
          >
            Далее
          </CustomButton>
        }
        {activeStep !== 0 && activeStep !== maxIntegrationsValue &&
          <>
            <CustomButton 
              variant='contained'
              onClick={()=>nextStep()}
              disabled={isLoadingApprove || ((date === null || date?.isValid() === false) || category === '')}
              endIcon={isLoadingApprove && <CircularProgress sx={{maxHeight:20, maxWidth:20}}/>}
            >
              создать
            </CustomButton>
          </>
        }
        {activeStep === maxIntegrationsValue && !isShowFinalyCard &&
          <CustomButton 
            variant='contained' 
            disabled={isLoadingApprove || ((date === null || date?.isValid() === false) || category === '')}
            endIcon={isLoadingApprove && <CircularProgress sx={{maxHeight:20, maxWidth:20}}/>}
            onClick={()=>nextStep(true)}
          >
            создать
          </CustomButton>
        }
        {isShowFinalyCard &&
          <CustomButton variant='contained' onClick={closeModal}>
            Готово
          </CustomButton>
        }
      </DialogActions>
      <NotificationAlerts
        error={{text:isErrorApprove ? isErrorApprove : '', onClose:()=>setErrorApprove(null), open:Boolean(isErrorApprove)}}
      />
    </Dialog>
  )
})