import {FC} from 'react'
import { IAsideCardLayout } from './IAsideCardLayout'
import { Paper } from '@mui/material'
import { AsideCardHeader } from '../AsideCardHeader/AsideCardHeader'

export const AsideCardLayout:FC<IAsideCardLayout> = (props) => {
  const {
    headerIcon, 
    headerTitle, 
    children, 
    disable = false, 
    rightContent, 
    fullHeight = false,
    headerSx,
    sx,
    id,
    requiredTitle = false,
    isErrorTitle = false,
    headerContent,
    headetTextSx,
    showDivider = true
  } = props

  return (
    <Paper
      id={id}
      elevation={0} 
      sx={{
        border:'none',
        boxShadow:!disable ? '0px 0px 8px 0px rgba(34, 60, 80, 0.2)' : 'none',
        p:2,
        backgroundColor: disable ? 'rgba(0, 0, 0, 0.05)' : '#fff',
        transition: 'all ease .3s',
        height: fullHeight ? '100%' : 'auto',
        ...sx
      }}
    >
      {headerTitle && 
        <AsideCardHeader 
          title={headerTitle} 
          rightContent={rightContent} 
          sx={headerSx}
          requiredTitle={requiredTitle}
          isErrorTitle={isErrorTitle}
          headerContent={headerContent}
          headerIcon={headerIcon}
          headetTextSx={headetTextSx}
          showDivider={showDivider}
        />
      }
      {children}
    </Paper>
  )
}