import {FC, useState, memo} from 'react'
import { FIlterFetchSelectChip } from '../../UI/FIlterFetchSelectChip/FIlterFetchSelectChip'
import ReferencesService from '../../../../services/references/references.service'
import { AxiosError } from 'axios'
import { IFilterSelectItem } from '../../UI/FilterSelectChip/IFilterSelectChip'
import { useSearchParams } from 'react-router-dom'
import { IFilterSeacrhChannel } from './IFilterSeacrhChannel'
import {createFilterOptions} from "@mui/material";

export const FilterSeacrhChannel:FC<IFilterSeacrhChannel> = memo((props) => {
  const {searchParamsName, limit} = props

  const [searchParams, setSearchParams] = useSearchParams()

  const queryValue:IFilterSelectItem[] = JSON.parse(searchParams.get(searchParamsName) || '[]')

  const [channels, setChannels] = useState<IFilterSelectItem[]>([])
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<null | string>(null)

  const search = (value:string) =>{
    setLoading(true)
    setError(null)

    const option:{ target: string | number} = {
      target:value
    }
    ReferencesService.getHintsChannelName(option)
      .then(res=>{
        // console.log(res, 'res seact ', searchParamsName)

        const items = res.data.map(x=>{
          const item:IFilterSelectItem = {
            name:x.name,
            value:x.id,
            iconUrl:x.url
          }
          return item
        })
        
        setChannels(prev=>items)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err search ', searchParamsName)
          setError(err.response?.data?.message)
          setLoading(false)
        }
      })
  }

  const changeUrlValue = (items:IFilterSelectItem[]) => {
    if (items.length === 0 ){
      searchParams.delete(searchParamsName)
      setSearchParams(searchParams)
    } else {
      searchParams.set(searchParamsName, JSON.stringify(items))
      setSearchParams(searchParams)
    }
  }

  const resetErr = () => setError(null)
  
  return (
    <FIlterFetchSelectChip
      label="Каналы"
      content={channels}
      searchParamName={searchParamsName}
      placeholder="Введите название канала или ID"
      loading={isLoading}
      onSearch={search}
      error={isError ? true : false}
      onResetError={resetErr}
      initValue={queryValue}
      changeUrlValue={changeUrlValue}
      limitTags={limit}
      filterOptions={createFilterOptions({ trim: true })}
    />
  )
})