import {ITemplate} from "../../../../../../services/payments/IPaymentsResponse";
import {ServiceApplicationForm} from "../ServiceApplicationForm/ServiceApplicationForm";
import {RequestActionsPanel} from "../../../../../../components/RequestActionsPanel/RequestActionsPanel";
import {formatDate} from "../../../../../../shared/functions/functions";
import {useTemplateProcessor} from "../../../../../../hooks/useTemplateProcessor";


interface Props {
  template:ITemplate
  text:string
  titleModal:string
}

export const TemplateProcessor = ({template, text , titleModal}: Props) => {

  const {
    templateText,
    replaceTemplateVariables,
    clearFormData,
    formData,
    handleChangeDate,
    handleChange
  }
    = useTemplateProcessor(template)

  const formatTemplateText = ()=>{
    return  replaceTemplateVariables(templateText, {
      ...formData,
      b: formatDate(formData.b as string),
      d: formatDate(formData.d as string),
      f: formatDate(formData.f as string),
    });
  }

  const copyText= formatTemplateText()

  return (
    <>
      <RequestActionsPanel subContent={<ServiceApplicationForm formData={formData} handleChange={handleChange} handleChangeDate={handleChangeDate}/>}
                           text={text}
                           copyText={copyText}
                           clearData={clearFormData}
                           titleModal={titleModal}
      />
    </>
  )
}