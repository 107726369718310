import {FC, useState} from 'react'
import { IAgreementsTable } from './IAgreementsTable'
import { IStrategyField } from '../../strategy/interface/IStrategyField'
import { TableDefault } from '../Table/TableDefault'
import { TableItem } from '../Table/ui/TableItem'
import { getAgreementsTableItem } from './shared/dataList'

export const AgreementsTable:FC<IAgreementsTable> = (props) => {
  const {
    userApprovments, 
    agreementId, 
    userId, 
    readOnly, 
    callback
  } = props

  const [tableCell] = useState<IStrategyField[]>([
    {title:'Кто согласует', code:'name', immutable:false, isSimple:true,},
    {title:'Комментарий', code:'comment', immutable:false, isSimple:false, isWritting:true},
    {title:'Результат', code:'approvedStatus', immutable:false, isSimple:false, isAgreementStatus:true},
    {title:'Дата согласования', code:'decisionDate', immutable:false, isSimple:false, isDate:true},
  ])

  return (
    <TableDefault
      tableRow={tableCell} 
      showBorderRadius={false} 
      maxWidth='100%' 
      widthCell='33%'
      stickyHeader={false}
      talbeHeaderColor='#fff'
    >
      {getAgreementsTableItem(userApprovments, agreementId).map(item=>
        <TableItem
          key={item.name} 
          cells={tableCell} 
          {...item} 
          agreementStatusReadOnly={userId !== item.userId || readOnly} 
          agreementStatusCallback={callback}
        />)
      }
    </TableDefault>
  )
}
