import { FC } from 'react'
import { DateCustomInput } from '../../../../../../components/UI/DateCustomInput/DateCustomInput'
import dayjs, { Dayjs } from 'dayjs'
import { IFinancesDateInput } from './IFinancesDateInput'
import { Typography } from '@mui/material'

export const FinancesDateInput:FC<IFinancesDateInput> = (props) => {
  const {
    onChange,
    value,
    field,
    id,
    isEditMode,
    error,
    errorText
  } = props

  const changeDate = (value:Dayjs | null) => {
    if(value !== null && value?.isValid()){
      onChange(id, field, value?.format('YYYY-MM-DDT00:00:00')+'.000Z')
    }
  }

  return (
    <>
      {isEditMode
        ? <DateCustomInput
            errorText={errorText}
            error={error}
            value={value ? dayjs(value) : null} 
            onChange={changeDate} 
            placeholder='дд.мм.год'
            sx={{width:'100%'}}
          />
        : <Typography>{dayjs(value)?.format('DD.MM.YYYY')}</Typography>
      }
    </>
  )
}