import {FC} from "react";
import {IContrActorChannel} from "./IContrActorChannel";
import {Avatar, Box, Typography} from "@mui/material";

const ContrActorChannel: FC<IContrActorChannel> = (props) => {
    const {channel} = props
    return (
        <Box sx={{display: 'flex'}}>
            <Typography>{channel.name}</Typography>
            <Avatar sx={{maxHeight:25, maxWidth:25, ml:1}} src={channel?.type?.icon}/>
        </Box>
    );
};

export default ContrActorChannel;