import {FC, useState} from 'react'
import {IPaymentOnCard} from './IPaymentOnCard'
import {Checkbox, Stack, Tooltip, Typography} from '@mui/material'
import {CreditScore} from '@mui/icons-material'
import {IUpdateStatusesRequest} from '../../../../../../services/integration/IntegrationsRequest'
import {observer} from "mobx-react-lite";

export const PaymentOnCard: FC<IPaymentOnCard> = observer((props) => {
  const {
    isPaymentOnCard,
    id,
    update_id,
    readOnly,
    onChangeUpdateId,
    setPaymentOnCard,
    changeField,
    service
  } = props

  const [isLoading, setLoading] = useState(false)

  const changeCheckbox = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true)

    const option: IUpdateStatusesRequest = {
      id,
      update_id,
      field: 'isPaymentOnCard',
      new_value: e.target.checked as any
    }

    try {
      const res = await service.updateSimpleField(option)
      setPaymentOnCard(res.data.isPaymentOnCard)
      changeField({
        id,
        update_id: res.data.update_id,
        field: 'isPaymentOnCard',
        new_value: res.data.isPaymentOnCard as any
      })
      onChangeUpdateId(res.data.update_id)
    }
    catch (e) {
      console.log(e)
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Checkbox checked={isPaymentOnCard} onChange={changeCheckbox} disabled={isLoading || readOnly}/>
      <Tooltip title={<Typography>Оплата канала на карту</Typography>}>
        <CreditScore color={isPaymentOnCard ? 'primary' : 'disabled'}/>
      </Tooltip>
    </Stack>
  )
})
