import { useState, useEffect, FC } from "react";
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout";
import ReferencesService from "../../../../services/references/references.service";
import {
  IReferencesBudgetParams,
  IReferencesPlanGetRequest,
} from "../../../../services/references/IReferencesRequest";
import { observer } from "mobx-react-lite";
import dashboardStore from "../../../../store/dashboard.store";
import { AxiosError } from "axios";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useSearchParams } from "react-router-dom";
import userStore from "../../../../store/user.store";
import { toJS } from "mobx";
import { IPlanPage } from "../../../../pages/DashboardPage/pages/PlanPage/IPlanPage";
import { RadioFilter } from "../../../../pages/DashboardPage/pages/PlanPage/ui/RadioFilter/RadioFilter";
import { SearchMontDatePicker } from "../../../../pages/DashboardPage/pages/MotivationPage/ui/SearchMontDatePicker/SearchMontDatePicker";
import { UserPlanTable } from "./ui/UserPlanTable/UserPlanTable";
import { UserBudgetTable } from "./ui/UserBudgetTable/UserBudgetTable";
import { CustomButton } from "../../../UI/Buttons/CustomButton/CustomButton";

export const UserPlanPage: FC<IPlanPage> = observer((props) => {
  const { isLk = true } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const [showContent, setShowContent] = useState<1 | 2>(
    !!userStore.user?.rights.filter((item) => toJS(item.code) === "search")[0] ? 2 : 1
  );

  const [dateMonth, setDateMonth] = useState<number>(
    searchParams.get("date") ? +searchParams.get("date")?.split("-")[1]! - 1 : new Date().getMonth()
  );
  const [date, setDate] = useState<Dayjs | null>(
    searchParams.get("date") ? dayjs(searchParams.get("date")) : dayjs(new Date())
  );

  const [isLoadingBudget, setLoadingBudget] = useState(false);
  const [isLoadingPlan, setLoadingPlan] = useState(false);

  const getAllPlans = (params: IReferencesPlanGetRequest) => {
    setLoadingPlan(true);

    ReferencesService.planGet(params)
      .then((res) => {
        // console.log(res.data, 'res plan get')
        dashboardStore.setPlanAll(res.data);
        setLoadingPlan(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err plan get')
          setLoadingPlan(false);
        }
      });
  };

  const getAllBudget = (params: IReferencesBudgetParams) => {
    setLoadingBudget(true);

    ReferencesService.budgetGet(params)
      .then((res) => {
        dashboardStore.setBudgetAll(res.data);
        setLoadingBudget(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          setLoadingBudget(false);
        }
      });
  };

  const planMonth = () => {
    if (date) {
      getAllPlans({ month: date.month() + 1, year: date.year() });
      setDateMonth(date.month());

      searchParams.set("date", `${date.year()}-${date.month() + 1}`);
      setSearchParams(searchParams);
    } else if (date === null) {
      searchParams.delete("date");
      setSearchParams(searchParams);
    }
  };

  const budgetMonth = () => {
    if (date) {
      getAllBudget({ month: date.month() + 1, year: date.year() });
      setDateMonth(date.month());

      searchParams.set("date", `${date.year()}-${date.month() + 1}`);
      setSearchParams(searchParams);
    } else if (date === null) {
      searchParams.delete("date");
      setSearchParams(searchParams);
    }
  };

  const changeRadio = (type: "release" | "search") => {
    if (type === "release") {
      setShowContent((prev) => 1);
      budgetMonth();
    } else {
      setShowContent((prev) => 2);
      planMonth();
    }
  };

  useEffect(() => {
    if (showContent === 1) {
      setLoadingBudget(true);
      getAllBudget(
        date
          ? { month: date.month() + 1, year: date.year() }
          : { month: new Date().getMonth() + 1, year: new Date().getFullYear() }
      );
      setLoadingBudget(false);
    } else {
      setLoadingPlan(true);
      getAllPlans(
        date
          ? { month: date.month() + 1, year: date.year() }
          : { month: new Date().getMonth() + 1, year: new Date().getFullYear() }
      );
      setLoadingPlan(false);
    }
  }, []);

  return (
    <Box overflow={'hidden'}>
      <AsideCardLayout sx={{ overflow: "hidden", p: 2, marginTop: 2 }}>
        {(isLoadingBudget || isLoadingPlan) && (
          <Stack direction={"row"} justifyContent={"center"} spacing={1}>
            <Typography variant="h5">Идёт загрузка...</Typography>
            <CircularProgress sx={{ minHeight: 35, maxHeight: 35, minWidth: 35, maxWidth: 35 }} />
          </Stack>
        )}
        <Stack direction={"column"} spacing={2}>
          <Box
            sx={{
              pt: 2,
              width: { xs: "100%", md: "auto" },
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: 1,
            }}>
            {!!userStore.user?.rights.filter((item) => toJS(item.code) === "search")[0] &&
            !!userStore.user?.rights.filter((item) => toJS(item.code) === "release")[0] ? (
              <Stack>
                <RadioFilter
                  initValue={showContent === 1 ? "release" : "search"}
                  changeFilter={changeRadio}
                />
                <SearchMontDatePicker initValue={date} onChange={setDate} />
              </Stack>
            ) : (
              <Stack>
                <SearchMontDatePicker initValue={date} onChange={setDate} />
              </Stack>
            )}
            <Box sx={{ display: "flex", height: "36px" }}>
              <CustomButton
                variant="contained"
                sx={{ ml: "auto", width: { xs: "100%", md: "auto" } }}
                disabled={isNaN(date?.month()!) && isNaN(date?.year()!)}
                onClick={showContent === 1 ? budgetMonth : planMonth}>
                применить
              </CustomButton>
            </Box>
          </Box>
          {showContent === 1 && !isLoadingBudget && dashboardStore.budgetAll !== null && (
            <Stack>
              <Typography sx={{ mb: 0.5, typography: { xs: "body1", sm: "h6" } }}>
                Таблица расходов
              </Typography>
              <Box>
                <UserBudgetTable
                  budgetItems={dashboardStore.budgetAll.filter(
                    (x) => x.isGeneral === false && x.userId === userStore.user?.id
                  )}
                  noEdit={isLk}
                />
              </Box>
            </Stack>
          )}
          {showContent === 2 && dashboardStore.planAll !== null && !isLoadingPlan && (
            <Stack>
              <Typography sx={{ mb: 0.5, typography: { xs: "body1", sm: "h6" } }}>План</Typography>
              <Box>
                <UserPlanTable
                  list={dashboardStore.planAll?.filter(
                    (x) => x.isGeneral === false && x.userId === userStore.user?.id
                  )}
                  noEdit={isLk}
                />
              </Box>
            </Stack>
          )}
        </Stack>
      </AsideCardLayout>
    </Box>
  );
});
