import { FC, useState, useEffect } from "react";
import { ITzGoodsSelect } from "./ITzGoodsSelect";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import goodsStore from "../../../../../store/goods.store";
import { observer } from "mobx-react-lite";
import { ITzUpdateSimpleFieldRequest } from "../../../../../services/tz/ITzRequest";
import TzService from "../../../../../services/tz/tz.service";
import tzTableStore from "../../../../../store/tzTable.store";
import { AxiosError } from "axios";
import { Check, Close } from "@mui/icons-material";
import { EditIconButton } from "../../../../UI/Buttons/EditIconButton/EditIconButton";
import { CustomIconButton } from "../../../../UI/Buttons/CustomIconButton/CustomIconButton";
import { AutocompleteSelectCheckboxField } from "../../../../AutocompleteSelectCheckboxField/AutocompleteSelectCheckboxField";

export const TzGoodsSelect: FC<ITzGoodsSelect> = observer((props) => {
  const { field, id, initValue, update_id, isEditIcon } = props;

  const [isLoading, setLoading] = useState(false);

  const [isEdit, setEdit] = useState(false);

  const [selected, setSelected] = useState<string[]>([]);

  const closeEdit = () => {
    setSelected(initValue ? initValue.map((item) => item.article) : []);
    setEdit(false);
  };

  const submit = () => {
    setLoading(true);

    const option: ITzUpdateSimpleFieldRequest = {
      field,
      id,
      new_value: selected?.map((item) => {
        return { article: item };
      }) as any,
      update_id,
    };
    TzService.updateSimpleField(option)
      .then((res) => {
        tzTableStore.updateSimpleField(id, field, res.data[field], res.data.update_id);

        setLoading(false);
        setEdit(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    setSelected(initValue ? initValue.map((item) => item.article) : []);
  }, [initValue]);

  return (
    <>
      {initValue && !isEdit && (
        <Stack onClick={() => setEdit(true)} direction={"row"} alignItems={"center"}>
          <Box>
            {initValue.map((item) => (
              <Typography key={item.article} noWrap>
                {item.name}
              </Typography>
            ))}
          </Box>
          {isEditIcon && <EditIconButton size="small" onClick={() => setEdit(true)} />}
        </Stack>
      )}
      {(initValue === null || (initValue?.length === 0 && !isEdit)) && (
        <Typography onClick={() => setEdit(true)} color={"grey"}>
          Нет товаров
        </Typography>
      )}
      {isEdit && (
        <Stack direction={"row"} alignItems={"center"}>
          {goodsStore?.goods ? (
            <AutocompleteSelectCheckboxField
              onChange={setSelected}
              list={goodsStore.goods}
              loading={isLoading}
              isTzSelect
              fullWidth
              value={selected}
              placeholder="Выберите товар"
            />
          ) : (
            <Skeleton variant="rounded" sx={{ height: 40, width: 40 }} />
          )}
          <CustomIconButton size="small" disabled={isLoading} onClick={closeEdit}>
            <Close fontSize="small" />
          </CustomIconButton>
          <CustomIconButton
            size="small"
            disabled={isLoading}
            onClick={submit}
            showBackground
            sx={{ ml: 0.5 }}>
            <Check fontSize="small" />
          </CustomIconButton>
        </Stack>
      )}
    </>
  );
});
