import {FC} from 'react'
import { IGoodsProductLists } from './IGoodsProductLists'
import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { GoodSampleItemWrapper } from '../GoodSampleItemWrapper/GoodSampleItemWrapper'
import { getGoodsFilterItems } from '../../../../../../components/IntegrationTable/shared/dataList'
import goodsStore from '../../../../../../store/goods.store'
import { observer } from 'mobx-react-lite'
import { GoodListInputItem } from './ui/GoodListInputItem/GoodListInputItem'
import { GoodsSearchAutocomplete } from './ui/GoodsSearchAutocomplete/GoodsSearchAutocomplete'
import {EmptyText} from "../../../../../../components/EmptyText/EmptyText";
import {RemoveItemButton} from "../../../../../../components/UI/Buttons/RemoveItemButton/RemoveItemButton";

export const GoodsProductLists:FC<IGoodsProductLists> = observer((props) => {
  const {
    list, 
    showDeleteButton,
    onDeleteGood,
    onChangeGood,
    disabled,
    isEdit
  } = props
  
  return (
    <>
      {list?.length === 0 && !isEdit
        ? <Stack spacing={1}>
            <GoodSampleItemWrapper title='Образец товара'>
              <EmptyText>{'отсутствует'}</EmptyText>
            </GoodSampleItemWrapper>
            <GoodSampleItemWrapper title='Цвет образца товара'>
              <EmptyText>{'отсутствует'}</EmptyText>
            </GoodSampleItemWrapper>
          </Stack>
        : null
      }
      {list?.map(good=>
        <Stack spacing={1} key={good?.id}>
          <GoodSampleItemWrapper title='Образец товара'>
            <Box sx={{display:'flex', alignItems:'center'}}>
              {isEdit ?
                goodsStore?.goods
                  ? <GoodsSearchAutocomplete
                      content={getGoodsFilterItems(goodsStore?.goods)}
                      value={good?.good || null}
                      onChangeGood={onChangeGood}
                      placeholder='Выберите образец товара'
                      disabled={disabled}
                      id={good?.id}
                    />
                  : <Skeleton variant='rounded' sx={{minHeight:'35px', maxHeight:'35px', width:'100%'}}/>
                : <Typography>{goodsStore?.goods?.find(x=>x?.article === good?.good?.article)?.name || <EmptyText/>}</Typography>
              }
              {showDeleteButton && 
                <Box sx={{ml:1}}>
                  <RemoveItemButton onClick={()=>onDeleteGood(good?.id)} disabled={disabled}/>
                </Box>
              }
            </Box>
          </GoodSampleItemWrapper>
          <GoodSampleItemWrapper title='Цвет образца товара'>
            {isEdit ?
              <GoodListInputItem 
                id={good?.id} 
                onChangeGood={onChangeGood} 
                showDeleteButton={showDeleteButton} 
                disabled={disabled}
                defaultValue={good?.color ? good?.color : undefined}
              />
              : <Typography>{good?.color || <EmptyText/>}</Typography>
            }
          </GoodSampleItemWrapper>
        </Stack>
      )}
    </>
  )
})