export enum METRIC_GROUP_BY {
  HOUR = 'hour',
  HOURS = 'hours',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export interface IMetricsAllSourceRequest {
  startDate: string;
  endDate: string;
  containRobots: boolean;
}

export interface IMetricsOnSourceRequest {
  source: string;
  groupBy: METRIC_GROUP_BY;
  startDate: string;
  endDate: string;
  containRobots: boolean;
}