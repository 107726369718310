import {FC, useState} from 'react'
import { ICheckboxField } from './ICheckboxField'
import { Checkbox } from '@mui/material'
import integrationTableStore from '../../../../../store/integrationTable.store'
import IntegrationsService from '../../../../../services/integration/integrations.service'
import { IUpdateStatusesRequest } from '../../../../../services/integration/IntegrationsRequest'
import { AxiosError } from 'axios'

export const CheckboxField:FC<ICheckboxField> = (props) => {
  const {
    id,
    field,
    update_id,
    checked,
    readOnly
  } = props

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    setError(null)

    const value = event.target.checked

    const options:IUpdateStatusesRequest = {
      id,
      field,
      update_id,
      new_value:value as any
    }
    IntegrationsService.updateSimpleField(options)
      .then(res=>{

        integrationTableStore.updateSimpleField(id, res.data.update_id, field, value)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setError(err?.response?.data?.message)
          setLoading(false)
        }
      })
    
  }

  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      disabled={isLoading || readOnly}
    />
  )
}
