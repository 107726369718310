import { FC, useState} from 'react'
import { TableDefault } from '../../../../../../components/Table/TableDefault'
import { TableItem } from '../../../../../../components/Table/ui/TableItem'
import { IStrategyField } from '../../../../../../strategy/interface/IStrategyField'
import statusesStore from '../../../../../../store/statuses.store'
import { ISearchMotivationTable } from './ISearchMotivationTable'
import { Box, Stack, Typography } from '@mui/material'
import { priceTransormFormat } from '../../../../../../shared/functions/functions'
import AllUsersStore from '../../../../../../store/AllUsers.store'
import { observer } from 'mobx-react-lite'
import { ButtonExcelDownload } from './ui/ButtonExcelDownload/ButtonExcelDownload'

export const SearchMotivationTable:FC<ISearchMotivationTable> = observer((props) => {
  const {
    searchMotivation, 
    sxProps, 
    motivationSum, 
    userId,
    talbeHeaderColor,
    month,
    year
  } = props

  const [motivationTableCol] = useState<IStrategyField[]>([
    {code:'id', title:'ID канала', immutable:false, isSimple:true,},
    {code:'name', title:'Имя канала', immutable:false, isSimple:true, maxWidth:'200px'},
    {code:'type', title:'Тип канала', immutable:false, isSimple:false, isType:true},
    {code:'size', title:'Размер канала', immutable:false, isSimple:false, isSize:true},
    {code:'status', title:'Статус', immutable:false, isSimple:false, isStatuses:true},
    {code:'releaseBy', title:'Выпускающий менеджер', immutable:false, isSimple:false, isReleaseUsers:true},
    {code:'viewsPredict', title:'Прогноз просмотров', immutable:false, isSimple:false, isSimpleView:true},
    {code:'views', title:'Просмотры', immutable:false, isSimple:false, isSimpleView:true},
    {code:'minOrders', title:'Минимум заказов', immutable:false, isSimple:true},
    {code:'dateFound', title:'Был найден', immutable:false, isSimple:false, isDate:true},
    {code:'dateGiven', title:'Дата передачи', immutable:false, isSimple:false, isDate:true},
    {code:'motivation', title:'Мотивация', immutable:false, isSimple:false, isIntegrationPaidSumm:true},
  ])

  return (
    <>
      <TableDefault
        tableRow={motivationTableCol}
        showBorderRadius={false}
        maxWidth='100%'
        sx={sxProps}
        talbeHeaderColor={talbeHeaderColor}
      >
        {searchMotivation?.channels?.map(item=>
          <TableItem
            key={item.id}
            cells={motivationTableCol}
            statusStore={statusesStore?.channel}
            isStatusReadOnly
            hover
            {...item}
          />)
        }
      </TableDefault>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{mt:2}}>
        <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
          <Typography variant='h5' sx={{fontSize:{xs:'16px', md:'1.28rem'}}}>
            Мотивация менеджера {AllUsersStore?.users?.find(user=>user.id === userId)?.firstName} составила:
          </Typography>
          {motivationSum !== null ?
            <Typography variant='h5' sx={{fontWeight:'bold', fontSize:{xs:'16px', md:'1.28rem'}}}>
              {priceTransormFormat(motivationSum)}
            </Typography>
            : null
          }
        </Stack>
        <ButtonExcelDownload month={month} userId={userId} year={year}/>
      </Stack>
    </>
  )
})