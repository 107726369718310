import { FC, useState } from 'react'
import { IPromokodAdd } from './models/IPromokodAdd'
import { Stack, TextField, Typography } from '@mui/material'
import { Check, Clear } from '@mui/icons-material'
import IntegrationsService from '../../services/integration/integrations.service'
import { IInsertPromokodRequest } from '../../services/integration/IntegrationsRequest'
import { AxiosError } from 'axios'
import integrationTableStore from '../../store/integrationTable.store'
import { NotificationAlerts } from '../NotificationAlerts/NotificationAlerts'
import { EditIconButton } from '../UI/Buttons/EditIconButton/EditIconButton'
import { CustomIconButton } from '../UI/Buttons/CustomIconButton/CustomIconButton'

export const PromokodAdd:FC<IPromokodAdd> = (props) => {
  const {
    title,
    subTitle,
    placeholder,
    onChangeValue,
    id,
    readOnly = false,
    onChangeSource,
    onChangeUpdateId,
    setLink
  } = props
    
  const [value, setValue] = useState('')
  const [isEdit, setEdit] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<null | string>(null)

  const closeEditMode = () => {
    setValue('')
    setEdit(false)
  }

  const submit = () => {
    setLoading(true)
    setError(null)

    const option:IInsertPromokodRequest = {
      id,
      promokod:value.replace(/\s/g, '').toUpperCase(),
      syncWithSource:true
    }
    IntegrationsService.insertPromokod(option)
      .then(res=>{
        // console.log(res, 'add res from integra card')
        onChangeValue({code:res.data.promokod?.code, dummies:res.data.promokod?.dummies, update_id:res.data.update_id, isActivated:res.data.promokod?.isActivated})
        onChangeUpdateId(res.data.update_id)
        
        integrationTableStore.insertPromokod({
          id:id,
          promokodCode: res.data.promokod?.code,
          dummyArray: res.data.promokod?.dummies,
          update_id: res.data.update_id,
          newSource: res.data.source,
          isActivated:res.data.promokod?.isActivated
        })

        onChangeSource(res.data.source)

        setLink(res.data?.link)

        closeEditMode()
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err add prom iuntegr card')
          setError(err?.response?.data?.message)
          setLoading(false)
        }
      })
  }

  const onKeyDownSend = (e:React.KeyboardEvent) => {
    if(e.code === "Enter" || e.code === "NumpadEnter"){
      submit()
    }
  }

  return (
    <>
      <Stack>
        <Stack direction={'row'} alignItems={'center'}>
          <Typography>{title}</Typography>
          {!isEdit && subTitle && <Typography sx={{ml:1}} noWrap>{subTitle}</Typography>}
          {!isEdit && !readOnly &&
            <EditIconButton size='small' onClick={()=>setEdit(true)}/>
          }
        </Stack>
        {isEdit &&
          <Stack direction={'row'} alignItems={'center'} sx={{mt:1}}>
            <TextField
              value={value?.toUpperCase()}
              onChange={e=>setValue(e.target.value.replace(/\s/g, ''))}
              size='small'
              fullWidth
              placeholder={placeholder}
              disabled={isLoading}
              onKeyDown={onKeyDownSend}
              autoFocus
              error={isError ? true : false}
            />
            <Stack direction={'row'} alignItems={'center'}>
              <CustomIconButton
                disabled={isLoading}
                size='small'
                onClick={closeEditMode}
              >
                <Clear fontSize='small'/>
              </CustomIconButton>
              <CustomIconButton disabled={value.trim()==='' || isLoading} size='small' onClick={submit} showBackground>
                <Check fontSize='small'/>
              </CustomIconButton>
            </Stack>
          </Stack>
        }
      </Stack>
      <NotificationAlerts
        error={{text:isError ? isError : '', open:isError ? true : false, onClose:()=>setError(null)}}
      />
    </>
  )
}
