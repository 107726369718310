import { makeAutoObservable } from "mobx"
import { ILayoutResponse } from "../services/layout/layoutResponse"

class ChannelStore {
  channels:ILayoutResponse['channelTypes'] | null = null

  constructor(){
    makeAutoObservable(this)
  }

  setChannels(channels:ILayoutResponse['channelTypes']) {
    this.channels = channels
  }

}

export default new ChannelStore()