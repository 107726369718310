import {FC, useState} from 'react'
import { IVideoReview } from './IVideoReview'
import { Checkbox, Stack } from '@mui/material'
import { VideoCameraFrontOutlined } from '@mui/icons-material'
import IntegrationsService from '../../../../../../services/integration/integrations.service'
import { IUpdateStatusesRequest } from '../../../../../../services/integration/IntegrationsRequest'
import { AxiosError } from 'axios'
import integrationPageStore from '../../../../../../store/integrationPage.store'
import integrationTableStore from '../../../../../../store/integrationTable.store'

export const VideoReview:FC<IVideoReview> = (props) => {
  const {
    checked,
    id,
    setUpdateId,
    update_id,
    readOnly
  } = props

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<null | string>(null)

  const changeChecked = (value:boolean) => {
    setLoading(true)
    setError(null)

    const options: IUpdateStatusesRequest = {
      id,
      new_value:value as any,
      field:'isVideoReview',
      update_id,
    }
    IntegrationsService.updateSimpleField(options)
      .then(res=>{
        integrationPageStore.setVideoReview(res.data.isVideoReview)
        integrationTableStore.changeField({id, update_id:res.data.update_id, field:'isVideoReview', new_value:res.data.isVideoReview as any})
        setUpdateId(res.data.update_id)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setError(err?.response?.data?.message)
        }
      })
      .finally(()=>{
        setLoading(false)
      })
  }

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
      <Checkbox 
        defaultChecked={checked}
        onChange={(e)=>changeChecked(e.target.checked)}
        disabled={readOnly || isLoading}
      />
      <VideoCameraFrontOutlined color={readOnly || !checked ? 'disabled' : 'primary'} fontSize='large'/>
    </Stack>
  )
}
