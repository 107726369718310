import {FC} from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { IPageNotFound } from './IPageNotFound'

export const PageNotFound:FC<IPageNotFound> = (props) => {
  const {height} = props

  return (
    <Box sx={{height:height ? height : '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
      <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
        <Typography variant='h4' textAlign={'center'}>Ошибка 404. Страница не найдена</Typography>
        <Typography variant='h5' color={'grey'} textAlign={'center'}>Страница может больше не существовать или быть перемещена на другой веб адрес.</Typography>
      </Stack>
    </Box>
  )
}