import {AxiosProgressEvent, AxiosResponse} from "axios";
import $gateway_api from "../../shared/api/gatewayAxios";
import {IFiltersUpadteRequest, IIntegrationsRequest} from "../integration/IntegrationsRequest";
import {ISettingsSelectedGroup} from "../../components/IntegrationTable/models/ISettingsSelectedGroup";
import {IIntegrationsResponse} from "../integration/IIntegrationsResponse";

export default class PrepaymentsService {

  static prepaymentFiltered(option:IIntegrationsRequest): Promise<AxiosResponse<IIntegrationsResponse>>{
    return $gateway_api.post('/api/prepayments/filtered', option)
  }
  static prePaymentSettingsUpdate(option:IFiltersUpadteRequest):Promise<AxiosResponse<ISettingsSelectedGroup[]>>{
    return $gateway_api.post('/api/common/filter-settings-update', option)
  }
  static prePaymentFilteredExcel(option:IIntegrationsRequest, onDownloadProgress?:((progressEvent: AxiosProgressEvent)=>void)):Promise<AxiosResponse<Blob>>{
    return $gateway_api.post('/api/prepayments/filtered/excel', option, {
      responseType:'blob',
      onDownloadProgress
    })
  }
}