import { FC, useState } from 'react'
import { IMotivationInputRoi } from './IMotivationInputRoi'
import { MotivationEditField } from '../MotivationEditField/MotivationEditField'
import { IMotivationRoiEditRequest } from '../../../../../../../../services/motivation/IMotivationRequest'
import MotivationService from '../../../../../../../../services/motivation/motivation.service'
import dashboardStore from '../../../../../../../../store/dashboard.store'
import { AxiosError } from 'axios'
import { IMotivationThreshold } from '../../../../../../../../services/motivation/IMotivationResponse'

export const MotivationInputRoi:FC<IMotivationInputRoi> = (props) => {
  const {
    initValue,
    id,
    firstIntegrationWithChannel,
    cellId,
    propertyType = 'threshold',
    readOnly = false
  } = props

  const [isEdit, setEdit] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const onSubmit = (value:string) => {
    setLoading(true)
    setError(null)

    const option: IMotivationRoiEditRequest = {
      id:cellId,
      propertyType:propertyType,
      value:+value,
    }
    MotivationService.edit(option)
      .then(res=>{
        // console.log(res, 'res create Coef')
        if(propertyType !== 'thresholdBigChannel'){
          dashboardStore.setEditReleaseRoiThreshold(id, cellId ,res.data as IMotivationThreshold)
        } else {
          dashboardStore.setEditReleaseRoiThresholdBigChannel(id, res.data.value)
        }

        setEdit(false)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err create Coef')
          setError(err?.response?.data?.message)
          setLoading(false)
        }
      })
  }

  return (
    <MotivationEditField
      initValue={initValue}
      isEdit={isEdit}
      setEdit={setEdit}
      isError={isError}
      isLoading={isLoading}
      onSubmit={onSubmit}
      readOnly={readOnly}
      sxProps={{maxWidth:'80px'}}
    />
  )
}