import { FC } from 'react'
import { IAddBudgetTable } from './IAddBudgetTable'
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { AddBudgetInput } from './ui/AddBudgetInput/AddBudgetInput'
import { priceTransormFormat } from '../../../../../../shared/functions/functions'

export const AddBudgetTable:FC<IAddBudgetTable> = observer((props) => {
  const {
    month,
    year,
    budget,
    isEdit
  } = props

  const monthNames:string[] = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]
  
  return (
    <Box 
      sx={{
        display:'grid', 
        gridAutoRows: 'min-content', 
        width:{xl: isEdit ? '70%' : '50%', lg:'75%', md:'65%', xs:'100%'}
      }}
    >
      <Typography sx={{mb:0.5, typography: {xs:'body1', sm:'h6'}}}>
        Бюджет на {monthNames[month]}
      </Typography>
      <TableContainer
        component={Paper} 
        elevation={0}
        sx={{
          border:'1px solid #e0e0e0',
          borderBottom:'none', 
          borderRadius:0,
          borderLeft:'none',
          height:'min-content',
        }}
      >
        <Table
          stickyHeader
          sx={{
            "& .MuiTableCell-root": {borderLeft: '1px solid #e0e0e0'},
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{p:1, width:120, background:' #f2f3f5'}}>
                <Typography variant="body1" fontWeight={'bold'} whiteSpace='nowrap'>Тип канала</Typography>
              </TableCell>
              <TableCell align="center" sx={{p:1, width:120, background:' #f2f3f5'}}>
                <Typography variant="body1" fontWeight={'bold'} whiteSpace='nowrap'>В графике</Typography>
              </TableCell>
              <TableCell align="center" sx={{p:1, minWidth:250, background:' #f2f3f5'}}>
                <Typography variant="body1" fontWeight={'bold'}>План</Typography>
              </TableCell>
              <TableCell align="center" sx={{p:1, background:' #f2f3f5'}}>
                <Typography variant="body1" fontWeight={'bold'}>Потрачено</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{p:1}}>
                <Typography>Старые</Typography>
              </TableCell>
              <TableCell sx={{p:1}}>
                <Typography>{priceTransormFormat(budget?.inScheduleOld)}</Typography>
              </TableCell>
              <TableCell sx={{p:1}}>
                <AddBudgetInput value={budget?.amountOld}/>
              </TableCell>
              <TableCell sx={{p:1}}>
                <Typography>{priceTransormFormat(budget?.wastedOld)}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{p:1}}>
                <Typography>Новые</Typography>
              </TableCell>
              <TableCell sx={{p:1}}>
                <Typography>{priceTransormFormat(budget?.inScheduleNew)}</Typography>
              </TableCell>
              <TableCell sx={{p:1}}>
                <AddBudgetInput
                  value={budget?.amountNew}
                />
              </TableCell>
              <TableCell sx={{p:1}}>
                <Typography>{priceTransormFormat(budget?.wastedNew)}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{p:1}}>
                <Typography>Итого</Typography>
              </TableCell>
              <TableCell sx={{p:1}}>
                <Typography>{priceTransormFormat(budget?.inScheduleAll)}</Typography>
              </TableCell>
              <TableCell sx={{p:1}}>
                <AddBudgetInput
                  value={budget?.amountAll}
                />
              </TableCell>
              <TableCell sx={{p:1}}>
                <Typography>{priceTransormFormat(budget?.wastedAll)}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
})