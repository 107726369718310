import {FC} from 'react'
import { IconButton } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ICustomIconButton } from './ICustomIconButton';
import { theme } from '../../../../app/theme'
import { purple_color, purple_light_color, purple_middle_color, red_color, red_light_color, red_middle_color } from '../../../../app/styles/variables';

export const CustomIconButton:FC<ICustomIconButton> = (props) => {
  const {
    children,
    showBackground = false,
    ...restProps
  } = props

  const iconTheme = createTheme({
    components: {
      MuiIconButton:{
        variants:[
          {
            props:{
              color:'primary',
            },
            style:{
              color:!showBackground ? 'rgba(0, 0, 0, 0.54)' : '#fff',
              '&:hover':{
                color:!showBackground ? purple_middle_color : '#fff',
                background:!showBackground ? purple_light_color : purple_middle_color
              },
              '&:active':{
                color:!showBackground ? purple_color : '#fff',
                background:!showBackground ? purple_light_color : purple_color
              },
              background:!showBackground ? undefined : theme.palette.primary.main,
            }
          },
          {
            props:{
              color:'error',
            },
            style:{
              color:red_color,
              backgroundColor:red_light_color,
              
              '&:hover':{
                backgroundColor:red_middle_color
              }
            }
          }
        ],
        defaultProps:{
          color:'primary',
          size:'small',
        }
      },
    },
    typography:theme.typography
  })

  return (
    <ThemeProvider theme={iconTheme}>
      <IconButton {...restProps}>
        {children}
      </IconButton>
    </ThemeProvider>
  )
}
