import {IconButton} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {IRemoveItemButton} from "./IRemoveItemButton";
import { red_color, red_light_color, red_middle_color } from "../../../../app/styles/variables";

export const RemoveItemButton = (props:IRemoveItemButton) => {
  const {Icon, isDisableColor, sx, ...rest} = props

  const IconToShow = Icon || Delete

  return (
      <IconButton  
        {...rest}  
        sx={{
          '&:hover': {
            backgroundColor: red_light_color,
            color: red_color,
          },
          '&:active': {
            backgroundColor: red_middle_color,
            color:red_color
          },
          ...sx
        }} size='small'
      >
        <IconToShow {...(isDisableColor && { color: 'disabled' })} fontSize="small" />
      </IconButton>
  );
};