import { ArrowOutward, Autorenew, Check } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout";
import { EmptyText } from "../../../../components/EmptyText/EmptyText";
import { AddedButton } from "../../../../components/UI/Buttons/AddedButton/AddedButton";
import {
  GOODS_CREATE_PAGE_OPEN,
  INTEGRATION_CARD_OPEN,
  PRODUCT_CARD_OPEN,
} from "../../../../shared/constants/searchParams";
import integrationPageStore from "../../../../store/integrationPage.store";
import productSampleCreateStore from "../../../../store/productSampleCreate.store";
import productSamplePageStore from "../../../../store/productSamplePage.store";
import userStore from "../../../../store/user.store";
import { IAsideCardSentSamples } from "./IAsideCardSentSamples";

export const AsideCardSentSamples: FC<IAsideCardSentSamples> = (props) => {
  const { integration } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOpenProductSamples = () => {
    const sampleId = integrationPageStore.productSampleInfo?.id?.toString();

    if (searchParams.has(PRODUCT_CARD_OPEN)) {
      searchParams.delete(INTEGRATION_CARD_OPEN);

      return setSearchParams(searchParams);
    }

    if (sampleId) {
      searchParams.set(PRODUCT_CARD_OPEN, sampleId);
      setSearchParams(searchParams);
    }
  };

  const handleOpenCreateGoodsCreatePage = () => {
    const userId = userStore.user?.id;

    if (userId) {
      productSampleCreateStore.setUserId(userId);
    }

    productSampleCreateStore.setIntegration(integration);

    searchParams.set(GOODS_CREATE_PAGE_OPEN, "create");
    setSearchParams(searchParams);
  };

  const productSamples = integrationPageStore.productSampleInfo?.productSamples;

  useEffect(() => {
    return () => {
      productSamplePageStore.setProductSamples([]);
      productSampleCreateStore.setUserId(null);
    };
  }, []);

  return (
    <AsideCardLayout
      headerTitle="Отправленные образцы"
      rightContent={
        productSamples && productSamples?.length !== 0 ? (
          <AddedButton sx={{ flexShrink: 0 }} onClick={handleOpenProductSamples}>
            Открыть
          </AddedButton>
        ) : (
          <AddedButton sx={{ flexShrink: 0 }} onClick={handleOpenCreateGoodsCreatePage}>
            Добавить образец
          </AddedButton>
        )
      }>
      {productSamples && productSamples?.length !== 0 ? (
        <Stack pt={1} direction={"row"} spacing={1} alignItems={"center"}>
          <Box maxWidth={"fit-content"}>
            {integrationPageStore.productSampleInfo?.isSampleRefund ? (
              <Box
                sx={{
                  p: 0.5,
                  background: "#7ECB41",
                  borderRadius: "5px",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <Check sx={{ color: "#fff" }} />
              </Box>
            ) : integrationPageStore.productSampleInfo?.hasRefund ? (
              <Box
                sx={{
                  p: 0.5,
                  background: "#7876FE",
                  borderRadius: "5px",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <Autorenew sx={{ color: "#fff" }} />
              </Box>
            ) : (
              <Box
                sx={{
                  p: 0.5,
                  background: "#7BB8FF",
                  borderRadius: "5px",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <ArrowOutward sx={{ color: "#fff" }} />
              </Box>
            )}
          </Box>
          <Box display={"flex"} flexDirection={"column"} columnGap={1}>
            {productSamples?.map((item) => (
              <Typography key={item.id} display={'flex'}>
                {item.good.name} / {item.color ? item.color : <EmptyText component={'span'}>отсутствует</EmptyText>}
              </Typography>
            ))}
          </Box>
        </Stack>
      ) : (
        <EmptyText mt={1}>Нет отправленных образцов</EmptyText>
      )}
    </AsideCardLayout>
  );
};
