import {FC, useState, useEffect} from 'react'
import { IUserAnalytics } from './IUserAnalytics'
import { AsideCardLayout } from '../../../../../AsideCardLayout/AsideCardLayout'
import { UserAnalyticDatePicker } from '../UserAnalyticDatePicker/UserAnalyticDatePicker'
import StatisticsService from '../../../../../../services/statistics/statistics.service'
import { IStatisticsRequestGeneral } from '../../../../../../services/statistics/IStatisticsRequest'
import dayjs, { Dayjs } from 'dayjs'
import { AxiosError } from 'axios'
import { IStatisticsResponseGeneral } from '../../../../../../services/statistics/IStatisticsResponse'
import { UsetAnalyticsContent } from './ui/UsetAnalyticsContent/UsetAnalyticsContent'
import { Box, Skeleton, Typography } from '@mui/material'

export const UserAnalytics:FC<IUserAnalytics> = (props) => {
  const {id} = props

  const [totalFrom, setTotalFrom] = useState<Dayjs | null>(dayjs(dayjs().format('YYYY-MM-01')))
  const [totalTo, setTotalTo] = useState<Dayjs | null>(dayjs(dayjs().format(`YYYY-MM-${dayjs(dayjs().format('YYYY-MM-DD')).daysInMonth()}`)))

  const [userStatistics, setUserStatistics] = useState<null | IStatisticsResponseGeneral>(null)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const getCurrentUserAnalytics = () => {
    if(totalFrom && totalTo){
      setLoading(true)
      setError(null)
      
      const option:IStatisticsRequestGeneral = {
        id,
        dateOptions:{
          from:totalFrom.format('YYYY-MM-DD'),
          to:totalTo.format('YYYY-MM-DD')
        }
      }
      StatisticsService.getGeneralStatistics(option)
        .then(res=>{
          // console.log(res, 'get statistic user')
          setLoading(false)
          setUserStatistics(res.data)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            // console.log(err, 'err user analytics')
            setLoading(false)
            setError(err?.response?.data?.message)
          }
        })
    }
  }

  useEffect(()=>{
    getCurrentUserAnalytics()
  },[totalFrom, totalTo])

  return (
    <AsideCardLayout
      headerTitle='Ключевые показатели'
      rightContent={
        <UserAnalyticDatePicker
          totalFrom={totalFrom}
          totalTo={totalTo} 
          setTotalFrom={setTotalFrom} 
          setTotalTo={setTotalTo}
          sx={{flexWrap:{md:'nowrap', xs:'wrap'}, width:{xs:'310px', md:'100%'}}}
          contentDateSx={{maxWidth:{xs:'288px', sm:'334px'}}}
          headerDateSx={{maxWidth:{xs:'288px', sm:'334px'}, margin: {xs:0, sm:'16px 0 8px 0'}, pr: {xs:0, sm:'12px'}, pl:{xs:2, sm:'24px'}}}
          submitBtnSx={{right:{xs:'16px', sm:0}}}
        />
      }
      headerSx={{flexWrap:{xs:'wrap'}}}
    >
      <Box sx={{pt:1}}>
        {!isLoading && userStatistics &&
          <UsetAnalyticsContent data={userStatistics}/>
        }
        {isLoading && 
          <Skeleton
            variant='rounded' 
            sx={{minHeight:{xs:'150px', sm:'250px',md:'465px'}, maxHeight:{xs:'150px', sm:'250px',md:'465px'}}}
          />
        }
        {!isLoading && isError &&
          <Box>
            <Typography>Недостаточно данных для отчёта</Typography>
          </Box>
        }
      </Box>
    </AsideCardLayout>
  )
}