import { observer } from 'mobx-react-lite'
import integrationPageStore from '../../../../../../../../store/integrationPage.store'
import { ICreateCommentsRequest } from '../../../../../../../../services/common/ICommonRequest'
import CommonService from '../../../../../../../../services/common/common.service'
import { AxiosError } from 'axios'
import { AsideCardComments } from '../../../../../../ui/AsideCardComments/AsideCardComments'

export const ChatComments = observer(() => {

  const addComment = (value: string) => {
    if (integrationPageStore.oplataId) {

      const option: ICreateCommentsRequest = {
        toEntity: "payment",
        entity_id: integrationPageStore.oplataId,
        text: value,
      }
      CommonService.createComments(option)
        .then((res) => {
          // console.log(res, "res comment add data")
          integrationPageStore.addPostedComments(res.data)
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
            // console.log(err, "err add comment")
          }
        })
    }
  }
  
  return (
    <>
      {integrationPageStore.oplataId &&
        <AsideCardComments
          onCloseChat={()=>integrationPageStore.setChatOpenPayment(false)}
          onOpenChat={()=>integrationPageStore.setChatOpenPayment(true)}
          sxBtnResize={{display:{xs:'none', lg:'block'}}}
          showResizeBtn
          comments={integrationPageStore.postedComments || []}
          addComment={addComment} 
          sx={{maxHeight:{xs:'300px', lg:'720px'}}}
          entity='payment'
          onChangeComments={()=>''}
          onEditComments={integrationPageStore.editComment}
          onDeleteComments={integrationPageStore.deletePostedComments}
          entityId={integrationPageStore.oplataId}
        />
      }
    </>
  )
})