import { makeAutoObservable } from "mobx";
import { AxiosError } from "axios";
import ReferencesService from "../services/references/references.service";
import { IReferencesIntegrationPlacesResponse } from "../services/references/IReferencesResponse";

class ReleasePlacementsStore {
  placements:IReferencesIntegrationPlacesResponse[] | null = null

  constructor(){
    makeAutoObservable(this)
  }

  setItems(placements:IReferencesIntegrationPlacesResponse[]) {
    this.placements = placements
  }

  getReleasesPlacement(){
    ReferencesService.getIntegrationReleasesPlaces()
      .then(res=>{
        // console.log(res, 'place releases')
        this.setItems(res.data)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err place releases')
        }
      })
  }

}

export default new ReleasePlacementsStore()