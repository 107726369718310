import {Box, Typography} from "@mui/material";
import {IChannelSize} from "./IChannelSize";
import selectsDataStore from "../../../../../store/selectsData.store";


export const ChannelSize = ({color}:IChannelSize) => {

  const channelSize = selectsDataStore?.channelSizes?.find(el => el?.code===color)
  return (
    <Box display={'inline-flex'}>
      <Box sx={{
        background:channelSize?.hexColor || '',
        p:'4px 8px',
        borderRadius:'16px',
        textAlign:'center',
      }}>
        <Typography>{channelSize?.name || '–' }</Typography>
      </Box>
    </Box>

  );
};

