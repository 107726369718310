import { Box, CircularProgress, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { AccordionWrapper } from "../../../../components/AccordionWrapper/AccordionWrapper";
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout";
import { NotificationAlerts } from "../../../../components/NotificationAlerts/NotificationAlerts";
import { TableDefault } from "../../../../components/Table/TableDefault";
import ChannelService from "../../../../services/channel/channel.service";
import { IChannel } from "../../../../services/channel/ICnannelResponse";
import { channelMainSectionRoutes } from "../../../../shared/routes/routes";
import { ALL_RULES } from "../../../../shared/rules/interface/EnumRights";
import companyUsersStore from "../../../../store/companyUsers.store";
import transferredChannelsStore from "../../../../store/transferredChannels.store";
import userStore from "../../../../store/user.store";
import { CustomChannelFilter } from "../CustomChannelFilter/CustomChannelFilter";
import { transferredChannelsFilters } from "../CustomChannelFilter/shared/dataList";
import {
  getTransferredChannelsAccordionItems,
  getTransferredChannelsItems,
} from "./shared/dataList";
import { ChannelTransferredList } from "./ui/ChannelTransferredList";

export const TransferredChannels = observer(() => {
  const [tableError, setTableError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowAlert, setShowAlert] = useState(false);

  const getTransferredChannelData = async () => {
    setIsLoading(true);
    setTableError(null);
    const userId = userStore.user?.id;
    try {
      const res = await ChannelService.getTransferredChannels(userId);
      transferredChannelsStore.setTableItems(res.data.allChannelsStatusTransferred);
      transferredChannelsStore.setTableAccordionItems(res.data.channelsAssignedToManagers);
    } catch (err) {
      if (err instanceof AxiosError) {
        setTableError(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeTableHandler = (item: IChannel) => {
    transferredChannelsStore.deleteTransferredChannelsItem(item.id);
    transferredChannelsStore.addTransferredChannelsItem(item);
    setShowAlert(true);
  };

  const onChangeStatusTableHandler = (id: number, update_id: number, value: string) => {
    transferredChannelsStore.changeUpdateIdItem(id, update_id, value);
    transferredChannelsStore.deleteTransferredChannelsItemByStatus(value, id);
  };

  const onChangeStatusAccordionTableHandler = (id: number, update_id: number, value: string) => {
    transferredChannelsStore.changeAccordionChannelUpdateIdItem(id, update_id, value);
    transferredChannelsStore.deleteTransferredAccordionChannelsItem(value, id);
  };

  const onChangeManagerHandler = (id: number, field: string, update_id: number, value: number) => {
    transferredChannelsStore.addReleaseByManager(id, field, update_id, +value);
  };

  useEffect(() => {
    let timerId = setTimeout(() => {
      getTransferredChannelData();
    }, 500);
    return () => {
      clearTimeout(timerId);
      transferredChannelsStore.setTableItems([]);
      transferredChannelsStore.setTableAccordionItems([]);
      transferredChannelsStore.resetFilters();
    };
  }, []);

  const userHasEditRights =
    userStore.user?.rights?.some((x) => x.code === ALL_RULES.release) &&
    !userStore.user?.rights?.some((x) => x.code === ALL_RULES.search_head);

  const allChannelsBlock = (
    <Box mb={2} borderBottom={"1px solid #e0e0e0"} borderRadius={"4px"} overflow={"hidden"}>
      <Typography variant={"h5"} mb={2}>
        Переданные каналы
      </Typography>
      <AccordionWrapper isOpen title="Все каналы">
        <TableDefault
          showBorderRadius={false}
          tableRow={getTransferredChannelsItems()}
          showBurgerCell>
          <ChannelTransferredList
            readOnlyDateGiven={
              !userStore.user?.rights?.find(
                (x) =>
                  x.code?.includes(ALL_RULES.edit_channel_transfer) ||
                  x.code?.includes(ALL_RULES.ADMIN)
              )
            }
            channels={transferredChannelsStore.filteredTableItems}
            cells={getTransferredChannelsItems()}
            readOnly={
              !userStore.user?.rights?.find(
                (x) =>
                  x.code?.includes(ALL_RULES.edit_channel_transfer) ||
                  x.code?.includes(ALL_RULES.ADMIN)
              )
            }
            onChange={onChangeTableHandler}
            onChangeStatus={onChangeStatusTableHandler}
          />
        </TableDefault>
      </AccordionWrapper>
    </Box>
  );
  return (
    <>
      {(isLoading || transferredChannelsStore.isLoading) && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 2 }}>
          Идёт загрузка таблицы... <CircularProgress sx={{ ml: 2 }} />
        </Box>
      )}
      {!isLoading && tableError && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 2 }}>
          <Typography variant="h5" color="error">
            Ошибка с таблицей! {tableError}
          </Typography>
        </Box>
      )}
      {!isLoading && !tableError && !transferredChannelsStore.isLoading && (
        <>
          <Box pb={2}>
            <CustomChannelFilter
              filters={transferredChannelsFilters}
              reset={transferredChannelsStore.resetFilters}
              setFilters={transferredChannelsStore.setFilters}
              title="Переданные каналы"
            />
          </Box>
          <AsideCardLayout
            sx={{
              p: { xs: 1, sm: 2 },
              overflowY: "auto",
              overflowX: "hidden",
              height: "100%",
              maxWidth: "calc(100vw - 70px - 32px)",
              transition: "none",
            }}>
            {!userHasEditRights && allChannelsBlock}
            <Box mb={2} borderBottom={"1px solid #e0e0e0"} borderRadius={"4px"} overflow={"hidden"}>
              <Typography variant={"h5"} mb={2}>
                Закрепленные за менеджерами каналы
              </Typography>
              {transferredChannelsStore.filteredTableAccordionItems &&
                transferredChannelsStore.filteredTableAccordionItems.map(
                  ({ managerId, channels }) => {
                    const manager = companyUsersStore.users.find((item) => item.id === +managerId);
                    const isOpen = userStore.user?.id === manager?.id;
                    return (
                      <AccordionWrapper
                        title={`Выпускающий менеджер: ${manager?.firstName} ${manager?.lastName} (${
                          channels.length ? channels.length : 0
                        })`}
                        key={managerId}
                        isOpen={isOpen}>
                        <TableDefault
                          showBorderRadius={false}
                          tableRow={getTransferredChannelsAccordionItems()}
                          showBurgerCell>
                          <ChannelTransferredList
                            channels={channels}
                            cells={getTransferredChannelsAccordionItems()}
                            readOnly={
                              !userStore.user?.rights?.find(
                                (x) =>
                                  x.code?.includes(ALL_RULES.edit_channel_transfer) ||
                                  x.code?.includes(ALL_RULES.ADMIN)
                              )
                            }
                            onChangeStatus={onChangeStatusAccordionTableHandler}
                            onChangeManager={onChangeManagerHandler}
                          />
                        </TableDefault>
                      </AccordionWrapper>
                    );
                  }
                )}
            </Box>
            {userHasEditRights && allChannelsBlock}
            <Routes>
              {channelMainSectionRoutes.map(({ path, element: Element }) => (
                <Route key={path} path={path} element={<Element />} />
              ))}
            </Routes>
            <NotificationAlerts
              sucsess={{
                text: "Выпускающий менеджер установлен",
                open: isShowAlert,
                onClose: () => setShowAlert(false),
              }}
            />
          </AsideCardLayout>
        </>
      )}
    </>
  );
});
