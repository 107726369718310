import { useEffect, useState } from "react"
import { Autocomplete, Box, CircularProgress, FormControl, MenuItem, TextField, Typography } from "@mui/material"
import GoodsCategoriesService from "../../../../../../../../services/goodsCategories/goodsCategories.service"
import { AxiosError } from "axios"
import { IGoodsCategoriesResponse, IGoodsCategoriesResponseProduct } from "../../../../../../../../services/goodsCategories/IGoodsCategoriesResponse"
import { AddedButton } from "../../../../../../../../components/UI/Buttons/AddedButton/AddedButton"
import { ISaveGoodsCategoriesResponse } from "../../../../../../../../services/goodsCategories/IGoodsCategoriesRequest"
import { NotificationAlerts } from "../../../../../../../../components/NotificationAlerts/NotificationAlerts"
import rulesStore from "../../../../../../../../store/rules.store"
import goodsStore from "../../../../../../../../store/goods.store"

export const AddCategoryForm = () => {

  const [isLoadingCategories, setLoadingCategories] = useState(false)
  const [categories, setCategories] = useState<null | IGoodsCategoriesResponse[]>(null)
  const [categoriesValue, setCategoriesValue] = useState<IGoodsCategoriesResponse | null>(null)

  const [isLoadingGoods, setLoadingGoods] = useState(false)
  const [goods, setGoods] = useState<null | IGoodsCategoriesResponseProduct[]>(null)
  const [goodsValue, setGoodsValue] = useState<IGoodsCategoriesResponseProduct | null>(null)

  const [isLoadingSubmit, setLoadingSubmit] = useState(false)
  const [errorSubmit, setErrorSubmit] = useState<string | null>(null)

  const getCategories = () => {
    setLoadingCategories(true)

    GoodsCategoriesService.getProductsGroupsCRM()
      .then(res=>{
        setCategories(res.data)
      })
      .catch(err=>{
        if(err instanceof AxiosError){

        }
      })
      .finally(()=>{
        setLoadingCategories(false)
      })
  }

  const getGoodsOnCurrentCategoty = (categoryId:number) => {
    setLoadingGoods(true)

    GoodsCategoriesService.getProductsGroupsCRM(categoryId)
      .then(res=>{
        setGoods(res.data[0]?.products || null)
      })
      .catch(err=>{
        if(err instanceof AxiosError){

        }
      })
      .finally(()=>{
        setLoadingGoods(false)
      })
  }
  
  const submit = () => {
    if(categoriesValue && goodsValue){
      setLoadingSubmit(true)

      const option:ISaveGoodsCategoriesResponse = {
        article:goodsValue.article,
        name:goodsValue.name,
        goodsCategoryId:categoriesValue.id,
        type:'goods'
      }
      GoodsCategoriesService.saveGoodsCategories(option)
        .then(res=>{
          
          rulesStore.addGoodsCategories(res.data, res.data.category.code)
          goodsStore.addedGoods(res.data)

          setGoods(null)
          setGoodsValue(null)
          setCategoriesValue(null)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            setErrorSubmit(err?.response?.data?.message)
          }
        })
        .finally(()=>{
          setLoadingSubmit(false)
        })
    }
  }

  useEffect(()=>{
    if(categoriesValue !== null){
      getGoodsOnCurrentCategoty(categoriesValue.id)

    } else {
      setGoods(null)
      setGoodsValue(null)
    }
  },[categoriesValue?.id])

  useEffect(()=>{
    getCategories()
  },[])

  return (
    <Box 
      sx={{
        display:"flex", 
        alignItems:{xs:"start", md:'center',},
        gap:2,
        flexDirection:{xs:'column', md:'row'},
      }}
    >
      <Typography>Товарная категория</Typography>
      <FormControl sx={{minWidth:{xs:'100%', md:200, lg:300}}}>
        <Autocomplete
          value={categoriesValue}
          onChange={(event: any, newValue: IGoodsCategoriesResponse | null) => {
            setCategoriesValue(newValue || null)
          }}
          options={categories || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          noOptionsText='Ничего не найдено'
          loading={isLoadingCategories}
          loadingText={'Загрузка...'}
          renderOption={(props, option) => {
            return (
              <MenuItem {...props} key={option.id} value={option.id} sx={{whiteSpace:'normal'}}>
                {option.name}
              </MenuItem>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={'Выберите'}
              size='small'
              autoFocus
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoadingCategories ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          size='small'
        />
      </FormControl>

      <FormControl sx={{minWidth:{xs:'100%', md:200, lg:300}}}>
        <Autocomplete
          value={goodsValue}
          onChange={(event: any, newValue: IGoodsCategoriesResponseProduct | null) => {
            setGoodsValue(newValue || null)
          }}
          options={goods || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.article === value.article}
          noOptionsText='Ничего не найдено'
          loading={isLoadingGoods}
          loadingText={'Загрузка...'}
          renderOption={(props, option) => {
            return (
              <MenuItem {...props} key={option.article} value={option.article} sx={{whiteSpace:'normal'}}>
                {option.name}
              </MenuItem>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={isLoadingGoods ? 'Загрузка товаров...' : 'Выберите товар'}
              size='small'
              autoFocus
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoadingGoods ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          size='small'
        />
      </FormControl>
      <AddedButton variant="contained" disabled={!goodsValue || isLoadingSubmit} onClick={submit}>
        Добавить
      </AddedButton>
      <NotificationAlerts
        error={{open:Boolean(errorSubmit), text:`${errorSubmit}`, onClose:()=>setErrorSubmit(null)}}
      />
    </Box>
  )
}
