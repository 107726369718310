import { makeAutoObservable } from "mobx";
import { IAllGoodsCategoriesResponse, IGoodsCategoriesResponseProduct } from "../services/goodsCategories/IGoodsCategoriesResponse";

class RulesStore {

  goodsCategoriesTable:IAllGoodsCategoriesResponse[] = []

  constructor(){
    makeAutoObservable(this)
  }

  setGoodsCategories = (items:IAllGoodsCategoriesResponse[]) => {
    this.goodsCategoriesTable = items
  }

  addGoodsCategories = (goodItem:IGoodsCategoriesResponseProduct, categoryCode:string) => {
    this.goodsCategoriesTable = this.goodsCategoriesTable.map(item=>{
      if(item.code === categoryCode){
        return {...item, goods:[...item.goods, goodItem]?.sort((a,b)=>a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1)}
      } else return item
    })
  }

  editGoodsCategories = (categoryCode:string, article:string, name:string) => {
    this.goodsCategoriesTable = this.goodsCategoriesTable.map(item=>{
      if(item.code === categoryCode){

        return {
          ...item, 
            goods:item.goods
              .map(x=>{

                if(x.article === article){
                  return {...x, name}
                } else return x

            })
            .sort((a,b)=>a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1)
        }

      } else return item
    })
  }

  removeGoodsCategories = (categoryCode:string, article:string) => {
    this.goodsCategoriesTable = this.goodsCategoriesTable.map(item=>{
      if(item.code === categoryCode){
        return {...item, goods:item.goods.filter(x=>x.article !== article)}
      } else return item
    })
  }

}

export default new RulesStore()