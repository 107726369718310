import { ALL_RULES } from "./interface/EnumRights"
import { IPermissionOpton } from "./interface/IPermissionOpton"
import UserStore from "../../store/user.store";
import financeCardPageStore from "../../store/financeCardPage.store";
import integrationPageStore from "../../store/integrationPage.store";

export const checkUserPermission = (option:IPermissionOpton):boolean => {
  const {rules, userRules} = option
  
  if(userRules.find(rule=>rule.code === ALL_RULES.ADMIN)){
    return true
  } else {

    const accessRules = userRules.map(rule=>rules.includes(rule.code))

    if(accessRules.filter(x=>x===true).length > 0) {
      return true
    } else return false

  }
}


export const editPaymentsPermission = (): boolean => {
  const {rights,id} = UserStore.user || {}
  return (!(rights?.some(el=>el.code===ALL_RULES.ADMIN)||rights?.some(el =>
    (el.code === ALL_RULES.edit_payment) &&
    !rights?.some(innerEl => innerEl.code === ALL_RULES.release) ||
    (el.code===ALL_RULES.edit_payment && integrationPageStore.releaseUserId === String(id)) ||
    (el.code===ALL_RULES.edit_payment && financeCardPageStore.releaseUserId === String(id))
  )));
};
