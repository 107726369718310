import {FC} from 'react'
import { IAddBudgetInput } from './IAddBudgetInput'
import { Stack, Typography } from '@mui/material'
import { priceTransormFormat } from '../../../../../../../../shared/functions/functions'

export const AddBudgetInput:FC<IAddBudgetInput> = (props) => {
  const {value} = props

  return (
    <>
        <Stack direction={'row'} alignItems={'center'}>
          <Typography
            sx={{ml:{xs:0, sm:0.5}}}
          >
            {priceTransormFormat(value || 0)}
          </Typography>
        </Stack>
    </>
  )
}
