import {FC} from 'react'
import { IContractCheckbox } from './IContractCheckbox'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { purple_color } from '../../../../../../app/styles/variables'

export const ContractCheckbox:FC<IContractCheckbox> = (props) => {
  const {
    initValue,
    onChange,
    disabled,
    text,
    readOnly = false,
    labelPlacement = 'end',
    field
  } = props

  return (
    <FormControlLabel
      slotProps={{typography:{sx:{cursor:readOnly ? 'auto' : 'pointer'}}, }}
      labelPlacement={labelPlacement}
      control={
        <Checkbox
          checked={initValue}
          onChange={!readOnly ? field ? e=>onChange(e?.target?.checked, field) : e=>onChange(e?.target?.checked) : undefined}
          inputProps={{style:{cursor:readOnly ? 'auto' : 'pointer'}}}
          disabled={disabled}
        />
      } 
      label={
        <Typography
          sx={{
            '&:hover':{
              color:readOnly ? 'auto' : purple_color
            }
          }}
        >
          {text}
        </Typography>
      }
      disabled={disabled}
      sx={{cursor:readOnly ? 'auto' : 'pointer', ml:0}}
    />
  )
}
