import {FC, useEffect, useState} from 'react'
import { IAddModalRelease } from './IAddModalRelease'
import { Box, Dialog, DialogTitle, Divider, SelectChangeEvent } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { AsideReleaseForm } from '../../../../../../../pages/TableAsidePage/ui/AsideReleaseForm'
import { IReleaseItem } from '../../../../../../ReleaseList/models/IReleaseItem'
import releasePlacementsStore from '../../../../../../../store/releasePlacements.store'
import { AxiosError } from 'axios'
import IntegrationsService from '../../../../../../../services/integration/integrations.service'
import integrationTableStore from '../../../../../../../store/integrationTable.store'
import { ICreateReleaseRequest } from '../../../../../../../services/integration/IntegrationsRequest'
import { NotificationAlerts } from '../../../../../../NotificationAlerts/NotificationAlerts'
import { sendDatePlusCustomTime } from '../../../../../../../shared/functions/functions'
import { CustomIconButton } from '../../../../../../UI/Buttons/CustomIconButton/CustomIconButton'

export const AddModalRelease:FC<IAddModalRelease> = (props) => {
  const {open, onClose, id,type} = props

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const [linkValue, setLinkValue] = useState('')
  const [viewValue, setViewValue] = useState('')
  const [commentsValue, setCommentsValue] = useState('')
  const [likeValue, setLikeValue] = useState('')
  const [dateValue, setDateValue] = useState<Date | null>(null)
  const [selectValueRelease, setSelectValueRelease] = useState(type||'')
  const [selectPlacementValue, setSelectPlacementValue] = useState(selectValueRelease === 'youtube' ? 'beginning' :   selectValueRelease === 'telegram' ? 'post' : '')

  const handleChange = (event: SelectChangeEvent) => {
    setSelectValueRelease(event.target.value as string)
  }

  const handleChangePlacement = (event: SelectChangeEvent) => {
    setSelectPlacementValue(event.target.value as string)
  }

  const addRelease = () => {
    if(dateValue && linkValue.trim() !=='' && selectValueRelease.trim() !==''){
      setLoading(true)
      setError(null)

      const item:IReleaseItem = {
        comments:+commentsValue,
        likes:+likeValue,
        link:linkValue,
        views:+viewValue,
        type:{code:selectValueRelease},
        releaseDate:sendDatePlusCustomTime({value:dateValue?.toISOString()}),
        integrationPlace:releasePlacementsStore.placements?.find(x=>x.code===selectPlacementValue) || null as any
      }
      
      const option:ICreateReleaseRequest = {
        id,
        release:item
      }
      
      IntegrationsService.createIntegrationRelease(option)
        .then(res=>{
          // console.log(res, 'res create pealrse')
          integrationTableStore.addRelese(+id, res.data)
          integrationTableStore.updateSimpleField(+id, res.data?.integration?.update_id, 'views', res.data?.integration?.views)
          
          setError(null)
          setLoading(false)
          onClose()
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            // console.log(err, 'err create relese')
            setError(Array.isArray(err.response?.data?.message) ? err.response?.data?.message?.join(', ') : err.response?.data?.message)
            setLoading(false)
          }
        })
      
    }
  }

  useEffect(()=>{
    setSelectPlacementValue(selectValueRelease === 'youtube' ? 'beginning' : selectValueRelease === 'telegram' ? 'post' : '')
  }, [selectValueRelease])

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle sx={{display:'flex', alignItems:'center', justifyContent:'space-between', p:{xs:1, sm:2}, pr:1, fontSize:{xs:'14px', sm:'1.07rem'}}}>
          Форма создания релиза
          <CustomIconButton onClick={onClose}>
            <Cancel/>
          </CustomIconButton>
        </DialogTitle>
        <Divider/>
        <Box sx={{p:{xs:1, sm:2}}}>
          <AsideReleaseForm
            typeValue={type}
            commentsValue={commentsValue}
            onChangeComments={e=>setCommentsValue(e.target.value)}
            likeValue={likeValue}
            onChangeLike={e=>setLikeValue(e.target.value)}
            linkValue={linkValue}
            onChangeLink={e=>setLinkValue(e.target.value)}
            viewValue={viewValue}
            onChangeView={e=>setViewValue(e.target.value)}
            selectValueRelease={selectValueRelease}
            onChangeSelect={handleChange}
            dateValue={dateValue}
            onChangeDate={setDateValue}
            onClose={onClose}
            onSubmit={addRelease}
            timeValue={dateValue}
            onChangeTime={setDateValue}
            placementValue={selectPlacementValue}
            onChangePlacement={handleChangePlacement}
            loading={isLoading}
            error={isError ? true : false}
          />
        </Box>
      </Dialog>
      <NotificationAlerts
         error={{onClose:()=>setError(null), open:Boolean(isError), text:isError ? isError : ''}}
      />
    </>
  )
}