import { FC } from "react"
import { IStrategyField } from "../../../../strategy/interface/IStrategyField"
import { AsideTableStatistic } from "../AsideTableStatistic/AsideTableStatistic"
import { IAsideTableStatisticField } from "../AsideTableStatistic/models/IAsideTableStatisticField"
import { IAsideTableTraficStatistic } from "./IAsideTableTraficStatistic"
import { transformSecToMin } from "../../../../shared/functions/functions"

export const AsideTableTraficStatistic:FC<IAsideTableTraficStatistic> = (props) => {
  const {dataOnSource} = props

  const row:IStrategyField[] = [
    {title:'Визиты', code:'visits', immutable:false, isSimple:true,},
    {title:'Посетители', code:'traffic', immutable:false, isSimple:true},
    {title:'Успешный заказ', code:'cr_order', immutable:false, isSimple:true, isPercent:true},
    {title:'Оформление заказа', code:'cr_cart', immutable:false, isSimple:true, isPercent:true},
    {title:'Целевые визиты', code:'targetVisits', immutable:false, isSimple:true},
    {title:'Отказы', code:'cancels', immutable:false, isSimple:true, isPercent:true},
    {title:'Глубина просмотров', code:'depth', immutable:false, isSimple:true,},
    {title:'Время на сайте', code:'timeSpend', immutable:false, isSimple:true,},
  ]

  const fieldOnSource:IAsideTableStatisticField[] = [
    {code:'visits', value:dataOnSource?.visits},
    {code:'traffic', value:dataOnSource?.traffic},
    {code:'cr_order', value:dataOnSource?.cr_order?.toFixed(2)},
    {code:'cr_cart', value:dataOnSource?.cr_cart?.toFixed(2)},
    {code:'targetVisits', value:dataOnSource?.targetVisits},
    {code:'cancels', value:dataOnSource?.cancels?.toFixed(2)},
    {code:'depth', value:dataOnSource?.depth?.toFixed(2)},
    {code:'timeSpend', value:dataOnSource && transformSecToMin(+dataOnSource?.timeSpend?.toFixed(0))},
  ]

  return (
    <AsideTableStatistic dataField={fieldOnSource} rows={row}/>
  )
}