import { IChannelAccountRequisite, IChannelFullResponse } from "../../../services/channel/ICnannelResponse"
import { IStrategyField } from "../../../strategy/interface/IStrategyField"
import { IAsideTableStatisticField } from "../../TableAsidePage/ui/AsideTableStatistic/models/IAsideTableStatisticField"
import { IChannelRequisite } from "../ui/ChannelAsideRequisite/models/IChannelRequisite"

export const getStatisticsChannel = ():IStrategyField[] => {
  const statisticsRow:IStrategyField[] = [
    {title:'Средний чек', code:'cac', immutable:false, isSimple:false, isCtrls:true, ctrlsIcon:'%'},
    {title:'Просмотры', code:'views', immutable:false, isSimple:false, isSimpleView:true},
    {title:'Трафик', code:'traffic', immutable:false, isSimple:true,},
    {title:'CPV', code:'cpv', immutable:false, isSimple:true, isMoneySimple:true, isMoneyShowPredictionRemains:true},
    {title:'CTR', code:'ctr', immutable:false, isSimple:false, isChangeNum:true, isPercent:true},
    {title:'CR', code:'cr', immutable:false, isSimple:false, isCtrls:true, ctrlsIcon:'%',isShowRemains:true},
    {title:'ROI', code:'roi', immutable:false, isSimple:true,isPercent:true},
  ]

  return statisticsRow
}

export const getFieldStatisticsChannel = (data:IChannelFullResponse, ctr:number):IAsideTableStatisticField[] => {
  const fields:IAsideTableStatisticField[] = [
    {code:'traffic', value:`${data.traffic}`,},
    {code:'roi', value:data.roi,},
    {code:'views', value:data.views,},
    {code:'cac', value:data.cac ? `${data.cac}` : 0},
    {code:'cpv', value:data.cpv ? `${data.cpv}` : 0},
    {code:'cr', value:data.cr ? `${data.cr}` : 0},
    {code:'ctr', value:ctr ? `${ctr}` : 0},
  ]

  return fields
}


export const getRequisiteItem = (account:IChannelAccountRequisite):IChannelRequisite=> {

  const itemFields:IAsideTableStatisticField[] = [
    {code:'group', value:account.type?.accountTypeGroup?.name},
    {code:'type', value:account.type?.name},
    {code:'value', value:account.value},
    {code:'fio', value:account.fio},
    {code:'id', value:account.id},
    {code:'typeCode', value:account.type?.code},
  ]

  const createItem = (fields:IAsideTableStatisticField[]):IChannelRequisite => {
    const item:any = {}

    for(let property of fields) {
      item[property.code] = property.value
    }

    return item
  }

  return createItem(itemFields)
}