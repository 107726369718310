import { ILayoutResponse } from "../../../../../../services/layout/layoutResponse"
import { IFilterSelectItem } from "../../../../../Filters/UI/FilterSelectChip/IFilterSelectChip"

export const getTzChannelList = (channels:ILayoutResponse['channelTypes']):IFilterSelectItem[] => {

  const listItems:IFilterSelectItem[] = Object.keys(channels).map(key=>{
    const newItem:IFilterSelectItem = {
      name:channels[key]?.name,
      value:key
    }
    return newItem
  })

  return listItems
}