import { FC, useEffect, useState } from 'react'
import { IIntegrationOplataPage } from './IIntegrationOplataPage'
import { Box, CircularProgress, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { CreateOplata } from './ui/CreateOplata/CreateOplata'
import integrationPageStore from '../../../../store/integrationPage.store'
import { OplataFullBlock } from './ui/OplataFullBlock/OplataFullBlock'
import IntegrationsService from '../../../../services/integration/integrations.service'
import { AxiosError } from 'axios'
import { checkNumberBoolean } from '../../../../shared/functions/functions'
import { CustomButton } from '../../../../components/UI/Buttons/CustomButton/CustomButton'

export const IntegrationOplataPage:FC<IIntegrationOplataPage> = observer((props) => {
  const {
    isReadOnly,
    setPriceValue,
    setUpdateId,
    update_id,
    id,
    onChangeCpv,
  } = props

  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const [isCreatePayments, setCreatePayments] = useState(false)

  const getApproveThisIntegrations = () => {
    setLoading(true)
    setError(null)

    IntegrationsService.approvedIntegration(id, true)
      .then(res=>{
        integrationPageStore.setLastPayInfo(res.data?.channel?.lastPayInfo || null)

        if(integrationPageStore?.isEmpyPaymet){
          integrationPageStore.setTotalSumm(res.data?.price || null)
        } else {
          integrationPageStore.setTotalSumm(res.data?.payment?.totalSumm || null)
          integrationPageStore.setPaymentUpdateId(checkNumberBoolean(res.data?.payment?.update_id!) ? res.data?.payment?.update_id! : null)
          integrationPageStore.setChatOpenPayment(true)
        }

        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){

          setError(err?.response?.data?.message)
          setLoading(false)
        }
      })
  }

  const closeSubmitCreate = () => {
    setCreatePayments(false)
    integrationPageStore.setEmptyPayment(false)
  }

  useEffect(()=>{
    getApproveThisIntegrations()

    return () => {

      if(integrationPageStore.isEmpyPaymet){
        integrationPageStore.clearCreateStore(integrationPageStore?.isEmpyPaymet)
      }

      setError(null)
    }
  }, [])

  return (
    <>
      {isLoading &&
        <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
          <Typography variant='h5'>Идёт проверка интеграции...</Typography>
          <CircularProgress sx={{maxHeight:35, minHeight:35, maxWidth:35, minWidth:35, ml:1}}/> 
        </Box>
      }
      {!isLoading && error &&
        <Typography variant='h5' color={'error'} textAlign={'center'}>{error}</Typography>
      }
      <Box>
        {!isLoading && error === null && (integrationPageStore?.isEmpyPaymet === true && isCreatePayments === false) &&
          <CustomButton
            size='small'
            variant='outlined'
            endIcon={<Add fontSize='small'/>}
            sx={{background:'#fff'}}
            onClick={()=>setCreatePayments(true)}
            disabled={isReadOnly}
          >
            Создать оплату
        </CustomButton>
        }
        {isCreatePayments &&
          <CreateOplata 
            readOnly={isReadOnly} 
            setPriceValue={setPriceValue}
            update_id={update_id}
            setUpdateId={setUpdateId}
            id={id}
            onChangeCpv={onChangeCpv}
            setCreatePayments={closeSubmitCreate}
          />
        }
        {!isLoading && error === null && integrationPageStore?.isEmpyPaymet === false &&
          <OplataFullBlock 
            readOnly={isReadOnly || (integrationPageStore.isOplataBlocked !== null ? integrationPageStore.isOplataBlocked : false)}
            setPriceValue={setPriceValue}
            update_id={update_id}
            setUpdateId={setUpdateId}
            id={id}
            onChangeCpv={onChangeCpv}
          />
        }
      </Box>
    </>
  )
})