import { FC, useState, useEffect } from "react"
import { AsideCardRelease } from "../AsideCardRelease"
import { ICreateReleaseRequest } from "../../../../services/integration/IntegrationsRequest"
import { IReleaseItem } from "../../../../components/ReleaseList/models/IReleaseItem"
import IntegrationsService from "../../../../services/integration/integrations.service"
import integrationTableStore from "../../../../store/integrationTable.store"
import { AxiosError } from "axios"
import { ITableAsideRelease } from "./ITableAsideRelease"
import ReleasesService from "../../../../services/releases/releases.service"
import { IReleasesRequestEdit } from "../../../../services/releases/IReleasesRequest"
import integrationPageStore from "../../../../store/integrationPage.store"

export const TableAsideRelease:FC<ITableAsideRelease> = (props) => {
  const {
    id, 
    readOnly, 
    releases,
    onChangeUpdateId,
    setUpdateId
  } = props

  const [releasesArr, setReleasesArr] = useState<IReleaseItem[]>(releases)
  
  const [isOpenForm, setOpenForm] = useState(false)
  const [isOpenEditModal, setOpenModalEdit] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const [onCloseModalEdit, setCloseModalEdit] = useState(false)
  const [onClosePlacement, setClosePlacement] = useState(false)

  const addRelease = (item:IReleaseItem) => {
    setLoading(true)
    setError(null)
    
    const option:ICreateReleaseRequest = {
      id:+id,
      release:item
    }
  
    IntegrationsService.createIntegrationRelease(option)
      .then(res=>{
        // console.log(res, 'res create pealrse'
        const newArr = [...releasesArr, res.data];
        newArr.sort((a, b) => new Date(a.releaseDate).getTime() - new Date(b.releaseDate).getTime());
        setReleasesArr(newArr);
        integrationTableStore.addRelese(+id, res.data)

        integrationPageStore.setViews(res.data?.integration?.views)
        integrationTableStore.updateSimpleField(+id, res.data?.integration?.update_id, 'views', res.data?.integration?.views)

        setUpdateId(res.data.integration.update_id)
        setOpenForm(false)
        setError(null)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err create relese')
          setError(Array.isArray(err.response?.data?.message) ? err.response?.data?.message?.join(', ') : err.response?.data?.message)
          setLoading(false)
        }
      })
  }

  const editRelease = (item:IReleasesRequestEdit) => {

    if(item.link.trim() !=='' && item.type.trim() !=='' && item.releaseDate.trim()!==''){
      setLoading(true)
      setError(null)

      ReleasesService.releaesEdit(item)
        .then(res=>{

          if(id && res.data.id){
            integrationTableStore.editRelese(+id, res.data.id, res.data, res.data?.integration?.update_id)
            setReleasesArr(prev=>prev.map(item=>item.id === res.data.id ? res.data : item).sort((a, b) => new Date(a.releaseDate).getTime() - new Date(b.releaseDate).getTime()))

            integrationPageStore.setGuarantee(res.data?.integration?.guarantee || null)
            integrationPageStore.setViews(res.data?.integration?.views)
            integrationTableStore.updateSimpleField(+id, res.data?.integration?.update_id, 'views', res.data?.integration?.views)
            integrationTableStore.changeGurange(+id, res.data?.integration?.guarantee || null, res.data.integration?.update_id)
          }

          if(onChangeUpdateId){
            onChangeUpdateId(res.data?.integration?.update_id)
          }

          setOpenModalEdit(false)
          setCloseModalEdit(true)
          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            // console.log(err, 'res err edit vodal')
            setLoading(false)
            setError(Array.isArray(err.response?.data?.message) ? err.response?.data?.message?.join(', ') : err.response?.data?.message)
          }
        })
    }
  }

  const deleteRelease = (itemId:number) => {
    //console.log(itemId, 'item id')
    setLoading(true)

    ReleasesService.removeReleaes(itemId)
      .then(res=>{
        // console.log(res, 'res remove releases in list')
        integrationTableStore.removeRelese(+id, releasesArr.filter(item=>item.id !== itemId))
        setReleasesArr(prev=>prev.filter(item=>item.id !== itemId))

        integrationPageStore.setViews(res.data?.integration?.views)
        integrationTableStore.updateSimpleField(+id, res.data?.integration?.update_id, 'views', res.data?.integration?.views)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err remove relese item in list')
          setLoading(false)
        }
      })
  }
  
  const editPlacement = (item:IReleasesRequestEdit, placement:string) => {
    const option:IReleasesRequestEdit = {
      id:item.id!,
      comments:item.comments,
      likes:item.likes,
      link:item.link,
      views:item.views,
      type:item.type,
      releaseDate:item.releaseDate,
      integrationPlace:placement.trim() !=='' ? placement : null as any
    }

    ReleasesService.releaesEdit(option)
      .then(res=>{
        // console.log(res, 'res edit in modal')

        if(id && res.data.id){
          integrationTableStore.editRelese(+id, res.data.id, res.data, res.data?.integration?.update_id)
          setReleasesArr(prev=>prev.map(item=>item.id === res.data.id ? res.data : item))
          
          if(onChangeUpdateId){
            onChangeUpdateId(res.data?.integration?.update_id)
          }

          setClosePlacement(true)
        }
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'res err edit vodal')
        }
      })
  }

  useEffect(()=>{
    if(onCloseModalEdit){
      setCloseModalEdit(false)
    }
  },[onCloseModalEdit])

  useEffect(()=>{
    if(onClosePlacement){
      setClosePlacement(false)
    }
  },[onClosePlacement])

  return (
    <AsideCardRelease
      id={id}
      releases={releasesArr}
      onAddRelease={addRelease}
      onDelete={deleteRelease}
      onEdit={editRelease}
      loading={isLoading}
      readOnly={readOnly}
      isShowAddRelease={isOpenForm}
      setShowAddRelease={setOpenForm}
      onEditPlacement={editPlacement}
      onCloseModalEdit={onCloseModalEdit}
      onClosePlacement={onClosePlacement}
      alertErrorText={isError}
      closeErrorAlert={()=>setError(null)}
      errorShowAlert={Boolean(isError)}
    />
  )
}