import { makeAutoObservable } from "mobx";
import { IReferencesPresetResponse } from "../services/references/IReferencesResponse";

class PresetFiltersStore {
  presetIntegrations:IReferencesPresetResponse[] | null = null

  presetChannels:IReferencesPresetResponse[] | null = null

  presetPayments:IReferencesPresetResponse[] | null = null

  presetAgreements:IReferencesPresetResponse[] | null = null

  constructor(){
    makeAutoObservable(this)
  }

  setAllPresetAgreements(preset:IReferencesPresetResponse[]){
    this.presetAgreements = preset
  }

  addPresetAgreements(filter:IReferencesPresetResponse){
    this.presetAgreements?.push(filter)
  }

  deletePresetAgreements = (id:number) =>{
    if(this.presetAgreements){
      this.presetAgreements = this.presetAgreements?.filter(item=>item.id !== id)
    }
  }

  setAllPresetIntegrations(preset:IReferencesPresetResponse[]){
    this.presetIntegrations = preset
  }

  addPresetIntegrations(filter:IReferencesPresetResponse){
    this.presetIntegrations?.push(filter)
  }

  deletePresetIntegrations = (id:number) =>{
    if(this.presetIntegrations){
      this.presetIntegrations = this.presetIntegrations?.filter(item=>item.id !== id)
    }
  }

  setAllPresetChannels(preset:IReferencesPresetResponse[]){
    this.presetChannels = preset
  }

  addPresetChannels(filter:IReferencesPresetResponse){
    this.presetChannels?.push(filter)
  }

  deletePresetChannels = (id:number) =>{
    if(this.presetChannels){
      this.presetChannels = this.presetChannels?.filter(item=>item.id !== id)
    }
  }

  setAllPresetPayments(preset:IReferencesPresetResponse[]){
    this.presetPayments = preset
  }

  addPresetPayments(filter:IReferencesPresetResponse){
    this.presetPayments?.push(filter)
  }

  deletePresetPayments = (id:number) =>{
    if(this.presetPayments){
      this.presetPayments = this.presetPayments?.filter(item=>item.id !== id)
    }
  }

}

export default new PresetFiltersStore()