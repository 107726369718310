import {FC} from 'react'
import { IPaymentChannelField } from './IPaymentChannelField'
import { Avatar, Link, Stack } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import channelStore from '../../../../../store/channel.store'
import { CHANNEL_CARD_OPEN } from '../../../../../shared/constants/searchParams'

export const PaymentChannelField:FC<IPaymentChannelField> = (props) => {
  const {id, channel} = props

  const [searchParams, setSearchParams] = useSearchParams()
  
  const currentChannel = channelStore?.channels![channel.type.code]

  const openChannel = () => {
    searchParams.set(CHANNEL_CARD_OPEN, `${channel.id}`)
    setSearchParams(searchParams)
  }

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <Avatar src={currentChannel.icon} alt={channel.name} sx={{height:25, width:25}} />
      <Link
        color={'secondary'}
        variant='body1'
        onClick={openChannel}
        sx={{cursor:'pointer', whiteSpace:'nowrap',textOverflow:'ellipsis', overflow:'hidden'}}
      >
        {channel.name}
      </Link>
    </Stack>
  )
}