import {FC, useState} from 'react'
import { ITableRequisiteItem } from './ITableRequisiteItem'
import { ChannelRequisiteModal } from '../../../ChannelRequisiteModal/ChannelRequisiteModal'
import { IBurgerItem } from '../../../../../../components/Table/models/ITableItem'
import { TableItem } from '../../../../../../components/Table/ui/TableItem'
import ChannelService from '../../../../../../services/channel/channel.service'
import { IChannelEditAccountRequest } from '../../../../../../services/channel/IChannelRequest'
import { AxiosError } from 'axios'
import { IChannelRequisite } from '../../models/IChannelRequisite'

export const TableRequisiteItem:FC<ITableRequisiteItem> = (props) => {
  const {
    cells,
    onDelete,
    onChangeState,
    readOnly = false,
    ...restProps
  } = props

  const [isEditOpen, setEditOpen] = useState(false)

  const [isEditLoading, setEditLoading] = useState(false)
  const [isEditError, setEditError] = useState<string | null>(null)

  const handelEditOpen = () => setEditOpen(true)
  const handelEditClose = () => setEditOpen(false)

  const requisiteBurgerList:IBurgerItem[] = [
    {title:'Редактировать', onClick:handelEditOpen, isShow:readOnly},
    {title:'Удалить', onClick:onDelete, isShow:readOnly},
  ]

  const editSubmit = (fio:string, value:string, type:string) => {
    setEditLoading(true)
    setEditError(null)

    const option:IChannelEditAccountRequest = {
      fio,
      id:restProps.id,
      type,
      value
    }
    ChannelService.editAccounts(option)
      .then(res=>{
        // console.log(res, 'res edit requisite')

        const newItem:IChannelRequisite = {
          id:res.data.id,
          type:res.data.type.name,
          value:res.data.value,
          fio:res.data.fio,
          group:res.data.type.accountTypeGroup.name,
          typeCode:res.data.type.code
        }

        onChangeState(prev=>prev.map(item=>item.id === res.data.id ? newItem : item))
        handelEditClose()
        setEditLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err edit reqsuisite')
          setEditError(err.message)
          setEditLoading(false)
        }
      })
  }
 
  return (
    <>
      <TableItem
        key={restProps.id}
        cells={cells}
        {...restProps}
        showBurgerMenu={readOnly}
        burgerItemLists={requisiteBurgerList}
      />
      {isEditOpen &&
        <ChannelRequisiteModal
          open={isEditOpen}
          onClose={handelEditClose}
          title='Редактировать реквизит'
          initFio={restProps.fio}
          initType={restProps.typeCode}
          initValue={restProps.value}
          onSubmit={editSubmit}
          error={isEditError}
          loading={isEditLoading}
        />
      }
    </>
  )
}