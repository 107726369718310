import {FC, useState} from 'react'
import { IContactInputEdit } from './IContactInputEdit'
import { Box, Stack, Typography } from '@mui/material'
import { ChannelTitleEdit } from '../../../../../../pages/ChannelAsidePage/ui/ChannelTitleEdit/ChannelTitleEdit'

export const ContactInputEdit:FC<IContactInputEdit> = (props) => {
  const {
    title,
    value,
    placeholder,
    notFoundTitle,
    onSubmit,
    readOnly = false
  } = props

  const [isEdit, setEdit] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const changeContact = (value:string) => {
    onSubmit(value)
    setEdit(false)
  }
  
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
      {/* <ChannelTitleEdit
        title={title}
        value={value}
        placeholder={placeholder}
        notFoundTitle={notFoundTitle}
        isEdit={isEdit}
        onCloseEdit={()=>setEdit(false)}
        onOpenEdit={()=>setEdit(true)}
        onSubmit={changeContact}
        isLoading={isLoading}
        tooltipPlacement='right'
        wrap
        readOnly={readOnly}
      /> */}
      <Typography>{title}</Typography>
      <Typography>{value}</Typography>
    </Stack>
  )
}
