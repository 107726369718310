import {ITemplate, VariablesType} from "../services/payments/IPaymentsResponse";
import {ChangeEvent, useState} from "react";
import {Dayjs} from "dayjs";

export const useTemplateProcessor = (template:ITemplate)=>{

  const {templateText,variables} = template || {}

  const [formData, setFormData] = useState<VariablesType>(template.variables);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setFormData(prevData => ({...prevData, [name]: value}));
  };

  const handleChangeDate = (date: Dayjs,name:string) => {
    const dateValue = date?.format('YYYY-MM-DD')
    setFormData(prevData => ({...prevData, [name]: dateValue}));
  };

  const replaceTemplateVariables = (templateText:string,variables:VariablesType) => {
    let result = templateText;
    for (const key in variables) {
      const placeholder = `{{${key}}}`;
      result = result.split(placeholder).join(String(variables[key]));
    }
    return result;
  };

  const clearFormData = () =>{
    setFormData(variables)
  }

  return {
    handleChange,replaceTemplateVariables,formData,handleChangeDate,clearFormData,templateText,variables
  }

}