import { makeAutoObservable } from "mobx";
import { IIntegrationsFullResponse } from "../services/integration/IIntegrationsResponse";
import { IProductSamplesGoods } from "../services/productSample/IProductSampleResponse";
import { SelectChangeEvent } from "@mui/material";
import { Dayjs } from "dayjs";

class ProductSampleCreateStore {
  integration: IIntegrationsFullResponse | null = null;

  userId: number | null = null;

  productSamples: IProductSamplesGoods[] = [{ id: -9999999 } as any];

  sendAt: string | null = null;

  sendFrom: string | null = null;

  hasRefund: boolean | null = null;

  isSampleRefund: boolean | null = null;

  comment: string | null = null;

  deliveryCost: number | null = null;

  refundCost: number | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setIntegration(integration: IIntegrationsFullResponse | null) {
    this.integration = integration;
  }

  setUserId(id: number | null) {
    this.userId = id;
  }

  onAddedGood = () => {
    const productSample = {
      id: this.productSamples[this.productSamples?.length - 1]?.id - 1,
    } as any;

    this.productSamples.push(productSample);
  };

  onDeleteGood = (id: number) => {
    this.productSamples = this.productSamples.filter((x) => x.id !== id);
  };

  setComment = (comment: string) => {
    this.comment = comment;
  };

  onChangeCheckbox = (hasRefund: boolean) => {
    this.hasRefund = hasRefund;

    if (!hasRefund) {
      this.isSampleRefund = null;
    }
  };

  onChangeIsSampleRefund = (isSampleRefund: boolean) => {
    this.isSampleRefund = isSampleRefund;
  };

  onChangeSendFrom = (event: SelectChangeEvent<string>) => {
    this.sendFrom = event.target.value;
  };

  onChangeGood = (id: number, field: "good" | "color", value: string) => {
    this.productSamples = this.productSamples.map((item) => {
      if (item.id === id) {
        if (field === "color") {
          return { ...item, [field]: value };
        } else return { ...item, good: { ...item?.good, article: value } };
      } else return item;
    });
  };

  onChangeDate = (date: Dayjs | null) => {
    if (date?.isValid()) {
      this.sendAt = date?.format("YYYY-MM-DD") + "T00:00:00.000Z";
    }
  };

  onManagerChange = (event: SelectChangeEvent<string>) => {
    this.userId = Number(event.target.value);
  };

  onChangeIntegration = (integration: IIntegrationsFullResponse | null) => {
    this.integration = integration;
  };

  setDeliveryCostValue = (value: string) => {
    const currentVal: string | null = value ? value.replace(",", ".") : null;

    if (currentVal) {
      this.deliveryCost = +currentVal;
    } else {
      this.deliveryCost = null;
    }
  };
  setRefundCostValue = (value: string) => {
    const currentVal: string | null = value ? value.replace(",", ".") : null;

    if (currentVal) {
      this.refundCost = +currentVal;
    } else {
      this.refundCost = null;
    }
  };

  clearStore = () => {
    this.integration = null;

    this.userId = null;

    this.productSamples = [{ id: -9999999 } as any];

    this.sendAt = null;

    this.sendFrom = null;

    this.hasRefund = null;

    this.isSampleRefund = null;

    this.comment = null;

    this.deliveryCost = null;

    this.refundCost = null;
  };
}

export default new ProductSampleCreateStore();
