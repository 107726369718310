import {FC, useState, useEffect} from 'react'
import { IMotivationChannelnput } from './IMotivationChannelnput'
import { InputEditField } from '../InputEditField/InputEditField'
import { Stack, Typography } from '@mui/material'
import { priceTransormFormat } from '../../../../../shared/functions/functions'
import MotivationService from '../../../../../services/motivation/motivation.service'
import { IMotivationEditSearchPricesRequest } from '../../../../../services/motivation/IMotivationRequest'
import { AxiosError } from 'axios'
import { EditIconButton } from '../../../../UI/Buttons/EditIconButton/EditIconButton'
import { NotificationAlerts } from '../../../../NotificationAlerts/NotificationAlerts'

export const MotivationChannelnput:FC<IMotivationChannelnput> = (props) => {
  const {
    id,
    value,
    readOnly = false
  } = props

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [errorTextOpen, setErrorTextOpen] = useState(false)

  const [isEdit, setEdit] = useState(false)

  const [price, setPrice] = useState(value ? value?.toString() : '')

  const submit = (numValue:string) => {
    setLoading(true)
    setError(null)
    setErrorTextOpen(false)

    const option:IMotivationEditSearchPricesRequest = {
      id,
      value:+numValue,
      propertyType:'price'
    }
    MotivationService.editSearchPrices(option)
      .then(res=>{
        setPrice(res.data?.value?.toString())
        setEdit(false)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setLoading(false)
          setError(err?.response?.data?.message)
          setErrorTextOpen(true)
        }
      })
  }

  const closeEdit = () => {
    setEdit(false)
    setPrice(value?.toString())
  }

  useEffect(()=>{
    setPrice(value?.toString())
  },[value])

  return (
    <>
      {!isEdit &&
        <Stack direction={'row'} alignItems={'center'}>
          <Typography noWrap>{priceTransormFormat(price, true)}</Typography>
          {readOnly === false &&
            <EditIconButton size='small' onClick={()=>setEdit(true)}/>
          }
        </Stack>
      }
      {isEdit &&
        <InputEditField
          initValue={price}
          onClose={closeEdit}
          onSubmit={submit}
          error={error}
          loading={isLoading}
          placeholder={'Введите цену'}
          inputType='number'
        />
      }
      <NotificationAlerts
        error={{text:errorTextOpen ? `${error}` : '', open:errorTextOpen, onClose:()=>setErrorTextOpen(false)}}
      />
    </>
  )
}
