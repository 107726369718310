import React, {FC} from 'react'
import { Box} from '@mui/material';
import { useSearchParams } from 'react-router-dom'
import { IWrapperRouteAgree } from './IWrapperRouteAgree';

export const WrapperRouteAgree:FC<IWrapperRouteAgree> = React.memo((props) => {
  const {
    children,
    id,
    searchParamsKey
  } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const openSearchParams = (e:React.MouseEvent) => {
    if(e.altKey){
      searchParams.set(searchParamsKey, id)
      setSearchParams(searchParams)
    }
  }
  
  return (
    <Box sx={{display:'contents'}} onClick={(e)=>openSearchParams(e)}>
      {children}
    </Box>
  )
})