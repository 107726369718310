import { FC } from 'react'
import { Button } from '@mui/material'
import { IDeleteButton } from './IDeleteButton';
import { Delete } from '@mui/icons-material'
import { AlertModal } from '../../AlertModal/AlertModal';

export const DeleteButton:FC<IDeleteButton> = (props) => {
  const {
    onDelete,
    confirmationTitle,
    subtext,
    deleteButtonLabel = 'Удалить',
    cancelButtonLabel = 'Отмена',
    isLoading,
    showAlert,
    onOpenAlert,
    onCloseAlert,
    subContent,
  } = props
  
  return (
    <>
      <Button
        size='small' 
        variant='text'
        sx={{
          color:'rgba(0, 0, 0, 0.54)',
          '&:hover': {
            backgroundColor: '#FFDBDB',
            color: '#FF5252',
          },
          '&:active': {
            backgroundColor: '#FFC7C7',
            color:'#FF5252'
          }
        }}
        endIcon={<Delete/>}
        onClick={onOpenAlert}
      >
        {deleteButtonLabel}
      </Button>
      {showAlert &&
        <AlertModal
          title={confirmationTitle}
          subtext={subtext}
          subContent={subContent}
          open={showAlert}
          onClose={onCloseAlert}
          colorSubmit="error"
          onSubmit={onDelete}
          buttonCancelName={cancelButtonLabel}
          buttonSubmitName={deleteButtonLabel}
          loading={isLoading}
        />
      }
    </>
  )
}
