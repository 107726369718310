import {FC, useState} from 'react'
import { IAddIntegrationDate } from './IAddIntegrationDate'
import { Stack, Typography } from '@mui/material'
import { DateInputEdit } from '../../../../../../components/Table/ui/Fields/DateInputEdit/DateInputEdit'
import IntegrationsService from '../../../../../../services/integration/integrations.service'
import integrationTableStore from '../../../../../../store/integrationTable.store'
import { IUpdateStatusesRequest } from '../../../../../../services/integration/IntegrationsRequest'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { NotificationAlerts } from '../../../../../../components/NotificationAlerts/NotificationAlerts'
import { sendDatePlusCustomTime } from '../../../../../../shared/functions/functions'
import { EditIconButton } from '../../../../../../components/UI/Buttons/EditIconButton/EditIconButton'

export const AddIntegrationDate:FC<IAddIntegrationDate> = (props) => {
  const {
    id, 
    update_id, 
    onChangeUpdateId, 
    readOnly
  } = props

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<null | string>(null)

  const [isEdit, setEdit] = useState(false)
  const [value, setValue] = useState('')
  const [date, setDate] = useState('')

  const submit = (dateValue:string) => {

    if(dateValue && dayjs(dateValue)?.isValid()) {

      setLoading(true)
      setError(null)
      const currentDate:string = sendDatePlusCustomTime({value:dateValue})

      const option:IUpdateStatusesRequest = {
        field:'integration_date',
        id,
        new_value:currentDate,
        update_id
      }
      IntegrationsService.updateSimpleField(option)
        .then(res=>{
          // console.log(res, 'res upd table date')
          integrationTableStore.updateDate(id, res.data.update_id, currentDate, 'integration_date')
          integrationTableStore.changeGurange(id, res.data?.guarantee || null, res.data.update_id)
          
          setDate(currentDate)

          if(onChangeUpdateId){
            onChangeUpdateId(res.data.update_id)
          }
          
          setValue(dayjs(dateValue) as any)
          setEdit(false)
          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError) {
            // console.log(err, 'err update date in table')
            setLoading(false)
            setError(err?.response?.data?.message)
          }
        })
    }

  }

  const onClose = () => {
    setValue(date)
    setEdit(false)
  }

  return (
    <>
      <Stack direction={{sm:'row'}} alignItems={{sm:'center'}}>
        <Typography mr={1} >Запланированная дата:</Typography>
        {!isEdit
          ? <Stack spacing={0.5} direction={'row'} alignItems={'center'}>
              {date ===''
                ? <Typography>Добавить</Typography>
                : <Typography>{dayjs(date)?.format('DD.MM.YY HH:mm')}</Typography>
              }
              {!readOnly &&
                <EditIconButton size='small' onClick={()=>setEdit(true)}/>
              }
            </Stack>
          : <DateInputEdit
              minWidth={'20vw'}
              maxWidth={'350px'}
              width={'100%'}
              date={date}
              onChange={setValue}
              onClose={onClose}
              onOpen={()=>setEdit(true)}
              onSubmit={submit}
              open={isEdit}
              value={value}
              fullWidth
              loading={isLoading}
            />
        }
      </Stack>
      <NotificationAlerts
        error={{text:isError ? isError : '', open:isError ? true : false, onClose:()=>setError(null)}}
      />
    </>
  )
}