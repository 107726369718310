import {FC, useState} from 'react'
import { Box, MenuItem } from '@mui/material'
import { AlertModal } from '../../AlertModal/AlertModal'
import { ITableBurgerMenuItem } from '../models/ITableBurgerMenuItem'

export const TableBurgerMenuItem:FC<ITableBurgerMenuItem> = (props) => {
  const {
    title,
    alertProtectedSetting,
    onClick,
  } = props

  const [showAlertProtected, setShowAlertProtected] = useState(false)

  return (
    <Box>
      <MenuItem
        key={title}
        onClick={()=>setShowAlertProtected(true)}
      >
        {title}
      </MenuItem>
      {showAlertProtected && alertProtectedSetting &&
        <AlertModal
          title={alertProtectedSetting?.title}
          subtext={alertProtectedSetting?.subtext}
          open={showAlertProtected}
          onClose={()=>setShowAlertProtected(false)}
          onSubmit={()=>onClick()}
          buttonSubmitName={alertProtectedSetting.buttonSubmitName}
          buttonCancelName={alertProtectedSetting.buttonCancelName}
          colorSubmit={alertProtectedSetting.colorSubmit}
        />
      }
    </Box>
  )
}
