import {FC} from 'react'
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography} from '@mui/material'
import { ICustomSelect } from './ICustomSelect'

export const CustomSelect:FC<ICustomSelect> = (props) => {
  const {
    value,
    onChange,
    content,
    error,
    label,
    fullWidth,
    placeholder,
    disabled = false,
    sx,
    width,
    noWrap,
    errorText
  } = props

  return (
    <FormControl size='small' fullWidth={fullWidth} disabled={disabled} sx={{...sx, width:width}}>
      {label && <InputLabel id="select-label" size='small'>{label}</InputLabel>}
      <Select
        labelId="select-label"
        value={value}
        label={label}
        onChange={onChange}
        size='small'
        error={error}
        displayEmpty
        fullWidth={fullWidth}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return label ? selected : <Typography color={'grey'} sx={{opacity:'.65'}}>{placeholder}</Typography>
          } return <Typography noWrap={noWrap}>{content?.find(x=>x?.value === selected)?.name}</Typography>
        }}
      >
        {content.map(item=><MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)}
      </Select>
      {error && <FormHelperText sx={{color:'red'}} >{errorText}</FormHelperText>}
    </FormControl>
  )
}