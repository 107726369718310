import {FC, useState} from 'react'
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Skeleton, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import AllUsersStore from '../../../../../../store/AllUsers.store'
import { SelectCheckbox } from '../../../../../../components/SelectCheckbox/SelectCheckbox'
import { getManagerInSelect } from '../../../../../CreateChannelPage/shared/dataList'
import categoriesGoodsStore from '../../../../../../store/categoriesGoods.store'
import { getAllGoodsCategories } from '../../../../../../components/IntegrationTable/shared/dataList'
import {DateRangePicker} from "./ui/DateRangePicker/DateRangePicker";
import {IFilterAnalytics} from "./IFilterAnalytics";
import { CustomButton } from '../../../../../../components/UI/Buttons/CustomButton/CustomButton'
import {purple_color} from "../../../../../../app/styles/variables";

export const FilterAnalytics:FC<IFilterAnalytics> = observer((props) => {
  const {
    startDate,
    endDate,
    changeStartDate,
    changeEndDate,
    searchGoodsCategories,
    searchManagers,
    changeFilter,
    initFilterType,
    onExportExcel
  } = props

  const [filterType, setFilterType] = useState<'managers' | 'goodsCategories'>(initFilterType)


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value as 'managers' | 'goodsCategories'

    changeFilter(value)
    setFilterType(prev=>value)
  }

  return (
    <Stack spacing={1}>
      <FormControl>
        <FormLabel id="demo-row-radio">Использовать фильтр по:</FormLabel>
        <RadioGroup
          value={filterType}
          onChange={handleChange}
          row
          aria-labelledby="demo-row-radio"
        >
          <FormControlLabel sx={{'&:hover':{color:purple_color}}} value="managers" control={<Radio />} label="Менеджерам" />
          <FormControlLabel sx={{'&:hover':{color:purple_color}}} value="goodsCategories" control={<Radio />} label="Товарным группам" />
        </RadioGroup>
      </FormControl>
      {filterType === 'managers' &&
        <Stack spacing={1}>
          <Typography>Выбор менеджеров</Typography>
          {AllUsersStore.users
            ? <SelectCheckbox
                list={getManagerInSelect(AllUsersStore.users, true, false)} 
                searhParamName='ids'
                label='Менеджеры'
              />
            : <Skeleton variant='rounded' sx={{height:40, width:'100%'}}/>
          }
        </Stack>
      }
      {filterType === 'goodsCategories' &&
        <Stack spacing={1}>
          <Typography>Товарные группы</Typography>
          {categoriesGoodsStore.categories
            ? <SelectCheckbox
                list={getAllGoodsCategories(categoriesGoodsStore.categories)} 
                searhParamName='goodsCategory'
                label='Категории'
              />
            : <Skeleton variant='rounded' sx={{height:40, width:'100%'}}/>
          }
        </Stack>
      }
      <Stack spacing={1}>
        <Typography>Период</Typography>
        <DateRangePicker initStartDate={startDate} initEndDate={endDate} onChangeStart={changeStartDate} onChangeEnd={changeEndDate}/>
      </Stack>
      <Stack 
        direction={{xs:'column', sm:'row', md:'column', lg:'row'}} 
        justifyContent={'space-between'}
        spacing={2}
        sx={{pt:1}}
      >
        <Box>
          <CustomButton 
            variant='contained' 
            onClick={filterType === 'managers' ? searchManagers : searchGoodsCategories}
            disabled={!startDate || !endDate || !startDate.isValid() || !endDate.isValid()}
          >
            Подтвердить
          </CustomButton>
        </Box>
        <Box>
          <CustomButton variant='outlined' sx={{whiteSpace:'nowrap'}} onClick={()=>onExportExcel(filterType)}>выгрузить в excel</CustomButton>
        </Box>
      </Stack>
    </Stack>
  )
})