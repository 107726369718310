import {FC} from 'react'
import { IAsideNotification } from './models/IAsideNotification'
import { Badge, Divider, Drawer, FormControlLabel, List, Stack, Switch, Typography } from '@mui/material'
import { AsideNotificationItem } from './ui/AsideNotificationItem/AsideNotificationItem'
import { Cancel, NotificationsNone } from '@mui/icons-material'
import { getNewDay } from '../PostedCommentItem/shared/function'
import { getDatePlusCustomTime } from '../../shared/functions/functions'
import { CustomIconButton } from '../UI/Buttons/CustomIconButton/CustomIconButton'

export const AsideNotification:FC<IAsideNotification> = (props) => {
  const {
    open,
    onClose,
    anchor,
    notificationsQuantity,
    data,
    changeReadState,
    hideRead
  } = props

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={anchor}
    >
      <Stack
        direction={'row'} 
        alignItems={'center'} 
        justifyContent={'space-between'} 
        sx={{
          pt:2,
          pr:1,
          pl:2,
          pb:1
        }}
      >
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography variant='h5'>Ваши Оповещения</Typography>
          <Badge badgeContent={notificationsQuantity} color='error'>
            <NotificationsNone fontSize='large'/>
          </Badge>
        </Stack>
        <CustomIconButton size='medium' onClick={onClose}>
          <Cancel/>
        </CustomIconButton>
      </Stack>
      <Divider/>
      <Stack direction={'row'} justifyContent={'space-between'} sx={{p:1,pr:2,pl:2}}>
        <FormControlLabel control={<Switch color='secondary' onChange={hideRead} />} label="Скрыть прочитанные" />
      </Stack>
      <Divider/>
      <List sx={{p:2, width:{md:700, sm:500, xs:'100vw'}, overflow:'auto'}}>
        <Stack direction={'column'} spacing={1}>
          {data.length === 0 &&
            <Typography variant='h5' textAlign={'center'}>Новых уведомлений нет</Typography>
          }
          {data.map((item, i, arr)=>
            <AsideNotificationItem
              key={item.id}
              {...item}
              changeReadState={changeReadState}
              newDay={
                getNewDay(arr[i-1] ? 
                  getDatePlusCustomTime({value:arr[i-1].createdAt, format:'DD.MM.YY'}) : undefined, 
                  getDatePlusCustomTime({value:item.createdAt,  format:'DD.MM.YY'})
                )
              }
            />)
          }
        </Stack>
      </List>
    </Drawer>
  )
}
