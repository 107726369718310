import { AxiosResponse } from "axios";
import $gateway_api from "../../shared/api/gatewayAxios";
import { INotificationItem } from "../../components/AsideNotification/ui/AsideNotificationItem/IAsideNotificationItem";

export default class NotificationsService {

  static getAllNotifications():Promise<AxiosResponse<INotificationItem[]>>{
    return $gateway_api.get('/api/notifications/all')
  }

  static readNotification(id:number):Promise<AxiosResponse>{
    return $gateway_api.post('/api/notifications/read', {id})
  }

}