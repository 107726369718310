import {useSearchParams} from "react-router-dom";
import {INTEGRATION_CARD_OPEN} from "../../../../../../shared/constants/searchParams";
import React from "react";
import {PrePaymentTableListArr} from "./PrePaymentTableListArr";


export interface IIntegrationTableList {
  tableHead:any[];
}
export const PrePaymentTableList = (props:IIntegrationTableList)=>{
  const [searchParams, setSearchParams] = useSearchParams()

  const navigateAside = (id:string | number) => {
    const params = new URLSearchParams(window.location.search)
    params.set(INTEGRATION_CARD_OPEN, id?.toString())
    searchParams.set(INTEGRATION_CARD_OPEN, id?.toString())
    setSearchParams(params)
  }
  return (
    <PrePaymentTableListArr tableHead={props.tableHead} navigateAside={navigateAside}/>
  )
}