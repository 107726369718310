import { FC, useEffect, useState } from 'react'
import { IFilterSearchAgreements } from './IFilterSearchAgreements'
import { useSearchParams } from 'react-router-dom'
import { Autocomplete, Avatar, Box, CircularProgress, FormControl, TextField, Typography } from '@mui/material'
import ReferencesService from '../../../../../../../../services/references/references.service'
import { AxiosError } from 'axios'
import { IIntegrationsFullResponse } from '../../../../../../../../services/integration/IIntegrationsResponse'

export const FilterSearchAgreements:FC<IFilterSearchAgreements> = (props) => {
  const {
    label,
    searchParamsName
  } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const queryValue:IIntegrationsFullResponse | null = JSON.parse(searchParams.get(searchParamsName) || 'null')

  const [isLoadingSearch, setLoadingSearch] = useState(false)
  const [errorSearch, setErrorSearch] = useState<string | null>(null)

  const [value, setValue] = useState<IIntegrationsFullResponse | null>(queryValue)
  const [integrationList, setIntegrationList] = useState<IIntegrationsFullResponse[] | null>(null)
  const [inputValue, setInputValue] = useState<string>('')

  const getIntegrationList = (value:string) => {
    setLoadingSearch(true)
    setIntegrationList(null)
    setErrorSearch(null)

    ReferencesService.hintsAgreements({target:value})
      .then(res=>{
        // console.log(res, 'res')

        setIntegrationList(res.data)
        setLoadingSearch(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setErrorSearch(err?.response?.data?.message)
          setLoadingSearch(false)
        }
      })
  }

  const changeUrlValue = (items:IIntegrationsFullResponse) => {
    if (!inputValue){
      searchParams.delete(searchParamsName)
      setSearchParams(searchParams)
    } else {
      searchParams.set(searchParamsName, JSON.stringify(items))
      setSearchParams(searchParams)
    }
  }

  useEffect(()=>{
    if(value){
      changeUrlValue(value)
    } else {
      searchParams.delete(searchParamsName)
      setSearchParams(searchParams)
    }
  },[value])

  useEffect(() => {
    if(inputValue.trim() !== ''){

      if(inputValue !== `${value?.id?.toString()} ${value?.channel?.name || ''}`){
        const delay = setTimeout(()=> getIntegrationList(inputValue), 500)
        return ()=>clearTimeout(delay)
      }

    }
  },[inputValue])

  return (
    <FormControl size='small' fullWidth>
      <Typography sx={{mb:0.5}}>{label}</Typography>
      <Autocomplete
        value={value}
        onChange={(event: any, newValue: IIntegrationsFullResponse | null) => {
          setValue(newValue)
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue)
        }}
        options={integrationList || []}
        getOptionLabel={(option) => `${option.id?.toString()} ${option?.channel?.name || ''}`}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <Avatar src={option?.channel?.type?.icon} alt={option?.channel?.name} sx={{height:20, width:20, mr:1}}/>
            <Typography>{option?.id} {option?.channel?.name}</Typography>
          </Box>
        )}
        noOptionsText='Ничего не найдено'
        loading={isLoadingSearch}
        loadingText={'Загрузка...'}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={'ID интеграции / Название канала'}
            size='small'
            autoFocus
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoadingSearch ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        size='small'
      />
    </FormControl>
  )
}
