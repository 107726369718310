import {FC} from 'react'
import { IAddPlanRow, IAddPlanTable } from './IAddPlanTable'
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { AddPlanInput } from './ui/AddPlanInput/AddPlanInput'

export const AddPlanTable:FC<IAddPlanTable> = (props) => {
  const {
    crFound,
    crGiven,
    factFound,
    factGiven,
    id,
    isGeneral,
    month,
    planFound,
    planGiven,
    userId,
    year
  } = props

  const monthNames:string[] = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]

  const rows:IAddPlanRow[] = [
    {title:'Найден', plan:planFound, fact:factFound, planCode:'planFound'},
    {title:'Передан', plan:planGiven, fact:factGiven, planCode:'planGiven'},
    {title:'Конверсия (CR)', plan:crFound, fact:crGiven,},
  ]

  return (
    <Box
      sx={{
        display:'grid', 
        gridAutoRows: 'min-content', 
        width:{xl:'50%', lg:'75%', md:'65%', xs:'100%'}
      }}
    >
      <Typography sx={{mb:0.5, typography: {xs:'body1', sm:'h6'}}}>План на {monthNames[month-1]}</Typography>
      <TableContainer
        component={Paper} 
        elevation={0}
        sx={{
          border:'1px solid #e0e0e0',
          borderBottom:'none', 
          borderRadius:0,
          borderLeft:'none',
          height:'min-content',
        }}
      >
        <Table
          stickyHeader
          sx={{
            "& .MuiTableCell-root": {borderLeft: '1px solid #e0e0e0'},
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{p:1, width:130, background:' #f2f3f5'}}>
                <Typography variant="body1" fontWeight={'bold'} whiteSpace='nowrap'>Канал</Typography>
              </TableCell>
              <TableCell align="center" sx={{p:1, minWidth:250, background:' #f2f3f5'}}>
                <Typography variant="body1" fontWeight={'bold'}>План</Typography>
              </TableCell>
              <TableCell align="center" sx={{p:1, minWidth:150, background:' #f2f3f5'}}>
                <Typography variant="body1" fontWeight={'bold'}>Факт</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map( (row, i)=>
              <TableRow key={row.title}>
                <TableCell sx={{p:1}}>
                  <Typography>{row.title}</Typography>
                </TableCell>
                <TableCell sx={{p:1}}>
                  {row.planCode
                    ? <AddPlanInput
                        value={row.plan}
                        planType={row.title === 'Передан' ? 'given_channels' : 'found_channels'}
                        month={month}
                        year={year}
                        field={row.planCode}
                      />
                    : <Typography>{row.plan} %</Typography>
                  }
                </TableCell>
                <TableCell sx={{p:1}}>
                  <Typography>{row.fact} {i===2 ? '%' : ''}</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
