import { AxiosProgressEvent, AxiosResponse } from "axios";
import $gateway_api from "../../shared/api/gatewayAxios";
import { IStatisticsGoodsCategoriesRequest, IStatisticsManagersRequest, IStatisticsRequestGeneral } from "./IStatisticsRequest";
import { IAllManagerSummaryResponse, IGoodsCategoriesSummaryResponse, IStatisticsResponseGeneral } from "./IStatisticsResponse";

export default class StatisticsService {

  static getGeneralStatistics(option:IStatisticsRequestGeneral): Promise<AxiosResponse<IStatisticsResponseGeneral>>{
    return $gateway_api.post('/api/statistics/general', option)
  }

  static getManagerSummary(option:IStatisticsManagersRequest): Promise<AxiosResponse<IAllManagerSummaryResponse[]>>{
    return $gateway_api.post('/api/statistics/managerSummary', option)
  }

  static getGoodsCategoriesSummary(option:IStatisticsGoodsCategoriesRequest): Promise<AxiosResponse<IGoodsCategoriesSummaryResponse[]>>{
    return $gateway_api.post('/api/statistics/goodsCategoriesSummary', option)
  }

  static managerSummaryExcel(option:IStatisticsManagersRequest, onDownloadProgress?:((progressEvent: AxiosProgressEvent)=>void)):Promise<AxiosResponse<Blob>>{
    return $gateway_api.post('/api/statistics/managerSummary/excel', option, {
      responseType:'blob',
      onDownloadProgress
    })
  }

  static goodsCategoriesSummaryExcel(option:IStatisticsGoodsCategoriesRequest, onDownloadProgress?:((progressEvent: AxiosProgressEvent)=>void)):Promise<AxiosResponse<Blob>>{
    return $gateway_api.post('/api/statistics/goodsCategoriesSummary/excel', option, {
      responseType:'blob',
      onDownloadProgress
    })
  }

}