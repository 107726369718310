import {useEffect} from 'react'
import userStore from '../../store/user.store'
import {observer} from 'mobx-react-lite'
import {useNavigate} from 'react-router-dom'
import {ROUTES} from '../../shared/routes/routes'
import {ALL_ROLES} from "../../shared/rules/interface/EnumRights";

export const MainPage = observer(() => {
  const navigate = useNavigate()

  useEffect(()=>{
    if(userStore.currentCompany !=='' ) {

      if (userStore.user?.role === ALL_ROLES.FINANCE_MANAGER) {
        navigate(userStore.currentCompany + '/' + ROUTES.FINANCES)
      } else if (userStore.user?.role === ALL_ROLES.CONTENT_MANAGER) {
        navigate(userStore.currentCompany + '/' + ROUTES.CHANNELS)
      } else
        navigate(userStore.currentCompany + '/' + ROUTES.INTEGRATIONS)

    }
  }, [userStore.currentCompany])

  return (
    <div></div>
  )
})