import { FC, useState } from 'react'
import { IPromokodList } from './models/IPromokodList'
import { PromokodItem } from './ui/PromokodItem'
import { Box, Button, Menu, Tooltip, Typography } from '@mui/material'
import { ErrorOutline, Help } from '@mui/icons-material'
import { NotificationAlerts } from '../NotificationAlerts/NotificationAlerts'
import { ActivatePromoModal } from '../ActivatePromoModal/ActivatePromoModal'
import { AlertModal } from '../AlertModal/AlertModal'
import {RemoveItemButton} from "../UI/Buttons/RemoveItemButton/RemoveItemButton";
import { EditIconButton } from '../UI/Buttons/EditIconButton/EditIconButton'
import { CopyIconButton } from '../UI/Buttons/CopyIconButton/CopyIconButton'
import { CustomIconButton } from '../UI/Buttons/CustomIconButton/CustomIconButton'

export const PromokodList:FC<IPromokodList> = (props) => {
  const {
    promokod,
    dummies,
    readOnly = false,
    id,
    isEditMode = false,
    isEdit,
    openEdit,
    addDummyComponent,
    isAddDummy,
    setAddDummy,
    removeDummyPromokod,
    isActivated,
    isOpenActivatedModal,
    isShowActivatedBtn,
    onCloseActivatedModal,
    onSubmitActivatedModal,
    onOpenActivatedModal,
    isLoadingActivated,
    sx,
    onDeletePromokod,
    disableActiveOnSiteBtn = false,
    goodsCategoryCode,
  } = props

  const [isShowAlert, setShowAlert] = useState(false)
  const [isShowDelete, setShowDelete] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget as any)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const copyText = (text:string) => {
    navigator.clipboard.writeText(text)
    setShowAlert(true)
    setAnchorEl(null)
  }
  
  return (
    <>
      <Box sx={{display:'inline-flex', alignItems:'center', verticalAlign:'super', ...sx}}>
        {!isEdit &&
          <>
            <Typography sx={{color: isActivated === false ? '#f44336' : 'inherit'}}>{promokod}</Typography>
            <CopyIconButton copyText={promokod} onCopyChange={()=>handleClose()}/>
            {isActivated && 
              <CustomIconButton onClick={handleClick} size='small'>
                <ErrorOutline fontSize='small'/>
              </CustomIconButton>
            }
            {isShowActivatedBtn && onOpenActivatedModal && isActivated === false && !readOnly &&
              <Box sx={{position:'relative'}}>
                <Button
                  sx={{ml:1}} 
                  variant='outlined' 
                  size='small' 
                  onClick={onOpenActivatedModal}
                  disabled={readOnly || disableActiveOnSiteBtn}
                >
                  завести на сайте
                </Button>
                {disableActiveOnSiteBtn === true &&
                  <Tooltip 
                    title={<Typography>Проверьте заполненность полей <br /> "Категория товаров в интеграции" <br />"Товары"</Typography>}
                    placement='top'
                    PopperProps={{
                      modifiers:[{name:'offset', options:{offset:[0,-10]}}]
                    }}
                  >
                    <span>
                      <CustomIconButton
                        size='small'
                        sx={{
                          position:'absolute',
                          top:'-14px', 
                          left:'-7px',
                        }}
                      >
                        <Help
                          fontSize='medium'
                        />
                      </CustomIconButton>
                    </span>
                  </Tooltip>
                }
              </Box>
            }
            {isEditMode && !readOnly && isActivated === false &&
              <EditIconButton onClick={openEdit} size='small'/>
            }
            <RemoveItemButton onClick={()=>setShowDelete(true)} disabled={readOnly}/>
          </>
        }
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <PromokodItem
            title={promokod} 
            onClick={()=>copyText(promokod)} 
            isMainPromokod 
            mainTitle={promokod} 
            isActivated={isActivated}
          />
          {dummies.map(item=>
            <PromokodItem 
              key={item}
              title={item} 
              mainTitle={promokod}
              readOnly={readOnly} 
              onClick={()=>copyText(item)} 
              onDeleted={removeDummyPromokod}
              isMainPromokod={false}
              isActivated={isActivated}
            />
          )}
          {!readOnly && isActivated === false &&
            <Box sx={{pl:1, pr:1, mt:0.5}}>
              <Button variant='outlined' size='small' fullWidth onClick={setAddDummy}>Добавить</Button>
            </Box>
          }
        </Menu>
        {isAddDummy && addDummyComponent &&
          addDummyComponent
        }
      </Box>
      <NotificationAlerts
        sucsess={{text:'Успешно скопировалось!', open:isShowAlert, onClose:()=>setShowAlert(false)}}
      />
      {isOpenActivatedModal && onCloseActivatedModal && onSubmitActivatedModal &&
        <ActivatePromoModal
          goodsCategoryCode={goodsCategoryCode}
          open={isOpenActivatedModal} 
          onClose={onCloseActivatedModal} 
          onSubmit={onSubmitActivatedModal}
          loading={isLoadingActivated}
        />
      }
      {isShowDelete && id && onDeletePromokod &&
        <AlertModal
          title='Внимание!'
          subtext='Вы действительно хотите удалить промокод'
          open={isShowDelete}
          onClose={()=>setShowDelete(false)}
          onSubmit={()=>onDeletePromokod(+id)}
          colorSubmit='primary'
          loading={false}
          buttonCancelName='отмена'
          buttonSubmitName='удалить'
        />
      }
    </>
  )
}