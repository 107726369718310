import {FC} from 'react'
import { IOrdBlock } from './IOrdBlock'
import { AsideCardLayout } from '../../../../components/AsideCardLayout/AsideCardLayout'
import { EditModeButton } from '../../../../components/UI/Buttons/EditModeButton/EditModeButton'
import { Box, Stack, Typography } from '@mui/material'
import { OrdCard } from './ui/OrdCard/OrdCard'

export const OrdBlock:FC<IOrdBlock> = (props) => {
  const {
    ordInfo,
    isEditMode,
    closeEdit,
    readOnly,
    startEdit,
    submit,
    isLoading,
    changeEditCheckbox,
    changeSimpleEditInput,
    changeSelect,
  } = props

  return (
    <AsideCardLayout
      headerTitle='ОРД'
      rightContent={
        <>
          {!(ordInfo === null || ordInfo?.length===0) &&
            <EditModeButton
              isEdit={isEditMode}
              onClose={closeEdit}
              onOpenEdit={startEdit}
              onSubmit={submit}
              loading={isLoading}
              disabledEdit={readOnly}
            />
          }
        </>
      }
    >
      {(ordInfo === null || ordInfo?.length===0) && !isEditMode &&
        <Box sx={{mt:'6px'}}>
          <Typography noWrap>Чтобы добавить ОРД, добавьте интеграцию</Typography>
        </Box>
      }
      {(ordInfo && ordInfo?.length>0) &&
        <Stack>
          {ordInfo?.map((item, i)=>
            <OrdCard
              key={item.id}
              ordInfo={{...item}}
              changeEditCheckbox={changeEditCheckbox}
              changeSelect={changeSelect}
              changeSimpleEditInput={changeSimpleEditInput}
              isEditMode={isEditMode}
              isLoading={isLoading}
              showDivider={i===ordInfo?.length-1 ? false : true}
            />
          )}
        </Stack>
      }
    </AsideCardLayout>
  )
}
