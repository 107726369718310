import { makeAutoObservable } from "mobx";
import { AxiosError } from "axios";
import ReferencesService from "../services/references/references.service";
import { IChannelCreateRequestSelect } from "../services/channel/IChannelRequest";

class AdvertisersStore {
  advertisers:IChannelCreateRequestSelect[] | null = null

  constructor(){
    makeAutoObservable(this)
  }

  getAdvertisers(){
    ReferencesService.getAdvertisers()
      .then(res=>{
        // console.log(res, 'res all advertisers')
        this.advertisers = res.data
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err all advertisers')
        }
      })
  }

}

export default new AdvertisersStore()