import { FC, useState, useEffect} from 'react'
import { TextField } from "@mui/material"
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout"
import productSampleCreateStore from '../../../../store/productSampleCreate.store'
import { IGoodComments } from './IGoodComments'

export const GoodComments:FC<IGoodComments> = (props) => {
  const {disabled} = props
  const [value, setValue] = useState('')

  useEffect(() => {
    const delay = setTimeout(()=> productSampleCreateStore.setComment(value), 450)
    return ()=>clearTimeout(delay)
  },[value])

  return (
    <AsideCardLayout headerTitle="Комментарий" sx={{height:'fit-content'}}>
      <TextField
        value={value}
        onChange={e=>setValue(e.target.value)}
        size="small"
        fullWidth
        placeholder="Введите комментарий"
        sx={{mt:1}}
        disabled={disabled}
      />
    </AsideCardLayout>
  )
}