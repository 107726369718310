import {FC, useState, useEffect} from 'react'
import { ITzSelectField } from './ITzSelectField'
import { TzSelect } from '../TzSelect/TzSelect'
import { observer } from 'mobx-react-lite'
import { ITzSimpleObjResponse } from '../../../../../services/tz/ITzResponse'
import ReferencesService from '../../../../../services/references/references.service'
import { AxiosError } from 'axios'
import { SelectChangeEvent, Stack, Typography} from '@mui/material'
import { ITzUpdateSimpleFieldRequest } from '../../../../../services/tz/ITzRequest'
import TzService from '../../../../../services/tz/tz.service'
import tzTableStore from '../../../../../store/tzTable.store'
import {Close} from '@mui/icons-material'
import {EmptyText} from "../../../../EmptyText/EmptyText";
import { EditIconButton } from '../../../../UI/Buttons/EditIconButton/EditIconButton'
import { CustomIconButton } from '../../../../UI/Buttons/CustomIconButton/CustomIconButton'

export const TzSelectField:FC<ITzSelectField> = observer((props) => {
  const {
    field,
    id,
    initValue,
    update_id,
    isEditIcon
  } = props

  const [value, setValue] = useState<string>(initValue ? initValue?.code : '')

  const [selectItems, setSelectItems] = useState<null | ITzSimpleObjResponse[]>(null)

  const [isLoading, setLoading] = useState(false)

  const [isEdit, setEdit] = useState(false)

  const getAllSelectItems = () => {
    setLoading(true)

    ReferencesService.referencesTz({field})
      .then(res=>{
        setSelectItems(res.data)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setLoading(false)
        }
      })
  }

  const changeSelect = (e:SelectChangeEvent | null) => {
    setLoading(true)

    const option: ITzUpdateSimpleFieldRequest = {
      field,
      id,
      new_value:e ? e.target.value : null!,
      update_id
    }
    TzService.updateSimpleField(option)
      .then(res=>{
        setValue(e ? e.target.value : '')

        if(selectItems && e){
          tzTableStore.updateSimpleField(id, field, res.data[field], res.data.update_id)
        } else {
          tzTableStore.updateSimpleField(id, field, null, res.data.update_id)
        }

        setEdit(false)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError) {
          setLoading(false)
        }
      })
  }

  useEffect(()=>{
    setValue(initValue ? initValue?.code : '')
  },[initValue])

  return (
    <>
      {!isEdit &&
        <Stack direction={'row'} onClick={()=>setEdit(true)} alignItems={'center'}>
            {initValue
              ? <Typography noWrap sx={{cursor:'pointer'}}>{initValue?.name}</Typography>
              : <EmptyText noWrap sx={{cursor:'pointer'}}>Нет данных</EmptyText>
            }
            {isEditIcon &&
              <EditIconButton size='small' onClick={()=>setEdit(true)}/>
            }
        </Stack>
      }
      {isEdit &&
        <Stack direction={'row'} alignItems={'center'}>
          <TzSelect
            initValue={initValue}
            value={value}
            onChange={changeSelect}
            list={selectItems}
            loading={isLoading}
            onOpen={selectItems === null ? getAllSelectItems : undefined}
          />
          <CustomIconButton disabled={isLoading} size='small' onClick={()=>setEdit(false)}>
            <Close fontSize='small'/>
          </CustomIconButton>
        </Stack>
      }
    </>
  )
})