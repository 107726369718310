import {FC} from 'react'
import { IManagerField } from './IManagerField'
import { observer } from 'mobx-react-lite'
import AllUsersStore from '../../../../../store/AllUsers.store'
import { Typography } from '@mui/material'

export const ManagerField:FC<IManagerField> = observer((props) => {
  const {id} = props

  const currentUser = AllUsersStore.users?.find(x=>x.id === id)

  return (
    <Typography sx={{whiteSpace:'nowrap'}}>{currentUser?.firstName} {currentUser?.lastName}</Typography>
  )
})