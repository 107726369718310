import { FC, useEffect, useState, memo } from 'react'
import { Autocomplete, Stack, TextField, Typography } from '@mui/material'
import { IFilterMultipleAuto } from './IFilterMultipleAuto'
import { useSearchParams } from 'react-router-dom'

export const FilterMultipleAuto:FC<IFilterMultipleAuto> = memo((props) => {
  const {
    label, 
    placeholder, 
    searchParamName, 
    inputType
  } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const [value, setValue] = useState<string[]>(searchParams.get(searchParamName)?.split(',') || [])
  const [inputValue, setInputValue] = useState<string>('')

  const changeUrlValue = () => {
    if (value.length === 0 ){
      searchParams.delete(searchParamName)
      setSearchParams(searchParams)
    } else {
      searchParams.set(searchParamName, `${value}`)
      setSearchParams(searchParams)
    }
  }
  
  useEffect(()=>{
    changeUrlValue()
  },[value])

  return (
    <Stack>
      <Typography
        variant='body2'
        sx={{height:27, display:'inline-flex'}}
        alignItems={'center'}
        justifyContent={'start'}
      >
        {label}
      </Typography>
      <Autocomplete
        value={value}
        onChange={(event: any, newValue: string[]) => {
          setValue(newValue)
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue)
        }}
        multiple
        options={[]}
        limitTags={3}
        getOptionLabel={(option) => option}
        ChipProps={{variant:'outlined'}}
        filterSelectedOptions
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            size='small'
            type={inputType}
          />
        )}
        size='small'
      />
    </Stack>
  )
})
