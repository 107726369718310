import {FC, useEffect, useState} from 'react'
import { IGoodsInputNum } from './IGoodsInputNum'
import { TextField } from '@mui/material'

export const GoodsInputNum:FC<IGoodsInputNum> = (props) => {
  const {
    disabled,
    defaultValue,
    setValue,
    placeholder = 'Введите стоимость' 
  } = props

  const [inputValue, setInputValue] = useState(defaultValue)

  const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {

    const { value } = e.target

    setInputValue(
      value
        .replace(/[^\d.,]/g, '')
        .replace(/^(\d*[\.,]?\d{0,2})[\.,]?.*$/, '$1')
    )
  }

  useEffect(()=>{

    const delayInputTimeoutId = setTimeout(() => {
      setValue(inputValue)
    }, 300)

    return () => clearTimeout(delayInputTimeoutId)

  }, [inputValue])

  return (
    <TextField
      size='small' 
      fullWidth 
      placeholder={placeholder}
      type='text' 
      disabled={disabled} 
      value={inputValue}
      onChange={onChange}
    />
  )
}
