import {AxiosResponse} from 'axios'
import $gateway_api from '../../shared/api/gatewayAxios'
import { ILayoutResponse } from './layoutResponse'

export default class LayoutService {

  static getLayoutData(): Promise<AxiosResponse<ILayoutResponse>>{
    return $gateway_api.get('/api/common/layout')
  }
    
}