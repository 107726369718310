import { IFilterSelectItem } from "../../../../../../../components/Filters/UI/FilterSelectChip/IFilterSelectChip"
import { IUser } from "../../../../../../../entities/user/models/IUser"
import { ALL_ROLES, ALL_RULES } from "../../../../../../../shared/rules/interface/EnumRights"

export const getCategotyManager = ():IFilterSelectItem[] => {
  return [
    {name:'Выпускающий менеджер', value:'release'},
    {name:'Поисковый менеджер', value:'search'},
  ]
}

interface IGetManagersOnType {
  managerType:'search'| 'release',
  allUsers:IUser[]
}

export const getManagersOnType = (options:IGetManagersOnType):IFilterSelectItem[] => {
  const {allUsers, managerType} = options

  if(managerType === 'search'){

    return allUsers
      .filter(user=>user.isActive === true)
      .filter(user=>user.role === ALL_ROLES.SEARCH_MANAGER)
      .map(user=>{
        const newItem:IFilterSelectItem = {
          name:`${user.firstName} ${user.lastName}`,
          value:user.id?.toString()
        }

        return newItem
      })

  } else {

    return allUsers
      .filter(user=>user.isActive === true)
      .filter(user=>user.rights.find(x=>x.code === ALL_RULES.release))
      .map(user=>{
        const newItem:IFilterSelectItem = {
          name:`${user.firstName} ${user.lastName}`,
          value:user.id?.toString()
        }

        return newItem
      })
  }
}