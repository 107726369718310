import {FC, useState, useEffect} from 'react'
import { ICreateInputFIeld } from './ICreateInputFIeld'
import { Box, Stack, TextField, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'

export const CreateInputFIeld:FC<ICreateInputFIeld> = observer((props) => {
  const {
    title, 
    inputType = 'text', 
    value, 
    onSubmit, 
    placeholder,
    required = false,
    error,
    errorText
  } = props

  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    const delay = setTimeout(()=> onSubmit(inputValue), 300)
    return ()=>clearTimeout(delay)
  },[inputValue])

  return (
    <Stack direction={{xs:'column', sm:'row'}} alignItems={{sm:'center'}}>
      <Typography sx={{width:{xs:'100%', sm:'170px'}}}>
        {title} {required && <Typography component={'span'} color={'error'}>*</Typography>}
      </Typography>
      <Box sx={{width:{xs:'100%', sm:'70%'}}}>
        <TextField
          size='small'
          placeholder={placeholder}
          type={inputType}
          error={error}
          fullWidth
          value={inputValue}
          onChange={e=>setInputValue(e.target.value)}
        />
        {error && <Typography variant='body2' color={'error'}>{errorText}</Typography>}
      </Box>
    </Stack>
  )
})