import { Backdrop, Box, CircularProgress, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { IUser } from "../../../../entities/user/models/IUser";
import { ChannelCardPage } from "../../../../pages/ChannelCardPage/ChannelCardPage";
import { TableAsidePage } from "../../../../pages/TableAsidePage/TableAsidePage";
import {
  CHANNEL_CARD_OPEN_REPEAT,
  INTEGRATION_CARD_OPEN_REPEAT,
} from "../../../../shared/constants/searchParams";
import repeatIntegrationStore from "../../../../store/repeatIntegration.store";
import { AsideCardLayout } from "../../../AsideCardLayout/AsideCardLayout";
import { RepeatTable } from "./ui/RepeatTable/RepeatTable";
import { UserRepeatDatePicker } from "./ui/UserRepeatDatePicker/UserRepeatDatePicker";

export const UserRepeatPage: FC<IUser> = observer((props) => {
  const { company, email, firstName, id, imageUrl, isActive, lastName, rights, role, roleName } =
    props;

  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading } = repeatIntegrationStore;

  const navigateAside = (id: string | number) => {
    const params = new URLSearchParams(window.location.search);
    params.set(INTEGRATION_CARD_OPEN_REPEAT, id?.toString());

    searchParams.set(INTEGRATION_CARD_OPEN_REPEAT, id?.toString());

    setSearchParams(params);
  };

  const closeIntegration = () => {
    searchParams.delete(INTEGRATION_CARD_OPEN_REPEAT);
    setSearchParams(searchParams);
  };

  const closeChannel = () => {
    searchParams.delete(CHANNEL_CARD_OPEN_REPEAT);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    repeatIntegrationStore.getCandidatesForRepeat(id);

    return () => {
      repeatIntegrationStore.setIntegrations(null);
    };
  }, []);

  return (
    <Box overflow={'hidden'}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, height: "100vh" }}
        open={repeatIntegrationStore.isLoadingSort}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AsideCardLayout sx={{ mt: 2, maxWidth: "calc(100vw - 18px)" }}>
        <Box
          sx={{
            overflow: "hidden",
            height: "83vh",
          }}>
          {isLoading && (
            <Stack direction={"row"} alignItems={"center"} spacing={1} justifyContent={"center"}>
              <Typography variant="h5">Идёт загрука интеграций...</Typography>
              <CircularProgress sx={{ height: 50, width: 50 }} />
            </Stack>
          )}
          {!isLoading && repeatIntegrationStore.inegrations !== null && (
            <Stack direction={"column"} spacing={2} sx={{ overflow: "hidden", height: "100%" }}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "start", sm: "center" }}
                spacing={4}>
                <Typography variant="h6">Таблица интеграций на повтор</Typography>
                <Stack spacing={0.5} direction={"row"}>
                  <Typography>Данные за:</Typography>
                  <UserRepeatDatePicker userId={id} />
                </Stack>
              </Stack>
              {repeatIntegrationStore.inegrations !== null &&
              !!repeatIntegrationStore.inegrations?.length ? (
                <RepeatTable
                  integrations={repeatIntegrationStore.inegrations}
                  navigateAside={navigateAside}
                />
              ) : (
                <Typography variant="h5" textAlign={"center"}>
                  Интеграций за выбранный период не найдено
                </Typography>
              )}
            </Stack>
          )}
        </Box>
      </AsideCardLayout>
      {searchParams.get(INTEGRATION_CARD_OPEN_REPEAT) && (
        <TableAsidePage
          integrationId={searchParams.get(INTEGRATION_CARD_OPEN_REPEAT)!}
          onClose={closeIntegration}
          channelSearchParamsKey={CHANNEL_CARD_OPEN_REPEAT}
        />
      )}
      {searchParams.get(CHANNEL_CARD_OPEN_REPEAT) && (
        <ChannelCardPage
          channelId={searchParams.get(CHANNEL_CARD_OPEN_REPEAT)!}
          onClose={closeChannel}
        />
      )}
    </Box>
  );
});
