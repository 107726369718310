import {useState, useEffect} from 'react'
import { AsideCardLayout } from '../../../../../../../../components/AsideCardLayout/AsideCardLayout'
import { TextField } from '@mui/material'
import integrationPageStore from '../../../../../../../../store/integrationPage.store'
import { observer } from 'mobx-react-lite'

export const CommentsCreate = observer(() => {
  const [value, setValue] = useState('')

  useEffect(() => {
    const delay = setTimeout(()=> integrationPageStore.changeComment(value), 450)
    return ()=>clearTimeout(delay)
  },[value])

  return (
    <AsideCardLayout headerTitle="Комментарий" sx={{height:'fit-content'}}>
        <TextField
          value={value}
          onChange={e=>setValue(e.target.value)}
          size="small"
          fullWidth
          placeholder="Введите комментарий"
          sx={{mt:1}}
        />
    </AsideCardLayout>
  )
})