import { FC, useEffect } from 'react'
import { IAsideTableItem } from './IAsideTableItem'
import { Box, Typography } from '@mui/material'
import {EmptyText} from "../../../../../../components/EmptyText/EmptyText";

export const AsideTableItem:FC<IAsideTableItem> = (props) => {
  const {
    title, 
    value, 
    textData, 
    currentValue, 
    borderBottom = true,
    keyName,
    onClick,
    symbol,
    secondRow,
    isOnlyText
  } = props

  const handelClick = () => {
    if(onClick && value !== undefined && keyName){
      onClick(value, keyName)
    }
  }

  useEffect(()=>{
    if(value === currentValue){
      handelClick()
    }
  },[])

  return (
    <Box
      sx={{
        display:"grid", 
        gridTemplateColumns:isOnlyText ? '1fr' : {xl:'1fr min-content',lg:'1fr min-content',md:'1fr', xs:'1fr'}, 
        p:{lg:1, xs:1},
        borderBottom:borderBottom ? '1px solid #e0e0e0':'none',
        cursor:isOnlyText ? 'auto' : 'pointer',
        transition:'all ease .3s',
        '&:hover':isOnlyText
          ? {}
          : {
              background:value === currentValue ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)'
            },
        background:value === currentValue ? 'rgba(0, 0, 0, 0.05)' : 'inherit',
        alignItems:'center'
      }}
      onClick={handelClick}
    >
      <Box sx={{display: 'flex', flexDirection:'column'}}>
        <Typography 
          variant='subtitle1' 
          sx={{fontWeight:isOnlyText ? 'bold' : 'normal', fontStyle:isOnlyText ? 'italic' : 'normal'}}
        >
          {title}
        </Typography>
        {secondRow ? <Typography variant='subtitle1'>{secondRow}</Typography>:false}
      </Box>
      <Typography variant='subtitle1' sx={{whiteSpace:'nowrap'}}>{textData !== null ? textData : <EmptyText>Нет данных</EmptyText>} {symbol && textData ? symbol : null}</Typography>
    </Box>
  )
}