import { FC, useState } from 'react'
import { AddIntegrationModal } from "../AddIntegrationModal/AddIntegrationModal"
import { IAddIntegrationWrapperButton } from './IIAddIntegrationWrapperButton'
import IntegrationsService from '../../../../../../services/integration/integrations.service'
import createPaymentStore from '../../../../../../store/createPayment.store'
import { AxiosError } from 'axios'

export const AddIntegrationWrapperButton:FC<IAddIntegrationWrapperButton> = (props) => {
  const {setIntegrations} = props

  const [isShowModal, setShowModal] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const addedIntegration = (id:string, closeCallback:()=>void) => {
    setLoading(true)
    setError(null)

    IntegrationsService.approvedIntegration(+id)
      .then(res=>{
        setIntegrations(res.data)
        
        createPaymentStore.addIntegrationsIds(res.data.id)
        createPaymentStore.setIntegrationContacts(res.data?.channel?.contact, false)

        closeCallback()
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setError(err?.response?.data?.message)
          setLoading(false)
        }
      })
  }

  const onCloseModal = () => {
    setError(null)
    setShowModal(false)
  }

  return (
    <AddIntegrationModal
      addedIntegration={addedIntegration}
      isShowModal={isShowModal}
      onCloseModal={onCloseModal}
      onOpenModal={setShowModal}
      error={error}
      isLoading={isLoading}
      sx={{width:{xs:'100%', sm:'auto'}}}
      onCloseError={()=>setError(null)}
    />
  )
}