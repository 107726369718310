import {ReactNode, useState} from "react";
import dayjs from "dayjs";
import {Stack,Typography} from "@mui/material";
import {CustomIconButton} from "../UI/Buttons/CustomIconButton/CustomIconButton";
import {OpenInNew} from "@mui/icons-material";
import {CopyIconButton} from "../UI/Buttons/CopyIconButton/CopyIconButton";
import {AlertModal} from "../AlertModal/AlertModal";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {NotificationAlerts} from "../NotificationAlerts/NotificationAlerts";
dayjs.extend(customParseFormat);


export interface Props {
  text: string
  subContent: ReactNode
  clearData:()=>void
  titleModal:string
  copyText:string
  disabled?:boolean
}

export const RequestActionsPanel = (props: Props) => {

  const {text, subContent, clearData , titleModal, copyText , disabled} = props

  const [isShowModal, setIsShowModal] = useState(false)
  const [isShowAlert, setShowAlert] = useState(false)

  const onCopyText = (text: string) => {
    setIsShowModal(false)
    navigator.clipboard.writeText(text)
    clearData()
    setShowAlert(true)
  }

  const onCloseModal = ()=>{
    setIsShowModal(false)
    clearData()
  }

  const onCopyTextHandler=()=>{
    onCopyText(copyText)
  }

  const setIsShowModalHandler = () =>{
    setIsShowModal(true)
  }

  return (
    <>
      <Stack direction={'row'} alignItems={'center'}>
        <CustomIconButton disabled={disabled} onClick={setIsShowModalHandler}>
          <OpenInNew/>
        </CustomIconButton>
        <Typography color={disabled ? '#bdbdbd' : 'inherit' } noWrap>{text}</Typography>
        <CopyIconButton disabled={disabled} copyText={copyText}/>
      </Stack>
      <AlertModal
        title={titleModal}
        subContent={subContent}
        onSubmit={onCopyTextHandler}
        buttonCancelName={'Закрыть'}
        buttonSubmitName={'Копировать'}
        open={isShowModal}
        onClose={onCloseModal}
      />
      <NotificationAlerts
        sucsess={{text:'Успешно скопировалось!', open:isShowAlert, onClose:()=>setShowAlert(false)}}
      />
    </>
  )
}