import {FC} from 'react'
import { IMotivationRoiTableItem } from './IMotivationRoiTableItem'
import { Avatar, Stack, TableCell, TableRow, Typography } from '@mui/material'
import { IMotivationRoiCoef, IMotivationThreshold } from '../../../../../../../../services/motivation/IMotivationResponse'
import { MotivationInputRoi } from '../MotivationInputRoi/MotivationInputRoi'
import { MotivationInputCoef } from '../MotivationInputCoef/MotivationInputCoef'
import { useSearchParams } from 'react-router-dom'
import { IUser } from '../../../../../../../../entities/user/models/IUser'
import { DeleteRoiTableItem } from './ui/DeleteRoiTableItem/DeleteRoiTableItem'

export const MotivationRoiTableItem:FC<IMotivationRoiTableItem> = (props) => {
  const {
    id,
    roiCoefs,
    thresholds,
    goodsCategory,
    readOnly = false,
    showDeleteIcon,
    month,
    year,
    roiCoefsBigChannels,
    thresholdsBigChannels
  } = props

  const [searchParams] = useSearchParams()

  const roiThresholdsBigChannels:IMotivationThreshold | null = thresholdsBigChannels ? thresholdsBigChannels[0]: null

  const roiOldChannelsBudgetComplete:IMotivationRoiCoef | null = roiCoefs?.find(x=>x?.motivationType === 'budget_complete_old_channel') || null
  const roiNewChannelsBudgetComplete:IMotivationRoiCoef | null = roiCoefs?.find(x=>x?.motivationType === 'budget_complete_new_channel') || null
  const roiBudgetCompleteNewChannelWithViews:IMotivationRoiCoef | null = roiCoefsBigChannels?.find(x=>x?.motivationType === 'budget_complete_big_channel') || null

  const roiOldChannelsBudgetNotComplete:IMotivationRoiCoef | null = roiCoefs?.find(x=>x?.motivationType === 'budget_not_complete_old_channel') || null
  const roiNewChannelsBudgetNotComplete:IMotivationRoiCoef | null = roiCoefs?.find(x=>x?.motivationType === 'budget_not_complete_new_channel') || null
  const roiBudgetNotCompleteNewChannelWithViews:IMotivationRoiCoef | null = roiCoefsBigChannels?.find(x=>x?.motivationType === 'budget_not_complete_big_channel') || null
  
  return (
    <TableRow>
      <TableCell sx={{p:1, pl:0, minWidth:!readOnly ? '150px' : 'auto'}}>
        <Stack direction={'row'} alignItems={'center'}>
          {showDeleteIcon &&
            <DeleteRoiTableItem
              code={goodsCategory?.code}
              month={month}
              year={year}
              readOnly={readOnly}
              id={id}
            />
          }
          {goodsCategory?.iconUrl ? <Avatar src={goodsCategory?.iconUrl} alt={goodsCategory?.name}/> : null}
          <Typography>{goodsCategory?.name}</Typography>
        </Stack>
      </TableCell>
      <TableCell align="center" sx={{p:1,}}>
        <MotivationInputRoi
          initValue={thresholds.find(x=>!x.firstIntegrationWithChannel)?.value || null}
          firstIntegrationWithChannel={false}
          id={id}
          cellId={thresholds.find(x=>!x.firstIntegrationWithChannel)?.id!}
          readOnly={readOnly}
        />
      </TableCell>
      <TableCell align="center" sx={{p:1,}}>
        <MotivationInputRoi
          initValue={thresholds.find(x=>x.firstIntegrationWithChannel)?.value || null}
          firstIntegrationWithChannel={true}
          id={id}
          cellId={thresholds.find(x=>x.firstIntegrationWithChannel)?.id!}
          readOnly={readOnly}
        />
      </TableCell>
      {roiThresholdsBigChannels &&
        <TableCell align="center" sx={{p:1,}}>
          <MotivationInputRoi
            initValue={roiThresholdsBigChannels?.value || null}
            firstIntegrationWithChannel={false}
            id={id}
            cellId={roiThresholdsBigChannels?.id!}
            readOnly={readOnly}
            propertyType='thresholdBigChannel'
          />
        </TableCell> 
      }
      <TableCell align="center" sx={{p:1, }}>
        <MotivationInputCoef
          initValue={roiOldChannelsBudgetComplete?.value || null}
          motivationType='budget_complete_old_channel'
          id={id}
          user={roiOldChannelsBudgetComplete?.user || {id:+searchParams.get('manager')!} as IUser || null}
          month={roiOldChannelsBudgetComplete?.month || +searchParams.get('date')!?.split('-')?.[1] || null}
          year={roiOldChannelsBudgetComplete?.year || +searchParams.get('date')!?.split('-')?.[0] || null}
          cellId={roiOldChannelsBudgetComplete?.id!}
          readOnly={readOnly}
          isRoiCoefsBigChannels={false}
        />
      </TableCell>
      <TableCell align="center" sx={{p:1, }}>
        <MotivationInputCoef
          initValue={roiNewChannelsBudgetComplete?.value || null}
          motivationType='budget_complete_new_channel'
          id={id}
          user={roiNewChannelsBudgetComplete?.user || {id:+searchParams.get('manager')!} as IUser || null}
          month={roiNewChannelsBudgetComplete?.month || +searchParams.get('date')!?.split('-')?.[1] || null}
          year={roiNewChannelsBudgetComplete?.year || +searchParams.get('date')!?.split('-')?.[0] || null}
          cellId={roiNewChannelsBudgetComplete?.id!}
          readOnly={readOnly}
          isRoiCoefsBigChannels={false}
        />
      </TableCell>
      {roiBudgetCompleteNewChannelWithViews &&
        <TableCell align="center" sx={{p:1, }}>
          <MotivationInputCoef
            initValue={roiBudgetCompleteNewChannelWithViews?.value || null}
            motivationType='budget_complete_big_channel'
            id={id}
            user={roiBudgetCompleteNewChannelWithViews?.user || {id:+searchParams.get('manager')!} as IUser || null}
            month={roiBudgetCompleteNewChannelWithViews?.month || +searchParams.get('date')!?.split('-')?.[1] || null}
            year={roiBudgetCompleteNewChannelWithViews?.year || +searchParams.get('date')!?.split('-')?.[0] || null}
            cellId={roiBudgetCompleteNewChannelWithViews?.id!}
            readOnly={readOnly}
            isRoiCoefsBigChannels
          />
        </TableCell>
      }
      <TableCell align="center" sx={{p:1, }}>
        <MotivationInputCoef
          initValue={roiOldChannelsBudgetNotComplete?.value || null}
          motivationType='budget_not_complete_old_channel'
          id={id}
          user={roiOldChannelsBudgetNotComplete?.user || {id:+searchParams.get('manager')!} as IUser || null}
          month={roiOldChannelsBudgetNotComplete?.month || +searchParams.get('date')!?.split('-')?.[1] || null}
          year={roiOldChannelsBudgetNotComplete?.year || +searchParams.get('date')!?.split('-')?.[0] || null}
          cellId={roiOldChannelsBudgetNotComplete?.id!}
          readOnly={readOnly}
          isRoiCoefsBigChannels={false}
        />
      </TableCell>
      <TableCell align="center" sx={{p:1, }}>
        <MotivationInputCoef
          initValue={roiNewChannelsBudgetNotComplete?.value || null}
          motivationType='budget_not_complete_new_channel'
          id={id}
          user={roiNewChannelsBudgetNotComplete?.user || {id:+searchParams.get('manager')!} as IUser || null}
          month={roiNewChannelsBudgetNotComplete?.month || +searchParams.get('date')!?.split('-')?.[1] || null}
          year={roiNewChannelsBudgetNotComplete?.year || +searchParams.get('date')!?.split('-')?.[0] || null}
          cellId={roiNewChannelsBudgetNotComplete?.id!}
          readOnly={readOnly}
          isRoiCoefsBigChannels={false}
        />
      </TableCell>
      {roiBudgetNotCompleteNewChannelWithViews &&
        <TableCell align="center" sx={{p:1, }}>
          <MotivationInputCoef
            initValue={roiBudgetNotCompleteNewChannelWithViews?.value || null}
            motivationType='budget_not_complete_big_channel'
            id={id}
            user={roiBudgetNotCompleteNewChannelWithViews?.user || {id:+searchParams.get('manager')!} as IUser || null}
            month={roiBudgetNotCompleteNewChannelWithViews?.month || +searchParams.get('date')!?.split('-')?.[1] || null}
            year={roiBudgetNotCompleteNewChannelWithViews?.year || +searchParams.get('date')!?.split('-')?.[0] || null}
            cellId={roiBudgetNotCompleteNewChannelWithViews?.id!}
            readOnly={readOnly}
            isRoiCoefsBigChannels
          />
        </TableCell>
      }
    </TableRow>
  )
}