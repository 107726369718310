import { makeAutoObservable } from "mobx";
import { IReferencesPaymentTypesResponse, IRequisteAccount } from "../services/references/IReferencesResponse";
import { AxiosError } from "axios";
import ReferencesService from "../services/references/references.service";

class SelectsDataStore {

  paymentType:null | IReferencesPaymentTypesResponse[] = []

  channelSizes:null | IRequisteAccount[] = null

  sourceCities:null | IRequisteAccount[] = null

  constructor(){
    makeAutoObservable(this)
  }

  getPaymentType = () => {
    ReferencesService.referencesPayment({field:'type'})
      .then(res=>{
        this.paymentType = res.data
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          
        }
      })
  }

  getChannelSizes = () => {
    ReferencesService.getChannelSizes()
      .then(res=>{
        this.channelSizes = res.data
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          
        }
      })
  }

  getSourceCities = () => {
    ReferencesService.getSourceCities()
      .then(res=>{
        this.sourceCities = res.data
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          
        }
      })
  }

}

export default new SelectsDataStore()