import {FC} from 'react'
import { IBudgetTable } from './IBudgetTable'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { BudgetTableItem } from './ui/BudgetTableItem/BudgetTableItem'

export const BudgetTable:FC<IBudgetTable> = (props) => {
  const {budgetItems, noEdit} = props

  return (
    <TableContainer
      component={Paper} 
      elevation={0}
      sx={{
        border:'1px solid #e0e0e0',
        borderBottom:'none', 
        borderRadius:0,
        borderLeft:'none',
        height:'min-content',
        maxHeight:'calc(100% - 32px)',
      }}
    >
      <Table
        stickyHeader
        sx={{
          "& .MuiTableCell-root": {borderLeft: '1px solid #e0e0e0'},
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{p:1, width:200, background:' #f2f3f5'}}>
              <Typography variant="body1" fontWeight={'bold'}>Менеджер</Typography>
            </TableCell>
            <TableCell align="center" sx={{p:1, width:120, background:' #f2f3f5'}}>
              <Typography variant="body1" fontWeight={'bold'} whiteSpace='nowrap'>Тип канала</Typography>
            </TableCell>
              <TableCell sx={{p:1, width:200, background:' #f2f3f5'}}>
                <Typography variant="body1" fontWeight={'bold'}>В графике</Typography>
              </TableCell>
            <TableCell align="center" sx={{p:1, minWidth:250, background:' #f2f3f5'}}>
              <Typography variant="body1" fontWeight={'bold'}>План</Typography>
            </TableCell>
            <TableCell align="center" sx={{p:1, background:' #f2f3f5'}}>
              <Typography variant="body1" fontWeight={'bold'}>Потрачено</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {budgetItems.map(item=><BudgetTableItem key={item.userId} noEdit={noEdit} {...item}/>)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}