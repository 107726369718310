import {FC, useState} from 'react'
import { IAlertModal } from './models/IAlertModal'
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { CustomButton } from '../UI/Buttons/CustomButton/CustomButton'
import { CancelButton } from '../UI/Buttons/CancelButton/CancelButton'
import { CustomIconButton } from '../UI/Buttons/CustomIconButton/CustomIconButton'

export const AlertModal:FC<IAlertModal> = (props) => {
  const {
    open,
    onClose,
    title,
    subtext,
    buttonCancelName= 'Закрыть',
    buttonSubmitName = 'Сохранить',
    onSubmit,
    loading = false,
    colorSubmit,
    error,
    subContent,
    promokodFlag,
    disabled,
    initValue,
    placeholder,
    textCase = 'inherit',
    inputType = 'text',
    required = false,
    subInputText,
    showTextField = false
  } = props

  const [value, setValue] = useState(initValue ? initValue : '')

  const textInputHandler = (eventValue: string) => {
    promokodFlag ?
      setValue(eventValue.replace(/\s/g, ''))
      :
      setValue(eventValue)
  }
  const onKeyDownSend = (e: React.KeyboardEvent) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      onSubmit(value)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={!loading ? onClose : undefined}
      fullWidth
      maxWidth='sm'
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle
        sx={{
          display:'flex',
          alignItems:'center',
          justifyContent:'space-between',
          p:{xs:1, sm: '8px 16px'},
          pr:{xs:0.5, sm:0.5},
        }}
      >
        {title}
        <CustomIconButton disabled={loading} onClick={onClose}>
          <Cancel/>
        </CustomIconButton>
      </DialogTitle>
      <Divider/>
      {(subtext || subInputText || showTextField || subContent) &&
        <>
          <DialogContent sx={{pl: 2}}>
            {subtext && subtext}
            {subInputText &&
              <Typography variant='subtitle1' sx={{mb: 0.5}}>
                {subInputText} {required ? <Typography component={'span'} color={'error'}>*</Typography> : null}
              </Typography>
            }
            {showTextField &&
              <TextField
                value={value}
                onChange={e => textInputHandler(e.target.value)}
                placeholder={placeholder}
                size='small'
                fullWidth
                disabled={loading}
                inputProps={{sx: {textTransform: textCase}}}
                onKeyDown={onKeyDownSend}
                autoFocus
                error={error}
                type={inputType}
                required={required}
              />
            }
            {subContent && subContent}
          </DialogContent>
          <Divider/>
        </>
      }
      <DialogActions>
        <CancelButton disabled={loading} variant='outlined' onClick={onClose}>
          {buttonCancelName}
        </CancelButton>
        <CustomButton
          disabled={loading || disabled || !!(showTextField && value.trim() === '')}
          variant='contained'
          onClick={()=>onSubmit(value)}
          color={colorSubmit}
          endIcon={loading && <CircularProgress sx={{maxHeight: 20, maxWidth: 20}}/>}
        >
          {buttonSubmitName}
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}
