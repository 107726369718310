import { makeAutoObservable } from "mobx";
import { AxiosError } from "axios";
import ReferencesService from "../services/references/references.service";
import { IGoodCategoriesItem } from "../services/references/IReferencesResponse";

class CategoriesGoodsStore {
  categories:IGoodCategoriesItem[] | null = null

  constructor(){
    makeAutoObservable(this)
  }

  getAllCategoriesGoods(){
    ReferencesService.getGoodCategories()
      .then(res=>{
        // console.log(res, 'res all categories')
        this.categories = res.data
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err all categories')
        }
      })
  }

}

export default new CategoriesGoodsStore()