import { FC } from 'react'
import { IUser } from '../../../../entities/user/models/IUser'
import { Box } from '@mui/material'
import { ALL_ROLES } from '../../../../shared/rules/interface/EnumRights'
import { UserReleaseTables } from './ui/UserReleaseTables/UserReleaseTables'
import { UserSearchTables } from './ui/UserSearchTables/UserSearchTables'

export const UserMotivationPage:FC<IUser> = (props) => {
  const {
    firstName,
    lastName,
    id,
    imageUrl,
    email,
    company,
    rights,
    role,
    roleName
  } = props

  return (
    <Box
      sx={{
        pt:{xs:1, sm:0}, 
        height:'88vh', 
        overflow:'hidden',
        display:'grid'
      }}
    >
      {(role === ALL_ROLES.RELEASE_MANAGER || role === ALL_ROLES.PRE_HEAD_MANAGER || id === 8) &&
        <UserReleaseTables userId={id}/>
      }
      {(role === ALL_ROLES.SEARCH_MANAGER || role === ALL_ROLES.SEARCH_HEAD_MANAGER) && id !== 8 &&
        <UserSearchTables rights={rights} userId={id}/>
      }
    </Box>
  )
}