import { FC, useState, useEffect, memo } from 'react'
import { IFilterDateMultiple } from './IIFilterDateMultiple'
import 'dayjs/locale/ru'
import dayjs, { Dayjs } from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import { Box, FormControl, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { DateCustomInput } from '../../../UI/DateCustomInput/DateCustomInput'
import { CustomIconButton } from '../../../UI/Buttons/CustomIconButton/CustomIconButton'

export const FilterDateMultiple:FC<IFilterDateMultiple> = memo((props) => {
  const {label, searchParamName} = props

  const [searchParams, setSearchParams] = useSearchParams()

  const queryDateArr:string[] = searchParams.get(searchParamName)?.split(',') || []

  const [fromValue, setFromValue] = useState<Dayjs | null>(queryDateArr.length > 0 ? dayjs(queryDateArr[0]) : null)
  const [toValue, setToValue] = useState<Dayjs | null>(queryDateArr.length > 0 ? dayjs(queryDateArr[1]) : null)

  const changeUrlValue = () => {
    const transformFromValue = fromValue?.format('YYYY-MM-DD')
    const transformToValue = toValue?.format('YYYY-MM-DD')

    if (transformFromValue === null && transformToValue === null){
      searchParams.delete(searchParamName)
      setSearchParams(searchParams)
    } else {
      if(typeof transformFromValue === 'string' && typeof transformToValue === 'string'){
        
        if(fromValue?.isValid() && toValue?.isValid() && +fromValue <= +toValue){
          searchParams.set(searchParamName, `${[transformFromValue, transformToValue]}`)
          setSearchParams(searchParams)
        } else {
          searchParams.delete(searchParamName)
          setSearchParams(searchParams)
        }
        
      }
    }
  }

  const resetFilter = () => {
    searchParams.delete(searchParamName)
    setSearchParams(searchParams)
    setFromValue(null)
    setToValue(null)
  }

  useEffect(()=>{
    changeUrlValue()
  },[fromValue, toValue])

  return (
    <FormControl size='small'>
      <Stack
        direction={'row'} 
        justifyContent={'space-between'} 
        alignItems={'center'} 
        sx={{height:27}}
      >
        <Typography variant='body2' noWrap>{label}</Typography>
        {(fromValue !== null || toValue !== null) &&
          <Box>
            <CustomIconButton size='small' color='error' onClick={resetFilter}>
              <Close fontSize='small'/>
            </CustomIconButton>
          </Box>
        }
      </Stack>
      <Stack
        direction={'row'} 
        justifyContent={'space-between'} 
        alignItems={'center'} 
        spacing={0.5}
      >
        <DateCustomInput
          value={fromValue}
          onChange={(val:any)=>setFromValue(dayjs(val))}
          placeholder='дд.мм.год'
        />
        <Typography>—</Typography>
        <DateCustomInput
          value={toValue}
          onChange={(val:any)=>setToValue(dayjs(val))}
          placeholder='дд.мм.год'
        />
      </Stack>
    </FormControl>
  )
})