import { FC, useState } from 'react'
import { IChannelAsideSource } from './IChannelAsideSource'
import { ChannelTitleEdit } from '../ChannelTitleEdit/ChannelTitleEdit'

export const ChannelAsideSource:FC<IChannelAsideSource> = (props) => {
  const {value, setValue, readOnly} = props

  const [isEditOpen, setEditOpen] = useState(false)

  const submit = (newValue:string) => {
    setValue(newValue)
    setEditOpen(false)
  }

  return (
    <ChannelTitleEdit
      title='Источник:'
      placeholder='Введите истоник'
      value={value}
      onSubmit={submit}
      isEdit={isEditOpen}
      onCloseEdit={()=>setEditOpen(false)}
      onOpenEdit={()=>setEditOpen(true)}
      readOnly={readOnly}
      required
    />
  )
}
