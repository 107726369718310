import { FC, useState } from 'react'
import { Settings, TableChart, Phonelink, AccountBalanceWallet, Equalizer, Dashboard, Task, PeopleAlt } from '@mui/icons-material'
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemButton, Stack } from '@mui/material'
import { theme } from '../../app/theme'
import { observer } from 'mobx-react-lite'
import userStore from '../../store/user.store'
import {CHANNELS_ROUTES, ROUTES} from '../../shared/routes/routes'
import { ILeftPanel } from './models/ILeftPanel'
import { NotificationButton } from './ui/NotificationButton/NotificationButton'
import { AsideSettings } from '../AsideSettings/AsideSettings'
import { Aside } from '../Aside/Aside'
import { checkUserPermission } from '../../shared/rules/permission'
import { ACCESS_RULES } from '../../shared/rules/rules'
import { ReactComponent as Logo } from '../../images/logo/CG_white.svg'
import { SidebarItem } from './ui/SidebarItem/SidebarItem'
import { ParserInfoIcon } from './ui/ParserInfoIcon/ParserInfoIcon'
import { ALL_ROLES } from '../../shared/rules/interface/EnumRights'
import { ReactComponent as Package } from '../../images/assets/Package.svg'
import {CustomButton} from "../UI/Buttons/CustomButton/CustomButton";

export const LeftPanel:FC<ILeftPanel> = observer((props) => {
  const {loading = false} = props

  const [isOpenUserAside, setOpenUserAside] = useState(false)
  const [isOpenSettings, setOpenSettings] = useState(false)

  const startPanelItems = [
    {
      icon:TableChart,
      tooltipText:'Интеграции',
      url: userStore.currentCompany + '/' + ROUTES.INTEGRATIONS,
      isShow:checkUserPermission({rules:ACCESS_RULES.integrations, userRules:userStore?.user?.rights || []})
    },
    {
      icon:Phonelink,
      tooltipText:'Каналы',
      url: userStore.currentCompany + '/' + ROUTES.CHANNELS + '/' + CHANNELS_ROUTES.MAIN ,
      isShow:true
    },
    {
      icon:Equalizer,
      tooltipText:'Аналитика',
      url: userStore.currentCompany + '/' + ROUTES.ANALYTICS,
      isShow:checkUserPermission({rules:ACCESS_RULES.analytics, userRules:userStore?.user?.rights || []})
    },
    {
      icon: PeopleAlt,
      tooltipText: 'Контрагенты',
      url: userStore.currentCompany + '/' + ROUTES.CONTRACTOR,
      isShow:checkUserPermission({rules:ACCESS_RULES.counteragents, userRules:userStore?.user?.rights || []})
    },
    {
      icon: AccountBalanceWallet,
      tooltipText: 'Оплаты',
      url: userStore.currentCompany + '/' + ROUTES.FINANCES,
      isShow:checkUserPermission({rules:ACCESS_RULES.finances, userRules:userStore?.user?.rights || []})
    },
    {
      icon:Package,
      tooltipText: 'Товары',
      url: userStore.currentCompany + '/' + ROUTES.GOODS,
      isShow:true,
      isCustomSvg:true
    }
  ]

  const panelItems = [
    {
      icon:Settings, 
      tooltipText:'Настройки' , 
      onClick:()=>setOpenSettings(true),
      isHover:true
    },
  ]

  return (
    <>
      <List sx={{background:theme.palette.primary.main, display:'flex', flexDirection:'column'}}>
        <Stack direction={'column'} spacing={1}>
          <ListItem sx={{justifyContent:'center', p:0, pt:1}}>
            <Logo height={35} width={35}/>
          </ListItem>
          {startPanelItems?.filter(item=>item.isShow === true)?.map((item)=>
            <SidebarItem
              key={item?.tooltipText}
              tooltipText={item?.tooltipText}
              icon={item?.icon}
              url={item?.url}
              isCustomSvg={item?.isCustomSvg}
            />)
          }
          {(userStore.user && userStore?.user?.role !== ALL_ROLES.CONTENT_MANAGER) && <ParserInfoIcon/>}
        </Stack>
        <Box sx={{mt:'auto',display:'flex',flexDirection:'column'}}>
          {userStore.user && checkUserPermission({rules:ACCESS_RULES.dashboard, userRules:userStore?.user?.rights}) &&
            <SidebarItem
              tooltipText={'Кабинет руководителя'}
              icon={Dashboard}
              url={userStore.currentCompany + "/" + ROUTES.DASHBOARD}
            />
          }
          {userStore.currentCompany && checkUserPermission({rules:ACCESS_RULES.tz, userRules:userStore?.user?.rights || []}) &&
            <Box sx={{mt:1}}>
              <SidebarItem
                tooltipText={'База ТЗ'}
                icon={Task}
                url={userStore.currentCompany + '/' + ROUTES.TZ}
              />
            </Box>
          }
          <NotificationButton loading={loading} />
          {panelItems.map(item=>
            <SidebarItem
              key={item.tooltipText}
              tooltipText={item.tooltipText}
              icon={item.icon}
              onClick={item.onClick}
              disabled={loading}
              isHover={item.isHover}
            />)
          }
          <CustomButton sx={{borderRadius: 0}} disabled={loading} onClick={()=>setOpenUserAside(true)}>
            <ListItemAvatar sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
              <Avatar src={userStore?.user?.imageUrl} alt={userStore?.user?.firstName + ' ' + userStore?.user?.lastName} />
            </ListItemAvatar>
          </CustomButton>
        </Box>
      </List>
      <Aside anchor='right' open={isOpenUserAside} onClose={()=>setOpenUserAside(false)}/>
      <AsideSettings anchor='left' open={isOpenSettings} onClose={()=>setOpenSettings(false)} />
    </>
  )
})