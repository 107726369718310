import {FC, useState} from 'react'
import { ICompanyAccountField } from './ICompanyAccountField'
import { FormControl, IconButton, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import paymentsTableStore from '../../../../../store/paymentsTable.store'
import PaymentsService from '../../../../../services/payments/payments.service'
import { IPaymentsCompanyAccountRequest } from '../../../../../services/payments/IPaymentsRequest'
import requisiteStore from '../../../../../store/requisite.store'
import { AxiosError } from 'axios'
import { Close, Edit } from '@mui/icons-material'

export const CompanyAccountField:FC<ICompanyAccountField> = observer((props) => {
  const {id, code, name} = props

  const [isEdit, setEdit] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const updateCompanyAccount = (event: SelectChangeEvent) => {
    setLoading(true)

    const option:IPaymentsCompanyAccountRequest = {
      id,
      companyAccount:event.target.value
    }
    PaymentsService.updateCompanyAccount(option)
      .then(res=>{
        // console.log(res, 'res update accoung', id)
        paymentsTableStore.updateCompanyAccount(id, res.data.companyAccount.code, res.data.companyAccount.name)
        setLoading(false)
        setEdit(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err company update')
          setLoading(false)
        }
      })
  }

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
      {!isEdit
        ? <>
            <Typography>{name}</Typography>
            <IconButton size='small' onClick={()=>setEdit(true)}>
              <Edit fontSize='small'/>
            </IconButton>
          </>
        : <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{minWidth:'170px'}}>
            <FormControl size='small' fullWidth disabled={isLoading}>
              <Select
                value={code}
                onChange={updateCompanyAccount}
                size='small'
              >
                {requisiteStore.requisiteInfo?.companyAccounts?.map(item=>
                  <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>)
                }
              </Select>
            </FormControl>
            <IconButton size='small' disabled={isLoading} onClick={()=>setEdit(false)}>
              <Close fontSize='small'/>
            </IconButton>
          </Stack>
      }
    </Stack>
  )
})