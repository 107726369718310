import {FC} from 'react'
import { ICustomIconButton } from '../CustomIconButton/ICustomIconButton'
import { CustomIconButton } from '../CustomIconButton/CustomIconButton'
import { Edit } from '@mui/icons-material'

export const EditIconButton:FC<ICustomIconButton> = (props) => {
  return (
    <CustomIconButton {...props}>
      <Edit fontSize='small'/>
    </CustomIconButton>
  )
}
