import { observer } from "mobx-react-lite"
import rulesStore from "../../../../../../../../store/rules.store"
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { GoodRulesItem } from "./ui/GoodRulesItem/GoodRulesItem"
import { EmptyText } from "../../../../../../../../components/EmptyText/EmptyText"

export const GoodsAndCategotyTable = observer(() => {

  return (
    <TableContainer
      component={Paper} 
      elevation={0}
      sx={{
        border:'1px solid #e0e0e0',
        borderBottom:'none', 
        borderRadius:0,
        borderLeft:'none',
      }}
    >
      <Table
        stickyHeader
        sx={{
          "& .MuiTableCell-root": {borderLeft: '1px solid #e0e0e0'},
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{background:'#f2f3f5', p:1, width:'20%'}}>
              <Typography fontWeight={'bold'} whiteSpace={'nowrap'}>Товарная категория</Typography>
            </TableCell>
            <TableCell align="center" sx={{background:'#f2f3f5', p:1}}>
              <Typography fontWeight={'bold'}>Товары</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rulesStore.goodsCategoriesTable.map(item=>
            <TableRow
              key={item.code}
            >
              <TableCell>
                <Typography>{item.name}</Typography>
              </TableCell>
              <TableCell sx={{pt:1, pb:1}}>
                {item.goods.map(good=>
                  <GoodRulesItem key={good.article} {...good}/>
                )}
                {!item.goods.length && <EmptyText>Товары отсутствуют</EmptyText>}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>

  )
})