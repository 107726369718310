import {FC , useState} from 'react'
import { IKpiPriceChannelInput } from './IKpiPriceChannelInput'
import MotivationService from '../../../../../../../../services/motivation/motivation.service'
import { IMotivationEditSearchPercentRequest } from '../../../../../../../../services/motivation/IMotivationRequest'
import { AxiosError } from 'axios'
import { Stack, Typography } from '@mui/material'
import { InputEditField } from '../../../../../../../../components/Table/ui/Fields/InputEditField/InputEditField'
import { priceTransormFormat } from '../../../../../../../../shared/functions/functions'
import { observer } from 'mobx-react-lite'
import dashboardStore from '../../../../../../../../store/dashboard.store'
import { EditIconButton } from '../../../../../../../../components/UI/Buttons/EditIconButton/EditIconButton'

export const KpiPriceChannelInput:FC<IKpiPriceChannelInput> = observer((props) => {
  const {
    id, 
    value,
    readOnly = false
  } = props

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const [isEdit, setEdit] = useState(false)

  const submit = (numValue:string) => {
    setLoading(true)
    setError(null)

    const option:IMotivationEditSearchPercentRequest = {
      id,
      commission:+numValue as any,
      propertyType: 'percent'
    }
    MotivationService.editSearchPercent(option)
      .then(res=>{

        dashboardStore.onChaneFieldPercentForSales(id, res.data?.commission)
        
        setEdit(false)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setLoading(false)
          setError(err?.response?.data?.message)
        }
      })
  }

  const closeEdit = () => {
    setEdit(false)
    setError(null)
  }

  return (
    <>
      {!isEdit &&
        <Stack direction={'row'} alignItems={'center'}>
          <Typography noWrap>{priceTransormFormat(value, false, true, true)} %</Typography>
          {readOnly === false &&
            <EditIconButton size='small' onClick={()=>setEdit(true)}/>
          }
        </Stack>
      }
      {isEdit &&
        <InputEditField
          initValue={value}
          onClose={closeEdit}
          onSubmit={submit}
          error={error}
          loading={isLoading}
          placeholder={'Введите цену'}
          inputType='number'
        />
      }
    </>
  )
})