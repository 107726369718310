import { FC, useState, useEffect, memo} from 'react'
import { IFilterSimpleField } from './IFilterSimpleField'
import { Box, FormControl, Stack, TextField, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { Close } from '@mui/icons-material'
import { CustomIconButton } from '../../../UI/Buttons/CustomIconButton/CustomIconButton'

export const FilterSimpleField:FC<IFilterSimpleField> = memo((props) => {
  const {
    label,
    placeholder,
    type,
    urlParam
  } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const [value, setValue] = useState(searchParams.get(urlParam) || '')

  const handelChange = (e:React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
  }

  const changeUrlValue = () => {
      if (value.trim() === '') {
        searchParams.delete(urlParam)
        setSearchParams(searchParams)
      } else {
        searchParams.set(urlParam, value)
        setSearchParams(searchParams)
      }
  }

  const resetFilter = () => {
    searchParams.delete(urlParam)
    setSearchParams(searchParams)
    setValue('')
  }

  useEffect(()=>{
    const delay = setTimeout(()=>changeUrlValue(), 500)
    return ()=>clearTimeout(delay)
  },[value])

  return (
    <FormControl size='small'>
      <Stack
        direction={'row'} 
        justifyContent={'space-between'} 
        alignItems={'center'} 
        sx={{height:27}}
      >
        <Typography variant='body2' noWrap>{label}</Typography>
        {value.trim() !== '' &&
          <Box>
            <CustomIconButton size='small' color='error' onClick={resetFilter}>
              <Close fontSize='small'/>
            </CustomIconButton>
          </Box>
        }
      </Stack>
      <TextField
        value={value}
        onChange={handelChange}
        size='small'
        placeholder={placeholder}
        type={type}
      />
    </FormControl>
  )
})