import { AxiosProgressEvent, AxiosResponse } from "axios";
import $gateway_api from "../../shared/api/gatewayAxios";
import { IProductSampleFilteredRequest, IProductSampleInsertOrUpdate } from "./IProductSampleRequest";
import { IProductSampleFilteredResponse, IProductSampleItemResponse, IProductSampleMinMaxResponse } from "./IProductSampleResponse";

export default class ProductSample {

  static filtered(options:IProductSampleFilteredRequest): Promise<AxiosResponse<IProductSampleFilteredResponse>>{
    return $gateway_api.post('/api/productSample/filtered', options)
  }

  static filteredExcel(options:IProductSampleFilteredRequest, onDownloadProgress?:((progressEvent: AxiosProgressEvent)=>void)): Promise<AxiosResponse<Blob>>{
    return $gateway_api.post('/api/productSample/filtered/excel', options, {
      responseType:'blob',
      onDownloadProgress,
    })
  }

  static getMinMaxFilters(): Promise<AxiosResponse<IProductSampleMinMaxResponse>>{
    return $gateway_api.get('/api/productSample/minMaxFilters')
  }

  static getProductSampleFull(id:number): Promise<AxiosResponse<IProductSampleItemResponse>>{
    return $gateway_api.get(`/api/productSample/full/${id}`)
  }

  static insertOrUpdate(options:IProductSampleInsertOrUpdate): Promise<AxiosResponse<IProductSampleItemResponse>>{
    return $gateway_api.post('/api/productSample/insertOrUpdate', options)
  }
  
}

