import {FC, useState, useEffect, memo, ChangeEvent} from 'react'
import { IChannelAudienceItemComponents } from './IChannelAudienceItem'
import { Box, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { ErrorOutline } from '@mui/icons-material'
import { priceTransormFormat } from '../../../../../../shared/functions/functions'

export const ChannelAudienceItem:FC<IChannelAudienceItemComponents> = memo((props) => {
  const {
    icon,
    symbol,
    text,
    widthInput,
    value,
    placeholder = '%',
    inputType = 'number',
    code,
    changeField,
    required = false,
    tooltipText,
    sxInput,
    sxText,
    isEdit,
    isNumFormat = false,
    symbolInText,
    disabled,
    shouldClampValue,
  } = props

  const [inputText, setInputText] = useState(value || '')

  const handleInputChange = (event:ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
      const clampedValue = shouldClampValue ? Math.min(Number(inputValue), 100).toString():inputValue;
      setInputText(clampedValue);
  };

  useEffect(()=>{
    const delay = setTimeout(()=> changeField(code, inputText), 400)
    return ()=>clearTimeout(delay)
  },[inputText])

  return (
    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={0.5}>
      <Stack direction={'row'} spacing={0.5}>
        {icon && icon}
        {text && <Typography sx={sxText}>{text} {required && isEdit ? <Typography component='span' color={'error'}>*</Typography> : null}</Typography>}
        {!text && required && isEdit ? <Typography component='span' color={'error'}>*</Typography> : null}
        {tooltipText && 
          <Tooltip title={<Typography>{tooltipText}</Typography>}>
            <ErrorOutline color='action'/>
          </Tooltip>
        }
      </Stack>
      {isEdit &&
        <Box sx={{display:{xs:'none', sm:'block'}}}>
          <TextField
            size='small'
            value={inputText}
            onChange={handleInputChange}
            sx={{width:widthInput ? widthInput : 60, ...sxInput}}
            placeholder={placeholder}
            type={inputType}
            disabled={disabled}
          />
        </Box>
      }
      {!isEdit &&
        <Typography sx={{display:{xs:'none', sm:'block'}}}>
          {value === '' ? '—' : isNumFormat ? priceTransormFormat((value || ''), false) : value} {symbolInText ? symbolInText : null}
        </Typography>
      }
      {symbol && <Typography sx={{display:{xs:'none', sm:'block'}}}>{symbol}</Typography>}
      <Box sx={{display:{xs:'flex', sm:'none', alignItems:'center'}}}>
        {isEdit &&
          <TextField
            size='small'
            defaultValue={value}
            onChange={e=>setInputText(e.target.value)}
            sx={{width:widthInput ? widthInput : 50, ...sxInput, mr:symbol ? 0.5 : 0}}
            placeholder={placeholder}
            type={inputType}
            disabled={disabled}
          />
        }
        {!isEdit &&
          <Typography>
            {value === '' ? '—' : isNumFormat ? priceTransormFormat((value || ''), false) : value} {symbolInText ? symbolInText : null}
          </Typography>
        }
        {symbol && <Typography>{symbol}</Typography>}
      </Box>
    </Stack>
  )
})