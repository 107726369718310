import { FC, useEffect, useState } from "react"
import { Checkbox, FormControlLabel, Skeleton, Stack, Typography } from "@mui/material"
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout"
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from 'chart.js'
import { Line } from "react-chartjs-2"
import { AsideMetricSelect } from "./ui/AsideMetricSelect/AsideMetricSelect"
import { IMetricsOnSourceRequest, METRIC_GROUP_BY } from "../../../../services/metrics/IMetricsRequest"
import { getMetricOrderBy } from "../../../../shared/functions/functions"
import { IAsideMetricChart } from "./IAsideMetricChart"
import MetricsService from "../../../../services/metrics/metrics.service"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import 'dayjs/locale/ru'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { AxiosError } from "axios"
import dayjs, { Dayjs } from "dayjs"
import { IVisitMetric } from "../../../../services/metrics/IMetricsResponse"
import { getDatePickerIconsStyle } from "../../../../components/UI/DateCustomInput/shared/getDatePickerIconsStyle"
import { CustomButton } from "../../../../components/UI/Buttons/CustomButton/CustomButton"
import { purple_color } from "../../../../app/styles/variables"

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend)

export const AsideMetricChart:FC<IAsideMetricChart> = (props) => {
  const {initDate, todayDate, source} = props

  const [withRobots, setWithRobots] = useState(false)
  const [orderBy, setOrderBy] = useState<METRIC_GROUP_BY>(initDate ? getMetricOrderBy(initDate, todayDate) : METRIC_GROUP_BY.HOUR)
  const [startDate, setStartDate] = useState<Dayjs | null>(initDate ? dayjs(initDate) : null)
  // const [startDate, setStartDate] = useState<Dayjs | null>(initDate ? dayjs(formatDateISO(initDate)) : null)
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(todayDate))
  // const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(formatDateISO(todayDate)))
  const [startDateError, setStartDateError] = useState(false)
  const [endDateError, setEndDateError] = useState(false)

  const [dataMetric, setDataMetric] = useState<IVisitMetric[] | null>(null)
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const getMetricSource = (source:string, containRobots:boolean, startDate:string, endDate:string) =>{
    setLoading(true)
    setError(null)

    const option:IMetricsOnSourceRequest = {
      containRobots,
      endDate:dayjs(endDate)?.format('YYYY-MM-DD'),
      // endDate:parseIsoDateToYYYYMMDD(endDate),
      startDate:dayjs(startDate)?.format('YYYY-MM-DD'),
      // startDate:parseIsoDateToYYYYMMDD(startDate),
      groupBy:orderBy,
      source
    }
    MetricsService.getMetricsOnSource(option)
      .then(res=>{
        // console.log(res, 'res metrick on metric chrat')

        setDataMetric(res.data.visitMetrics)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err matric in metrick chrat')
          setLoading(false)
          setDataMetric(null)
          setError(err.response?.data.message)
        }
      })
  }

  const submitFilter = () => {
    if(startDate && endDate && !startDateError && !endDateError){
      getMetricSource(source, withRobots, startDate?.format('YYYY-MM-DD'), endDate?.format('YYYY-MM-DD'))
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWithRobots(event.target.checked)
  }

  const options = {
    responsive:true,
    scaleShowLabels:false,
    elements:{
      point:{radius:3}
    },
    plugins:{
      legend:{display:false},
      tooltip:{
        enabled:true,
        displayColors:false,
        titleAlign:'center' as "center",
      }
    },
  }
 
  const chartData = {
    labels:dataMetric?.map(x=>x.time_intervals.map(d=>dayjs(d).format('DD.MM.YY'))),
    datasets:[
      {
        fill:false,
        label:'Визиты',
        data:dataMetric?.map(x=>x.value),
        borderColor:'1px solid #e0e0e0',
      }
    ]
  }

  useEffect(()=>{
    if(initDate){
      getMetricSource(source, withRobots, initDate, todayDate)
    }
  },[])

  return (
    <AsideCardLayout>
      <Stack spacing={1}>
        <Stack
          direction={'row'}
          alignItems={'start'}
          justifyContent={'space-between'}
          spacing={{xs:0, md:1}} 
          sx={{display:{xs:'grid', md:'flex', rowGap:2}, gridTemplateColumns:'1fr', rowGap:2}}
        >
          <Stack 
            direction={'row'} 
            alignItems={'start'} 
            spacing={{xs:0, md:1}} 
            sx={{display:{xs:'grid', md:'flex'}, rowGap:2, columnGap:2, gridTemplateColumns:{xs:'1fr', sm:'1fr 1fr'}}}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
              <DatePicker
                value={startDate}
                label='от'
                disabled={isLoading}
                onChange={(newValue)=>setStartDate(newValue)}
                sx={{width:{xs:'100%', md:'150px'}}}
                slotProps={{
                  textField:{
                    error:startDateError,
                    size:'small',
                    helperText: startDateError && 'некорректная дата',
                  },
                  ...getDatePickerIconsStyle() as any
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
              <DatePicker
                value={endDate}
                label='до'
                disabled={isLoading}
                onChange={(newValue)=>setEndDate(newValue)}
                sx={{width:{xs:'100%', md:'150px'}}}
                slotProps={{
                  textField:{
                    error:endDateError,
                    size:'small',
                    helperText: endDateError && 'некорректная дата',
                  },
                  ...getDatePickerIconsStyle() as any
                }}
              />
            </LocalizationProvider>
            <AsideMetricSelect isLoading={isLoading} value={orderBy} onChange={setOrderBy}/>
            <FormControlLabel 
              disabled={isLoading} 
              control={<Checkbox checked={withRobots} onChange={handleChange}/>} 
              label={
                <Typography 
                  sx={{
                    '&:hover':{
                      color:purple_color
                    }
                  }}
                >
                  С роботами
                </Typography>
              }
            />
          </Stack>
          <CustomButton
            variant="outlined"
            onClick={submitFilter}
            disabled={isLoading || (startDate && endDate ? startDate?.format('YYYY.MM.DD') > endDate?.format('YYYY.MM.DD') : false)}
          >
            Применить
          </CustomButton>
        </Stack>
        <Stack spacing={1}>
          {!isLoading && dataMetric && <Typography variant="h5">Динамика трафика</Typography>}
          {!isLoading && dataMetric && <Line style={{maxWidth:'100%', maxHeight:'300px'}} options={options} data={chartData}/>}
          {isLoading && <Skeleton variant="rounded" height={'200px'} width={'100%'}/>}
          {isError && <Typography variant="h6" color={'error'} sx={{fontWeight:'normal', fontStyle: 'italic', fontSize:'0.9rem'}}>{isError}</Typography>}
        </Stack>
      </Stack>
    </AsideCardLayout>
  )
}
