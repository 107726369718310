import {FC, useState} from 'react'
import { IChannelDateGiven } from './IChannelDateGiven'
import { Box, Typography } from '@mui/material'
import { ChannelDate } from '../ChannelDate/ChannelDate'
import channelCardStore from '../../../../store/channelCard.store'
import { observer } from 'mobx-react-lite'
import userStore from '../../../../store/user.store'
import { ALL_RULES } from '../../../../shared/rules/interface/EnumRights'

export const ChannelDateGiven:FC<IChannelDateGiven> = observer((props) => {
  const {
    field,
    id,
    initValue,
    update_id,
    onChangeUpdateId,
    setInitValue,
    isNotValueTextHidden = false
  } = props

  const [startValue] = useState<string | null>(initValue)

 const isHiddenEdit = ():boolean=> {
    if(userStore.user?.rights?.find(x=>x?.code === ALL_RULES.ADMIN || x?.code === ALL_RULES.edit_date_given || x?.code === ALL_RULES.release)) {
      return false
    } else return true

    // if(userStore.user?.rights?.find(x=>x?.code === ALL_RULES.ADMIN || x?.code === ALL_RULES.edit_date_given)) {

    //   return false
    // } else if(startValue === null){

    //   if(userStore.user?.rights?.find(x=>x?.code === ALL_RULES.search || ALL_RULES.release)){
    //     return false
    //   } return true

    // } else if((startValue === null && initValue !== null) === true) {

    //   if(userStore.user?.rights?.find(x=>x?.code === ALL_RULES.search || ALL_RULES.release)){
    //     return false
    //   } return true

    // } return true
  }

  return (
    <Box sx={{display:'flex', flexWrap:'wrap', alignItems:'center'}}>
      <Typography noWrap sx={{mr:0.5}}>Дата передачи:</Typography>
      <ChannelDate
        initValue={initValue}
        id={id} 
        update_id={update_id} 
        onChangeUpdateId={onChangeUpdateId}
        setInitValue={setInitValue}
        readOnly={channelCardStore.isBanned ? true : false}
        field={field}
        hiddenEdit={isHiddenEdit()}
        isManagerEdit
        isNotValueTextHidden={isHiddenEdit()}
        marginTop={0.5}
        maxWidth={'min-content'}
      />
    </Box>
  )
})