import {makeAutoObservable} from "mobx";
import {ISettingsSelected} from "../components/IntegrationTable/models/ISettingsSelected";
import {ISettingsSelectedGroup} from "../components/IntegrationTable/models/ISettingsSelectedGroup";
import {IUpdateStatusesRequest} from "../services/integration/IntegrationsRequest";

export interface IDragParams {
  code: string;
  ordering: number;
}

export interface setCheckedParams {
  code: string;
  group: string;
}

export interface ResumePrePayment {
  earliestPaidAt: string
  totalIntegrationPrice: number
  totalPaysSum: number

}

class PrePaymentTableStore {

  resumePrePayment: ResumePrePayment | null = null;

  page: number = 1

  tableItems: any[] = []

  allFilter: string[] = []

  isLoading: boolean = true

  isLoadingSort: boolean = true

  totalCount: number = 0

  initSettingsConfirm: ISettingsSelected[] = []
  initSettingsSelect: ISettingsSelectedGroup[] = []

  selectLists: ISettingsSelectedGroup[] = []
  confirmLists: ISettingsSelected[] = []
  curDragItemParams: IDragParams | null = null
  isPageNotFound: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setResumePrePayment = (resumePrePayment: ResumePrePayment) => {
    this.resumePrePayment = resumePrePayment
  }

  addItem(item: any) {
    this.tableItems = [item, ...this.tableItems]
  }

  setLoading(value: boolean) {
    this.isLoading = value
  }

  setTotalCount(count: number) {
    this.totalCount = count
  }

  setPage(page: number) {
    this.page = page
  }

  setAllFilter(array: string[]) {
    this.allFilter = array
  }

  setIsPageNotFound(value: boolean) {
    this.isPageNotFound = value
  }

  setTableItems(items: any[]) {
    this.tableItems = items
  }

  deleteOnStatusClick(id: number, status: string) {
    if (this.tableItems) {
      if (status === 'release') {
        this.tableItems = this.tableItems.filter(el => el.id !== id);
      }
    }
  }

  changeUpdateIdItem = (id: number, update_id: number, type: string) => {
    this.tableItems = this.tableItems.map(item => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          status: {code: type}
        }
        return newItem
      }
      return item
    })
  }

  changeField=(option:IUpdateStatusesRequest) =>{
    const {field, id, new_value, update_id} = option

    this.tableItems = this.tableItems.map(item=>{
      if(item.id === id){
        const newItem = {
          ...item,
          update_id,
          [field]:new_value
        }
        return newItem
      } else return item
    })
  }

  setInitSettingsSelect(array: ISettingsSelectedGroup[]) {
    this.initSettingsSelect = array
  }

  setInitSettingsConfirm(array: ISettingsSelected[]) {
    this.initSettingsConfirm = array
  }

  setSelectedList(array: ISettingsSelectedGroup[]) {
    this.selectLists = array
  }

  setConfirmList(array: ISettingsSelected[]) {
    this.confirmLists = array
  }

  setSelectedChecked(params: setCheckedParams) {
    const {code, group} = params

    this.selectLists.map(groupArr => groupArr.group === group ?
      groupArr.fields.map(item => item.code === code ? item.isSelected = !item.isSelected : item)
      : groupArr)
  }

  addToConfirmList(item: ISettingsSelected) {
    const newArr: any[] = [...this.confirmLists, {...item, ordering: this.confirmLists.length + 1}]

    this.confirmLists = newArr
  }

  removeConfirmList(id: string) {
    const newArr: any[] = [...this.confirmLists.filter(item => item.code !== id).map((item, i) => {
      return {...item, ordering: i + 1}
    })]

    this.confirmLists = newArr
  }

  changePaymentStatusPrePaymentTable(integrationId:number, code:string | null, update_id:number){
    this.tableItems = this.tableItems.map(item=>{
      if(item.id === integrationId){
        const newItem = {
          ...item,
          paymentStatus:code ? {code:code} : null,
          payment:{...item.payment, status:code ? {code:code} : null, update_id}
        }
        return newItem
      } return item
    })
  }

  onConfirmDelete(id: string) {
    this.selectLists.map(groupArr =>
      groupArr.fields.map(item => item.code === id ? item.isSelected = !item.isSelected : item)
    )
  }

  onDragStart = (params: IDragParams) => {
    this.curDragItemParams = params
  }

  onDragOver = (e: React.DragEvent) => {
    e.preventDefault()
  }

  onDrop = (e: React.DragEvent, params: IDragParams) => {
    e.preventDefault()

    if (params.code && params.ordering && this.curDragItemParams?.code && this.curDragItemParams?.ordering && params.code !== this.curDragItemParams.code) {

      const dragItem = {code: this.curDragItemParams.code, ordering: params.ordering}
      const downItem = {
        params,
        code: params.code,
        ordering: this.curDragItemParams.ordering < params.ordering ? params.ordering - 1 : params.ordering + 1
      }

      const afterDragArr: any[] = this.confirmLists.map(item => {
        if (item.code === dragItem.code) {
          return {...item, ...dragItem}
        } else if (item.code === downItem.code) {
          return {...item, ...downItem}
        } else {
          return {
            ...item,
            ordering: item.ordering >= dragItem.ordering ? item.ordering + 1 : item.ordering - 1
          }
        }
      })

      this.confirmLists = afterDragArr.sort((a, b) => a.ordering > b.ordering ? 1 : -1).map((x, i) => {
        return {...x, ordering: i + 1}
      })
    }
  }
}

export default new PrePaymentTableStore()