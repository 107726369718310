import {observer} from "mobx-react-lite"
import {AsideCardLayout} from "../../../../components/AsideCardLayout/AsideCardLayout"
import {Skeleton, Stack} from "@mui/material"
import {CreateInputFIeld} from "../CreateInputFIeld/CreateInputFIeld"
import createChannelStore from "../../../../store/createChannel.store"
import channelStore from "../../../../store/channel.store"
import { getSocialTypeInSelect} from "../../shared/dataList"
import {CreateSelectField} from "../CreateSelectField/CreateSelectField"
import channelTheame from "../../../../store/channelTheame"
import {getChannelTheameFilters} from "../../../../components/ChannelTable/ui/ChannelFiter/shared/dataList"
import { CreateDateInput } from "../CreateDateInput/CreateDateInput"
import { getManagerFilterItems } from "../../../../components/IntegrationTable/shared/dataList"
import AllUsersStore from "../../../../store/AllUsers.store"
import userStore from "../../../../store/user.store"

export const CreateAsideMain = observer(() => {
  return (
    <AsideCardLayout headerTitle='Основное'>
      <Stack sx={{pt: 1}} spacing={1}>
        <CreateInputFIeld
          title='Название канала:'
          value={createChannelStore.name}
          placeholder='Введите имя канала'
          inputType='text'
          onSubmit={createChannelStore.setName}
          required
          error={createChannelStore?.isSaveError?.join('')?.includes('name') && createChannelStore?.name === ''}
          errorText={'Заполните поле'}
        />
        <CreateInputFIeld
          title="Ссылка на канал:"
          value={createChannelStore.link}
          onSubmit={createChannelStore.setLink}
          placeholder="Введите ссылку"
          required
          error={createChannelStore?.isSaveError?.join('')?.includes('link') && createChannelStore?.link === ''}
          errorText={'Заполните поле'}
        />
        {channelStore.channels
          ? <CreateSelectField
              title='Тип канала:'
              label='тип'
              value={createChannelStore.type}
              onChange={createChannelStore.setType}
              content={getSocialTypeInSelect(channelStore.channels)}
              required
              error={createChannelStore?.isSaveError?.join('')?.includes('type') && createChannelStore?.type === ''}
              errorText={'Заполните поле'}
            />
          : <Skeleton variant='rounded' sx={{height: 40}}/>
        }
        <CreateInputFIeld
          title="Прогноз кол-ва просмотров:"
          value={createChannelStore.viewsPredict ? createChannelStore.viewsPredict : ''}
          onSubmit={createChannelStore.setViewsPredict}
          placeholder="Введите кол-во"
          inputType="number"
          required
          error={createChannelStore?.isSaveError?.join('')?.includes('viewsPredict') && !createChannelStore?.viewsPredict}
          errorText={'Заполните поле'}
        />
        <CreateInputFIeld
          title="Кол-во подписчиков:"
          value={createChannelStore.audience ? createChannelStore.audience : ''}
          onSubmit={createChannelStore.setAudience}
          placeholder="Введите кол-во"
          inputType="number"
          required
          error={createChannelStore?.isSaveError?.join('')?.includes('audience') && !createChannelStore?.audience}
          errorText={'Заполните поле'}
        />
        {channelTheame.theame
          ? <CreateSelectField
              title='Тематика канала:'
              label='тема'
              value={createChannelStore.theme ? createChannelStore.theme : ''}
              onChange={createChannelStore.setTheme}
              content={getChannelTheameFilters(channelTheame.theame)}
            />
          : <Skeleton variant='rounded' sx={{height: 40}}/>
        }
        <CreateInputFIeld
          title="Прайс:"
          value={createChannelStore.initialPrice ? createChannelStore.initialPrice : ''}
          onSubmit={createChannelStore.setInitialPrice}
          placeholder="Введите цену"
          inputType="number"
        />
        <CreateInputFIeld
          title='Прямой контакт:'
          value={createChannelStore.contact ? createChannelStore.contact : ''}
          placeholder='Введите контакт'
          inputType='text'
          onSubmit={createChannelStore.setContact}
        />
        <CreateInputFIeld
          title='Агенство:'
          value={createChannelStore.agency ? createChannelStore.agency : ''}
          placeholder='Введите агенство'
          inputType='text'
          onSubmit={createChannelStore.setAgency}
        />
        {AllUsersStore.users
          ? <CreateSelectField
              title='Найден менеджером:'
              value={createChannelStore.foundBy ? createChannelStore.foundBy : userStore.user?.id || ''}
              onChange={createChannelStore.setFoundBy}
              content={getManagerFilterItems(AllUsersStore.users)}
            />
          : <Skeleton variant='rounded' sx={{height: 40}}/>
        }
        <CreateDateInput
          title='Дата нахождения:'
          date={createChannelStore.dateFound ? createChannelStore.dateFound : ''}
          onSubmit={createChannelStore.setDateFound}
          required
          error={createChannelStore?.isSaveError?.join('')?.includes('dateFound') && !createChannelStore?.dateFound}
          errorText='Заполните поле'
        />
      </Stack>
    </AsideCardLayout>
  )
})