import {FC, useState} from 'react'
import { ICategorySelect } from './ICategorySelect'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

export const CategorySelect:FC<ICategorySelect> = (props) => {
  const {
    content,
    initValue, 
    placeholder, 
    searchQueryName, 
    disabled,
    removeQueryName,
    removeQueryDate,
    removeState
  } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const [value, setValue] = useState(searchParams.get(searchQueryName) || initValue)

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string)

    searchParams.set(searchQueryName, event.target.value)
    
    if(removeQueryName){
      searchParams.delete(removeQueryName)
    }

    if(removeQueryDate){
      searchParams.delete(removeQueryDate)
    }

    setSearchParams(searchParams)

    if(removeState){
      removeState()
    }
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="simple-select-label" size='small'>{placeholder}</InputLabel>
      <Select
        labelId="simple-select-label"
        value={value}
        label={placeholder}
        onChange={handleChange}
        size='small'
        disabled={disabled}
      >
        {content.map(item=><MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)}
      </Select>
    </FormControl>
  )
}