import {AxiosResponse} from 'axios'
import $users_api from '../../shared/api/usersAxios'
import { IUser } from '../../entities/user/models/IUser'

export default class UsersService {

  static getAllUsers(): Promise<AxiosResponse<IUser[]>>{
    return $users_api.get('/api/users/all')
  }
    
}