import { FC, useState, useEffect } from 'react'
import { IFinancePaymentInput } from './IFinancePaymentInput'
import { Box, Link, Stack, TextField, Typography } from '@mui/material'
import { priceTransormFormat } from '../../../../../../../../shared/functions/functions'
import { Link as RouterLink } from "react-router-dom"

export const FinancePaymentInput:FC<IFinancePaymentInput> = (props) => {
  const {
    id,
    field,
    value,
    changeField,
    title,
    placeholder = 'Введите значение',
    inputType = 'text',
    isEditMode,
    isPrice,
    isLink = false,
    error= false,
    errorText,
  } = props

  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    const delay = setTimeout(()=> changeField(id, field, inputValue), 500)
    return ()=>clearTimeout(delay)
  },[inputValue])

  useEffect(()=>{
    setInputValue(value)
  }, [value])

  return (
    <Stack spacing={0.5}>
      {title && <Typography>{title}</Typography>}
      {isEditMode
        ? <TextField
            error={error}
            value={inputValue}
            onChange={(e)=>setInputValue(e.target.value)}
            placeholder={placeholder}
            size='small'
            type={inputType}
            helperText={error ? errorText:''}
          />
        : <>
            {!isLink
              ? <Typography noWrap>{isPrice ? priceTransormFormat(value, true) : value}</Typography>
              : <Box>
                  <Link 
                    color={'secondary'} 
                    component={RouterLink} 
                    to={value} 
                    noWrap
                    target='_blank'
                    sx={{display:'inline-block', maxWidth:200}}
                  >
                    {value}
                  </Link>
                </Box>
            }
          </>
      }
    </Stack>
  )
}
