import {FC, useState} from 'react'
import {IChannelTitleEdit} from './IChannelTitleEdit'
import {Box, CircularProgress, Stack, TextField, Tooltip, Typography} from '@mui/material'
import {ArrowForward, Check, Clear} from '@mui/icons-material'
import { EditIconButton } from '../../../../components/UI/Buttons/EditIconButton/EditIconButton'
import { CopyIconButton } from '../../../../components/UI/Buttons/CopyIconButton/CopyIconButton'
import { CustomIconButton } from '../../../../components/UI/Buttons/CustomIconButton/CustomIconButton'

export const ChannelTitleEdit: FC<IChannelTitleEdit> = (props) => {
  const {
    isPercent,
    link,
    title,
    value,
    placeholder,
    notFoundTitle = 'Введите источник',
    isEdit,
    onCloseEdit,
    onOpenEdit,
    onSubmit,
    isLoading = false,
    icon,
    tooltipPlacement,
    wrap,
    required = false,
    readOnly = false,
    isCopy = false,
  } = props

  const [nameValue, setNameValue] = useState(value ? value : '')

  const closeEdit = () => {
    setNameValue(value)
    onCloseEdit()
  }

  const onKeyDownSend = (e: React.KeyboardEvent) => {
    if (nameValue.trim() !== '') {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        onSubmit(nameValue)
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: wrap ? '1fr' : 'min-content 1fr',
          overflow: 'hidden',
          alignItems: 'center'
        }}
      >
        <Typography
          noWrap
          sx={{mr: 0.5}}
        >
          {title} {required ? <Typography component={'span'} color={'error'}>*</Typography> : null} {(!wrap && value?.trim() === '') && notFoundTitle}
        </Typography>
        <Stack direction={'row'} alignItems={'center'} sx={{overflow: 'hidden'}}>
          {!isEdit &&
            <>
              <Stack direction={'row'} alignItems={'center'} spacing={0.5} sx={{overflow: 'hidden'}}>
                {wrap && value?.trim() === '' && <Typography noWrap>{notFoundTitle}</Typography>}
                {icon}
              <Typography noWrap>{isPercent?`${value}%`:value}</Typography>
              </Stack>
              {!readOnly &&
                <Tooltip title='Изменить' placement={tooltipPlacement}>
                  <Box>
                    <EditIconButton size='small' onClick={onOpenEdit}/>
                  </Box>
                </Tooltip>
              }
              {isCopy && nameValue.trim() !== '' &&
                <Tooltip title='Копировать' placement={tooltipPlacement}>
                  <Box>
                    <CopyIconButton copyText={value}/>
                  </Box>
                </Tooltip>
              }
              {link &&
                <Tooltip title={<Typography>Ссылка на канал</Typography>}>
                  <Box>
                    <CustomIconButton size='small' onClick={() => window.open(link, '_blank')}>
                      <ArrowForward fontSize='small'/>
                    </CustomIconButton>
                  </Box>
                </Tooltip>
              }
            </>
          }
        </Stack>
      </Box>
      {isEdit &&
        <Stack direction={'row'} sx={{mt: 0.5}}>
          <TextField
            value={nameValue}
            onChange={(e) => setNameValue(e.target.value)}
            variant='outlined'
            size='small'
            fullWidth
            sx={{minWidth: '160px'}}
            placeholder={placeholder}
            InputProps={{
              endAdornment: isLoading && <CircularProgress sx={{maxHeight: 20, maxWidth: 20}}/>
            }}
            onKeyDown={onKeyDownSend}
            autoFocus
            required={required}
          />
          <Stack direction={'row'} alignItems={'center'} sx={{ml:0.5}}>
            <Tooltip title='Отмена'>
              <Box>
                <CustomIconButton size='small' disabled={isLoading} onClick={closeEdit}>
                  <Clear fontSize='small'/>
                </CustomIconButton>
              </Box>
            </Tooltip>
            <Tooltip title='Подтвердить' onClick={() => onSubmit(nameValue)}>
              <Box>
                <CustomIconButton size='small' disabled={((value ? value : '') === nameValue) || isLoading || nameValue.trim() === ''} showBackground>
                  <Check fontSize='small'/>
                </CustomIconButton>
              </Box>
            </Tooltip>
          </Stack>
        </Stack>
      }
    </>
  )
}