import {Box, CircularProgress, Typography} from "@mui/material";
import {TableDefault} from "../../../../../../components/Table/TableDefault";
import React, {useEffect, useState} from "react";
import {AxiosError, AxiosProgressEvent} from "axios";
import {LS_TAKE_REQUEST_PREPAYMENT} from "../../../../../../shared/constants/localstorage";
import PrepaymentsService from "../../../../../../services/prepayments/prepayments.service";
import {setCheckedParams} from "../../../../../../store/integrationTable.store";
import {
  IFiltersUpadteRequest,
  IIntegrationsRequest,
  includeInSelect
} from "../../../../../../services/integration/IntegrationsRequest";
import userStore from "../../../../../../store/user.store";
import {getFilterIntegrationQuery} from "../../../../../../components/IntegrationTable/shared/dataList";
import {toJS} from "mobx";
import {selectionStretegy} from "../../../../../../strategy/strategy";
import {useSearchParams} from "react-router-dom";
import {ISettingsSelected} from "../../../../../../components/IntegrationTable/models/ISettingsSelected";
import {downloadExcel} from "../../../../../../shared/functions/functions";
import prePaymentStore from "../../../../../../store/prePayment.store";
import {NotificationProgress} from "../../../../../../components/NotificationProgress/NotificationProgress";
import {IntegrationTableSettings} from "../../../../../../components/IntegrationTable/ui/IntegrationTableSettings";
import {NotificationAlerts} from "../../../../../../components/NotificationAlerts/NotificationAlerts";
import {PrePaymentTableList} from "./PrePaymentTableList";
import {observer} from "mobx-react-lite";

export const PrePaymentTable = observer(() => {

    const [searchParams, setSearchParams] = useSearchParams()
    const [tableError, setTableError] = useState<string | null>(null)
    const [isLoadingSettings, setLoadingSettings] = useState(false)
    const [errorAlert, setErrorAlert] = useState<string | null>(null)
    const [showAlert, setShowAlert] = useState(false)
    const [tableHead, setTableHead] = useState<any[]>([])
    const [isSettingsOpen, setSettingsOpen] = useState(false)
    const [propgressExport, setPropgressExport] = useState(0)
    const [isExcelLoading, setExcelLoading] = useState(false)
    const [takeParamRequest, setTakeParamRequest] = useState(() =>
      [`${localStorage.getItem(LS_TAKE_REQUEST_PREPAYMENT) ? localStorage.getItem(LS_TAKE_REQUEST_PREPAYMENT) : 100}`]
    )

    const sendSaveSettings = async (array: ISettingsSelected[]) => {
      setLoadingSettings(true)
      setErrorAlert(null)

      const options: IFiltersUpadteRequest = {
        entity: 'prepayment',
        new_settings: array
      }
      try {
        const res = await PrepaymentsService.prePaymentSettingsUpdate(options)
        // console.log(res.data, 'res data upadate filterrs')

        prePaymentStore.setSelectedList(res.data)
        prePaymentStore.setInitSettingsSelect(res.data)

        const totalArr: ISettingsSelected[] = []

        for (let i = 0; i < res.data.length; i++) {
          totalArr.push(...res.data[i].fields)
        }

        totalArr.sort((a, b) => a.ordering > b.ordering ? 1 : -1)

        prePaymentStore.setConfirmList([...totalArr].filter(x => Boolean(x.isSelected)))
        prePaymentStore.setInitSettingsConfirm([...totalArr].filter(x => Boolean(x.isSelected)))

        const rows: any[] = []

        toJS(prePaymentStore).confirmLists.map(field => rows.push(selectionStretegy(field)))
        setTableHead(rows)

        setLoadingSettings(false)
        setSettingsOpen(false)
        setShowAlert(true)
      } catch (err) {
        if (err instanceof AxiosError) {
          console.log(err)
          setErrorAlert(err.message)
          setLoadingSettings(false)
        }
      }
    }

    const onChaneTakeRequest = (event: React.MouseEvent<HTMLElement>, newFormats: string[]) => {
      if (newFormats !== null) {
        setTakeParamRequest(newFormats)
        localStorage.setItem(LS_TAKE_REQUEST_PREPAYMENT, `${newFormats}`)
        if (searchParams.has('page')) {
          searchParams.set('page', '1');
          setSearchParams(searchParams);
        }
        searchParams.set('take', newFormats as any);
        setSearchParams(searchParams);
        prePaymentStore.setPage(1)
      } else return
    }

    const getPrePaymentData = async (page: number) => {
      prePaymentStore.setLoading(true)
      setTableError(null)

      const take = localStorage.getItem(LS_TAKE_REQUEST_PREPAYMENT) ? localStorage.getItem(LS_TAKE_REQUEST_PREPAYMENT) : 100

      const params: IIntegrationsRequest = {
        includeInSelect: prePaymentStore.allFilter as includeInSelect[],
        take: +`${take}`,
        company: userStore?.currentCompany,
        page,
        filterQuery: getFilterIntegrationQuery(searchParams)
      }
      try {
        const res = await PrepaymentsService.prepaymentFiltered(params)
        // console.log(res, 'res integr table DATA')
        const rows: any[] = []
        toJS(prePaymentStore).confirmLists.map(field => rows.push(selectionStretegy(field)))
        setTableHead(rows);
        prePaymentStore.setTableItems(res.data.items)
        prePaymentStore.setTotalCount(res.data.totalCount)
        prePaymentStore.setLoading(false)
        prePaymentStore.setResumePrePayment(res.data.stats)
      } catch (err) {
        if (err instanceof AxiosError) {
          // console.log(err, 'err interg data')
          setTableError(err.message)
          prePaymentStore.setLoading(false)
        }
      }
    }
    const addConfirmList = (item: ISettingsSelected) => prePaymentStore.addToConfirmList(item)
    const removeConfirmArr = (id: string) => prePaymentStore.removeConfirmList(id)
    const setSelectChecked = (params: setCheckedParams) => prePaymentStore.setSelectedChecked(params)

    const changePage = (page: number) => {
      prePaymentStore.setPage(page);
      searchParams.set('page', page.toString());
      setSearchParams(searchParams);
      getPrePaymentData(page);
    };

    const getFilteredPrePayment = () => {
      const currentPage = Number(searchParams.get('page')) || 1
      getPrePaymentData(currentPage)
      prePaymentStore.setPage(currentPage)
    }

    const totalPages = Math.ceil(prePaymentStore.totalCount / (Array.isArray(takeParamRequest) ? +takeParamRequest[0] : takeParamRequest))

    const allowedPageSizes = [100, 300, 500]

    const isPageSizeAllowed = allowedPageSizes.some(number => searchParams.get('take') === number.toString());

    const submitSettings = () => {
      sendSaveSettings(prePaymentStore.confirmLists)
    }

    const resetSettings = () => {
      const store = toJS(prePaymentStore)

      prePaymentStore.setConfirmList(store.initSettingsConfirm)
      prePaymentStore.setSelectedList(store.initSettingsSelect)
    }

    const deleteConfirmItem = (code: string) => {
      prePaymentStore.onConfirmDelete(code)
      prePaymentStore.removeConfirmList(code)
    }

    const onDownloadProgress = (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.estimated && progressEvent.total) {
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
        setPropgressExport(prev => progress)
      }
    }

    const onExportExcel = () => {
      setExcelLoading(true)
      setPropgressExport(prev => 0)

      const params: IIntegrationsRequest = {
        includeInSelect: prePaymentStore.allFilter as includeInSelect[],
        take: +`${100}`,
        company: userStore?.currentCompany,
        page: 1,
        filterQuery: getFilterIntegrationQuery(searchParams)
      }
      PrepaymentsService.prePaymentFilteredExcel(params, onDownloadProgress)
        .then(res => {
          downloadExcel(res.data, 'Предоплаты')
          setPropgressExport(prev => 0)
        })
        .catch(err => {
          if (err instanceof AxiosError) {
            // console.log(err, 'download excel err')
          }
        })
        .finally(() => {
          setExcelLoading(false)
        })
    }

    useEffect(() => {
      const takeFromUrl = Number(searchParams.get('take'))
      if (searchParams.has('take')) {
        localStorage.setItem(LS_TAKE_REQUEST_PREPAYMENT, `${takeFromUrl}`)
        setTakeParamRequest([takeFromUrl.toString()])
      }
      if (searchParams.has('take') && !isPageSizeAllowed) {
        prePaymentStore.setIsPageNotFound(true)
      }
    }, [])

    useEffect(() => {
      if (totalPages && searchParams.has('page') && Number(searchParams.get('page')) > totalPages) {
        prePaymentStore.setIsPageNotFound(true)
      }
    }, [totalPages])

    useEffect(() => {
      getFilteredPrePayment()
      return () => {
        prePaymentStore.setTableItems([])
      }
    }, []);

    return (
      <>
        {isExcelLoading && <NotificationProgress propgress={propgressExport}/>}
        {prePaymentStore.isLoading &&
				 <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2}}>
					Идёт загрузка таблицы... <CircularProgress sx={{ml: 2}}/>
				 </Box>
        }
        {prePaymentStore.isLoading === false && tableError &&
				 <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2}}>
					<Typography variant='h5' color='error'>Ошибка с таблицей! {tableError}</Typography>
				 </Box>
        }
        {prePaymentStore.isLoading === false && tableError === null &&
				 <>
					<TableDefault
					 tableRow={tableHead}
					 showBurgerCell
					 settingsOnclick={() => setSettingsOpen(true)}
					 totalItems={prePaymentStore.tableItems.length}
					 showFooter
					 toggleItems={['100', '300', '500']}
					 toggleValues={takeParamRequest}
					 onChaneToggleButton={onChaneTakeRequest}
					 onExportExcel={onExportExcel}
					 disabledExportExcelBtn={isExcelLoading}
					 maxHeight={'calc(100% - 55px)'}
					 isShowSort
					 showScrollButton
					 hideBorder
					 onChangePage={changePage}
					 totalCount={prePaymentStore.totalCount}
					 paginateStep={Array.isArray(takeParamRequest) ? +takeParamRequest[0] : takeParamRequest}
					 page={prePaymentStore.page}
					 showCheckboxCell
					 showBorderRadius={false}
					 showBoxShadow
					>
					 <PrePaymentTableList tableHead={tableHead}/>
					</TableDefault>
           {isSettingsOpen &&
						<IntegrationTableSettings
						 open={isSettingsOpen}
						 onClose={() => setSettingsOpen(false)}
						 onSubmit={submitSettings}
						 onReset={resetSettings}
						 loading={isLoadingSettings}
						 deleteConfirmItem={deleteConfirmItem}
						 arrayConfirmItems={prePaymentStore.confirmLists}
						 arraySelectItems={prePaymentStore.selectLists}
						 onDragStart={prePaymentStore.onDragStart}
						 onDragOver={prePaymentStore.onDragOver}
						 onDrop={prePaymentStore.onDrop}
						 addToConfirmList={addConfirmList}
						 removeConfirmList={removeConfirmArr}
						 setSelectedChecked={setSelectChecked}
						 currentArray={prePaymentStore.confirmLists}
						/>
           }
					<NotificationAlerts
					 sucsess={{text: 'Настройки успешно сохранились!', open: showAlert, onClose: () => setShowAlert(false)}}
					 error={{
             text: errorAlert ? errorAlert : '',
             open: errorAlert ? true : false,
             onClose: () => setErrorAlert(null)
           }}
					/>
				 </>
        }
      </>
    )
  }
)
