import { Alert, LinearProgress, Snackbar, Typography } from '@mui/material'
import {FC} from 'react'
import { INotificationProgress } from './INotificationProgress'

export const NotificationProgress:FC<INotificationProgress> = (props) => {
  const {
    propgress
  } = props

  return (
    <Snackbar
      anchorOrigin={{vertical:'top', horizontal:'right'}}
      open
    >
      <Alert severity="info" sx={{ width: '100%' }}>
        <Typography>Идёт загрузка... <Typography component={'span'} fontWeight={'bold'}>{propgress} %</Typography></Typography>
        <LinearProgress variant="determinate" value={propgress} color='info' />
      </Alert>
    </Snackbar>
  )
}
