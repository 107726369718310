import { makeAutoObservable } from "mobx";
import { IChannel, IChannelFullResponse } from "../services/channel/ICnannelResponse";
import { IUpdateStatusesRequest } from "../services/integration/IntegrationsRequest";

class ChannelProcessedStore {
  tableItems: IChannel[] = [];
  isLoading: boolean = false;
  filters: Record<string, string | number> = {};
  constructor() {
    makeAutoObservable(this);
  }

  setTableItems(items: IChannel[]) {
    this.tableItems = items;
  }

  setFilters = (filters: Record<string, string | number>) => {
    this.filters = filters;
  }

  // Через get, чтобы работало удаление и изменение каналов, и не дублировать методы на отфильтрованный массив
  get filteredTableItems() {
    return [...this.tableItems].filter((item) => {
      return Object.keys(this.filters).every((key) => {
        const filterValue = this.filters[key];

        if (filterValue === null || filterValue === undefined) return true;

        // Универсальная функция для получения вложенных значений, таких как "status.code"
        const getNestedValue = (obj: any, path: string): any => {
          return path.split(".").reduce((value, key) => value?.[key], obj);
        };

        // Получаем значение поля из объекта item (например, item.status.code)
        const itemValue = getNestedValue(item, key === "status" ? "status.code" : key);

        // Если фильтр содержит несколько значений через запятую
        if (typeof filterValue === "string" && filterValue.includes(",")) {
          const filterValuesArray = filterValue.split(",").map((value) => value.trim());

          // Фильтрация по диапазону дат для поля dateGiven
          if (key === "dateGiven") {
            const [startDateString, endDateString] = filterValuesArray;
            const startDate = new Date(startDateString);
            const endDate = new Date(endDateString);

            // Приведение обеих дат к началу дня для корректного сравнения
            const itemDate = new Date(itemValue as string);
            const itemDateStartOfDay = new Date(itemDate.setHours(0, 0, 0, 0));
            const startDateStartOfDay = new Date(startDate.setHours(0, 0, 0, 0));
            const endDateEndOfDay = new Date(endDate.setHours(23, 59, 59, 999));

            // Сравнение с диапазоном дат (включительно)
            return (
              itemDateStartOfDay >= startDateStartOfDay && itemDateStartOfDay <= endDateEndOfDay
            );
          }

          // Фильтрация для остальных полей, если значение присутствует в списке фильтров
          return filterValuesArray.includes(String(itemValue));
        }

        // Обычная проверка для одиночных значений фильтра
        return String(itemValue) === String(filterValue);
      });
    });
  }

  resetFilters = () => {
    this.filters = {};
  }

  deleteProcessedChannelsItem = (item: IChannelFullResponse) => {
    if (this.tableItems) {
      if (!item.transfer.isProcessed) {
        this.tableItems = this.tableItems.filter((el) => el.id !== item.id);
      }
    }
  };

  deleteProcessedItem = (item: IChannelFullResponse) => {
    if (this.tableItems) {
      this.tableItems = this.tableItems.filter((el) => el.id !== item.id);
    }
  };

  changeUpdateIdItem = (id: number, update_id: number, type: string) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          status: { code: type },
        };
        return newItem;
      }
      return item;
    });
  };

  changeField = (option: IUpdateStatusesRequest) => {
    const { field, id, new_value, update_id } = option;

    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          [field]: new_value,
        };
        return newItem;
      } else return item;
    });
  };

  addReleaseByManager = (id: number, field: string, update_id: number, value: number) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          [field]: { id: value },
        };
        return newItem;
      }
      return item;
    });
  };

  sortTableItem(type: string, sortType: "asc" | "desc") {
    this.tableItems.sort((a: any, b: any) => {
      const aValue = a[type];
      const bValue = b[type];

      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;

      const dateA = new Date(aValue);
      const dateB = new Date(bValue);

      return sortType === "desc"
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    });
  }
}

export default new ChannelProcessedStore();
