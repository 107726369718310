import { AxiosProgressEvent, AxiosResponse } from "axios";
import $gateway_api from "../../shared/api/gatewayAxios";
import { ICreateIntegrationChannelRequest, ICreateReleaseRequest, IFiltersUpadteRequest, IInsertDummiesRequest, IInsertGoodsRequest, IInsertPromokodRequest, IIntegrationActivatePromoRequest, IIntegrationApproveRepeatRequest, IIntegrationDelete, IIntegrationGaranteeRequest, IIntegrationGetCandidatesForRepeatRequest, IIntegrationPromokodDelete, IIntegrationsRequest, IUpdatePriceRequest, IUpdateStatusesRequest } from "./IntegrationsRequest";
import { ICreateIntegrationChannelResponse, ICreateReleaseResponse, IInsertDummiesResponse, IInsertGoodsResponse, IIntegrationActivatePromoResponse, IIntegrationGoodsCategoryThresholds, IIntegrationMinMaxResponse, IIntegrationsFullResponse, IIntegrationsResponse, IUpdatePriceResponse, IUpdateStatusesResponse } from "./IIntegrationsResponse";
import { ISettingsSelectedGroup } from "../../components/IntegrationTable/models/ISettingsSelectedGroup";

export default class IntegrationsService {

  static getIntegrations(params:IIntegrationsRequest):Promise<AxiosResponse<IIntegrationsResponse>>{
    return $gateway_api.post('/api/integrations/filtered', params)
  }

  static getUpdateFilters(params:IFiltersUpadteRequest):Promise<AxiosResponse<ISettingsSelectedGroup[]>>{
    return $gateway_api.post('/api/common/filter-settings-update', params)
  }
  
  static updateIntegrationStatuses(params:IUpdateStatusesRequest):Promise<AxiosResponse<IUpdateStatusesResponse>> {
    return $gateway_api.post('/api/integrations/update-status', params)
  }

  static getIntegrationFull(id:number):Promise<AxiosResponse<IIntegrationsFullResponse>>{
    return $gateway_api.get('api/integrations/full/'+id)
  }
  static createIntegrationRelease(option:ICreateReleaseRequest):Promise<AxiosResponse<ICreateReleaseResponse>> {
    return $gateway_api.post('/api/integrations/insert-release', option)
  }

  static createIntegrationChannel(option:ICreateIntegrationChannelRequest):Promise<AxiosResponse<ICreateIntegrationChannelResponse>> {
    return $gateway_api.post('/api/integrations/create', option)
  }

  static insertPromokod(option:IInsertPromokodRequest):Promise<AxiosResponse<IIntegrationsFullResponse>> {
    return $gateway_api.post('/api/integrations/insert-promokod', option)
  }

  static insertDummies(option:IInsertDummiesRequest):Promise<AxiosResponse<IInsertDummiesResponse>> {
    return $gateway_api.post('/api/integrations/insert-dummies', option)
  }

  static updatePrice(option:IUpdatePriceRequest):Promise<AxiosResponse<IUpdatePriceResponse>> {
    return $gateway_api.post('/api/integrations/update-price', option)
  }

  static updatePredictions(option:IUpdateStatusesRequest):Promise<AxiosResponse<IIntegrationsFullResponse>> {
    return $gateway_api.post('/api/integrations/update-predictions', option)
  }

  static insertGoods(option:IInsertGoodsRequest):Promise<AxiosResponse<IInsertGoodsResponse>> {
    return $gateway_api.post('/api/integrations/insert-goods', option)
  }

  // static updateDate(option:IUpdateStatusesRequest):Promise<AxiosResponse<IIntegrationsFullResponse>> {
  //   return $gateway_api.post('/api/integrations/update-date', option)
  // }
  
  static getMinMaxFilter():Promise<AxiosResponse<IIntegrationMinMaxResponse>>{
    return $gateway_api.get('/api/integrations/minMaxFilters')
  }

  static updateSimpleField(options:IUpdateStatusesRequest):Promise<AxiosResponse<IIntegrationsFullResponse>>{
    return $gateway_api.post('/api/integrations/update-simple-field', options)
  }

  static integrationCopy(options:{id:number}):Promise<AxiosResponse<{id:number}>>{
    return $gateway_api.post('/api/integrations/copy', options)
  }

  static integrationCreateShortLink(options:{id:number}):Promise<AxiosResponse<{shortLink:string}>>{
    return $gateway_api.post('/api/integrations/short-link', options)
  }

  static integrationActivatePromo(options:IIntegrationActivatePromoRequest):Promise<AxiosResponse<IIntegrationActivatePromoResponse>>{
    return $gateway_api.post('api/integrations/activate-promo', options)
  }

  static getCandidatesForRepeat(options:IIntegrationGetCandidatesForRepeatRequest):Promise<AxiosResponse<IIntegrationsFullResponse[]>>{
    return $gateway_api.post('/api/integrations/getCandidatesForRepeat', options)
  }

  static integrationApproveRepeat(options:IIntegrationApproveRepeatRequest):Promise<AxiosResponse<IIntegrationsFullResponse>>{
    return $gateway_api.post('/api/integrations/approveRepeat', options)
  }

  static getGoodsCategoryThresholds():Promise<AxiosResponse<IIntegrationGoodsCategoryThresholds[]>>{
    return $gateway_api.get('/api/integrations/getGoodsCategoryThresholds')
  }

  static setGoodsCategoryThreshold(options:IIntegrationGoodsCategoryThresholds):Promise<AxiosResponse<IIntegrationGoodsCategoryThresholds>>{
    return $gateway_api.post('/api/integrations/setGoodsCategoryThreshold', options)
  }

  static delete(option:IIntegrationDelete):Promise<AxiosResponse<{id:number}>>{
    return $gateway_api.post('/api/integrations/delete/'+`${option.integrationId}`, {
        update_id:option.update_id
      }
    )
  }

  static approvedIntegration(id:number, isIntegrations?:boolean):Promise<AxiosResponse<IIntegrationsFullResponse>>{
    return $gateway_api.post('/api/integrations/approvedIntegration/'+id, {isIntegrations})
  }
  
  static deletePromokod(options:IIntegrationPromokodDelete):Promise<AxiosResponse<IIntegrationsFullResponse>>{
    return $gateway_api.post('/api/integrations/delete-promokod', options)
  }

  static setGarant(options:IIntegrationGaranteeRequest):Promise<AxiosResponse<IIntegrationsFullResponse>>{
    return $gateway_api.post('/api/integrations/setGarant', options)
  }

  static integrationsFilteredExcel(option:IIntegrationsRequest, onDownloadProgress?:((progressEvent: AxiosProgressEvent)=>void)):Promise<AxiosResponse<Blob>>{
    return $gateway_api.post('/api/integrations/filtered/excel', option, {
      responseType:'blob',
      onDownloadProgress
    })
  }

}