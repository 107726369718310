import { IUser } from "../../../../../entities/user/models/IUser";
import { ALL_RULES } from "../../../../../shared/rules/interface/EnumRights";

export const showDeleteBtn = (user:IUser):boolean => {
  let show = false

  if(user.rights.some(x=>x.code === ALL_RULES.ADMIN) || user.rights.some(x=>x.code === ALL_RULES.delete_channel)){
    show = true
  }

  return show
}