import {FC} from 'react'
import { INotificationAlerts } from './models/INotificationAlerts'
import { Alert, Snackbar } from '@mui/material'

export const NotificationAlerts:FC<INotificationAlerts> = (props) => {
  const {sucsess, error} = props

  return (
    <>
      {sucsess &&
        <Snackbar
          anchorOrigin={{vertical:'top', horizontal:'left'}}
          open={sucsess.open}
          autoHideDuration={sucsess.autoHideDuration ? sucsess.autoHideDuration : 1500}
          onClose={sucsess.onClose}
        >
          <Alert onClose={sucsess.onClose} severity="success" sx={{ width: '100%' }}>
            {sucsess.text}
          </Alert>
        </Snackbar>
      }
      {error &&
        <Snackbar
          anchorOrigin={{vertical:'top', horizontal:'left'}}
          open={error.open}
          autoHideDuration={error.autoHideDuration ? error.autoHideDuration : 3000}
          onClose={error.onClose}
        >
          <Alert onClose={error.onClose} severity="error" sx={{ width: '100%' }}>
            {error.text}
          </Alert>
        </Snackbar>
      }
    </>
  )
}
