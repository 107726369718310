import { FC } from "react"
import { Alert, AlertTitle } from "@mui/material"
import { IBlockedCard } from "./IBlockedCard"

export const BlockedCard:FC<IBlockedCard> = (props) => {
  const {
    title = 'Невозможно оплатить интеграцию', 
    text = 'Проверьте интеграцию. У интеграции сменился статус или она была удалена.'
  } = props

  return (
    <Alert severity="error">
      <AlertTitle>{title}</AlertTitle>
      {text}
    </Alert>
  )
}
