import {FC} from 'react'
import { Box, Button, DialogActions, IconButton, Menu, Stack, Typography } from '@mui/material'
import { LocalizationProvider, PickersActionBarProps, StaticDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ru'
import EventIcon from '@mui/icons-material/Event';
import {IAnalyticTextMultiplyDateSelect} from "./IAnalyticTextMultiplyDateSelect";
import { Close } from '@mui/icons-material'
import { getDatePickerIconsStyle } from '../../../../../../../../components/UI/DateCustomInput/shared/getDatePickerIconsStyle'

export const AnalyticTextMultiplyDateSelect:FC<IAnalyticTextMultiplyDateSelect> = (props) => {
  const {
    title,
    anchorEl,
    onClose,
    onOpen,
    valueFirst,
    changeValueFirst,
    valueSecon,
    changeValueSecond,
    disabled,
    onSubmit,
    sx,
    contentDateSx,
    headerDateSx,
    submitBtnSx,
    onClearDate,
    showIconClearDate,
  } = props

  const open = Boolean(anchorEl)

  const MyActionBar = ({}: PickersActionBarProps) => {
    return (
      <DialogActions sx={{display:'none'}}/>
    )
  }

  return (
    <>
      <Box
        sx={{display: 'flex', alignItems: 'center'}}
        color={'secondary'}
      >
        <Box
          sx={{
            '&:hover':{
              textDecorationStyle:'dashed',
            },
            color: 'black',
            fontWeight: 'bold',
            display:'inline-flex',
            alignItems:'center'
          }}
          onClick={onOpen}
        >
          <Typography color={'inherit'} fontWeight={'inherit'}>{title}</Typography>
          <EventIcon sx={{cursor:'pointer'}}/>
        </Box>
        {showIconClearDate && onClearDate &&
          <IconButton size='small' onClick={onClearDate}>
            <Close fontSize='small' color='error'/>
          </IconButton>
        }
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        <Stack direction={'row'} sx={{position:'relative', ...sx}}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
            <StaticDatePicker
              value={valueFirst}
              onChange={value=>changeValueFirst(value)}
              slots={{
                toolbar:undefined,
                actionBar:MyActionBar,
              }}
              sx={{
                "& .MuiPickersCalendarHeader-root": {...headerDateSx as any},
                "& .MuiPickersFadeTransitionGroup-root":{...contentDateSx as any},
              }}
              slotProps={{...getDatePickerIconsStyle()}}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
            <StaticDatePicker
              value={valueSecon}
              onChange={value=>changeValueSecond(value)}
              slots={{
                toolbar:undefined,
                actionBar:MyActionBar,
              }}
              sx={{
                "& .MuiPickersCalendarHeader-root": {...headerDateSx as any},
                "& .MuiPickersFadeTransitionGroup-root":{...contentDateSx as any},
              }}
              slotProps={{...getDatePickerIconsStyle()}}
            />
          </LocalizationProvider>
          <Box sx={{position:'absolute', bottom:0, right:0, pl:2, pr:2, ...submitBtnSx}}>
            <Button
              variant='contained'
              disabled={disabled}
              onClick={onSubmit}
            >
              Готово
            </Button>
          </Box>
        </Stack>
      </Menu>
    </>
  )
}