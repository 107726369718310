import {makeAutoObservable} from "mobx";
import {IIntegrationCategory} from "../services/layout/layoutResponse";

class ChannelTransferStore
{
  channelTransferStatus:IIntegrationCategory[]=[]

  constructor(){
    makeAutoObservable(this)
  }

  setTransfer(channelTransferStatus:IIntegrationCategory[]) {
    this.channelTransferStatus = channelTransferStatus
  }

}

export default new ChannelTransferStore()