import { makeAutoObservable } from "mobx";
import { AxiosError } from "axios";
import ReferencesService from "../services/references/references.service";
import { IReferencesTzResponse } from "../services/references/IReferencesResponse";

class AllTzStore {
  tzItems:IReferencesTzResponse[] | null = null

  constructor(){
    makeAutoObservable(this)
  }

  addTzinStore = (tz:IReferencesTzResponse) => {
    if(this.tzItems !== null){
      this.tzItems = [...this.tzItems, tz]
    }
  }

  changeTzInStore = (id:number, field:string, value:any, update_id:number ) => {
    if(this.tzItems !== null){
      this.tzItems = this.tzItems.map(item=>{
        if(item?.id === id){
          return {
            ...item,
            [field]:value,
            update_id
          }
        } else return item
      })
    }
  }

  getAllUsers(){
    ReferencesService.getAllTz()
      .then(res=>{
        // console.log(res, 'res all tz')
        this.tzItems = res.data
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err all tz')
        }
      })
  }

}

export default new AllTzStore()