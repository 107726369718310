import { AxiosResponse } from "axios";
import $gateway_api from "../../shared/api/gatewayAxios";
import { ITzFullItemResponse, ITzGetAllPesponse } from "./ITzResponse";
import { ITzCreateRequest, ITzGetAllRequest, ITzUpdateSimpleFieldRequest } from "./ITzRequest";

export default class TzService {

  static all(option:ITzGetAllRequest):Promise<AxiosResponse<ITzGetAllPesponse>>{
    return $gateway_api.post('/api/tz/all', option)
  }

  static tzCreate(option:ITzCreateRequest):Promise<AxiosResponse<ITzFullItemResponse>>{
    return $gateway_api.post('/api/tz/create', option)
  }

  static updateSimpleField(option:ITzUpdateSimpleFieldRequest):Promise<AxiosResponse<ITzFullItemResponse>>{
    return $gateway_api.post('/api/tz/update-simple-field', option)
  }

}