import { AxiosProgressEvent, AxiosResponse } from "axios";
import $gateway_api from "../../shared/api/gatewayAxios";
import { IAgreementsFiltered, IAgreementsRequestApprove, IAgreementsRequestCreate, IAgreementsRequestPutComment, IAgreementsRequestupdateStatus, IAgreementsFilteredExcel } from "./IAgreementsRequest";
import { IAgreementsFilteredResponse, IAgreementsResponseUpdateStatus, IUserApprovments } from "./IAgreementsResponse";
import { IIntegrationsFullResponse } from "../integration/IIntegrationsResponse";

export default class AgreementsService {

  static create(option:IAgreementsRequestCreate): Promise<AxiosResponse<IIntegrationsFullResponse>>{
    return $gateway_api.post('/api/agreements/create', option)
  }

  static approve(option:IAgreementsRequestApprove): Promise<AxiosResponse<IUserApprovments>>{
    return $gateway_api.post('/api/agreements/approve', option)
  }

  static putComment(option:IAgreementsRequestPutComment): Promise<AxiosResponse<IUserApprovments>>{
    return $gateway_api.post('/api/agreements/putComment', option)
  }

  static getAgreementsFiltered(option:IAgreementsFiltered): Promise<AxiosResponse<IAgreementsFilteredResponse>>{
    return $gateway_api.post('/api/agreements/filtered', option)
  }

  static updateStatus(option:IAgreementsRequestupdateStatus): Promise<AxiosResponse<IAgreementsResponseUpdateStatus>>{
    return $gateway_api.post('/api/agreements/updateStatus', option)
  }

  static agreementsFilteredExcel(option:IAgreementsFilteredExcel, onDownloadProgress?:((progressEvent: AxiosProgressEvent)=>void)):Promise<AxiosResponse<Blob>>{
    return $gateway_api.post('/api/agreements/filtered/excel', option, {
      responseType:'blob',
      onDownloadProgress
    })
  }
  
}