import { FC, useEffect, useState } from 'react'
import { AsideCardLayout } from '../../../../components/AsideCardLayout/AsideCardLayout'
import { IChannelAsideRequisite } from './IChannelAsideRequisite'
import { Box, Stack } from '@mui/material'
import { TableDefault } from '../../../../components/Table/TableDefault'
import { IStrategyField } from '../../../../strategy/interface/IStrategyField'
import { getRequisiteItem } from '../../shared/dataList'
import { ChannelRequisiteModal } from '../ChannelRequisiteModal/ChannelRequisiteModal'
import ChannelService from '../../../../services/channel/channel.service'
import { IChannelInsertAccountRequest } from '../../../../services/channel/IChannelRequest'
import { AxiosError } from 'axios'
import { IChannelRequisite } from './models/IChannelRequisite'
import { TableRequisiteItem } from './ui/TableRequisiteItem/TableRequisiteItem'
import { useSearchParams } from 'react-router-dom'
import { CHANNEL_MODAL_OPEN } from '../../../../shared/constants/searchParams'
import {EmptyText} from "../../../../components/EmptyText/EmptyText";
import { AddedButton } from '../../../../components/UI/Buttons/AddedButton/AddedButton'

export const ChannelAsideRequisite:FC<IChannelAsideRequisite> = (props) => {
  const {accounts, channelId, readOnly} = props

  const [searchParams, setSearchParams] = useSearchParams()

  const [totalItems, setTotalItems] = useState<IChannelRequisite[]>([])

  const [isLoadingAdd, setLoadingAdd] = useState(false)
  const [isErrorAdd, setErrorAdd] = useState<string | null>(null)

  const rows:IStrategyField[] = [
    {title:'Группа', code:'group', immutable:false, isSimple:true,},
    {title:'Тип', code:'type', immutable:false, isSimple:true,},
    {title:'Значение', code:'value', immutable:false, isSimple:true,},
    {title:'ФИО', code:'fio', immutable:false, isSimple:true,},
  ]

  const addNewRequisite = (fio:string, value:string, type:string) => {
    setLoadingAdd(true)
    setErrorAdd(null)

    const option:IChannelInsertAccountRequest = {
      value,
      channelId,
      fio,
      type
    }
    ChannelService.insertAccounts(option)
      .then(res=>{
        // console.log(res, 'res add requisit')

        const item:IChannelRequisite = {
          id:res.data.id,
          type:res.data.type.name,
          value:res.data.value,
          fio:res.data.fio,
          group:res.data.type.accountTypeGroup.name,
          typeCode:res.data.type.code
        }

        setTotalItems(prev=>[...prev, item])

        searchParams.delete(CHANNEL_MODAL_OPEN)
        setSearchParams(searchParams)

        setLoadingAdd(false)    
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err add requiset')
          
          setErrorAdd(err.message)
          setLoadingAdd(false)
        }
      })
  }

  const removeItem = (id?:string | number) => {
    ChannelService.deleteAccounts({id:`${id}`})
      .then(res=>{
        // console.log(res, 'res remove requisite')
        setTotalItems(prev=>prev.filter(item=>item.id !== id))
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err remove request')
        }
      })
  }

  const handelOpen = () => {
    searchParams.set(CHANNEL_MODAL_OPEN, 'true')
    setSearchParams(searchParams)
  }
  const handelClose = () =>  {
    searchParams.delete(CHANNEL_MODAL_OPEN)
    setSearchParams(searchParams)
  }

  useEffect(()=>{
    setTotalItems(accounts.map(acc=>getRequisiteItem(acc)))
  },[accounts])

  return (
    <>
      <AsideCardLayout
        headerTitle='Реквизиты'
        sx={{mt:2}}
        rightContent={!readOnly &&
          <Box>
            <AddedButton variant='outlined' onClick={handelOpen}>Добавить</AddedButton>
          </Box>
        }
        showDivider={false}
      >
        <Stack spacing={2} sx={{mt:1}}>
          {totalItems.length > 0 &&
            <TableDefault
              tableRow={rows}
              showBorderRadius={false}
              showBurgerCell={readOnly === true ? false : true}
              maxHeight='300px'
              maxWidth='100%'
            >
              {totalItems.map(account=>
                <TableRequisiteItem
                  key={account.id}
                  cells={rows}
                  {...account}
                  onDelete={removeItem}
                  onChangeState={setTotalItems}
                  readOnly={readOnly === true ? false : true}
                />)
              }
            </TableDefault>
          }
          {totalItems.length === 0 && <EmptyText>Реквизитов сейчас нет</EmptyText>}
        </Stack>
      </AsideCardLayout>
      {searchParams.has(CHANNEL_MODAL_OPEN) &&
        <ChannelRequisiteModal
          open={searchParams.has(CHANNEL_MODAL_OPEN)}
          onClose={handelClose}
          title='Добавить реквизит'
          onSubmit={addNewRequisite}
          error={isErrorAdd}
          loading={isLoadingAdd}
        />
      }
    </>
  )
}