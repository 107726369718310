import {FC, useState} from 'react'
import { Check, Clear } from '@mui/icons-material'
import { CircularProgress, Stack, TextField } from '@mui/material'
import { IInputEditField } from './IInputEditField'
import { CustomIconButton } from '../../../../UI/Buttons/CustomIconButton/CustomIconButton'

export const InputEditField:FC<IInputEditField> = (props) => {
  const {
    onClose,
    onSubmit,
    error = null,
    initValue,
    loading = false,
    minWidth,
    placeholder,
    fullWidth = false,
    inputType = 'text',
    required = false,
    maxWidth
  } = props

  const [value, setValue] = useState(initValue ? initValue : '')

  const onKeyDownSend = (e:React.KeyboardEvent) => {
    if(e.code === "Enter" || e.code === "NumpadEnter"){
      onSubmit(value)
    }
  }

  return (
    <Stack direction={'row'} spacing={0.5}>
      <TextField
        value={value}
        onChange={e=>setValue(e.target.value)}
        size='small'
        placeholder={placeholder}
        disabled={loading}
        sx={{minWidth:minWidth ? minWidth : '150px', maxWidth:maxWidth}}
        error={error ? true : false}
        fullWidth={fullWidth}
        type={inputType}
        InputProps={{
          endAdornment:loading && <CircularProgress sx={{maxHeight:20, maxWidth:20}} /> 
        }}
        onKeyDown={onKeyDownSend}
        autoFocus
        required={required}
      />
      <Stack direction={'row'} alignItems={'center'}>
        <CustomIconButton disabled={loading} size='small' onClick={onClose}>
          <Clear fontSize='small'/>
        </CustomIconButton>
        <CustomIconButton
          disabled={loading || value.trim()==='' || value.trim()===initValue}
          size='small' 
          onClick={()=>onSubmit(value)}
          showBackground
        >
          <Check fontSize='small'/>
        </CustomIconButton>
      </Stack>
    </Stack>
  )
}