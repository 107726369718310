import {FC, useEffect, useState} from 'react'
import { InputEditField } from '../InputEditField/InputEditField'
import { IPaymentPriceField } from './IPaymentPriceField'
import { IconButton, Stack, Typography } from '@mui/material'
import { Edit } from '@mui/icons-material'
import PaymentsService from '../../../../../services/payments/payments.service'
import { IPaymentsEditRequest } from '../../../../../services/payments/IPaymentsRequest'
import { AxiosError } from 'axios'
import {priceTransormFormat} from "../../../../../shared/functions/functions";

export const PaymentPriceField:FC<IPaymentPriceField> = (props) => {
  const {id, price, field, readOnly, changeCommission} = props

  const [priceValue, setPriceValue] = useState(price.toString())
  const [isLoading, setLoading] = useState(false)
  const [isEdit, setEdit] = useState(false)

  const changePrice = (value:string) => {
    setLoading(true)

    const option:IPaymentsEditRequest = {
      id,
      [field]:value
    }
    PaymentsService.paymentEdit(option)
      .then(res=>{
        // console.log(res, field, 'change price payment')
        if(changeCommission){
          changeCommission(id, res.data[field])
        }
        setPriceValue(res.data[field])
        setEdit(false)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, field, 'err change price payment')
          setLoading(false)
        }
      })
  }

  const openEdit = () => setEdit(true)
  const closeEdit = () => setEdit(false)

  useEffect(()=>{
    setPriceValue(price.toString())
  },[props])

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
      {!isEdit
        ? <>
            <Typography sx={{whiteSpace:'nowrap'}}>{priceTransormFormat(priceValue, true)}</Typography>
            {!readOnly &&
              <IconButton size='small' onClick={openEdit}>
                <Edit fontSize='small'/>
              </IconButton>
            }
          </>
        : <InputEditField
            initValue={priceValue}
            inputType='number'
            placeholder='Введите число'
            onClose={closeEdit}
            onSubmit={changePrice}
            error={null}
            loading={isLoading}
          />
      }
    </Stack>
  )
}
