import {FC, useState} from 'react'
import { IBudgetEditField } from './IBudgetEditField'
import { InputEditField } from '../InputEditField/InputEditField'
import { Stack, Typography } from '@mui/material'
import { priceTransormFormat } from '../../../../../shared/functions/functions'
import ReferencesService from '../../../../../services/references/references.service'
import { IReferencesSetBudgetUserRequest } from '../../../../../services/references/IReferencesRequest'
import { AxiosError } from 'axios'
import { observer } from 'mobx-react-lite'
import dashboardStore from '../../../../../store/dashboard.store'
import { EditIconButton } from '../../../../UI/Buttons/EditIconButton/EditIconButton'

export const BudgetEditField:FC<IBudgetEditField> = observer((props) => {
  const {
    initValue, 
    month, 
    userId, 
    year, 
    budgetType,
    field,
    fullWidth = false
  } = props

  const [isOpenEdit, setOpenEdit] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const submit = (value:string) => {
    setLoading(true)
    setError(null)

    const parmas:IReferencesSetBudgetUserRequest = {
      amount:+value,
      month,
      userId,
      year,
      budgetType
    }
    ReferencesService.budgetSetToUser(parmas)
      .then(res=>{
        // console.log(res, 'res set to user budget')
        dashboardStore.setToUser(userId, field, +value)

        const newValue:undefined | number = res.data?.find(x=>x.isGeneral===true)?.[field]
        dashboardStore.setGeneral(Number(newValue), field)

        setOpenEdit(false)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err set to user budget')
          setError(err.response?.data?.message)
          setLoading(false)
        }
      })

  }
  
  return (
    <>
      {!isOpenEdit
        ? <Stack direction={'row'} alignItems={'center'} spacing={0.5} sx={{minHeight:38}}>
            <Typography sx={{whiteSpace:'nowrap'}}>{priceTransormFormat(initValue)}</Typography>
            <EditIconButton size='small' onClick={()=>setOpenEdit(true)}/>
          </Stack>
        : <InputEditField
            initValue={`${initValue}`}
            inputType='number'
            placeholder={'Введите бюджет'}
            onClose={()=>setOpenEdit(false)}
            onSubmit={submit}
            error={isError}
            loading={isLoading}
            fullWidth={fullWidth}
          />
      }
    </>
  )
})