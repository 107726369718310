import {lightGreen, red, yellow} from "@mui/material/colors"
import {getTableCellColor} from "../../../shared/functions/functions"
import {getAudiencePercentColor} from "../../../pages/ChannelCardPage/ui/ChannelAudience/shared/functions";

export const changeTableColor = (code: string, value: number, isFlagEnabled: boolean = false) => {
  if (code === 'targetAudience') {
    return getAudiencePercentColor(value)
  }
  if (code.toLowerCase().includes('roi')) {
    return getTableCellColor({
      green: {from: 100, before: 999999999, color: lightGreen[400]},
      orange: {from: 50, before: 100, color: yellow[400]},
      none: {from: -1, before: 50, color: 'inherit'},
      red: {from: -99999999, before: -1, color: red[400]},
      value
    })
  } else if (code === 'CTR' || code === 'CR' || isFlagEnabled) {
    return getTableCellColor({
      green: {from: 0.5, before: 999999999, color: lightGreen[400]},
      orange: {from: 0, before: 0.5, color: yellow[400]},
      none: {from: 0, before: 0, color: 'inherit'},
      red: {from: 0.0000001, before: 0.1, color: red[400]},
      value
    })
  } else return 'inherit'
}