import { FC, useState, useEffect, memo } from 'react'
import { IFilterMultiple } from './IFilterMultiple'
import { Box, FormControl, Slider, Stack, TextField, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { FilterSkeleton } from '../FilterSkeleton/FilterSkeleton'
import { Close } from '@mui/icons-material'
import { CustomIconButton } from '../../../UI/Buttons/CustomIconButton/CustomIconButton'

export const FilterMultiple:FC<IFilterMultiple> = memo((props) => {
  const {
    label, 
    initValue, 
    step, 
    isLoading = false, 
    searchParamName,
    hiddenSlider = false,
    showLabel = false,
    allNumLastCell = false
  } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const [onBlur, setBlur] = useState(searchParams.has(searchParamName))
  const [value, setValue] = useState<string[]>(searchParams.get(searchParamName) ? searchParams.get(searchParamName)!?.split(',') : initValue?.map(x=>x?.toString()) || ['', ''])
  
  const handleChange = ( event: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], +value[1]).toString(), value[1]])
    } else {
      setValue([value[0], Math.max(newValue[1], +value[0]).toString()])
    }
    setBlur(true)
  }

  const changeinputMinValue = (e:React.ChangeEvent<HTMLInputElement>) => {
    const validateValue = e.target.value.trim().replace(/[A-Za-zА-Яа-яЁё]/, '')

    const currentValue = validateValue === '' ? '' : validateValue
    const totalValue = +currentValue > +initValue[1] ? initValue[1].toString() : currentValue
  
    setValue(prev=>[totalValue, prev[1]])
    setBlur(true)

  }

  const changeinputMaxValue = (e:React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value.trim().replace(/[A-Za-zА-Яа-яЁё]/, '')

    if(!allNumLastCell){
      const totalValue = +currentValue > initValue[1] ? initValue[1].toString() : +currentValue < +initValue[0] ? initValue[0].toString() : currentValue

      setValue(prev=>[prev[0], totalValue])
      setBlur(true)

    } else {
      const totalValue = +currentValue < +initValue[1] ? +currentValue : initValue[1]
      
      setValue(prev=>[prev[0], totalValue?.toString()])
      setBlur(true)
    }
    
  }

  const resetFilter = () => {
    searchParams.delete(searchParamName)
    setSearchParams(searchParams)
    setValue(initValue.map(x=>x.toString()))
    setBlur(false)
  }

  const changeUrlValue = () => {
    searchParams.set(searchParamName, `${value}`)
    setSearchParams(searchParams)
  }

  useEffect(()=>{
    if(onBlur){
      const delay = setTimeout(()=>changeUrlValue(), 300)
      return ()=>clearTimeout(delay)
    }
  },[value])

  return (
    <>
    {!isLoading &&
      <FormControl size='small'>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{height:27}}>
          <Typography noWrap variant='body2'>{label}</Typography>
          {onBlur &&
            <Box>
              <CustomIconButton size='small' color='error' onClick={resetFilter}>
                <Close fontSize='small'/>
              </CustomIconButton>
            </Box>
          }
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
          <TextField
            value={value[0] ? value[0] : ''}
            onChange={changeinputMinValue}
            type='text'
            size='small'
            label={showLabel ? 'от' : null}
          />
          <TextField
            value={value[1] ? value[1] : ''}
            onChange={changeinputMaxValue}
            type='number'
            size='small'
            label={showLabel ? 'до' : null}
          />
        </Stack>
        {hiddenSlider !== true &&
          <Slider
            min={(+initValue[0] !== null || +initValue[0] !== undefined) ? +initValue[0] : undefined}
            max={(+initValue[1] !== null || +initValue[1] !== undefined) ? +initValue[1] : undefined}
            step={step}
            value={value.map(x=>+x)}
            onChange={handleChange}
            disableSwap
            size='small'
            onBlur={()=>setBlur(true)}
            sx={{maxWidth:'90%', display:'flex', margin:'auto'}}
          />
        }
      </FormControl>
    }
    {isLoading &&
      <FilterSkeleton/>
    }
    </>
  )
})
