import {Paper, Stack, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import PrePaymentStore from "../../../../../../store/prePayment.store";
import dayjs from "dayjs";

export const ResumePrePaymentCard = observer(() => {

    const {earliestPaidAt, totalIntegrationPrice, totalPaysSum} = PrePaymentStore.resumePrePayment || {}

    return (
      <>
        {PrePaymentStore.resumePrePayment &&
				 <Paper sx={{padding: '12px', borderRadius: 2, mb: '12px'}} elevation={0} variant="outlined">
					<Typography variant={'h6'} mb={2}>Интеграции оплаченные и невышедшие</Typography>
					<Stack sx={{flexDirection: {xs: 'column', md: 'row', alignItems: {xs: 'flex-start', md: 'center'}}}} gap={2}>
					 <Typography>{`Интеграций: ${PrePaymentStore.tableItems.length || 0}`}</Typography>
					 <Typography>Общая стоимость: <Typography sx={{
             backgroundColor: '#ffe664',
             padding: 1,
             display: 'inline',
             borderRadius: 1
           }}>{totalIntegrationPrice}</Typography></Typography>
					 <Typography>Оплаченная сумма: <Typography sx={{
             backgroundColor: '#afee7c',
             padding: 1,
             display: 'inline',
             borderRadius: 1
           }}>{totalPaysSum}</Typography></Typography>
					 <Typography>{`Самая давняя оплата: от ${dayjs(earliestPaidAt)?.format('DD.MM.YYYY')}`}</Typography>
					</Stack>
				 </Paper>
        }
      </>
    )
  }
)