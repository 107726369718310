import { useState, useEffect} from 'react'
import StatisticsService from '../../../../services/statistics/statistics.service'
import { AxiosError } from 'axios'
import { Box, Paper, Skeleton, Stack, Typography } from '@mui/material'
import { SelectManager } from './ui/SelectManager/SelectManager' 
import { AsideGeneralAnalytics } from './ui/AsideGeneralAnalytics/AsideGeneralAnalytics' 
import { IStatisticsResponseGeneral } from '../../../../services/statistics/IStatisticsResponse'
import { AnalyticChart } from './ui/AnalyticChart/AnalyticChart' 
import { AnalyticDatePicker } from './ui/AnalyticDatePicker/AnalyticDatePicker' 
import { observer } from 'mobx-react-lite'
import analyticsStore from '../../../../store/analytics.store'
import { getAnalyticsParams } from '../../shared/functions'
import { useSearchParams } from 'react-router-dom'

export const GeneralAnalytics = observer(() => {

  const [searchParams] = useSearchParams()

  const [allStatistics, setAllStatistics] = useState<null | IStatisticsResponseGeneral>(null)
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)
  
  const [chartShowId, setChartShowID] = useState(0)
  const [keyNameChart, setKeyName] = useState('')

  const getStatistics = () => {
    setLoading(true)
    setError(null)
    setAllStatistics(null)
   
    const option = getAnalyticsParams(searchParams, analyticsStore.dateFrom!.format('YYYY-MM-DD'), analyticsStore.dateTo!.format('YYYY-MM-DD'))

    StatisticsService.getGeneralStatistics(option)
      .then(res=>{
        // console.log(res, 'res general stistics')
        setAllStatistics(res.data)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err general stitistics')
          setLoading(false)
          setError(err.message)
        }
      })
  }

  const changeChartId = (value:number, keyName:string) => {
    setChartShowID(value)
    setKeyName(keyName)
  }

  useEffect(()=>{
    getStatistics()
  }, [searchParams.get('id'), searchParams.get('date')])

  return (
    <Stack spacing={2}>
      <Stack
        component={Paper}
        elevation={0}
        direction={'row'}
        columnGap={'50px'}
        alignItems={'center'}
        sx={{p:2, boxShadow:'0px 0px 8px 0px rgba(34, 60, 80, 0.2)', flexWrap:'wrap'}}
      >
        <Stack direction={'row'} alignItems={'center'} sx={{flexWrap:'wrap'}}>
          <Typography>Статистика по</Typography>
          <SelectManager/>
        </Stack>
        <Stack direction={'row'} sx={{flexWrap:'wrap'}}>
          <Typography sx={{mr:0.5}}>Данные за: </Typography>
          <AnalyticDatePicker/>
        </Stack>
      </Stack>
      <Stack component={Paper} elevation={0} sx={{p:2, boxShadow:'0px 0px 8px 0px rgba(34, 60, 80, 0.2)'}}>
        <Box
          sx={{
            display:'grid',
            gridTemplateColumns:isError ? '1fr' : {xl:'1fr 2fr', lg:'1fr 2fr', md:'1fr 3fr', xs:'1fr'}, 
            columnGap:4,
            height:isError ? 570 : 'inherit',
          }}
        >
          {!isLoading && allStatistics &&
            <AsideGeneralAnalytics
              allDataStatistics={allStatistics} 
              initValue={chartShowId} 
              onChangeChartId={changeChartId}
            />
          }
          {isLoading &&
            <Skeleton variant='rounded' sx={{height:'570px'}}/>
          }
          <Box sx={{height:'100%'}}>
            {!isLoading
              ? <AnalyticChart data={allStatistics ? allStatistics[keyNameChart] : null}/>
              : <Skeleton variant='rounded' sx={{height:570}}/>
            }
          </Box>
        </Box>
      </Stack>
    </Stack>
  )
})