import { FC, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { FinancesOplata } from '../../../../../../../FinancesCreatePage/ui/FinancesOplata/FinancesOplata'
import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { ContractDateInput } from '../../../../../../../FinanceCardPage/ui/ContractBlock/ui/ContractDateInput/ContractDateInput'
import dayjs from 'dayjs'
import { OplataStatus } from '../../../../../../../FinanceCardPage/ui/OplataInCard/ui/OplataStatus/OplataStatus'
import { EditModeButton } from '../../../../../../../../components/UI/Buttons/EditModeButton/EditModeButton'
import { IOplataCard } from './IOplataCard'
import { IPaymentsFullResponse } from '../../../../../../../../services/payments/IPaymentsResponse'
import integrationPageStore from '../../../../../../../../store/integrationPage.store'
import statusesStore from '../../../../../../../../store/statuses.store'
import { IFinancePaymentEditRequest } from '../../../../../../../../services/payments/IPaymentsRequest'
import PaymentsService from '../../../../../../../../services/payments/payments.service'
import { AxiosError } from 'axios'
import integrationTableStore from '../../../../../../../../store/integrationTable.store'
import { checkNumberBoolean } from '../../../../../../../../shared/functions/functions'
import { NotificationAlerts } from '../../../../../../../../components/NotificationAlerts/NotificationAlerts'
import { transformPaysData } from '../../../../../../../FinanceCardPage/shared/dataList'
import {EmptyText} from "../../../../../../../../components/EmptyText/EmptyText";

export const OplataCard:FC<IOplataCard> = observer((props) => {
  const {readOnly, integrationId} = props
  const [isEditMode, setEditMode] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<null | string>(null)

  const startEdit = () => {
    integrationPageStore.startEditOplata()
    setEditMode(true)
  }
  
  const closeEdit = () => {
    integrationPageStore.closeEditOplatat()
    setEditMode(false)
  }

  const changeStatus = (data:IPaymentsFullResponse) => {
    integrationPageStore.setStatus(data?.status)
    integrationPageStore.setPaidAt(data?.paidAt)

    integrationTableStore.updatePaymentsDate(integrationId, data?.paidAt, 'paidAt')
    integrationTableStore.changeIntegrationPaymentStatus(integrationId, data?.status?.code || null)

    integrationPageStore.setPaymentUpdateId(data.update_id || null)

    if(data?.status?.code === 'cancel'){
      integrationPageStore.setOplataBlocked(true)
    }

  }

  const submit = () => {
    if(integrationPageStore.oplataId && checkNumberBoolean(integrationPageStore.paymentUpdateId)){
      setLoading(true)
      setError(null)

      const option:IFinancePaymentEditRequest = {
        id:integrationPageStore.oplataId,
        update_id:integrationPageStore.paymentUpdateId!,
        releaseUserId:Number(integrationPageStore.editReleaseUserId) || undefined,
        plannedPaidAt:integrationPageStore.editPlannedPaidAt || undefined
        
      }
      PaymentsService.financePaymentEdit(option)
        .then(res=>{
          integrationPageStore?.setReleaseUserId(res.data?.releaseUser?.id?.toString() || null)
          integrationPageStore.setPlannedPaidAt(res.data?.plannedPaidAt)

          if(integrationPageStore.editPlannedPaidAt){
            integrationTableStore.updatePaymentsDate(integrationId, res.data?.plannedPaidAt, 'plannedPaymentDate')
          }

          if(!isEditMode){
            integrationPageStore.setPays(transformPaysData(res.data?.pays || []))
          } else {
            integrationPageStore.setPays(transformPaysData(res.data?.pays || []))
            integrationPageStore.syncEditPaysWithPays()
          }

          integrationPageStore.setPaymentUpdateId(res.data?.update_id || null)

          closeEdit()
          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            setLoading(false)
            setError(err?.response?.data?.message)
          }
        })
  
    }
  }

  return (
    <>
      <FinancesOplata
        oplataId={integrationPageStore?.oplataId}
        isEditMode={isEditMode}
        plannedPaidAt={integrationPageStore.createdAt}
        releaseUserId={isEditMode ? integrationPageStore.editReleaseUserId : integrationPageStore.releaseUserId}
        setReleaseUserId={integrationPageStore.setEditReleaseUserId}
        disabled={isLoading}
        underDateContent={
          <Box sx={{mt:'6px',}}>
            <Typography sx={{ mb: '6px', gap:1, display:'flex'}}>
              Дата факт. оплаты: {integrationPageStore.paidAt
              ? dayjs(integrationPageStore?.paidAt)?.format('DD.MM.YYYY')
              : <EmptyText>отсутствует</EmptyText>
            }
            </Typography>
            {isEditMode
              ? <Stack direction={{xs:'column', sm:'row'}} alignItems={{xs:'start', sm:'center'}} spacing={{xs:'6px', sm:'6px'}}>
                  <Typography sx={{whiteSpace:'nowrap'}}>Дата плановой оплаты:</Typography>
                  <ContractDateInput
                    field=''
                    onChange={integrationPageStore.setEditPlannedPaidAt as any}
                    value={integrationPageStore.editPlannedPaidAt}
                    disabled={isLoading}
                    sx={{width:{xs:'100%', lg:'200px'}}}
                  />
                </Stack>
              : <Typography>
                  Дата плановой оплаты: {integrationPageStore.plannedPaidAt ? dayjs(integrationPageStore?.plannedPaidAt)?.format('DD.MM.YYYY') :<EmptyText>отсутствует</EmptyText>}
                </Typography>
            }
          </Box>
        }  
        rightContent={
          <Stack direction={'row'} alignItems={'center'} spacing={'6px'}>
            {integrationPageStore?.status?.code && integrationPageStore.oplataId &&
              <>
                {statusesStore.payments && checkNumberBoolean(integrationPageStore.paymentUpdateId)
                  ? <OplataStatus
                      update_id={integrationPageStore.paymentUpdateId!}
                      statusStore={statusesStore.payments} 
                      statusType={integrationPageStore?.status?.code}
                      id={integrationPageStore.oplataId}
                      onChangeStatus={changeStatus}
                      readOnly={
                        integrationPageStore.status?.code === 'cancel' ? false : readOnly
                      }
                    />
                  : <Skeleton variant='rounded' sx={{maxHeight:40, minHeight:40, minWidth:150, maxWidth:150}}/>
                }
              </>
            }
            <EditModeButton
              isEdit={isEditMode}
              onClose={closeEdit}
              onOpenEdit={startEdit}
              onSubmit={submit}
              loading={false}
              disabledEdit={readOnly}
            />
          </Stack>
        }
      />
      <NotificationAlerts
        error={{text:isError ? isError : '', onClose:()=>setError(null), open:Boolean(isError), autoHideDuration:5000}}
      />
    </>
  )
})