import {FC, useState} from 'react'
import { AsideSelectManager } from '../AsideSelectManager/AsideSelectManager'
import ChannelService from '../../../../services/channel/channel.service'
import { IUpdateStatusesRequest } from '../../../../services/integration/IntegrationsRequest'
import { IAddReleaseManager } from './IAddReleaseManager'
import { AxiosError } from 'axios'
import channelTableStore from '../../../../store/channelTable.store'

export const AddReleaseManager:FC<IAddReleaseManager> = (props) => {
  const {
    id, 
    update_id, 
    onChangeUpdateId, 
    field,
    initValue,
    readOnly,
    fieldNotCode,
    isDeleteIcon,
    placeholder,
    onChange,
    onChangeManager
  } = props

  const [value, setValue] = useState<{value:string}>(initValue ? {value:`${initValue}`} : {value:''})
  
  const [isLoadingDelete, setLoadingDelete] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const onChangeReleseManager = (value:string) => {
    setLoading(true)

    const option:IUpdateStatusesRequest = {
      id,
      field:fieldNotCode ? fieldNotCode : field,
      new_value:value,
      update_id:update_id
    }
    ChannelService.updateSimpleField(option)
      .then(res=>{
        // console.log(res, 'res update releseBy')
        onChange && onChange(res?.data)
        onChangeManager&&onChangeManager(id, field, res.data.update_id, +value)
        channelTableStore.addReleaseByManager(id, field, update_id, +value)
        onChangeUpdateId && onChangeUpdateId(res.data.update_id)
        setValue({value:value})
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err releaseBy')
          setLoading(false)
        }
      })
  }

  const onDelete = () => {
    setLoadingDelete(true)

    const option:IUpdateStatusesRequest = {
      id,
      field:fieldNotCode ? fieldNotCode : field,
      new_value:null!,
      update_id:update_id
    }
    ChannelService.updateSimpleField(option)
      .then(res=>{
        // console.log(res, 'res update releseBy')

        channelTableStore.removeReleaseByManager(id, field, update_id)
        setValue({value:''})

        onChangeUpdateId&&onChangeUpdateId(res.data.update_id)

        setLoadingDelete(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err releaseBy')
          setLoadingDelete(false)
        }
      })
  }

  return (
    <AsideSelectManager
      placeholder={placeholder}
      isDeleteIcon={isDeleteIcon}
      initValue={value.value}
      onChange={onChangeReleseManager}
      isLoading={isLoading || isLoadingDelete}
      isError={isError}
      readOnly={readOnly}
      onDelete={onDelete}
    />
  )
}
