import { FC } from 'react'
import { ISettingsSelectedGroup } from '../models/ISettingsSelectedGroup'
import { Divider, List, ListItemText } from '@mui/material'
import { TableSettingsItem } from './TableSettingsItem'
import { observer } from 'mobx-react-lite'
import { ISettingsSelected } from '../models/ISettingsSelected'

export const TableSettingsGroup:FC<ISettingsSelectedGroup> = observer((props) => {
  const {
    group,
    fields,
    loading = false,
    addToConfirmList,
    removeConfirmList,
    setSelectedChecked,
    currentGroup,
    showDivider = true
  } = props
      
  const onChangeCheckboxStore = (item:ISettingsSelected) => {
    setSelectedChecked({group, code:item.code})

    if(!item.isSelected){
      removeConfirmList(item.code)
    } else {
      addToConfirmList(item)
    }
  }

  return (
    <List>
      <ListItemText primaryTypographyProps={{fontWeight:'bold'}}>
        {group}
      </ListItemText>
      <List dense sx={{display:'grid', gridTemplateColumns:{xs:'repeat(1, 1fr)', sm:'repeat(2, 1fr)',md:'repeat(3, 1fr)'}, columnGap:'0px', rowGap:'0px'}}>
        {fields.map(item=>
          <TableSettingsItem
            key={item.code + group}
            {...item}
            disabled={(item.isSelected && currentGroup.length === 1 || loading) ? true : false}
            onClick={()=>onChangeCheckboxStore(item)}
          />
        )}
      </List>
      {showDivider && <Divider sx={{borderStyle:'dashed'}}/>}
    </List>
  )
})