import {FC, useState} from 'react'
import { IChannelTextField } from './IChannelTextField'
import { InputEditField } from '../InputEditField/InputEditField'
import { IUpdateStatusesRequest } from '../../../../../services/integration/IntegrationsRequest'
import ChannelService from '../../../../../services/channel/channel.service'
import channelTableStore from '../../../../../store/channelTable.store'
import { AxiosError } from 'axios'
import { Stack, Typography } from '@mui/material'
import { CopyIconButton } from '../../../../UI/Buttons/CopyIconButton/CopyIconButton'
import { EditIconButton } from '../../../../UI/Buttons/EditIconButton/EditIconButton'

export const ChannelTextField:FC<IChannelTextField> = (props) => {
  const {
    id, 
    field, 
    update_id, 
    initValue,
    readOnly
  } = props

  const [isEdit, setEdit] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const [isCopyAlert, setCopyAlert] = useState(false)

  const submit = (value:string) => {
    setLoading(true)
    setError(null)

    const option:IUpdateStatusesRequest = {
      id,
      field,
      new_value:value,
      update_id
    }
    ChannelService.updateSimpleField(option)
      .then(res=>{
        // console.log(res, 'res update simple cahnnel field edit')
        
        channelTableStore.changeField({id, field, new_value:value, update_id:res.data.update_id})
        
        setEdit(false)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError) {
          // console.log(err, 'err channel simple fiel edit')
          setLoading(false)
          setError(err?.response?.data?.message)
        }
      })
  }

  return (
    <>
      {!isEdit &&
        <Stack direction={'row'} alignItems={'center'} sx={{maxWidth:180}}>
          <Typography sx={{mr:initValue ? 0.5 : 0, whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden'}}>
            {initValue ? initValue : 'Добавить'}
          </Typography>
          {initValue &&
            <CopyIconButton copyText={initValue} notificationText='Контакт скопирован!'/>
          }
          <EditIconButton disabled={readOnly} size='small' onClick={()=>setEdit(true)}/>
        </Stack>
      }
      {isEdit &&
        <InputEditField
          initValue={initValue ? initValue?.toString() : undefined}
          onClose={()=>setEdit(false)}
          onSubmit={submit}
          placeholder='Введите контакт'
          inputType='text'
          error={isError}
          loading={isLoading}
          maxWidth={180}
        />
      }
    </>
  )
}