import dayjs, { Dayjs } from "dayjs";
import { makeAutoObservable } from "mobx";

class AnalyticsStore {

  managerId:number | null = null

  dateFrom:Dayjs | null = dayjs(dayjs().format('YYYY-MM-01'))

  dateTo:Dayjs | null = dayjs(dayjs().format(`YYYY-MM-${dayjs(dayjs().format('YYYY-MM-DD')).daysInMonth()}`))

  constructor(){
    makeAutoObservable(this)
  }

  setManagerId = (value:string) => {
    this.managerId = +value
  }

  setDateFrom = (value:Dayjs | null) => {
    this.dateFrom = value
  }

  setDateTo = (value:Dayjs | null) => {
    this.dateTo = value
  }

}

export default new AnalyticsStore()