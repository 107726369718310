import { makeAutoObservable } from "mobx"
import { IIntegrationsFullResponse } from "../services/integration/IIntegrationsResponse"
import { IProductSamplesGoods } from "../services/productSample/IProductSampleResponse"
import { SelectChangeEvent } from "@mui/material"
import { Dayjs } from "dayjs"
import { IPostedCommentItem } from "../components/PostedCommentItem/models/IPostedCommentItem"

class ProductSamplePageStore {
  productSampleId:number | null = null

  integration:IIntegrationsFullResponse | null = null

  userId:number | null = null

  productSamples:IProductSamplesGoods[] = []

  sendAt:string | null = null

  sendFrom:string | null = null

  hasRefund:boolean | null = null

  isSampleRefundValue:boolean | null = null

  update_id:number | null = null

  comment:IPostedCommentItem[] = []

  deliveryCostValue:number | null = null
  fioValue:string | null = null
  phoneValue:string | null = null
  refundCostValue:number | null = null
  sendToValue:string | null = null
  trackNumberCGValue:string | null = null
  trackNumberRefundValue:string | null = null
  trackNumberTCValue:string | null = null

  // edit mode

  editIntegration:IIntegrationsFullResponse | null = null

  editUserId:number | null = null

  editProductSamples:IProductSamplesGoods[] = []

  editSendAt:string | null = null

  editSendFrom:string | null = null

  editHasRefund:boolean | null = null

  editIsSampleRefundValue:boolean | null = null

  editRefundCostValue:number | null = null
  editDeliveryCostValue:number | null = null

  constructor(){
    makeAutoObservable(this)
  }

  setUpdateId = (update_id:number | null) => {
    this.update_id = update_id
  }
  setSampleRefund = (isSampleRefundValue:boolean | null) => {
    this.isSampleRefundValue = isSampleRefundValue
  }
  setCheckbox = (hasRefund:boolean) => {
    this.hasRefund = hasRefund
  }
  setIntegration = (integration:IIntegrationsFullResponse | null) => {
    this.integration = integration
  }
  setDeliveryCostValue = (value:number | null) => {
    this.deliveryCostValue = value
  }
  onChangeDeliveryCostValue = (value:string) => {
    const currentVal:string | null = value ? value.replace(',','.') : null

    if(currentVal){
      this.editDeliveryCostValue = +currentVal
    } else {
      this.editDeliveryCostValue = null
    }
  }
  setFioValue = (value:string | null) => {
    this.fioValue = value
  }
  setPhoneValue = (value:string | null) => {
    this.phoneValue = value
  }
  setRefundCostValue = (value:number | null) => {
    this.refundCostValue = value
  }
  onChangeRefundCostValue = (value:string) => {
    const currentVal:string | null = value ? value.replace(',','.') : null

    if(currentVal){
      this.editRefundCostValue = +currentVal
    } else {
      this.editRefundCostValue = null
    }
  }
  setSendToValue = (value:string | null) => {
    this.sendToValue = value
  }
  setTrackNumberCGValue = (value:string | null) => {
    this.trackNumberCGValue = value
  }
  setTrackNumberRefundValue = (value:string | null) => {
    this.trackNumberRefundValue = value
  }
  setTrackNumberTCValue = (value:string | null) => {
    this.trackNumberTCValue = value
  }

  setProductSamples = (productSamples:IProductSamplesGoods[]) => {
    this.productSamples = productSamples
  }

  setProductSampleId= (productSampleId:number | null) => {
    this.productSampleId = productSampleId
  }

  setComment = (comment:IPostedCommentItem[]) => {
    this.comment = comment
  }

  setUserId = (userId:number | null) => {
    this.userId = userId
  }

  setSendAt = (sendAt:string | null) => {
    this.sendAt = sendAt
  }

  setSendFrom = (sendFrom:string | null) => {
    this.sendFrom = sendFrom
  }

  onAddedGood = () => {
    const productSample = {id:-Date.now()} as any

    this.editProductSamples.push(productSample)
  }

  onDeleteGood = (id:number) => {
    this.editProductSamples = this.editProductSamples.filter(x=>x.id !== id)
  }

  onChangeCheckbox = (hasRefund:boolean) => {
    this.editHasRefund = hasRefund

    if(!hasRefund){
      this.editIsSampleRefundValue = null
      this.editRefundCostValue = null
    }
  }

  onChangeSampleRefund = (isSampleRefundValue:boolean) => {
    this.editIsSampleRefundValue = isSampleRefundValue
  }

  onChangeSendFrom = (event:SelectChangeEvent<string>) => {
    this.editSendFrom = event.target.value
  }

  onChangeGood = (id:number, field:'good' | 'color', value:string)=> {
    this.editProductSamples = this.editProductSamples.map(item=>{
      if(item.id === id){

        if(field === 'color'){
          return {...item, [field]:value}
        } else return {...item, good:{...item?.good, article:value}}

      } else return item
    })
  }

  onChangeDate = (date:Dayjs | null) => {
    if(date?.isValid()){
      this.editSendAt = date?.format('YYYY-MM-DD') + 'T00:00:00.000Z'
    }
  }

  onManagerChange = (event:SelectChangeEvent<string>) => {
    this.editUserId = Number(event.target.value)
  }

  onChangeIntegration = (integration:IIntegrationsFullResponse | null) => {
    this.editIntegration = integration
  }

  addPostedComments = (comment:IPostedCommentItem) => {
    this.comment = [...this.comment, comment]
  }

  editComment = (id:number, comment:IPostedCommentItem) => {
    if(this.comment){
      this.comment = this.comment.map(item=>{
        if(item.id === id){
          return comment
        } else return item
      })
    }
  }

  deletePostedComments = (id:number) => {
    if(this.comment){
      this.comment = this.comment.filter(item=>item.id !== id)
    }
  }

  startEdit = () => {
    this.editIntegration = this.integration

    this.editUserId = this.userId

    this.editProductSamples = this.productSamples?.length === 0 ? [{id:-Date.now()} as any] : this.productSamples

    this.editSendAt = this.sendAt

    this.editSendFrom = this.sendFrom

    this.editHasRefund = this.hasRefund

    this.editIsSampleRefundValue = this.isSampleRefundValue

    this.editRefundCostValue = this.refundCostValue
    this.editDeliveryCostValue = this.deliveryCostValue
  }

  closeEdit = () => {
    this.editIntegration = null
    this.editUserId = null
    this.editProductSamples = []
    this.editSendAt = null
    this.editSendFrom = null
    this.editHasRefund = null
    this.editIsSampleRefundValue = null
    this.editRefundCostValue = null
    this.editDeliveryCostValue = null
  }

  clearStore = () => {
    this.productSampleId = null

    this.integration = null

    this.userId = null

    this.productSamples = []

    this.sendAt = null

    this.sendFrom = null

    this.hasRefund = null
    
    this.isSampleRefundValue = null

    this.comment = []

    this.deliveryCostValue= null
    this.fioValue= null
    this.phoneValue= null
    this.refundCostValue= null
    this.sendToValue= null
    this.trackNumberCGValue= null
    this.trackNumberRefundValue= null
    this.trackNumberTCValue= null

    this.editIntegration = null
    this.editUserId = null
    this.editProductSamples = []
    this.editSendAt = null
    this.editSendFrom = null
    this.editHasRefund = null
    this.editIsSampleRefundValue = null
    this.editRefundCostValue = null
    this.editDeliveryCostValue = null
  }

}

export default new ProductSamplePageStore()