import {FC} from 'react'
import {Avatar, Box, Stack} from '@mui/material'
import {AsideCardLayout} from '../../../AsideCardLayout/AsideCardLayout'
import {IUser} from '../../../../entities/user/models/IUser'
import {ContactInfo} from './ui/ContactInfo/ContactInfo'
import {UserAnalytics} from './ui/UserAnalytics/UserAnalytics'
import {UserSubDetails} from './ui/UserSubDetails/UserSubDetails'
import {UserBudgetInfo} from './ui/UserBudgetInfo/UserBudgetInfo'
import {ALL_RULES} from '../../../../shared/rules/interface/EnumRights'

export const UserMainPage: FC<IUser> = (props) => {
  const {
    firstName,
    lastName,
    id,
    imageUrl,
    email,
    company,
    rights,
    role,
    roleName
  } = props

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {sm: '1fr', md: '1fr 2fr'},
        gridAutoRows: 'min-content',
        pt: 2,
        pb: 2,
        columnGap: 3,
        rowGap: 3,
        overflow: 'auto',
        height: '88vh'
      }}
    >
      <Stack spacing={3}>
        <AsideCardLayout>
          <Stack alignItems={'center'} justifyContent={'center'} sx={{height: '100%'}}>
            <Avatar src={imageUrl} alt={`${firstName} ${lastName}`} sx={{height: 200, width: 200}}/>
          </Stack>
        </AsideCardLayout>
        <Box>
          <ContactInfo {...props}/>
        </Box>
        <UserSubDetails  company={company} roleName={roleName}/>
      </Stack>
      <Box sx={{gridColumn: 'span 1', gridRow: 'span 3', maxWidth: {xs:'100%', lg:'80%'}}}>
        <UserAnalytics id={id}/>
      </Box>
      {rights.find(x => x.code === ALL_RULES.release) && <UserBudgetInfo userId={id}/>}
    </Box>
  )
}