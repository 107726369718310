import React, {FC} from 'react'
import { IIntegrationTableList } from '../../IntegrationTable/models/IIntegrationTableList'
import { ChannelTableListArr } from './ChannelTableListArr'
import { useSearchParams } from 'react-router-dom'
import { CHANNEL_CARD_OPEN } from '../../../shared/constants/searchParams'

export const ChannelTableList:FC<IIntegrationTableList> = React.memo((props) => {
  const {tableHead} = props

  const [searchParams, setSearchParams] = useSearchParams()

  const navigateChannel = (id:string | number) => {
    const params = new URLSearchParams(window.location.search)
    params.set(CHANNEL_CARD_OPEN, id?.toString())
    
    searchParams.set(CHANNEL_CARD_OPEN, id?.toString())

    setSearchParams(params)
  }

  return (
    <ChannelTableListArr tableHead={tableHead} navigateChannel={navigateChannel}/>
  )
})