import {useState} from 'react'
import { getManagerInSelect } from '../../../../../CreateChannelPage/shared/dataList'
import AllUsersStore from '../../../../../../store/AllUsers.store'
import { Skeleton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import analyticsStore from '../../../../../../store/analytics.store'
import { useSearchParams } from 'react-router-dom'
import {ManagerTextSelect} from "./ui/ManagerTextSelect/ManagerTextSelect";

export const SelectManager = observer(() => {

  const [searchParams, setSearchParams] = useSearchParams()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const currentManager = AllUsersStore?.users?.find(user=>searchParams.get('id') ? user.id === +searchParams.get('id')! : user.id ===  analyticsStore.managerId)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  
  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeManager = (value:string) => {
    analyticsStore.setManagerId(value)

    searchParams.set('id', value)
    setSearchParams(searchParams)

    if(value === null){
      searchParams.delete('id')
      setSearchParams(searchParams)
    }

    handleClose()
  }

  return (
    <>
      {AllUsersStore.users
        ? <ManagerTextSelect
            title={currentManager ? `${currentManager?.firstName} ${currentManager?.lastName}` : 'всем менеджерам'}
            anchorEl={anchorEl}
            onOpen={handleClick}
            onClose={handleClose}
            value={currentManager ? currentManager?.id.toString() : ''}
            onChange={changeManager}
            list={getManagerInSelect(AllUsersStore?.users)}
          />
        : <Skeleton variant='rectangular' sx={{height:22, width:150}}/>
      }
    </>
  )
})