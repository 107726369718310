import { IChannelAudienceItem } from "../ui/ChannelAudienceItem/IChannelAudienceItem";

export interface IChannelAudienceContentOnType {
  headerStats?:IChannelAudienceItem[];
  ages?:IChannelAudienceItem[];
  gender?:IChannelAudienceItem[];
  countries?:IChannelAudienceItem[];
  telegramStats?:IChannelAudienceItem[];
}

export interface IChannelAudienceRequest {
  audience?:number | null;
  ['13-17']?:number | null;
  ['18-24']?:number | null;
  ['25-34']?:number | null;
  ['35-44']?:number | null;
  ['45-54']?:number | null;
  ['55-64']?:number | null;
  ['65_plus']?:number | null;
  fromPC?:number | null;
  fromPhone?:number | null;
  subs?:number | null;
  notSubs?:number | null;
  man?:number | null;
  woman?:number | null;
  ru?:number | null;
  by?:number | null;
  ua?:number | null;
  kz?:number | null;
  er?:number | null;
  err?:number | null;
}

export interface IChannelAudienceIcons {
  maleIcon:React.ReactNode;
  womanIcon:React.ReactNode;
  ruIcon:React.ReactNode;
  byIcon:React.ReactNode;
  uaIcon:React.ReactNode;
  kzIcon:React.ReactNode;
}

export const getChannelContentOnType = (
    data:IChannelAudienceRequest, 
    icons:IChannelAudienceIcons, 
    changeField:(code:string, value:string)=>void, 
    type:string,
    listEnableRequired?:boolean,
    listSubsHeaderRequired?:boolean
  ):IChannelAudienceContentOnType => {

  const {
    audience,
    fromPC,
    fromPhone,
    subs,
    notSubs,
    man,
    woman,
    ru,
    by,
    ua,
    kz,
    er,
    err,
  } = data

  const {
    maleIcon,
    womanIcon,
    ruIcon,
    byIcon,
    uaIcon,
    kzIcon,
  } = icons
  
  const allHeaderStats:IChannelAudienceItem[] = [
    {
      text:'Кол-во подписчиков:',
      code:'audience',
      value:audience?.toString() || '',
      changeField,
      inputType:'number',
      placeholder:'Кол-во',
      widthInput:85,
      isNumFormat:true,
      required:listSubsHeaderRequired !== undefined ? listSubsHeaderRequired : false
    },
    {
      text:'С подпиской:',
      code:'subs',
      value:subs?.toString() || '',
      changeField,
      inputType:'number',
      required:listEnableRequired !== undefined ? listEnableRequired : true,
      symbol:'%'
    },
    {
      text:'Без подписки:',
      code:'notSubs',
      value:notSubs?.toString() || '',
      changeField,
      inputType:'number',
      required:listEnableRequired !== undefined ? listEnableRequired : true,
      symbol:'%'
    },
    {
      text:'С компьютера:',
      code:'fromPC',
      value:fromPC?.toString() || '',
      changeField,
      inputType:'number',
      required:listEnableRequired !== undefined ? listEnableRequired : true,
      symbol:'%'
    },
    {
      text:'С телефона:',
      code:'fromPhone',
      value:fromPhone?.toString() || '',
      changeField,
      inputType:'number',
      required:listEnableRequired !== undefined ? listEnableRequired : true,
      symbol:'%'
    },
  ]

  const allAges:IChannelAudienceItem[] = [
    {
      text:'13 - 17',
      code:'13-17',
      value:data['13-17']?.toString() || '',
      changeField,
      inputType:'number',
      required:listEnableRequired !== undefined ? listEnableRequired : true,
      symbol:'%'
    },
    {
      text:'18 - 24',
      code:'18-24',
      value:data['18-24']?.toString() || '',
      changeField,
      inputType:'number',
      required:listEnableRequired !== undefined ? listEnableRequired : true,
      symbol:'%'
    },
    {
      text:'25 - 34',
      code:'25-34',
      value:data['25-34']?.toString() || '',
      changeField,
      inputType:'number',
      required:listEnableRequired !== undefined ? listEnableRequired : true,
      symbol:'%'
    },
    {
      text:'35 - 44',
      code:'35-44',
      value:data['35-44']?.toString() || '',
      changeField,
      inputType:'number',
      required:listEnableRequired !== undefined ? listEnableRequired : true,
      symbol:'%'
    },
    {
      text:'45 - 54',
      code:'45-54',
      value:data['45-54']?.toString() || '',
      changeField,
      inputType:'number',
      required:listEnableRequired !== undefined ? listEnableRequired : true,
      symbol:'%'
    },
    {
      text:'55 - 64',
      code:'55-64',
      value:data['55-64']?.toString() || '',
      changeField,
      inputType:'number',
      required:listEnableRequired !== undefined ? listEnableRequired : true,
      symbol:'%'
    },
    {
      text:'65 +',
      code:'65_plus',
      value:data['65_plus']?.toString() || '',
      changeField,
      inputType:'number',
      required:listEnableRequired !== undefined ? listEnableRequired : true,
      symbol:'%',
      sxText:{mr:2}
    },
  ]

  const allGender:IChannelAudienceItem[] = [
    {
      icon:maleIcon,
      code:'man',
      value:man?.toString() || '',
      changeField,
      inputType:'number',
      required:listEnableRequired !== undefined ? listEnableRequired : true,
      symbol:'%'
    },
    {
      icon:womanIcon,
      code:'woman',
      value:woman?.toString() || '',
      changeField,
      inputType:'number',
      required:listEnableRequired !== undefined ? listEnableRequired : true,
      symbol:'%'
    },
  ]

  const allCountries:IChannelAudienceItem[] = [
    {
      icon:ruIcon,
      code:'ru',
      value:ru?.toString() || '',
      changeField,
      inputType:'number',
      required:listEnableRequired !== undefined ? listEnableRequired : true,
      symbol:'%'
    },
    {
      icon:byIcon,
      code:'by',
      value:by?.toString() || '',
      changeField,
      inputType:'number',
      required:listEnableRequired !== undefined ? listEnableRequired : true,
      symbol:'%'
    },
    {
      icon:uaIcon,
      code:'ua',
      value:ua?.toString() || '',
      changeField,
      inputType:'number',
      required:listEnableRequired !== undefined ? listEnableRequired : true,
      symbol:'%'
    },
    {
      icon:kzIcon,
      code:'kz',
      value:kz?.toString() || '',
      changeField,
      inputType:'number',
      required:listEnableRequired !== undefined ? listEnableRequired : true,
      symbol:'%'
    },
  ]

  const allTelegramStats:IChannelAudienceItem[] = [
    {
      text:'ERR - вовлеченность на канале',
      code:'err',
      value:err?.toString() || '',
      changeField,
      inputType:'number',
      tooltipText:'Отношение среднего охвата 1 публикации к количеству подписчиков канала. Может быть более 100% из-за прочтений поста вне канала, например, в результате репостов в другие каналы',
      symbolInText:'%'
    },
    {
      text:'ER - комменатрии и лайки',
      code:'er',
      value:er?.toString() || '',
      changeField,
      inputType:'number',
      tooltipText:'Коэффициент вовлеченности подписчиков. Считается как отношение всей активности подписчиков (пересылки + комментарии + реакции) к количеству просмотров публикаций',
      symbolInText:'%'
    },
  ]


  const telegrammHeader:IChannelAudienceItem[] = allHeaderStats.filter(x=>x.code === 'audience')

  const vkHeader:IChannelAudienceItem[] = allHeaderStats.filter(x=>x.code !== 'subs' && x.code !== 'notSubs' && x.code !== '55-64' && x.code !== '65_plus')
  const vkAges:IChannelAudienceItem[] = allAges.filter(x=>x.code !== '55-64' && x.code !== '65_plus')

  const instAndYoutubeHeader:IChannelAudienceItem[] = allHeaderStats.filter(x=>x.code !== 'fromPC' && x.code !== 'fromPhone')

  if(type === 'telegram'){
    return {headerStats:telegrammHeader, telegramStats:allTelegramStats}
  } else if(type === 'vk') {
    return {headerStats:vkHeader, ages:vkAges, countries:allCountries, gender:allGender}
  } else if(type === 'instagram' || type === 'youtube') {
    return {headerStats:instAndYoutubeHeader, ages:allAges, countries:allCountries, gender:allGender}
  } else  return {headerStats:allHeaderStats, ages:allAges, countries:allCountries, gender:allGender}

}