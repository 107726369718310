import { makeAutoObservable } from "mobx"
import { IChannelTheame } from "../entities/ChannelTheame/models/IChannelTheame"

class ChannelTheameStore {
  theame:IChannelTheame[] | null = null

  constructor(){
    makeAutoObservable(this)
  }

  setTheame(theame:IChannelTheame[]) {
    this.theame = theame
  }
  addTheame(theame:IChannelTheame){
    if(this.theame){
      this.theame=[...this.theame, theame]
    }
  }

}

export default new ChannelTheameStore()