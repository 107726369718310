import { makeAutoObservable } from "mobx"
import { IReferencesRequisiteResponse } from "../services/references/IReferencesResponse"
import ReferencesService from "../services/references/references.service";
import { AxiosError } from "axios"

class RequisiteStore {

  requisiteInfo:IReferencesRequisiteResponse | null = null

  constructor(){
    makeAutoObservable(this)
  }

  getRequisiteInfo(){
    ReferencesService.getRequisite()
      .then(res=>{
        // console.log(res, 'res request info all')
        this.requisiteInfo = res.data
      })
      .catch(err=>{
        if(err instanceof AxiosError) {
          // console.log(err, 'err request info all')
        }
      })
  }

}

export default new RequisiteStore()