import { makeAutoObservable } from "mobx";
import { IMetricsOnSourceResponse } from "../services/metrics/IMetricsResponse";
import { ICrmOrderByPromoResponse } from "../services/crm/ICrmRespone";
import {
  IIntegrationFullPayment,
  IIntegrationsFullResponse,
  IIntegrationsGuaranteeResponse,
} from "../services/integration/IIntegrationsResponse";
import { IFinancesPaymentItem } from "../pages/FinancesCreatePage/ui/FinancesPayments/ui/FinancesPaymentItem/IFinancesPaymentItem";
import { IContractorCreateResponse } from "../services/contractor/IContractorIResponse";
import {
  IFinanceContractBlock,
  IFinancePaymentsStatus,
  IOrdInfo,
  ITemplate,
} from "../services/payments/IPaymentsResponse";
import { IUser } from "../entities/user/models/IUser";
import { IContractInsertOrUpdateRequest } from "../services/payments/IPaymentsRequest";
import { IPostedCommentItem } from "../components/PostedCommentItem/models/IPostedCommentItem";
import { ILastPayInfo } from "../services/channel/ICnannelResponse";
import {
  IProductSampleItemResponse,
  IProductSamplesGoods,
} from "../services/productSample/IProductSampleResponse";

class IntegrationPageStore {
  chatOpen: boolean = true;

  chatOpenPayment: boolean = true;

  integrationId: number | null = null;

  source: string | null = null;

  price: number | null = null;

  cpvValue: number | null = null;

  type: string = "";

  views: number | null = null;

  integrationDate: string | null = null;

  metricData: IMetricsOnSourceResponse | null = null;

  dataOrder: ICrmOrderByPromoResponse | null = null;

  isOplataBlocked: boolean | null = null;

  isVideoReview: boolean | null = null;

  integrationTargetAudience: string | null = null;

  predictionViews: number | null = null;
  predictionTraffic: number | null = null;
  predictionCpv: number | null = null;
  predictionCtr: number | null = null;
  predictionCr: number | null = null;
  predictionOrders: number | null = null;
  predictionRoi: number | null = null;
  averageCheck: number | null = null;

  roi: number | null = null;

  isEmpyPaymet: boolean | null = null;

  releaseUserId: string | null = null;

  asiedeReleaseUserId: string | null = null;

  paidSumm: number | null = null;

  totalSumm: number | null = null;

  plannedPaidAt: string | null = null;

  integrationIds: number[] = [];

  integrationContacts: string[] = [];

  comment: string | null = null;

  pays: IFinancesPaymentItem[] = [];

  counteragent: IContractorCreateResponse | null = null;

  contractBlock: IFinanceContractBlock | null = null;

  oplataId: number | null = null;

  createdAt: string | null = null;
  createdBy: IUser | null = null;

  status: IFinancePaymentsStatus | null = null;
  paymentType: { code: string; name: string } | null = null;
  paidAt: string | null = null;

  editPays: IFinancesPaymentItem[] | null = null;
  editContractBlock: IContractInsertOrUpdateRequest | null = null;
  editPlannedPaidAt: null | string = null;
  editReleaseUserId: null | string = null;
  editCounteragent: IContractorCreateResponse | null = null;

  postedComments: IPostedCommentItem[] | null = null;

  hasAct: boolean | null = null;
  edithasAct: boolean | null = null;

  isServiceProvided: boolean | null = null;
  editIsServiceProvided: boolean | null = null;

  actLink: string | null = null;
  editActLink: string | null = null;

  selfEmployedCheckLink: string | null = null;
  editSelfEmployedCheckLink: string | null = null;

  hasSelfEmployedCheck: boolean | null = null;
  editHasSelfEmployedCheck: boolean | null = null;

  ordInfoBlock: IOrdInfo[] | null = null;
  editOrdInfoBlock: IOrdInfo[] | null = null;

  lastPayInfo: ILastPayInfo | null = null;

  guarantee: IIntegrationsGuaranteeResponse | null = null;

  integrations: IIntegrationsFullResponse[] | null = null;

  paymentUpdateId: null | number = null;

  errorPayments: string | null = null;

  isCheckGuaranteeCompleteAll: boolean | null = null;

  isPaymentOnCard: boolean | null = null;

  template: ITemplate[] = [];

  productSampleInfo: Partial<IProductSampleItemResponse> | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setProductSampleInfo(productSamplesInfo: IProductSampleItemResponse) {
    if (productSamplesInfo !== null) {
      this.productSampleInfo = {
        id: productSamplesInfo.id || null,
        productSamples: productSamplesInfo.productSamples || null,
        hasRefund: productSamplesInfo.hasRefund || undefined,
        isSampleRefund: productSamplesInfo.isSampleRefund || undefined,
      };
    } else {
      this.productSampleInfo = null;
    }
  }

  setPaymentOnCard = (isPaymentOnCard: boolean | null) => {
    this.isPaymentOnCard = isPaymentOnCard;
  };

  setChatOpenPayment = (chatOpenPayment: boolean) => {
    this.chatOpenPayment = chatOpenPayment;
  };

  setTemplate = (template: ITemplate[]) => {
    this.template = template;
  };

  setIsCheckGuaranteeCompleteAll = (isCheckGuaranteeCompleteAll: boolean | null) => {
    this.isCheckGuaranteeCompleteAll = isCheckGuaranteeCompleteAll;
  };

  setChatOpen = (chatOpen: boolean) => {
    this.chatOpen = chatOpen;
  };

  setIntegrationTargetAudience = (integrationTargetAudience: string | null) => {
    this.integrationTargetAudience = integrationTargetAudience;
  };

  setVideoReview = (isVideoReview: boolean | null) => {
    this.isVideoReview = isVideoReview;
  };

  setPaymentUpdateId = (paymentUpdateId: null | number) => {
    this.paymentUpdateId = paymentUpdateId;
  };

  setIntegrations = (integrations: IIntegrationsFullResponse[] | null) => {
    this.integrations = integrations;
  };

  setType = (value: string) => {
    this.type = value;
  };

  onChangeIntegrationsForPayment = (id: number, value: number) => {
    if (this.integrations) {
      this.integrations = this.integrations.map((item) => {
        if (item.id === id) {
          return { ...item, price: value };
        } else return item;
      });
    }
  };

  setGuarantee = (guarantee: IIntegrationsGuaranteeResponse | null) => {
    this.guarantee = guarantee;
  };

  setViews = (views: number | null) => {
    this.views = views;
  };

  setLastPayInfo = (lastPayInfo: ILastPayInfo | null) => {
    this.lastPayInfo = lastPayInfo;
  };

  setPredictionRoi = (value: number | null) => {
    this.predictionRoi = value;
  };

  setPredictionViews = (value: number | null) => {
    this.predictionViews = value;
  };

  setPredictionTraffic = (value: number | null) => {
    this.predictionTraffic = value;
  };

  setPredictionCpv = (value: number | null) => {
    this.predictionCpv = value;
  };

  setPredictionCtr = (value: number | null) => {
    this.predictionCtr = value;
  };

  setPredictionCr = (value: number | null) => {
    this.predictionCr = value;
  };

  setRoi = (value: number | null) => {
    this.roi = value;
  };
  setAsiedeReleaseUserId = (id: string | null) => {
    this.asiedeReleaseUserId = id;
  };

  setPredictionOrders = (value: number | null) => {
    this.predictionOrders = value;
  };

  setAverageOrderSumm(value: number | null) {
    this.averageCheck = value;
  }

  setPaidSumm(value: number | null) {
    this.paidSumm = value;
  }

  setContractBlock = (contractBlock: IFinanceContractBlock | null) => {
    this.contractBlock = contractBlock;
  };

  setEmptyPayment = (isEmpyPaymet: boolean | null) => {
    this.isEmpyPaymet = isEmpyPaymet;
  };

  setIntegrationContacts = (integrationContact: string, isDeleteContacts: boolean) => {
    if (isDeleteContacts === false) {
      this.integrationContacts = [integrationContact];
    } else {
      this.integrationContacts = [
        ...this.integrationContacts.filter((x) => x !== integrationContact),
      ];
    }
  };

  setReleaseUserId = (releaseUserId: string | null) => {
    this.releaseUserId = releaseUserId;
  };

  setIntegrationId = (integrationId: number | null) => {
    this.integrationId = integrationId;
  };

  setPlannedPaidAt = (plannedPaidAt: string | null) => {
    this.plannedPaidAt = plannedPaidAt;
  };

  setTotalSumm = (totalSumm: number | null) => {
    this.totalSumm = totalSumm;

    if (this.editPays && this.editPays?.length <= 1) {
      this.editPays = this.editPays?.map((item) => {
        return { ...item, totalSumm: totalSumm };
      });
    }
  };

  setIntegrationsIds = (integrations: IIntegrationsFullResponse[] | null, id: number) => {
    if (integrations) {
      this.integrationIds = integrations?.map((item) => {
        return item.id;
      });
    } else {
      this.integrationIds = [id];
    }
  };

  changeComment = (comment: string | null) => {
    this.comment = comment;
  };

  addPays = (pay: IFinancesPaymentItem, isFirstItem: boolean) => {
    if (isFirstItem) {
      this.pays = [pay];
    } else {
      const newArr = [...this.pays, pay];

      this.pays = newArr.map((item) => {
        return { ...item, totalSumm: newArr?.length <= 1 ? this.totalSumm : null };
      });
    }
  };

  changePaymentType = (id: number, field: string, typeValue: string) => {
    this.pays = this.pays.map((item) => {
      if (item.id === id) {
        if (typeValue === "card") {
          const pay: IFinancesPaymentItem = {
            id: Number(new Date()),
            direction: null,
            paymentType: "card",
            plannedPaidAt: item.plannedPaidAt,
            source: null,
            totalSumm: item.totalSumm,
            cardOrPhone: this.lastPayInfo?.phoneOrCard?.replaceAll(" ", "") || null,
            fio: this.lastPayInfo?.fio || null,
            paymentLink: null,
            selfEmployedCheck: null,
          };

          return pay;
        } else {
          const pay: IFinancesPaymentItem = {
            id: Number(new Date()),
            direction: this.counteragent?.id?.toString() || null,
            paymentType: typeValue,
            plannedPaidAt: item.plannedPaidAt,
            source: "ip-bokova",
            totalSumm: item.totalSumm,
            cardOrPhone: null,
            fio: null,
            paymentLink: null,
            selfEmployedCheck: null,
          };

          return pay;
        }
      } else return item;
    });
  };

  changeFieldPaysItem = (id: number, field: string, newValue: string) => {
    this.pays = this.pays.map((item) => {
      if (item.id === id) {
        return { ...item, [field]: newValue };
      } else return item;
    });
  };

  deletePays = (id: number) => {
    const filterArr = this.pays.filter((x) => x.id !== id);

    this.pays = filterArr.map((item) => {
      return { ...item, totalSumm: filterArr?.length <= 1 ? this.totalSumm : item.totalSumm };
    });
  };

  setCounteragent = (counteragent: IContractorCreateResponse | null) => {
    this.counteragent = counteragent;

    this.pays = this.pays.map((item) => {
      if (item.paymentType === "bank" || "netting") {
        return { ...item, direction: `${counteragent?.id}` };
      } else return item;
    });

    if (this.editPays) {
      this.editPays = this.editPays?.map((item) => {
        if (item?.paymentType === "bank" || "netting") {
          return { ...item, direction: counteragent?.id?.toString() || null };
        } else return item;
      });
    }
  };

  setOplataBlocked = (value: boolean | null) => {
    this.isOplataBlocked = value;
  };

  setOplataId = (id: number | null) => {
    this.oplataId = id;
  };

  setPostedComments = (postedComments: IPostedCommentItem[] | null) => {
    this.postedComments = postedComments;
  };

  addPostedComments = (postedComments: IPostedCommentItem) => {
    if (this.postedComments) {
      this.postedComments = [...this.postedComments, postedComments];
    }
  };

  editComment = (id: number, comment: IPostedCommentItem) => {
    if (this.postedComments) {
      this.postedComments = this.postedComments.map((item) => {
        if (item.id === id) {
          return comment;
        } else return item;
      });
    }
  };

  deletePostedComments = (id: number) => {
    if (this.postedComments) {
      this.postedComments = this.postedComments.filter((item) => item.id !== id);
    }
  };

  startEditClosingDocs = () => {
    this.edithasAct = this.hasAct;
    this.editIsServiceProvided = this.isServiceProvided;
    this.editActLink = this.actLink;
    this.editSelfEmployedCheckLink = this.selfEmployedCheckLink;
    this.editHasSelfEmployedCheck = this.hasSelfEmployedCheck;
  };

  closeEditClosingDocs = () => {
    this.edithasAct = null;
    this.editActLink = null;

    this.editHasSelfEmployedCheck = null;
    this.editSelfEmployedCheckLink = null;

    this.editIsServiceProvided = null;
  };

  setOrdInfoBlock = (ordInfoBlock: IOrdInfo[] | null) => {
    this.ordInfoBlock = ordInfoBlock;
  };

  startEditOrdInfoBlock = () => {
    this.editOrdInfoBlock = this.ordInfoBlock;
  };

  closeEditOrdInfoBlock = () => {
    this.editOrdInfoBlock = null;
  };

  changeEditCheckbox = (id: number, field: string, checked: boolean) => {
    if (this.editOrdInfoBlock) {
      this.editOrdInfoBlock = this.editOrdInfoBlock?.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            [field]: checked,
          };
        } else return item;
      });
    }
  };

  setFormatSelect = (id: number, formatCode: string) => {
    if (this.editOrdInfoBlock) {
      this.editOrdInfoBlock = this.editOrdInfoBlock?.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            format: {
              name: item?.format?.name || undefined,
              code: formatCode,
            },
          };
        } else return item;
      });
    }
  };

  changeSimpleEditInput = (id: number, field: string, value: string | number, type: string) => {
    if (this.editOrdInfoBlock) {
      this.editOrdInfoBlock = this.editOrdInfoBlock?.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            [field]: value,
          };
        } else return item;
      });
    }
  };

  closePaymentStore = () => {
    this.isOplataBlocked = null;
    this.oplataId = null;
    this.contractBlock = null;
    this.counteragent = null;
    this.createdAt = null;
    this.createdBy = null;
    this.releaseUserId = null;
    this.status = null;
    this.totalSumm = null;
    this.paymentType = null;
    this.paidAt = null;
    this.editPays = null;
    this.editContractBlock = null;
    this.plannedPaidAt = null;
    this.editPlannedPaidAt = null;
    this.editReleaseUserId = null;
    this.editCounteragent = null;
    this.integrationContacts = [];
    this.postedComments = [];

    this.edithasAct = null;
    this.isServiceProvided = null;
    this.editIsServiceProvided = null;
    this.actLink = null;
    this.editActLink = null;
    this.selfEmployedCheckLink = null;
    this.editSelfEmployedCheckLink = null;
    this.hasSelfEmployedCheck = null;
    this.editHasSelfEmployedCheck = null;
    this.asiedeReleaseUserId = null;
    this.integrationId = null;

    this.ordInfoBlock = null;
    this.editOrdInfoBlock = null;

    this.lastPayInfo = null;

    this.chatOpenPayment = true;
  };

  clearCreateStore = (enableEmpyPayment: boolean | null) => {
    this.releaseUserId = this.asiedeReleaseUserId;
    this.plannedPaidAt = null;
    this.isEmpyPaymet = enableEmpyPayment;
    this.comment = null;

    this.lastPayInfo = null;
    this.pays = [];
  };

  // change payments in integrations

  setHasAct = (hasAct: boolean | null) => {
    this.hasAct = hasAct;
  };

  setErrorPayments = (errorPayments: string | null) => {
    this.errorPayments = errorPayments;
  };

  setSelfEmployedCheckLink = (selfEmployedCheckLink: string | null) => {
    this.selfEmployedCheckLink = selfEmployedCheckLink;
  };

  setActLink = (actLink: string | null) => {
    this.actLink = actLink;
  };

  setServiceProvided = (isServiceProvided: boolean | null) => {
    this.isServiceProvided = isServiceProvided;
  };

  setHasSelfEmployedCheck = (hasSelfEmployedCheck: boolean | null) => {
    this.hasSelfEmployedCheck = hasSelfEmployedCheck;
  };

  setEditCounteragent = (editCounteragent: IContractorCreateResponse | null) => {
    this.editCounteragent = editCounteragent;
  };

  changeEditHasAct = (checked: boolean) => {
    this.edithasAct = checked;
  };

  changeEditActLink = (editActLink: string) => {
    if (editActLink?.trim() !== "") {
      this.edithasAct = true;
    } else {
      this.edithasAct = false;
    }

    this.editActLink = editActLink;
  };

  changeEditSelfEmployedCheckLink = (editSelfEmployedCheckLink: string) => {
    if (editSelfEmployedCheckLink?.trim() !== "") {
      this.editHasSelfEmployedCheck = true;
    } else {
      this.editHasSelfEmployedCheck = false;
    }

    this.editSelfEmployedCheckLink = editSelfEmployedCheckLink;
  };

  changeEditHasSelfEmployedCheck = (editHasSelfEmployedCheck: boolean) => {
    this.editHasSelfEmployedCheck = editHasSelfEmployedCheck;
  };

  changeEditServiceProvided = (checked: boolean) => {
    this.editIsServiceProvided = checked;
  };

  setCeatedBy = (createdBy: IUser | null) => {
    this.createdBy = createdBy;
  };

  setCeatedAt = (createdAt: string | null) => {
    this.createdAt = createdAt;
  };

  setPaidAt = (paidAt: string | null) => {
    this.paidAt = paidAt;
  };

  setEditPlannedPaidAt = (editPlannedPaidAt: string | null) => {
    this.editPlannedPaidAt = editPlannedPaidAt;
  };

  setPaymentType = (paymentType: { code: string; name: string } | null) => {
    this.paymentType = paymentType;
  };

  setPays = (pays: IFinancesPaymentItem[] | null) => {
    if (pays) {
      this.pays = pays;
    } else {
      this.pays = [];
    }
  };

  setStatus = (status: IFinancePaymentsStatus | null) => {
    this.status = status;
  };

  setEditReleaseUserId = (editReleaseUserId: string | null) => {
    this.editReleaseUserId = editReleaseUserId;
  };

  syncEditPaysWithPays = () => {
    if (this.pays) {
      this.editPays = [...this.pays];
    }
  };

  clearEditPays = () => {
    this.editPays = null;
  };

  addPayItemInEditPays = () => {
    const allElems = this.editPays?.length || this.pays?.length;

    const pay: IFinancesPaymentItem = {
      paymentType: "bank",
      direction: this.counteragent?.id?.toString() || null,
      fio: null,
      paymentLink: null,
      plannedPaidAt: null,
      selfEmployedCheck: null,
      source: "ip-bokova",
      totalSumm: allElems === 0 ? this.totalSumm : null,
      id: -99999999 - (allElems || 0),
      cardOrPhone: null,
      status: { code: "not_paid" } as any,
    };

    if (this.editPays === null && this.pays) {
      this.editPays = [...this.pays, pay];
    } else if (this.editPays !== null) {
      this.editPays = [...this.editPays, pay];
    }
  };

  changeEditPaymentType = (id: number, field: string, typeValue: string) => {
    if (this.editPays) {
      this.editPays = this.editPays?.map((item) => {
        if (item.id === id) {
          if (typeValue === "card") {
            const pay: IFinancesPaymentItem = {
              id: item?.id,
              direction: null,
              paymentType: "card",
              plannedPaidAt: item.plannedPaidAt,
              source: null,
              totalSumm: item.totalSumm,
              cardOrPhone: this.lastPayInfo?.phoneOrCard?.replaceAll(" ", "") || null,
              fio: this.lastPayInfo?.fio || null,
              paymentLink: null,
              selfEmployedCheck: null,
              status: item.status || undefined,
            };

            return pay;
          } else {
            const pay: IFinancesPaymentItem = {
              id: item?.id,
              direction: this.counteragent?.id?.toString() || null,
              paymentType: typeValue,
              plannedPaidAt: item.plannedPaidAt,
              source: "ip-bokova",
              totalSumm: item.totalSumm,
              cardOrPhone: null,
              fio: null,
              paymentLink: null,
              selfEmployedCheck: null,
              status: item.status || undefined,
            };

            return pay;
          }
        } else return item;
      });
    }
  };

  changeSimpleFielEditPay = (id: number, field: string, newValue: string) => {
    if (this.editPays) {
      this.editPays = this.editPays?.map((item) => {
        if (item.id === id) {
          return { ...item, [field]: newValue };
        } else return item;
      });
    }
  };

  onChangePayStatus = (
    id: number,
    status: string,
    paidAt: string | null,
    oplataPaidAt?: string | null
  ) => {
    this.paidAt = oplataPaidAt !== undefined ? oplataPaidAt : this.paidAt;

    if (this.pays) {
      this.pays = this.pays?.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            status: { ...item?.status, code: status } as any,
            paidAt: paidAt as any,
          };
        } else return item;
      });
    }
  };

  onChangeEditStatus = (id: number, status: string, paidAt: string | null) => {
    if (this.editPays) {
      this.editPays = this.editPays?.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            status: { ...item?.status, code: status } as any,
            paidAt: paidAt as any,
          };
        } else return item;
      });
    }
  };

  syncContractWithEditContract = () => {
    if (this.contractBlock === null) {
      this.editContractBlock = {} as any;
    } else {
      this.editContractBlock = { ...(this.contractBlock as any) };
    }
  };

  closeContarctEdit = () => {
    this.editContractBlock = null;
  };

  removeAttachment = () => {
    if (this.editContractBlock) {
      const newObj: any = { ...this.editContractBlock, attachment: null! };

      const editContractBlock: any = {};

      for (let key in newObj) {
        if (newObj[key]) {
          editContractBlock[key] = newObj[key];
        }
      }

      this.editContractBlock = editContractBlock;
    }
  };

  changeSimpleEditContractBlock = (
    value: string | number | boolean,
    field: string,
    type?: "contract" | "attachment",
    id?: number
  ) => {
    if (this.editContractBlock) {
      if (type) {
        this.editContractBlock = {
          ...this.editContractBlock,
          [type]: {
            ...this.editContractBlock[type],
            id: id,
            [field]: value,
          },
        };
      } else {
        this.editContractBlock = { ...this.editContractBlock, [field]: value };
      }
    }
  };

  changePostedCommentsWithEditContractBlock = (
    value: string | number,
    field: string,
    type?: "contract" | "attachment",
    id?: number
  ) => {
    if (this.editContractBlock) {
      if (type) {
        this.editContractBlock = {
          ...this.editContractBlock,
          [type]: {
            ...this.editContractBlock[type],
            id: id,
            [field]:
              typeof value === "string" ? (value.trim() !== "" ? [{ text: value }] : []) : [],
          },
        };
      } else {
        this.editContractBlock = { ...this.editContractBlock, [field]: value };
      }
    }
  };

  startEditOplata = () => {
    this.editReleaseUserId = this.releaseUserId;
    this.editPlannedPaidAt = this.plannedPaidAt;
  };

  closeEditOplatat = () => {
    this.editReleaseUserId = null;
    this.editPlannedPaidAt = null;
  };

  startEditCounterAgent = () => {
    this.editCounteragent = this.counteragent;
  };

  closeEditCounterAgent = () => {
    this.editCounteragent = null;
  };

  deleteEditPaymentType = (id: number) => {
    if (this.editPays) {
      this.editPays = this.editPays?.filter((x) => x.id !== id);
    }
  };

  resetEditOrdInfo = () => {
    if (Array.isArray(this.editOrdInfoBlock)) {
      this.editOrdInfoBlock = this.editOrdInfoBlock.map((item) => ({
        ...item, 
        token: '',
        linkToCreative: '',
        hasAct: false, 
        format: { ...item.format, code: '' },
      }));
    }
  };
}

export default new IntegrationPageStore();
