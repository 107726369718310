import { useEffect, useState } from 'react'
import { AsideCardLayout } from '../../../../components/AsideCardLayout/AsideCardLayout'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import dayjs, { Dayjs } from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import StatisticsService from '../../../../services/statistics/statistics.service'
import { AxiosError, AxiosProgressEvent } from 'axios'
import { ManagerTable } from './ui/ManagerTable/ManagerTable'
import plashkiStore from '../../../../store/plashki.store'
import { FilterAnalytics } from './ui/FilterAnalytics/FilterAnalytics'
import { QueryStats } from '@mui/icons-material'
import { GoodsCategoryTable } from './ui/GoodsCategoryTable/GoodsCategoryTable'
import { downloadExcel } from '../../../../shared/functions/functions'
import { NotificationProgress } from '../../../../components/NotificationProgress/NotificationProgress'
import {IStatisticsGoodsCategoriesRequest, IStatisticsManagersRequest} from "../../../../services/statistics/IStatisticsRequest";

export const AllStatisticPage = observer(() => {

  const [searchParams, setSearchParams] = useSearchParams()

  const [showContent, setShowContent] = useState<1 | 2>(searchParams.get('goodsCategory') ? 2 : 1)

  const [isLoading, setLoading] = useState(false)

  const [startDate, setStartDate] = useState<Dayjs | null>(searchParams.get('start_date') ? dayjs(searchParams.get('start_date')) : dayjs().startOf('month'));
  const [endDate, setEndDate] = useState<Dayjs | null>(searchParams.get('end_date') ? dayjs(searchParams.get('end_date')) : dayjs().endOf('month'));

  const [isExcelLoading, setExcelLoading] = useState(false)
  const [propgressExport, setPropgressExport] = useState(0)

  const changeStartDate = (newDate: Dayjs | null) => {
    if (newDate && newDate.isValid()) {
      setStartDate(newDate);
      searchParams.set('start_date', newDate.format('YYYY-MM-DD'));
    } else {
      searchParams.delete('start_date');
    }
    setSearchParams(searchParams);
  };

  const changeEndDate = (newDate: Dayjs | null) => {
    if (newDate && newDate.isValid()) {
      setEndDate(newDate);
      searchParams.set('end_date', newDate.format('YYYY-MM-DD'));
    } else {
      searchParams.delete('end_date');
    }
    setSearchParams(searchParams);
  };


  const changeSearchParams = (type: 'managers' | 'goodsCategories') => {

    setShowContent(type === 'managers' ? 1 : 2);
    if (type === 'managers') {
      plashkiStore.setGoods(null);
      searchParams.delete('goodsCategory');
    } else {
      plashkiStore.setManager(null);
      searchParams.delete('managers');
    }
    searchParams.delete('ids');

    const start = dayjs().startOf('month');
    const end = dayjs().endOf('month');
    setStartDate(start);
    setEndDate(end);

    searchParams.set('start_date', start.format('YYYY-MM-DD'));
    searchParams.set('end_date', end.format('YYYY-MM-DD'));
    setSearchParams(searchParams);

    if (type === 'managers') {
      searchStatisticManagers();
    } else {
      searchStatisticGoodsCategories();
    }
  }

  const searchStatisticManagers = () => {
    if(startDate && endDate && startDate.isValid() && endDate.isValid()){
      setLoading(true)
      setShowContent(1)

      const option:IStatisticsManagersRequest = {
        userIds:searchParams.get('ids') ? searchParams.get('ids')!.split(',')?.map(x=>+x) : [],
        dateOptions: {
          from: startDate?.format('YYYY-MM-DD'),
          to: endDate?.format('YYYY-MM-DD')
        },
      }
      StatisticsService.getManagerSummary(option)
        .then(res=>{
          // console.log(res, 'res managers')
          plashkiStore.setManager(res.data)

          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            // console.log(err, 'err managers')
            setLoading(false)
          }
        })
    }
  }

  const searchStatisticGoodsCategories = () => {
    if(startDate && endDate && startDate.isValid() && endDate.isValid()){
      setLoading(true)
      setShowContent(2)

      const option:IStatisticsGoodsCategoriesRequest = {
        categories:searchParams.get('goodsCategory') ? searchParams.get('goodsCategory')!.split(',') : [],
        dateOptions: {
          from: startDate?.format('YYYY-MM-DD'),
          to: endDate?.format('YYYY-MM-DD')
        },
      }
      StatisticsService.getGoodsCategoriesSummary(option)
        .then(res=>{
          // console.log(res, 'res goodsCategory')
          plashkiStore.setGoods(res.data)
          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            // console.log(err, 'err goodsCategory')
            setLoading(false)
          }
        })
    }
  }

  const onDownloadProgress = (progressEvent:AxiosProgressEvent) => {
    if(progressEvent.estimated && progressEvent.total){
      const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
      setPropgressExport(prev=>progress)
    }
  }

  const onExportExcel = (type: 'managers' | 'goodsCategories') => {
    if (startDate && endDate && startDate.isValid() && endDate.isValid()) {
      setExcelLoading(true);
      setPropgressExport(0);

      const option:IStatisticsManagersRequest | IStatisticsGoodsCategoriesRequest  = {
        dateOptions: {
          from: startDate.format('YYYY-MM-DD'),
          to: endDate.format('YYYY-MM-DD')
        },
        ...(type === 'managers' ? {
          userIds: searchParams.get('ids')?.split(',').map(x => +x) || []
        } : {}),
        ...(type === 'goodsCategories' ? {
          categories: searchParams.get('goodsCategory')?.split(',') || []
        } : {})
      };

      const formatDate = (date: Dayjs) => date.format('YYYY-MM-DD');
      const fileNameDate = `${formatDate(startDate)}_to_${formatDate(endDate)}`;
      const fileName = type === 'managers'
        ? `Аналитика по менеджерам за ${fileNameDate}`
        : `Аналитика по товарным группам за ${fileNameDate}`;


      StatisticsService[type === 'managers' ? 'managerSummaryExcel' : 'goodsCategoriesSummaryExcel'](option, onDownloadProgress)
        .then(res => {
          downloadExcel(res.data, fileName);
          setPropgressExport(0);
        })
        .catch(err => {
          if (err instanceof AxiosError) {
          }
        })
        .finally(() => {
          setExcelLoading(false);
        });
    }
  };


  useEffect(()=>{
    if(showContent === 1){
      searchStatisticManagers()
    }

    if(showContent === 2){
      searchStatisticGoodsCategories()
    }
    return ()=>{
      plashkiStore.setGoods(null)
      plashkiStore.setManager(null)
    }
  }, [])

  return (
    <>
      {isExcelLoading && <NotificationProgress propgress={propgressExport}/>}
      <Stack spacing={2} sx={{overflow:'hidden'}}>
        <AsideCardLayout 
          sx={{
            display:'grid', 
            gridTemplateColumns:{xs:'100%', md:'1fr 220px', lg:'1fr 300px', xl:'1fr 400px'}, 
            columnGap:4, 
            height:'100%'
          }}
        >
          <Box 
            sx={{
              overflow:'auto',
              gridRow:{xs:2, md:'auto'},
              mt:{xs:2, md:0}
            }}
          >
            {isLoading &&
              <Box>
                <Stack 
                  direction={'row'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  spacing={1} 
                >
                  <Typography variant='h5'>Идёт загрузка...</Typography>
                  <CircularProgress sx={{height:25, width:25}}/>
                </Stack>
              </Box>
            }
            {!isLoading && plashkiStore.managers === null && showContent === 1 &&
              <Stack alignItems={'center'}>
                <QueryStats sx={{height:150, width:150}}/>
                <Typography variant='h5'>Для отображения статистики</Typography>
                <Typography variant='h6'sx={{fontWeight:'normal'}}>Выберите менеджеров и период</Typography>
              </Stack>
            }
            {!isLoading && plashkiStore.managers && showContent === 1 &&
              <Stack spacing={2} sx={{overflow:'auto'}}>
                {plashkiStore.managers?.filter(x=>x.manager!==null).map((item, ind)=>
                  <ManagerTable 
                    key={`${item?.manager?.firstName} ${item?.manager?.lastName}`} 
                    manager={item} 
                    savingDepartment={(plashkiStore.managers?.[0]?.manager === null && ind === 0) ? plashkiStore.managers?.[0]?.saving : undefined}
                  />
                )}
              </Stack>
            }
            {!isLoading && plashkiStore.goods === null && showContent === 2 &&
              <Stack alignItems={'center'}>
                <QueryStats sx={{height:150, width:150}}/>
                <Typography variant='h5'>Для отображения статистики</Typography>
                <Typography variant='h6'sx={{fontWeight:'normal'}}>Выберите категорию товаров и период</Typography>
              </Stack>
            }
            {!isLoading && plashkiStore.goods && showContent === 2 &&
              <Stack spacing={2} sx={{overflow:'auto'}}>
                {plashkiStore.goods.map(item=><GoodsCategoryTable key={item.goodsCategory} stats={item}/>)}
              </Stack>
            }
          </Box>
          <FilterAnalytics
            startDate={startDate}
            endDate={endDate}
            changeStartDate={changeStartDate}
            changeEndDate={changeEndDate}
            searchManagers={searchStatisticManagers}
            searchGoodsCategories={searchStatisticGoodsCategories}
            changeFilter={changeSearchParams}
            initFilterType={searchParams.get('goodsCategory') ? 'goodsCategories' : 'managers'}
            onExportExcel={onExportExcel}
          />
        </AsideCardLayout>
      </Stack>
    </>
  )
})