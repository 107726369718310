import {FC} from 'react'
import { IAsideNotificationItemComponent, INotificationItemVariables } from './IAsideNotificationItem'
import { Avatar, Box, Divider, Stack, Typography } from '@mui/material'
import { Done, DoneAll } from '@mui/icons-material'
import { blue, grey } from '@mui/material/colors'
import companyUsersStore from '../../../../store/companyUsers.store'
import { observer } from 'mobx-react-lite'
import { getDatePlusCustomTime } from '../../../../shared/functions/functions'

export const AsideNotificationItem:FC<IAsideNotificationItemComponent> = observer((props) => {
  const {
    isRead,
    newDay,
    text,
    createdAt,
    id,
    variables,
    sourceId,
    changeReadState,
  } = props

  const currentUser = companyUsersStore.users.find(item=>item.id === sourceId)

  const textParseToHtml = ():string => {
    const textParam = JSON.parse(variables)
    const textParamKey = Object.keys(textParam)[0]

    const totalParams:INotificationItemVariables = textParam[textParamKey]
    const newText = `<a style='color:#5F82BA;' href=${totalParams.link} target='_blank'>${totalParams.text}</a>`

    const textSplit = text.split(' ')
    const finishText = textSplit.map(text=>text === `{${textParamKey}}` ? newText : text).join(' ')

    return finishText
  }

  const readNotificaton = () => {
    if(!Boolean(isRead)){
      changeReadState(id)
    } else return
  }

  return (
    <>
      {newDay && <Divider><Typography variant='body1' color={grey[500]}>{getDatePlusCustomTime({value:createdAt, format:'DD.MM.YY'})}</Typography></Divider>}
      <Box 
        sx={{
          display:'grid', 
          gridTemplateColumns:'min-content 1fr min-content', 
          columnGap:'20px', 
          cursor:!Boolean(isRead) ? 'pointer' : 'auto'
        }}
        onClick={readNotificaton}
      >
        <Avatar src={currentUser?.imageUrl} alt={`${currentUser?.firstName} ${currentUser?.lastName}`} sx={{height:30, width:30, mt:1}}/>
        <Stack 
          direction={'row'} 
          spacing={1} 
          sx={{
            background:!isRead ? blue[50] : 'inherit', 
            borderRadius:2, 
            p:1, 
            transition:'all ease .3s'
          }}
        >
          <Typography dangerouslySetInnerHTML={{__html:textParseToHtml()}}></Typography>
        </Stack>
        <Stack direction={'column'} alignItems={'flex-end'}>
          <Typography variant='body2' color={grey[500]}>{getDatePlusCustomTime({value:createdAt, format:'HH:mm'})}</Typography>
          {isRead
            ? <DoneAll color='secondary' fontSize='small'/>
            : <Done color='secondary' fontSize='small'/>
          }
        </Stack>
      </Box>
    </>
  )
})