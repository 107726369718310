import { makeAutoObservable } from "mobx"
import { IIntegrationCategory } from "../services/layout/layoutResponse"

class CategoryStore {
  category:IIntegrationCategory[] | null = null

  constructor(){
    makeAutoObservable(this)
  }

  setCategory(category:IIntegrationCategory[]) {
    this.category = category
  }

}

export default new CategoryStore()