import {IStrategyField} from "../../../../../../strategy/interface/IStrategyField";
import {TableDefault} from "../../../../../../components/Table/TableDefault";
import {TableItem} from "../../../../../../components/Table/ui/TableItem";
import statusesStore from "../../../../../../store/statuses.store";
import {IIntegrationDetailsTable} from "./IIntegrationDetailsTable";
import {INTEGRATION_CARD_OPEN} from "../../../../../../shared/constants/searchParams";
import userStore from "../../../../../../store/user.store";
import {ROUTES} from "../../../../../../shared/routes/routes";

export const IntegrationDetailsTable = ({ details}:IIntegrationDetailsTable) => {

  const integrationCardOpen = ((id:number) => {
    window.open('/'+userStore.currentCompany+'/'+ROUTES.INTEGRATIONS+'?'+INTEGRATION_CARD_OPEN+'='+id, '_blank')
  })

  const data:IStrategyField[] = [
    {title:'ID', code:'id',isSimple:true,immutable:false},
    {title:'Дата', code:'integration_date',isSimple:false,immutable:false,isDate:true},
    {title:'Промокод', code:'promokod', isSimple:true, immutable:false},
    {title:'Статус', code:'status', immutable:false, isSimple:false, isStatuses:true,isPaymentStatus:false},
    {title:'Стоимость', code:'price',  isSimple:true, immutable:false,isMoneySimple:true},
    {title:'Кол-во заказов', code:'validOrders', isSimple:true, immutable:false},
    {title:'Сумма заказов', code:'ordersSumm', isSimple:true, immutable:false,isMoneySimple:true},
    {title:'Тз', code:'tz', immutable: false, isSimple: false, isTz: true, readOnly:true},
    {title:'ROI', code:'roi', isSimple:true, immutable:false, isPercent:true},
    {title:'Стоимость заказа', code:'cac', isSimple:true, immutable:false,isMoneySimple:true},
    {title:'Просмотры', code:'views', isSimple:false, isSimpleView:true, immutable:false,},
    {title:'Трафик', code:'traffic', isSimple:true, immutable:false},
    {title:'CPV', code:'cpv', isSimple:true, immutable:false,isMoneySimple:true, isMoneyShowPredictionRemains:true},
    {title:'CTR', code:'ctr', isSimple:true, immutable:false, ctrlsIcon:'%'},
    {title:'CR', code:'cr', isSimple:true, immutable:false, ctrlsIcon:'%'},
    {title:'Скв.CR', code:'crThrough', isSimple:true, immutable:false, ctrlsIcon:'%'},
  ]

  return (
    <TableDefault showBurgerCell sx={{mb:2,mt:2}} tableRow={data} showBorderRadius={false}>
      {details.map(item => {
        const customPorops = {
          ...item,
          promokod:item?.promokod?.code,
        }
        return <TableItem isShowNavigateIcon onNavigate={integrationCardOpen} key={item.id} statusStore={statusesStore?.statuses} isStatusReadOnly {...customPorops} cells={data}/>;
      })}
    </TableDefault>
  );
};

