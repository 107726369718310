import { useState, useEffect} from 'react'
import { TextField } from "@mui/material"
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout"
import createPaymentStore from '../../../../store/createPayment.store'

export const FinancesComments = () => {

  const [value, setValue] = useState('')

  useEffect(() => {
    const delay = setTimeout(()=> createPaymentStore.changeComment(value), 450)
    return ()=>clearTimeout(delay)
  },[value])

  return (
    <AsideCardLayout headerTitle="Комментарий" sx={{height:'fit-content'}}>
        <TextField
          value={value}
          onChange={e=>setValue(e.target.value)}
          size="small"
          fullWidth
          placeholder="Введите комментарий"
          sx={{mt:1}}
        />
    </AsideCardLayout>
  )
}