import { makeAutoObservable } from "mobx";
import { IIntegrationsFullResponse } from "../services/integration/IIntegrationsResponse";
import categoriesGoodsStore from "./categoriesGoods.store";
import { IUpdateStatusesRequest } from "../services/integration/IntegrationsRequest";
import dayjs, { Dayjs } from "dayjs";
import IntegrationsService from "../services/integration/integrations.service";

class RepeatIntegrationStore {
  inegrations: null | IIntegrationsFullResponse[] = null;

  isLoadingSort: boolean = false;

  dateFrom: Dayjs | null = dayjs(dayjs().subtract(6, "months").format("YYYY-MM-DD"));

  dateTo: Dayjs | null = dayjs(dayjs().format("YYYY-MM-DD"));

  isLoading: boolean = false;

  error: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  getCandidatesForRepeat(id: number) {
    this.setIsLoading(true);
    const formattedDateFrom = this.dateFrom?.format("YYYY-MM-DD");
    const formattedDateTo = this.dateTo?.format("YYYY-MM-DD");

    if (formattedDateFrom && formattedDateTo) {
      IntegrationsService.getCandidatesForRepeat({
        userId: id,
        dateRange: {
          from: formattedDateFrom,
          to: formattedDateTo,
        },
      })
        .then((res) => this.setIntegrations(res.data))
        .catch((err) => this.setError(err.message))
        .finally(() => this.setIsLoading(false));
    }
  }

  setDateFrom(value: Dayjs | null) {
    this.dateFrom = value;
  }

  setDateTo(value: Dayjs | null) {
    this.dateTo = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setError(value: string) {
    this.error = value;
  }

  setIntegrations = (integrations: null | IIntegrationsFullResponse[]) => {
    if (integrations) {
      return (this.inegrations = integrations?.sort(
        (a, b) => Date.parse(b.integration_date) - Date.parse(a.integration_date)
      ));
    }
    this.inegrations = integrations;
  };

  removeIntegrations = (id: number) => {
    if (this.inegrations) {
      this.inegrations = this.inegrations.filter((item) => item.id !== id);
    }
  };

  changeField = (option: IUpdateStatusesRequest) => {
    const { field, id, new_value, update_id } = option;
    if (this.inegrations) {
      this.inegrations = this.inegrations.map((item) => {
        if (item.id === id) {
          const newItem = {
            ...item,
            update_id,
            [field]: new_value,
          };
          return newItem;
        } else return item;
      });
    }
  };

  sortRepeatItem(type: string, sortType: "asc" | "desc") {
    this.isLoadingSort = true;

    if (this.inegrations !== null) {
      setTimeout(() => {
        sortType === "desc"
          ? (this.inegrations = [...(this.inegrations as IIntegrationsFullResponse[])].sort(
              (a, b) => {
                if (a[type] === null) {
                  return 1;
                }

                if (b[type] === null) {
                  return -1;
                }

                if (a[type] === b[type]) {
                  return 0;
                }

                if (type === "integration_date") {
                  return Number(new Date(a[type])) < Number(new Date(b[type])) ? 1 : -1;
                }

                if (type === "goodsCategoryCode") {
                  const categoriesRuA = categoriesGoodsStore.categories?.find(
                    (x) => x?.code === a[type]
                  )?.name;
                  const categoriesRuB = categoriesGoodsStore.categories?.find(
                    (x) => x?.code === b[type]
                  )?.name;

                  return categoriesRuA! < categoriesRuB! ? 1 : -1;
                } 

                if (type === 'roi') {
                  return Number(a[type]) > Number(b[type]) ? 1 : -1;
                }
                
                return 0;
              }
            ))
          : (this.inegrations = [...(this.inegrations as IIntegrationsFullResponse[])].sort(
              (a, b) => {
                if (a[type] === null) {
                  return 1;
                }

                if (b[type] === null) {
                  return -1;
                }

                if (a[type] === b[type]) {
                  return 0;
                }

                if (type === "integration_date") {
                  return Number(new Date(a[type])) > Number(new Date(b[type])) ? 1 : -1;
                }

                if (type === "goodsCategoryCode") {
                  const categoriesRuA = categoriesGoodsStore.categories?.find(
                    (x) => x?.code === a[type]
                  )?.name;
                  const categoriesRuB = categoriesGoodsStore.categories?.find(
                    (x) => x?.code === b[type]
                  )?.name;

                  return categoriesRuA! > categoriesRuB! ? 1 : -1;
                }

                if (type === 'roi') {
                  return Number(a[type]) < Number(b[type]) ? 1 : -1;
                }
                
                return 0;
              }
            ));

        setTimeout(() => {
          this.isLoadingSort = false;
        }, 0);
      }, 0);
    }
  }
}

export default new RepeatIntegrationStore();
