import {AxiosResponse} from 'axios'
import $gateway_api from '../../shared/api/gatewayAxios'
import { ICreateCommentsRequest, IDeleteCommentsRequest, IEditCommentsRequest } from './ICommonRequest'
import { IPostedCommentItem } from '../../components/PostedCommentItem/models/IPostedCommentItem'
import { ISettingsSelectedGroup } from '../../components/IntegrationTable/models/ISettingsSelectedGroup'
import { IFiltersUpadteRequest } from '../integration/IntegrationsRequest'

export default class CommonService {

  static createComments(option:ICreateCommentsRequest): Promise<AxiosResponse<IPostedCommentItem>>{
    return $gateway_api.post('/api/common/comments', option)
  }
  
  static editComments(option:IEditCommentsRequest): Promise<AxiosResponse<IPostedCommentItem>>{
    return $gateway_api.post('/api/common/comments/edit', option)
  }

  static deleteComments(option:IDeleteCommentsRequest): Promise<AxiosResponse<{id:number}>>{
    return $gateway_api.post('/api/common/comments/delete', option)
  }

  static filterSettingsUpdate(option:IFiltersUpadteRequest):Promise<AxiosResponse<ISettingsSelectedGroup[]>>{
    return $gateway_api.post('/api/common/filter-settings-update', option)
  }

}