import {FC} from 'react'
import { IStatusPaymentEmptyField } from './IStatusPaymentEmptyField'
import { StatusEmptyField } from '../StatusEmptyField/StatusEmptyField'
import { observer } from 'mobx-react-lite'
import statusesStore from '../../../../../store/statuses.store'
import { IStatusesPayments } from '../../../../Statuses/models/ISettingsFilters'
import { IPaymentsEditRequest } from '../../../../../services/payments/IPaymentsRequest'
import PaymentsService from '../../../../../services/payments/payments.service'
import paymentsTableStore from '../../../../../store/paymentsTable.store'
import { AxiosError } from 'axios'

export const StatusPaymentEmptyField:FC<IStatusPaymentEmptyField> = observer((props) => {
  const {id, readOnly = false} = props

  const changeStatus = (value:string) => {

    const option:IPaymentsEditRequest = {
      id,
      status:value
    }

    PaymentsService.paymentEdit(option)
      .then(res=>{
        // console.log(res, 'res change sttuas')
        paymentsTableStore.changeStatusTable(id, value)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err change stuts')
        }
      })
  }

  return (
    <StatusEmptyField
      statusStore={statusesStore.payments as IStatusesPayments}
      readOnly={readOnly}
      onChangePayments={changeStatus}
    />
  )
})