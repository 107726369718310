import { FC } from 'react'
import { IProductSampleList } from './IProductSampleList'
import { Typography } from '@mui/material'
import {EmptyText} from "../../../../EmptyText/EmptyText";

export const ProductSampleList:FC<IProductSampleList> = (props) => {
  const {list} = props

  return (
    <>
      {list?.length>0 ?
        list?.map(good=><Typography key={good?.id} sx={{whiteSpace:'nowrap',display:'flex'}}>{good?.good?.name} / {good?.color || <EmptyText/>}</Typography>)
      : <EmptyText sx={{whiteSpace:'nowrap'}}>товары отсутствуют</EmptyText>}
    </>
  )
}
