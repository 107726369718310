import { FC, useState } from "react";
import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { IGoodsSearchAutocomplete } from "./IGoodsSearchAutocomplete";
import { IFilterSelectItem } from "../../../../../../../../components/Filters/UI/FilterSelectChip/IFilterSelectChip";

export const GoodsSearchAutocomplete: FC<IGoodsSearchAutocomplete> = (props) => {
  const {
    onChangeGood,
    value,
    disabled,
    content,
    placeholder = "Выберите образец товара",
    id,
  } = props;

  const [valueState, setValueState] = useState<IFilterSelectItem | null>(
    value ? { name: value?.name, value: value?.article, filterCode: value?.category?.name } : null
  );
  const [inputValue, setInputValue] = useState("");

  const sortedOptions = [...content].sort((a: IFilterSelectItem, b: IFilterSelectItem) => {
    if (a.filterCode === b.filterCode) {
      const isALatin = /^[A-Za-z]/.test(a.name);
      const isBLatin = /^[A-Za-z]/.test(b.name);

      if (isALatin && !isBLatin) return -1;
      if (!isALatin && isBLatin) return 1;

      return a.name.localeCompare(b.name);
    }

    if (a.filterCode && b.filterCode) {
      return a.filterCode.localeCompare(b.filterCode);
    }

    return 0;
  });

  return (
    <Autocomplete
      value={valueState}
      onChange={(event: any, newValue: IFilterSelectItem | null) => {
        setValueState(newValue);
        onChangeGood(id, "good", newValue?.value as any);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={sortedOptions}
      groupBy={(option) => option?.filterCode!}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      fullWidth
      size="small"
      disabled={disabled}
      noOptionsText="Ничего не найдено"
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} size="small" disabled={disabled} />
      )}
      renderGroup={(params) => (
        <>
          <Typography pl={2} fontWeight={'bold'}>{params.group}</Typography>
          <Stack>{params.children}</Stack>
        </>
      )}
    />
  );
};
