import { Box, CircularProgress } from "@mui/material"

export const LazyPageLoading = () => {
  return (
    <Box
      sx={{
        height:'100%', 
        display:'flex', 
        alignItems:'center', 
        justifyContent:'center'
      }}
    >
      <CircularProgress sx={{minHeight:100, minWidth:100}}/>
    </Box>
  )
}