import { FC, useState } from 'react'
import { IGoodsCategoriesResponseProduct } from '../../../../../../../../../../services/goodsCategories/IGoodsCategoriesResponse'
import { Stack, TextField, Typography } from '@mui/material'
import { EditModeButton } from '../../../../../../../../../../components/UI/Buttons/EditModeButton/EditModeButton'
import { RemoveItemButton } from '../../../../../../../../../../components/UI/Buttons/RemoveItemButton/RemoveItemButton'
import GoodsCategoriesService from '../../../../../../../../../../services/goodsCategories/goodsCategories.service'
import { AxiosError } from 'axios'
import { AlertModal } from '../../../../../../../../../../components/AlertModal/AlertModal'
import { NotificationAlerts } from '../../../../../../../../../../components/NotificationAlerts/NotificationAlerts'
import rulesStore from '../../../../../../../../../../store/rules.store'
import goodsStore from '../../../../../../../../../../store/goods.store'
import { IEditGoodResponse } from '../../../../../../../../../../services/goodsCategories/IGoodsCategoriesRequest'

export const GoodRulesItem:FC<IGoodsCategoriesResponseProduct> = (props) => {
  const {
    article,
    canBeDeleted,
    category,
    forFirstIntegrationWithChannel,
    isActive,
    name
  } = props

  const [isEdit, setEdit] = useState(false)

  const [isDeleteOpen, setDeleteOpen] = useState(false)

  const [deleteLoading, setDeleteLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const [errorDelete, setErrorDelete] = useState<string | null>(null)

  const submit = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSubmitLoading(true)

    const form = e.target as HTMLFormElement

    const name:string = form['good-name'].value

    const option: IEditGoodResponse = {
      article,
      name
    }

    GoodsCategoriesService.editGoods(option)
      .then(res=>{
        rulesStore.editGoodsCategories(category.code, article, res.data.name)
        goodsStore.editGoods(article, res.data.name)

        setEdit(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){

        }
      })
      .finally(()=>{
        setSubmitLoading(false)
      })
  }

  const deleteGood = () => {
    setDeleteLoading(true)
    setErrorDelete(null)

    GoodsCategoriesService.removeGoods({article})
      .then(res=>{
        rulesStore.removeGoodsCategories(category.code, article)
        goodsStore.removeGoods(article)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setErrorDelete(err?.response?.data?.message)
        }
      })
      .finally(()=>{
        setDeleteLoading(false)
        setDeleteOpen(false)
      })
  }

  return (
    <form onSubmit={submit}>
      <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{minHeight:37}}>
        {!isEdit
          ? <Typography>{name}</Typography>
          : <TextField 
              id='good-name' 
              size='small'
              defaultValue={name} 
              placeholder='Введите текст'
              sx={{minWidth:300}}
              autoFocus
            />
        }
        <Stack direction={'row'} alignItems={'center'}>
          <EditModeButton
            isEdit={isEdit}
            onOpenEdit={()=>setEdit(true)}
            onClose={()=>setEdit(false)}
            onSubmit={()=>''}
            disabledEdit={deleteLoading}
            loading={submitLoading}
            btnType='submit'
          />
          {(canBeDeleted && !isEdit) && <RemoveItemButton onClick={()=>setDeleteOpen(true)} disabled={deleteLoading}/>}
        </Stack>
      </Stack>
      <AlertModal
        title='Внимание!'
        subtext='Вы действительно хотите удалить товар?'
        open={isDeleteOpen}
        onClose={()=>setDeleteOpen(false)}
        onSubmit={deleteGood}
        loading={deleteLoading}
        buttonSubmitName='Удалить'
        buttonCancelName='Отмена'
      />
      <NotificationAlerts
        error={{text:errorDelete || '', open:Boolean(errorDelete), onClose:()=>setErrorDelete(null)}}
      />
    </form>
  )
}
