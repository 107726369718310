import {useState, useEffect} from 'react'
import AgreementsService from '../../../../../../services/agreements/agreements.service'
import { AxiosError, AxiosProgressEvent } from 'axios'
import dashboardStore from '../../../../../../store/dashboard.store'
import { Backdrop, Box, CircularProgress, Stack, Typography } from '@mui/material'
import { TableDefault } from '../../../../../../components/Table/TableDefault'
import { observer } from 'mobx-react-lite'
import { AgreemenTableList } from './AgreemenTableList'
import { useSearchParams } from 'react-router-dom'
import { IAgreementsFilterQuery, IAgreementsFiltered, IAgreementsFilteredExcel } from '../../../../../../services/agreements/IAgreementsRequest'
import {LS_TAKE_REQUEST_AGREEMENTS} from '../../../../../../shared/constants/localstorage'
import { NotificationAlerts } from '../../../../../../components/NotificationAlerts/NotificationAlerts'
import { IntegrationTableSettings } from '../../../../../../components/IntegrationTable/ui/IntegrationTableSettings'
import CommonService from '../../../../../../services/common/common.service'
import { IFiltersUpadteRequest } from '../../../../../../services/integration/IntegrationsRequest'
import { ISettingsSelected } from '../../../../../../components/IntegrationTable/models/ISettingsSelected'
import { toJS } from 'mobx'
import { setCheckedParams } from '../../../../../../store/integrationTable.store'
import { selectionStretegy } from '../../../../../../strategy/strategy'
import { NotificationProgress } from '../../../../../../components/NotificationProgress/NotificationProgress'
import { downloadExcel } from '../../../../../../shared/functions/functions'
import { AgreementFilter } from '../AgreementFilter/AgreementFilter'
import { getFilterAgreementsQuery } from '../AgreementFilter/shared/dataList'

export const AgreementTable = observer(() => {
  const [searchParams,setSearchParams] = useSearchParams()

  const [tableHead, setTableHead] = useState<any[]>([])

  const [isLoading, setLoading] = useState(false)
  const [currentSortType, setCurrentSortType] = useState('')

  const [isSettingsOpen, setSettingsOpen] = useState(false)
  const [isLoadingSettings, setLoadingSettings] = useState(false)

  const [tableError, setTableError] = useState<string | null>(null)
  
  const [showAlert, setShowAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState<string | null>(null)

  const [propgressExport, setPropgressExport] = useState(0)
  const [isExcelLoading, setExcelLoading] = useState(false)

  const [takeParamRequest, setTakeParamRequest] = useState(() => 
    [`${localStorage.getItem(LS_TAKE_REQUEST_AGREEMENTS) ? localStorage.getItem(LS_TAKE_REQUEST_AGREEMENTS) : 100}`]
  )

  const onChaneTakeRequest = (event: React.MouseEvent<HTMLElement>,  newFormats: string[]) => {
    if(newFormats !== null) {
      setTakeParamRequest(newFormats)
      localStorage.setItem(LS_TAKE_REQUEST_AGREEMENTS, `${newFormats}`)
      if (searchParams.has('page')){
        searchParams.set('page', '1');
        setSearchParams(searchParams);
      }
      searchParams.set('take',newFormats as any);
      setSearchParams(searchParams);
      dashboardStore.setAgreementsPage(1)
    } else return
  }

  const getAgreements = (filterQuery:IAgreementsFilterQuery, page:number) => {
    setLoading(true)

    const option:IAgreementsFiltered = {
      filters:filterQuery,
      page:page,
      take:+`${takeParamRequest}`
    }
    AgreementsService.getAgreementsFiltered(option)
      .then(res=>{
        // console.log(res, 'res get all agre')

        const rows:any[] = []
        toJS(dashboardStore).agreementConfirmLists.map(field=>rows.push(selectionStretegy(field)))
        setTableHead(rows)

        dashboardStore.addAgreementsAll(res.data.items)
        dashboardStore.setTotalCount(res.data.count)

        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err get all agre')
          setLoading(false)
        }
      })
  }

  const sendSaveSettings = async (array:ISettingsSelected[]) => {
    setLoadingSettings(true)
    setErrorAlert(null)

    const options:IFiltersUpadteRequest = {
      entity:'agreement',
      new_settings:array
    }
    try {
      const res = await CommonService.filterSettingsUpdate(options)
      // console.log(res.data, 'res data upadate filterrs')

      dashboardStore.setSelectedList(res.data)
      dashboardStore.setInitSettingsSelect(res.data)

      const totalArr:ISettingsSelected[] = []

      for(let i = 0; i < res.data.length; i++){
        totalArr.push(...res.data[i].fields)
      }

      totalArr.sort((a,b)=>a.ordering > b.ordering ? 1 : -1)

      dashboardStore.setConfirmList(totalArr.filter(x=>Boolean(x.isSelected)))
      dashboardStore.setInitSettingsConfirm(totalArr.filter(x=>Boolean(x.isSelected)))

      const rows:any[] = []

      toJS(dashboardStore).agreementConfirmLists.map(field=>rows.push(selectionStretegy(field)))
      setTableHead(rows)
      
      setLoadingSettings(false)
      setSettingsOpen(false)
      setShowAlert(true)
      
    } catch (err) {
      if (err instanceof AxiosError) {
        console.log(err)
        setErrorAlert(err.message)
        setLoadingSettings(false)
      }
    }
  }

  const sortHandler = (type:string, sortType:'asc' | 'desc') => dashboardStore.sortAgreementTableItem(type, sortType)
  const addConfirmList = (item:ISettingsSelected) => dashboardStore.addToConfirmList(item)
  const removeConfirmArr = (id:string) => dashboardStore.removeConfirmList(id)
  const setSelectChecked = (params:setCheckedParams) => dashboardStore.setSelectedChecked(params)
  
  const changePage = (page:number) =>{
    dashboardStore.setAgreementsPage(page)
    getAgreements(getFilterAgreementsQuery(searchParams), page)
    searchParams.set('page', page.toString());
    setSearchParams(searchParams);
  } 

  const submitSettings = () => {
    sendSaveSettings(dashboardStore.agreementConfirmLists)
  }

  const resetSettings = () => {
    const store = toJS(dashboardStore)

    dashboardStore.setConfirmList(store.agreementsInitSettingsConfirm)
    dashboardStore.setSelectedList(store.agreementsInitSettingsSelect)
  }

  const deleteConfirmItem = (code:string) => {
    dashboardStore.onConfirmDelete(code)
    dashboardStore.removeConfirmList(code)
  }

  const onDownloadProgress = (progressEvent:AxiosProgressEvent) => {
    if(progressEvent.estimated && progressEvent.total){
      const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
      setPropgressExport(prev=>progress)
    }
  }

  const onExportExcel = () => {
    setExcelLoading(true)
    setPropgressExport(prev=>0)

    const option:IAgreementsFilteredExcel = {
      includeInSelect:[],
      filters:getFilterAgreementsQuery(searchParams),
      page:1,
      take:+`${100}`
    }
    AgreementsService.agreementsFilteredExcel(option, onDownloadProgress)
      .then(res=>{
        downloadExcel(res.data, 'Согласование')
        setPropgressExport(prev=>0)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          console.log(err, 'download excel err')
        }
      })
      .finally(()=>{
        setExcelLoading(false)
      })
  }

  const getFilteredAgreement = () => {
    const currentPage = Number(searchParams.get('page')) || 1
    getAgreements(getFilterAgreementsQuery(searchParams), currentPage)
    dashboardStore.setAgreementsPage(currentPage)
  }

  const totalPages = Math.ceil(dashboardStore.agreementsTotalCount / (Array.isArray(takeParamRequest) ? +takeParamRequest[0] : takeParamRequest))

  const allowedPageSizes = [100 , 300 , 500]

  const isPageSizeAllowed = allowedPageSizes.some(number => searchParams.get('take') === number.toString());

  useEffect(() => {
    getFilteredAgreement()
    return () => {
      dashboardStore.addAgreementsAll([])
    }
  }, [takeParamRequest])


  useEffect(() => {
    const takeFromUrl =   Number(searchParams.get('take'))
    if (searchParams.has('take')){
      localStorage.setItem(LS_TAKE_REQUEST_AGREEMENTS, `${takeFromUrl}`)
      setTakeParamRequest([takeFromUrl.toString()])
    }
    if (searchParams.has('take') && !isPageSizeAllowed) {
      dashboardStore.setIsPageNotFound(true)
    }
  }, [])

  useEffect(()=>{
    if ( totalPages && searchParams.has('page') && Number(searchParams.get('page')) > totalPages ) {
      dashboardStore.setIsPageNotFound(true)
    }
  },[totalPages])

  useEffect(()=>{
    return () => {
      dashboardStore.addAgreementsAll(null)
    }
  }, [])

  return (
    <Box 
      sx={{display:'grid', overflow:'hidden', gridTemplateRows: 'min-content 1fr', gridAutoRows:' min-content'}}
    >
      {isExcelLoading && <NotificationProgress propgress={propgressExport}/>}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          isLoading === false  && dashboardStore.isLoadingSort && 
            !!dashboardStore.agreementsAll?.length && (currentSortType !=='' || searchParams.get('sort')) ? true : false
        }
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
      <>
        {isLoading &&
          <Stack alignItems={'center'} justifyContent={'start'}>
            <Stack direction={'row'} alignItems={'center'} spacing={4}>
              <Typography variant='h4'>Идёт загрузка...</Typography>
              <CircularProgress sx={{minHeight:50, minWidth:50, maxHeight:50, maxWidth:50}}/>
            </Stack>
          </Stack>
        }
        {!isLoading && dashboardStore.agreementsAll !== null &&
          <AgreementFilter onChangeLoading={setLoading}/>
        }
        {!isLoading && dashboardStore.agreementsAll !== null &&
          <Box sx={{display:'grid', overflow:'hidden'}}>
            {dashboardStore.agreementsAll.length === 0 &&
              <Stack alignItems={'center'} sx={{mt:2}}>
                <Typography variant='h4'>Согласований не найдено</Typography>
                <Typography variant='h5'>Обновите страницу или зайдите позже</Typography>
              </Stack>
            }
            {dashboardStore.agreementsAll.length > 0 &&
              <>
                <TableDefault
                  tableId='agreements-table'
                  tableHeaderId='agreements-table-headerId'
                  tableRow={tableHead}
                  maxHeight={'calc(100% - 55px)'}
                  maxWidth='100%'
                  showBurgerCell
                  showAgreeIconsCell
                  showBorderRadius={false}
                  isShowSort
                  sortHandler={sortHandler}
                  currentSortType={currentSortType}
                  onChangeCurrentSortType={setCurrentSortType}
                  settingsOnclick={()=>setSettingsOpen(true)} 
                  totalItems={dashboardStore.agreementsAll?.length}
                  showFooter
                  toggleItems={['100', '300', '500']}
                  toggleValues={takeParamRequest}
                  onChaneToggleButton={onChaneTakeRequest}
                  onExportExcel={onExportExcel}
                  disabledExportExcelBtn={isExcelLoading}
                  onChangePage={changePage}
                  totalCount={dashboardStore.agreementsTotalCount}
                  paginateStep={Array.isArray(takeParamRequest) ? +takeParamRequest[0] : takeParamRequest}
                  page={dashboardStore.agreementsPage}
                  hideBorder
                  showBoxShadow
                >
                  <AgreemenTableList tableHead={tableHead}/>
                </TableDefault>
                {isSettingsOpen &&
                  <IntegrationTableSettings
                    open={isSettingsOpen}
                    onClose={()=>setSettingsOpen(false)}
                    onSubmit={submitSettings}
                    onReset={resetSettings}
                    loading={isLoadingSettings}
                    deleteConfirmItem={deleteConfirmItem}
                    arrayConfirmItems={dashboardStore.agreementConfirmLists}
                    arraySelectItems={dashboardStore.agreementSelectLists}
                    onDragStart={dashboardStore.onDragStart}
                    onDragOver={dashboardStore.onDragOver}
                    onDrop={dashboardStore.onDrop}
                    addToConfirmList={addConfirmList}
                    removeConfirmList={removeConfirmArr}
                    setSelectedChecked={setSelectChecked}
                    currentArray={dashboardStore.agreementConfirmLists}
                  />
                }
                <NotificationAlerts
                  sucsess={{text:'Настройки успешно сохранились!', open:showAlert, onClose:()=>setShowAlert(false)}}
                  error={{text:errorAlert ? errorAlert : '', open:errorAlert ? true : false, onClose:()=>setErrorAlert(null)}}
                />
              </>
            }
          </Box>
        }
      </>
    </Box>
  )
})