import {FC} from 'react'
import {Box, Menu, MenuItem, Typography} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {IManagerTextSelect} from "./IManagerTextSelect";
import { ExpandLess } from '@mui/icons-material';
import { CustomIconButton } from '../../../../../../../../components/UI/Buttons/CustomIconButton/CustomIconButton';

export const ManagerTextSelect:FC<IManagerTextSelect> = (props) => {
  const {
    title,
    anchorEl,
    onClose,
    onOpen,
    onChange,
    list,
    value
  } = props

  const open = Boolean(anchorEl)

  return (
    <>
      <Box
        color={'secondary'}
        onClick={onOpen}
        sx={{
          display: 'flex',
          alignItems:'center'
        }}
      >:
      <Typography
        sx={{
          color: 'black',
          '&:hover':{
            textDecorationStyle:'dashed',
          },
          fontWeight: 'bold',
          ml: 0.5
        }}
      >
        {title}
        </Typography>
        <CustomIconButton>{open ? <ExpandLess/> : <ExpandMoreIcon/>}</CustomIconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        {list.map(item=>
          <MenuItem
            key={item.value}
            value={item.value}
            selected={item.value === value}
            onClick={()=>onChange(item.value)}
          >
            {item.name}
          </MenuItem>)
        }
      </Menu>
    </>
  )
}