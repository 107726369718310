import {ITemplate} from "../../../../../../../../services/payments/IPaymentsResponse";
import {RequestActionsPanel} from "../../../../../../../../components/RequestActionsPanel/RequestActionsPanel";
import {useTemplateProcessor} from "../../../../../../../../hooks/useTemplateProcessor";
import {PaymentPayRequestForm} from "../PaymentPayRequestForm/PaymentPayRequestForm";


interface Props {
  template:ITemplate
  text:string
  titleModal:string
  disabled?:boolean
}

export const TemplateProcessorPays=({template, text , titleModal, disabled}:Props)=>{

  const {
    templateText,
    replaceTemplateVariables,
    clearFormData,
    formData,
    handleChange
  }
    = useTemplateProcessor(template)

  const copyText= replaceTemplateVariables(templateText,formData)

  return(
    <RequestActionsPanel subContent={<PaymentPayRequestForm formData={formData} handleChange={handleChange}/>}
                         text={text}
                         copyText={copyText}
                         clearData={clearFormData}
                         titleModal={titleModal}
                         disabled={disabled}
    />
  )
}