import { IUserApprovments } from "../../../services/agreements/IAgreementsResponse";

export const getAgreementsTableItem = (userApprovments:IUserApprovments[], agreementId:number):Array<any> => {
  const tableItems:any[] = userApprovments?.map(item=>{
    
    const newItem = {
      name:`${item.user.firstName} ${item.user.lastName}`,
      comment:item.comment,
      approvedStatus: item.approvedStatus,
      id:agreementId,
      userId:item.user.id,
      decisionDate:item.decisionDate
    }
    return newItem
  })

  return tableItems
}