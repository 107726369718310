import {FC} from 'react'
import { ICommentItem } from './ICommentItem'
import { Box, Divider, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import AllUsersStore from '../../../../../../../store/AllUsers.store'
import { IUser } from '../../../../../../../entities/user/models/IUser'
import { getDatePlusCustomTime } from '../../../../../../../shared/functions/functions'

export const CommentItem:FC<ICommentItem> = observer((props) => {
  const {
    channelId,
    id,
    postedAt,
    postedById,
    text,
    type,
    showDivider
  } = props

  const user:IUser | undefined = AllUsersStore.users?.find(user=>user.id ===  postedById)

  return (
    <Box sx={{mb:0.5}}>
      <Typography fontWeight={'bold'}>{getDatePlusCustomTime({value:postedAt, format:'DD.MM.YY'})} {user?.firstName} {user?.lastName}:</Typography>
      <Typography sx={{lineBreak:'anywhere'}}>
        {text}
        <Typography variant='subtitle2' sx={{float:'right', ml:'4px', mt:'4px', fontWeight:'bold'}}>
          {getDatePlusCustomTime({value:postedAt, format:'HH:mm'})}
        </Typography>
      </Typography>
      
      {showDivider ? <Divider sx={{backgroundColor:'#fff', mt:0.5}}/> : null}
    </Box>
  )
})