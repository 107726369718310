import dayjs from "dayjs";
import { METRIC_GROUP_BY } from "../../services/metrics/IMetricsRequest";
import { CHANNEL_CARD_OPEN } from "../constants/searchParams";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

interface middleColorOption {
  green: {
    from: number;
    before: number;
    color: string;
  };
  orange: {
    from: number;
    before: number;
    color: string;
  };
  red: {
    from: number;
    before: number;
    color: string;
  };
  none?: {
    from: number;
    before: number;
    color: string;
  };
  value: number;
}

export const getTableCellColor = (option: middleColorOption): string => {
  let currentColor = "";

  const { value } = option;

  const arrayColorOptions = [...Object.entries(option)].filter((group) => group[0] !== "value");

  arrayColorOptions.map((group) =>
    value >= group[1].from && value <= group[1].before ? (currentColor = group[1].color) : group
  );

  return currentColor;
};

export const priceTransormFormat = (
  initPrice: string | number,
  isShowCurrecny?: boolean,
  showRemains?: boolean,
  showMinimumRemains?: boolean
): string => {
  const price = +initPrice;
  const formatter = price.toLocaleString("ru-RU", {
    maximumFractionDigits: showRemains === false ? 0 : 2,
    minimumFractionDigits: showMinimumRemains && price !== 0 ? 2 : 0,
  });

  if (isShowCurrecny === false) {
    return formatter;
  } else return formatter + " ₽";
};

export const getMetricOrderBy = (initDate: string, todayDate: string): METRIC_GROUP_BY => {
  const init = dayjs(initDate);
  const today = dayjs(todayDate);

  let order: METRIC_GROUP_BY;

  if (today.diff(init, "day") === 0) {
    order = METRIC_GROUP_BY.HOUR;
  } else if (today.diff(init, "day") > 0 && today.diff(init, "day") < 7) {
    order = METRIC_GROUP_BY.HOURS;
  } else if (today.diff(init, "day") > 7 && today.diff(init, "month") === 0) {
    order = METRIC_GROUP_BY.DAY;
  } else if (today.diff(init, "month") > 0 && today.diff(init, "month") < 4) {
    order = METRIC_GROUP_BY.WEEK;
  } else if (today.diff(init, "month") >= 4) {
    order = METRIC_GROUP_BY.MONTH;
  }

  return order!;
};

export const downloadExcel = (data: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", `${fileName}.xlsx`);
  document.body.appendChild(link);
  link.click();

  link.remove();
  URL.revokeObjectURL(link.href);
};

export const downloadWord = (data: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", `${fileName}.docx`);
  document.body.appendChild(link);
  link.click();

  link.remove();
  URL.revokeObjectURL(link.href);
};

export const transformSecToMin = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const sec = seconds - minutes * 60;

  const timeMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const secMinutes = sec < 10 ? `0${sec}` : sec;

  const timeString = `${timeMinutes}:${secMinutes}`;

  return timeString;
};

export interface IDatePlusCustomOptions {
  value: string;
  hours?: number;
  format?: string;
}

export const getDatePlusCustomTime = (options: IDatePlusCustomOptions): string => {
  const { value, hours = 5, format = "DD.MM.YYYY" } = options;

  return dayjs(value).utc().add(hours, "hours").format(format);
};

export interface IDateSendPlusCustomOptions {
  value: string;
  hours?: string;
  addHours?: number;
}

export const sendDatePlusCustomTime = (options: IDateSendPlusCustomOptions): string => {
  const { value, hours = "05:00", addHours = 5 } = options;

  return dayjs(value).utc().add(addHours, "hours").toISOString().replace("Z", `+${hours}`);
};

export const getCurrentDatePlusLocaleTime = (): string => {
  const date = new Date();
  const numDateTimeZome = date.getTimezoneOffset() * 60 * 1000;
  const msLocal = date.getTime() - numDateTimeZome;
  const iso = new Date(msLocal).toISOString();

  return iso;
};

export const checkNumberBoolean = (value: number | null | undefined): boolean => {
  if (value !== null && value !== undefined) {
    return true;
  } else return false;
};

export const formatDate = (dateString: string) => {
  return dayjs(dateString).format("DD.MM.YYYY");
};
