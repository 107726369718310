import {FC, useState} from 'react'
import { ICheckListItem } from './ICheckListItem'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { IUpdateStatusesRequest } from '../../../../../../../../services/integration/IntegrationsRequest'
import IntegrationsService from '../../../../../../../../services/integration/integrations.service'
import integrationTableStore from '../../../../../../../../store/integrationTable.store'
import { AxiosError } from 'axios'
import { purple_color } from '../../../../../../../../app/styles/variables'

export const CheckListItem:FC<ICheckListItem> = (props) => {
  const {
    field,
    id,
    title,
    setUpdateId,
    update_id,
    data,
    readOnly
  } = props

  const [value, setValue] = useState<boolean>(data[field])
  
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    setError(null)

    const value = event.target.checked

    const options:IUpdateStatusesRequest = {
      id,
      field,
      update_id,
      new_value:value as any
    }
    IntegrationsService.updateSimpleField(options)
      .then(res=>{

        integrationTableStore.updateSimpleField(id, res.data.update_id, field, value)
        
        setValue(value)

        setUpdateId(res.data.update_id)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setError(err?.response?.data?.message)
          setLoading(false)
        }
      })
    
  }

  return (
    <Box sx={{width:'fit-content'}}>
      <FormControlLabel
        disabled={readOnly || isLoading}
        control={
          <Checkbox
            checked={value}
            onChange={handleChange}
          />
        }
        label={
          <Typography 
            sx={{
              '&:hover':{
                color:purple_color
              }
            }}
          >
            {title}
          </Typography>
        }
      />
    </Box>
  )
}
