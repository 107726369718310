import { FC, useState } from 'react'
import { IChannelAsidePrice } from './IChannelAsidePrice'
import { AsidePriceEdit } from '../../../TableAsidePage/ui/AsidePriceEdit/AsidePriceEdit'
import { IUpdateStatusesRequest } from '../../../../services/integration/IntegrationsRequest'
import { AxiosError } from 'axios'
import channelTableStore from '../../../../store/channelTable.store'
import ChannelService from '../../../../services/channel/channel.service'
import { NotificationAlerts } from '../../../../components/NotificationAlerts/NotificationAlerts'

export const ChannelAsidePrice:FC<IChannelAsidePrice> = (props) => {
  const {
    id,
    field_code,
    title,
    update_id,
    setPrice,
    price,
    onChangeUpdateId,
    isPercent,
    placeholder,
    readOnly,
    onChangePredict
  } = props

  const [isEdit, setEdit] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const updateInitPrice = (value:string) => {
    setLoading(true)
    setError(null)

    const option:IUpdateStatusesRequest = {
      field:field_code,
      id,
      new_value:value,
      update_id
    }
    ChannelService.updatePrice(option)
      .then(res=>{
        // console.log(res, 'res update channel init price')
        setPrice(+value)
        channelTableStore.changeField({id, field:field_code, new_value:value, update_id:res.data.update_id})

        if(onChangeUpdateId){
          onChangeUpdateId(res.data.update_id)
        }
        
        if(onChangePredict){
          onChangePredict(id, field_code, +value, res.data)
        }

        setLoading(false)
        setEdit(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError) {
          // console.log(err, 'change price err')
          setLoading(false)
          setError(err?.response?.data?.message)
        }
      })
  } 

  return (
    <>
      <AsidePriceEdit
        open={isEdit}
        onClose={()=>setEdit(false)}
        onOpen={()=>setEdit(true)}
        title={title}
        price={price}
        initValue={price} 
        submitPrice={updateInitPrice}
        isEditMode
        isLoading={isLoading}
        tooltipPlacement='right'
        isPercent={isPercent}
        placeholder={placeholder}
        readOnly={readOnly}
      />
      <NotificationAlerts
        error={{text:isError ? isError : '', open:isError ? true : false, onClose:()=>setError(null)}}
      />
    </>
  )
}
