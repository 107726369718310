import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { RenderFiltersList } from "../../../../components/Filters/components/RenderFiltersList/RenderFiltersItem";
import { CancelButton } from "../../../../components/UI/Buttons/CancelButton/CancelButton";
import { CustomButton } from "../../../../components/UI/Buttons/CustomButton/CustomButton";
import { CustomIconButton } from "../../../../components/UI/Buttons/CustomIconButton/CustomIconButton";
import { ICustomFilter } from "./ICustomChannelFilter";

export const CustomChannelFilter: FC<ICustomFilter> = observer((props) => {
  const { setFilters, reset, filters, title } = props;

  const [isFiltersOpen, setIsFilterOpen] = useState(false);

  // костыль на ререндер, вместо очищения полей
  const [key, setKey] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const onSubmit = () => {
    const searchParamsObj = Object.fromEntries(searchParams.entries());
    setIsFilterOpen(false);
    setFilters(searchParamsObj);
  };

  const resetFilters = () => {
    reset();
    setSearchParams(new URLSearchParams());
    setKey((prev) => prev + 1);
  };

  return (
    <>
      <Accordion
        elevation={0}
        key={key}
        expanded={isFiltersOpen}
        disableGutters
        TransitionProps={{ timeout: 0 }}
        sx={{
          mb: 0,
          border: "none",
          borderRadius: "16px",
          "&.MuiAccordion-root": { borderRadius: "16px" },
          boxShadow: "0px 0px 8px 0px rgba(34, 60, 80, 0.2)",
          "&::before": {
            display: "none",
          },
        }}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "start", sm: "center" }}
          justifyContent={"space-between"}
          sx={{ pl: { xs: 1, sm: 2 }, pr: { xs: 1, sm: 2 } }}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography variant="h5" sx={{ display: { xs: "none", sm: "inline-block" } }}>
              {title}
            </Typography>
            <AccordionSummary
              expandIcon={
                <CustomIconButton>
                  <ExpandMore fontSize="medium" />
                </CustomIconButton>
              }
              onClick={() => setIsFilterOpen((prev) => !prev)}
              sx={{ pr: 1, pl: 1, width: "180px", "&:hover": { color: "#7C64D9" } }}>
              <Typography>{isFiltersOpen ? "Свернуть фильтр" : "Развернуть фильтр"}</Typography>
            </AccordionSummary>
          </Stack>
        </Stack>
        <Divider />
        <AccordionDetails sx={{ p: 1, pb: 2 }}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xl: "repeat(5, 1fr)",
                lg: "repeat(4,1fr)",
                md: "repeat(3,1fr)",
                sm: "repeat(2,1fr)",
                xs: "repeat(1,1fr)",
              },
              rowGap: 1,
              columnGap: 2,
              pl: 1,
              pr: 1,
              overflow: "auto",
              maxHeight: { xs: "300px", md: "450px" },
              pb: 1,
            }}>
            <RenderFiltersList filters={filters} />
          </Box>
          <Divider sx={{ margin: "0 -8px" }} />
          <Box
            sx={{
              mt: 2,
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "min-content 1fr min-content" },
              columnGap: 1,
              rowGap: { xs: 1, sm: 0 },
              alignItems: "start",
              overflow: "auto",
              maxHeight: 82,
              pr: 1,
            }}>
            <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
              <CustomButton variant="contained" onClick={onSubmit}>
                Применить
              </CustomButton>
              <CancelButton variant="outlined" onClick={resetFilters}>
                Сбросить
              </CancelButton>
            </Stack>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
});
