import {FC, useState} from 'react'
import { IMotivationInputCoef } from './IMotivationInputCoef'
import { MotivationEditField } from '../MotivationEditField/MotivationEditField'
import { AxiosError } from 'axios'
import { IMotivationRoiEditRequest } from '../../../../../../../../services/motivation/IMotivationRequest'
import MotivationService from '../../../../../../../../services/motivation/motivation.service'
import dashboardStore from '../../../../../../../../store/dashboard.store'
import { IMotivationRoiCoef } from '../../../../../../../../services/motivation/IMotivationResponse'

export const MotivationInputCoef:FC<IMotivationInputCoef> = (props) => {
  const {
    initValue,
    id,
    motivationType,
    month,
    user,
    year,
    cellId,
    readOnly = false,
    isRoiCoefsBigChannels
  } = props
  
  const [isEdit, setEdit] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const onSubmit = (value:string) => {
    setLoading(true)
    setError(null)

    const option: IMotivationRoiEditRequest = {
      id:cellId,
      propertyType:isRoiCoefsBigChannels ? 'coefBigChannel' : 'coef',
      value:+value,
    }
    MotivationService.edit(option)
      .then(res=>{
        // console.log(res, 'res create Coef')
        if(!isRoiCoefsBigChannels){
          dashboardStore.setEditReleaseCreateCoef(id, cellId, res.data as IMotivationRoiCoef)
        } else {
          dashboardStore.setEditReleaseRoiCoefsBigChannels(id, cellId, res.data as IMotivationRoiCoef)
        }
        
        setEdit(false)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err create Coef')
          setError(err?.response?.data?.message)
          setLoading(false)
        }
      })
  }

  return (
    <MotivationEditField
      initValue={initValue}
      isEdit={isEdit}
      setEdit={setEdit}
      isError={isError}
      isLoading={isLoading}
      onSubmit={onSubmit}
      readOnly={readOnly}
      sxProps={{maxWidth:'100px'}}
    />
  )
}