import { FC } from 'react'
import { IAsideTableStatistic } from './IAsideTableStatistic'
import { TableDefault } from '../../../../components/Table/TableDefault'
import { TableItem } from '../../../../components/Table/ui/TableItem'
import { IAsideTableStatisticField } from './models/IAsideTableStatisticField'

export const AsideTableStatistic:FC<IAsideTableStatistic> = (props) => {
  const {
    id,
    update_id,
    onChangeUpdateId,
    rows,
    dataField,
  } = props

  const createItem = (fields:IAsideTableStatisticField[]):Object => {
    const item:any = {}

    for(let property of fields) {
      item[property.code] = property.value
    }

    return item
  }

  return (
    <TableDefault maxWidth='100%' tableRow={rows} showBorderRadius={false}>
      <TableItem cells={rows} id={id} onChangeUpdateId={onChangeUpdateId} update_id={update_id} {...createItem(dataField)} sx={{p:1, pb:1, pt:1}}/>
    </TableDefault>
  )
}