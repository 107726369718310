import { toJS } from "mobx";
import { IGoodCategoryStatsResponse, IGoodsCategoriesSummaryResponse } from "../../../../../../../services/statistics/IStatisticsResponse";
import { ILayoutResponse } from "../../../../../../../services/layout/layoutResponse";

interface IGoodCategoryTypeStatsRow extends IGoodCategoryStatsResponse {
  type: {
    type: { code: string };
    name: string;
  };
}

export const getGoodsCategoryRows = (stats:IGoodsCategoriesSummaryResponse, channelData:ILayoutResponse['channelTypes']):IGoodCategoryTypeStatsRow[] => {
  const keyRows:string[] = ['released', 'all', ...Object.keys(stats.stats)?.filter(x=>x !== 'all' && x !== 'released')]

  const rows:IGoodCategoryTypeStatsRow[] = keyRows.map(key=>{
    const allStats:IGoodCategoryStatsResponse = {...toJS(stats.stats[key])}

    const item:IGoodCategoryTypeStatsRow = {
      type: {
        type: { code: key },
        name: key === 'all' ? 'План/прогноз' : key === 'released' ? 'Выпущено' : channelData[key]?.name || key
      },
      averageCPV:allStats.averageCPV || null,
      averageCR:allStats.averageCR || null,
      averageCRThrough:allStats.averageCRThrough || null,
      averageCTR:allStats.averageCTR || null,
      averageOrderPrice:allStats.averageOrderPrice || null,
      averageRoi:allStats.averageRoi || null,
      cnt:allStats.cnt || null,
      ordersCnt:allStats.ordersCnt || null,
      ordersSumm:allStats.ordersSumm || null,
      totalPrice:allStats.totalPrice || null,
      traffic:allStats.traffic || null,
      views:allStats.views || null,
      averageCheck: allStats.averageCheck || null
    }

    return item
  })

  return rows
}