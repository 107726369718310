import {FC, useState} from 'react'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material'
import { ISelectCheckbox } from './ISelectCheckbox';
import { useSearchParams } from 'react-router-dom';
import {purple_color} from "../../app/styles/variables";

export const SelectCheckbox:FC<ISelectCheckbox> = (props) => {
  const {list, searhParamName, label} = props

  const [searchParams, setSearchParams] = useSearchParams()

  const [selectedItems, setSelectedItems] = useState<string[]>(searchParams.get(searhParamName)?.split(',') || [])

  const handleChange = (event: SelectChangeEvent<typeof selectedItems>) => {
    const value = event.target.value

    setSelectedItems(typeof value === 'string' ? value.split(',') : value)

    searchParams.set(searhParamName, `${value}`)
    setSearchParams(searchParams)

    if(value.length === 0){
      searchParams.delete(searhParamName)
      setSearchParams(searchParams)
    }
  }

  return (
    <FormControl sx={{width: '100%'}}>
      <InputLabel id="demo-multiple-checkbox-label" size='small'>{label}</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        input={<OutlinedInput label={label} />}
        size='small'
        multiple
        value={selectedItems}
        onChange={handleChange}
        renderValue={(selected)=>{
          return list.filter(x=>selected.includes(x.value)).map(x=>x.name).join(', ')
        }}
        MenuProps={{PaperProps:{sx:{maxHeight:310}}}}
      >
        {list.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <Checkbox checked={selectedItems.indexOf(item.value) > -1} />
            <ListItemText sx={{'&:hover':{color:purple_color}}} primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
