import {FC, useState} from 'react'
import { IDeleteRoiTableItem } from './IDeleteRoiTableItem'
import { AlertModal } from '../../../../../../../../../../components/AlertModal/AlertModal'
import MotivationService from '../../../../../../../../../../services/motivation/motivation.service'
import { IMotivationChangeGoodsCategoryRequest } from '../../../../../../../../../../services/motivation/IMotivationRequest'
import { AxiosError } from 'axios'
import dashboardStore from '../../../../../../../../../../store/dashboard.store'
import {RemoveItemButton} from "../../../../../../../../../../components/UI/Buttons/RemoveItemButton/RemoveItemButton";

export const DeleteRoiTableItem:FC<IDeleteRoiTableItem> = (props) => {
  const {
    code,
    month,
    year,
    id,
    readOnly = false
  } = props

  const [isLoading, setLoading] = useState(false)
  const [isAlertOpen, setAlertOpen] = useState(false)

  const deleteItem = () => {
    if(month!==0 && year!==0){
      setLoading(true)

      const option: IMotivationChangeGoodsCategoryRequest = {
        code,
        month,
        year
      }
      MotivationService.deleteGoodsCategoryFromMotivation(option)
        .then(res=>{
          dashboardStore.deleteReleaseMotivationRoiRow(id)
          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            setLoading(false)
          }
        })
    }
  }

  return (
    <>
      <RemoveItemButton
        onClick={()=>setAlertOpen(true)}
        disabled={readOnly}
      />
      <AlertModal
        title='Внимание!'
        subtext='Вы действительно хотите удалить товарную категорию?'
        open={isAlertOpen}
        loading={isLoading}
        disabled={isLoading}
        onClose={()=>setAlertOpen(false)}
        onSubmit={deleteItem}
        colorSubmit='error'
        buttonCancelName='отмена'
        buttonSubmitName='Удалить'
      />
    </>
  )
}
