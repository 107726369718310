import {VariablesType} from "../../../../../../services/payments/IPaymentsResponse";
import {ChangeEvent} from "react";
import {Box, TextField, Typography} from "@mui/material";
import dayjs, {Dayjs} from "dayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/ru'
import {getDatePickerIconsStyle} from "../../../../../../components/UI/DateCustomInput/shared/getDatePickerIconsStyle";



interface Props {
  formData:VariablesType
  handleChange:(e: ChangeEvent<HTMLInputElement>)=>void
  handleChangeDate:(e: Dayjs,name:string)=>void
}

export const ServiceApplicationForm = (props:Props) => {

  const {formData, handleChange, handleChangeDate} = props

  return (
    <Box component="form">
      <Box mb={2} display="flex" flexWrap="wrap" alignItems="center" gap={1}>
        <Typography>Добрый день! Услуга по Приложению №</Typography>
        <TextField
          sx={{maxWidth: '100px'}}
          size={'small'}
          variant="outlined"
          name={'a'}
          value={formData['a'] || ''}
          onChange={handleChange}
        />
        <Typography>от</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
          <DatePicker
            value={dayjs(formData['b']) || ''}
            onChange={(date,context) => handleChangeDate(date as any,'b')}
            name="b"
            slotProps={{
              textField:{
                size:'small',
                placeholder:'месяц год'
              },
              ...getDatePickerIconsStyle()
            }}
            sx={{maxWidth: '130px'}}
          />
        </LocalizationProvider>
        <Typography>к Договору №</Typography>
        <TextField
          sx={{maxWidth: '100px'}}
          size={'small'}
          variant="outlined"
          name="c"
          value={formData['c'] || ''}
          onChange={handleChange}
        />
        <Typography>от</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
          <DatePicker
            value={dayjs(formData['d']) || ''}
            onChange={(date,context) => handleChangeDate(date as any,'d')}
            name="d"
            slotProps={{
              textField:{
                size:'small',
                placeholder:'месяц год'
              },
              ...getDatePickerIconsStyle()
            }}
            sx={{maxWidth: '130px'}}
          />
        </LocalizationProvider>
        <Typography>на сумму</Typography>
        <TextField
          sx={{maxWidth: '100px'}}
          size={'small'}
          variant="outlined"
          name="e"
          value={formData['e'] || ''}
          onChange={handleChange}
        />
        <Typography>рублей оказана с Вашей стороны. Просьба направить акт оказанных услуг от</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
          <DatePicker
            value={dayjs(formData['f']) || ''}
            onChange={(date,context) => handleChangeDate(date as any,'f')}
            name="f"
            slotProps={{
              textField:{
                size:'small',
                placeholder:'месяц год'
              },
              ...getDatePickerIconsStyle()
            }}
            sx={{maxWidth: '130px'}}
          />
        </LocalizationProvider>
        <Typography>г.</Typography>
      </Box>
    </Box>
  );
};