import {FC, useState} from 'react'
import { IAddReleaseField } from './IAddReleaseField'
import { Add } from '@mui/icons-material'
import { AddModalRelease } from './ui/AddModalRelease/AddModalRelease'
import { CustomIconButton } from '../../../../UI/Buttons/CustomIconButton/CustomIconButton'

export const AddReleaseField:FC<IAddReleaseField> = (props) => {
  const {id, readOnly = false, type} = props

  const [isShowAddRelease, setShowAddRelease] = useState(false)

  return (
    <>
      <CustomIconButton disabled={readOnly} onClick={!readOnly ? ()=>setShowAddRelease(true) : undefined}>
        <Add/>
      </CustomIconButton>
      {isShowAddRelease &&
        <AddModalRelease
				  type={type}
          id={id}
          open={isShowAddRelease}
          onClose={()=>setShowAddRelease(false)}
        />
      }
    </>
  )
}
