import {FC} from 'react'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { METRIC_GROUP_BY } from '../../../../../../services/metrics/IMetricsRequest'
import { IAsideMetricSelectComponent, IAsideMetricSelectItem } from './IAsideMetricSelect'

export const AsideMetricSelect:FC<IAsideMetricSelectComponent> = (props) => {
  const {value, onChange, isLoading = false} = props

  const filters:IAsideMetricSelectItem[] = [
    {value:METRIC_GROUP_BY.HOUR, name:'по часам'},
    {value:METRIC_GROUP_BY.HOURS, name:'по несколько часов'},
    {value:METRIC_GROUP_BY.DAY, name:'по дням'},
    {value:METRIC_GROUP_BY.WEEK, name:'по неделям'},
    {value:METRIC_GROUP_BY.MONTH, name:'по месяцам'},
  ]

  return (
    <FormControl disabled={isLoading}>
      <InputLabel id="select-label" size='small'>Детализация</InputLabel>
      <Select
        labelId="select-label"
        label='Детализация'
        size='small'
        value={value}
        onChange={(e:SelectChangeEvent<any>)=>onChange(e.target.value)}
      >
        {filters.map(item=>
          <MenuItem key={item.value} value={item.value}>
            <Typography>{item.name}</Typography>
          </MenuItem>)}
      </Select>
    </FormControl>
  )
}
