import {FC} from 'react'
import { IGuaranteeCheckboxField } from './IGuaranteeCheckboxField'
import { Checkbox } from '@mui/material'

export const GuaranteeCheckboxField:FC<IGuaranteeCheckboxField> = (props) => {
  const {checked} = props
  
  return (
    <Checkbox checked={checked} onChange={()=>''}/>
  )
}
