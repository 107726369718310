import {FC} from 'react'
import { IAnalyticChart } from './IAnalyticChart'
import { Box, Typography } from '@mui/material'
import Chart from "react-apexcharts";
import dayjs from 'dayjs';

export const AnalyticChart:FC<IAnalyticChart> = (props) => {
  const {data} = props

  const option:ApexCharts.ApexOptions = {
    xaxis:{
      categories:data?.series.map(item=>dayjs(+item.seriesDate * 1000).format('DD.MM.YY')),
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    colors:['#1e293b'],
    chart:{
      toolbar:{
        tools:{
          zoom:true,
          download:false,
          selection:false,
          zoomin:true,
          zoomout:true,
          pan:false,
          reset:true
        }
      }
    }
  }

  const series:any = [
    {
      name: 'всего',
      data: data?.series.map(item=>item.value)
    },
  ]

  return (
    <Box sx={{height:'100%'}}>
      {data && data?.series?.length > 0
        ? <Chart
            options={option}
            series={series}
            type='area'
            height={570}
          />
        : <Typography
            variant='h5'
            sx={{display:'flex', alignItems:'center', justifyContent:'center', height:'100%', mt:{xs:1, sm:0}}}
          >
            Недостаточно данных для отчета
          </Typography>
      }
    </Box>
  )
}