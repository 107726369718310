import { FC } from 'react'
import { Box, Stack } from '@mui/material'
import { FinancesOplata } from '../../../../../FinancesCreatePage/ui/FinancesOplata/FinancesOplata'
import { FinancesContrAgent } from '../../../../../FinancesCreatePage/ui/FinancesContrAgent/FinancesContrAgent'
import { FinancesPayments } from '../../../../../FinancesCreatePage/ui/FinancesPayments/FinancesPayments'
import { AddButton } from './ui/AddButton/AddButton'
import { observer } from 'mobx-react-lite'
import integrationPageStore from '../../../../../../store/integrationPage.store'
import { IFinancesPaymentItem } from '../../../../../FinancesCreatePage/ui/FinancesPayments/ui/FinancesPaymentItem/IFinancesPaymentItem'
import { ICreateOplata } from './ICreateOplata'
import { TotalPrice } from './ui/TotalPrice/TotalPrice'
import { CommentsCreate } from './ui/CommentsCreate/CommentsCreate'

export const CreateOplata:FC<ICreateOplata> = observer((props) => {
  const {
    readOnly,
    setPriceValue,
    setUpdateId,
    update_id,
    id,
    onChangeCpv,
    setCreatePayments,
  } = props

  const addPayment = (type:string | null) => {
    const pay:IFinancesPaymentItem = {
      id:Number(new Date()),
      direction: type === 'card' ? null : (integrationPageStore.counteragent?.id?.toString() || null),
      paymentType: 'bank',
      plannedPaidAt:null,
      source:'ip-bokova',
      totalSumm:integrationPageStore.pays?.length <= 1 ? integrationPageStore.totalSumm : null,
      cardOrPhone:null,
      fio:null,
      paymentLink:null,
      selfEmployedCheck:null
    }

    integrationPageStore.addPays(pay, false)
  }

  return (
    <Box
      sx={{
        display:'grid',
        gridTemplateColumns:{xs:'100%', md:'70% calc(30% - 16px)', lg:'80% calc(20% - 16px)'},
        columnGap:2
      }}
    >
      <Box>
        <TotalPrice 
          readOnly={true} 
          setPriceValue={setPriceValue} 
          setUpdateId={setUpdateId} 
          update_id={update_id}
          id={id}
          onChangeCpv={onChangeCpv}
        />
        <Box 
          sx={{
            mt:2,
            display:'grid', 
            gridTemplateColumns:{xs:'100%', lg:'1fr 1fr'}, 
            rowGap:2, 
            columnGap:2
          }}
        >
          <Stack spacing={2}>
            <FinancesOplata
              isEditMode
              releaseUserId={integrationPageStore.releaseUserId}
              setReleaseUserId={integrationPageStore.setReleaseUserId}
              plannedPaidAt={integrationPageStore.plannedPaidAt}
              setPlannedPaidAt={integrationPageStore.setPlannedPaidAt}
              disabled={readOnly}
            />
            <FinancesContrAgent
              isEditMode
              isShowEditIcon={false}
              counteragent={integrationPageStore.counteragent}
              integrationContacts={integrationPageStore.integrationContacts}
              setCounteragent={integrationPageStore.setCounteragent}
              sxContAgentWidth={{width:{xs:'100%', lg:'60%'}}}
              disabled={readOnly}
            />
          </Stack>
          <Box sx={{height:'fit-content'}}>
            <FinancesPayments
              isEditMode={!readOnly}
              isShowEditIcon={false}
              addPayment={addPayment}
              pays={integrationPageStore.pays}
              counteragent={integrationPageStore.counteragent || undefined}
              changeFieldPaysItem={integrationPageStore.changeFieldPaysItem}
              changePaymentType={integrationPageStore.changePaymentType}
              onDeletePayment={integrationPageStore.deletePays}
              showDelete
            />
            <Box sx={{display:{xs:'none', md:'block'}, mt:2, float:'right'}}>
              <AddButton integrationId={id} closeCreateMode={setCreatePayments}/>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{mt:{xs:2, md:0}}}>
        <CommentsCreate/>
        <Box sx={{display:{xs:'block', md:'none'}, mt:2, float:'right'}}>
          <AddButton integrationId={id} closeCreateMode={setCreatePayments}/>
        </Box>
      </Box>
    </Box>
  )
})