import {FC, useState} from 'react'
import { DeleteButton } from '../../../../../../components/UI/DeleteButton/DeleteButton'
import { IIntegrationDeleteBtn } from './IIntegrationDeleteBtn'
import { AxiosError } from 'axios'
import integrationTableStore from '../../../../../../store/integrationTable.store'
import IntegrationsService from '../../../../../../services/integration/integrations.service'
import { useSearchParams } from 'react-router-dom'
import { INTEGRATION_CARD_OPEN } from '../../../../../../shared/constants/searchParams'

export const IntegrationDeleteBtn:FC<IIntegrationDeleteBtn> = (props) => {
  const {
    id,
    update_id,
  } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const [isLoading, setLoading] = useState(false)

  const [isShowAlert, setShowAlert] = useState(false)

  const deleteIntegration = () => {
    setLoading(true)

    IntegrationsService.delete({integrationId:id, update_id})
      .then(res=>{
        integrationTableStore.delete(res.data.id)

        searchParams.delete(INTEGRATION_CARD_OPEN)
        setSearchParams(searchParams)

        setShowAlert(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          
        }
      })
      .finally(()=>{
        setLoading(false)
      })
  }


  return (
    <DeleteButton
      deleteButtonLabel={'удалить интеграцию'} 
      confirmationTitle={'Внимание!'} 
      subtext={'Вы действительно хотите удалить интеграцию?'}
      onDelete={deleteIntegration}
      isLoading={isLoading}
      onCloseAlert={()=>setShowAlert(false)}
      showAlert={isShowAlert}
      onOpenAlert={()=>setShowAlert(true)}
    />
  )
}
