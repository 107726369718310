import { makeAutoObservable } from "mobx";
import { IGoodItem } from "../components/Table/ui/Fields/GoodsField/ui/GoodItem/IGoodItem";
import ReferencesService from "../services/references/references.service";
import { AxiosError } from "axios";

class GoodsStore {
    
  goods:IGoodItem[] | null = null
  fix: string = ''

  constructor(){
    makeAutoObservable(this)
  }

  setGoods(goods:IGoodItem[]){
    this.goods = goods
  }

  addedGoods(good:IGoodItem){
    if(this.goods){
      this.goods.push(good)
    }
  }

  removeGoods(article:string){
    if(this.goods){
      this.goods = this.goods.filter(x=>x.article !== article)
    }
  }

  editGoods = (article:string, name:string) => {
    if(this.goods){
      this.goods = this.goods.map(x=>{
        if(x.article === article){
          return {...x, name}
        } else return x
      })
    }
  }

  setFixValue(newValue: string){
    this.fix = newValue
  }

  getGoodsItems(){
    ReferencesService.getAllGoods()
      .then(res=>{
        // console.log(res, 'res goods all')
        this.setGoods(res.data)
      })
      .catch(err=>{
        if(err instanceof AxiosError) {
          // console.log(err, 'err goods all')
        }
      })
  }

}

export default new GoodsStore()