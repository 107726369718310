import {FC} from 'react'
import { ISearchManagerSelect } from './ISearchManagerSelect'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

const allManagers = {name: 'Все менеджеры', value: 0}

export const SearchManagerSelect:FC<ISearchManagerSelect> = (props) => {
  const {
    content,
    initValue,
    handleChange,
    label,
    disabled = false,
    isAllManagers= false,
  } = props

  return (
    <FormControl fullWidth>
      <InputLabel id="select-label" size='small'>{label}</InputLabel>
      <Select
        labelId="select-label"
        value={initValue}
        onChange={handleChange}
        label={label}
        size='small'
        disabled={disabled}
      >
        {isAllManagers && [ allManagers, ...content.map(item => item)].map((item, index) => (
          <MenuItem key={item.value} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
        {!isAllManagers&&content.map(item=><MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)}
      </Select>
    </FormControl>
  )
}