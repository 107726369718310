import { FC } from 'react'
import { IAsideCardPrediction } from './IAsideCardPrediction'
import { TableItem } from '../../../../components/Table/ui/TableItem'
import { TableDefault } from '../../../../components/Table/TableDefault'
import { IStrategyField } from '../../../../strategy/interface/IStrategyField'
import { observer } from 'mobx-react-lite'
import integrationPageStore from '../../../../store/integrationPage.store'
import {priceTransormFormat} from "../../../../shared/functions/functions";
import {EmptyText} from "../../../../components/EmptyText/EmptyText";

export const AsideCardPrediction:FC<IAsideCardPrediction> = observer((props) => {
  const {
    data, 
    id, 
    update_id, 
    readOnly = false, 
    onChangeUpdateId
  } = props

  const tableRows:IStrategyField[] = [
    {code:'goodsCategory', title:'Товарная категория', immutable:false, isSimple:true},
    {code:'prediction_views', title:'Просмотры', immutable:false, isSimple:false, isPredictions:true},
    {code:'prediction_traffic', title:'Трафик', immutable:false, isSimple:false, isPredictions:true, readOnly:true},
    {code:'prediction_cpv', title:'CPV', immutable:false, isSimple:true, isMoneySimple:true, readOnly: true, isMoneyShowPredictionRemains:true},
    {code:'prediction_ctr', title:'CTR', immutable:false, isSimple:false, isPredictions:true, isPercent:true, isShowRemains:true},
    {code:'prediction_cr', title:'CR', immutable:false, isSimple:false, isPredictions:true, isPercent:true, isShowRemains:true},
    {code:'prediction_roi', title:'ROI', immutable:false, isSimple:false, isPredictions:true, isPercent:true, readOnly: true},
    {code:'prediction_orders', title:'Заказы', immutable:false, isSimple:false, isPredictions:true, readOnly: true},
    {title:'Средний чек', code: 'averageCheck', immutable: false, isSimple: true, },
  ]

  const predictionDate = {
    prediction_views:integrationPageStore.predictionViews || 0,
    prediction_traffic:integrationPageStore.predictionTraffic || 0,
    prediction_cpv:integrationPageStore.predictionCpv || 0,
    prediction_ctr:integrationPageStore.predictionCtr || 0,
    prediction_cr:integrationPageStore.predictionCr || 0,
    prediction_orders:integrationPageStore.predictionOrders || 0,
    prediction_roi:integrationPageStore.predictionRoi || 0,
    averageCheck: !integrationPageStore.averageCheck ? <EmptyText>Нет данных</EmptyText> :   priceTransormFormat(integrationPageStore.averageCheck.toString(), true, true, true),
    id,
    update_id,
    goodsCategory:data?.goodsCategory?.name
  }

  return (
      <TableDefault tableRow={tableRows} showBorderRadius={false} maxWidth='100%'>
        <TableItem
          cells={tableRows} 
          {...predictionDate}
          onChangeUpdateId={onChangeUpdateId}
          readonly={readOnly}
        />
      </TableDefault>
  )
})
