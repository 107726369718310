import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { IPageNotFound } from '../PageNotFound/IPageNotFound'

export const NotRightPage:FC<IPageNotFound> = (props) => {
  const {height} = props

  return (
    <Box 
      sx={{
        height: height ? height : '100vh', overflow:'hidden', display:'flex', alignItems:'center', justifyContent:'center'
      }}
    >
      <Typography variant='h4'>У вас недостаточно прав для просмотра данной страницы</Typography>
    </Box>
  )
}
