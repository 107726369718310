import { FC, useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import { NotificationAlerts } from '../../../../../../../../components/NotificationAlerts/NotificationAlerts'
import { IAddButton } from './IAddButton'
import PaymentsService from '../../../../../../../../services/payments/payments.service'
import { AxiosError } from 'axios'
import { IFinancePaymentsInsertRequest } from '../../../../../../../../services/payments/IPaymentsRequest'
import integrationPageStore from '../../../../../../../../store/integrationPage.store'
import { IFinancesPaymentItem } from '../../../../../../../FinancesCreatePage/ui/FinancesPayments/ui/FinancesPaymentItem/IFinancesPaymentItem'
import { transformPaysData } from '../../../../../../../FinanceCardPage/shared/dataList'
import userStore from '../../../../../../../../store/user.store'
import integrationTableStore from '../../../../../../../../store/integrationTable.store'

export const AddButton:FC<IAddButton> = (props) => {
  const {closeCreateMode, integrationId} = props

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string[] | null>(null)

  const createOplata = () => {
    setLoading(true)
    setError(null)

    const option: IFinancePaymentsInsertRequest = {
      integrations:integrationPageStore.integrationIds,
      pays:integrationPageStore.pays?.map(item=>{
        const newItem:IFinancesPaymentItem = {
          direction:item.direction,
          paymentType:item.paymentType,
          plannedPaidAt:item.plannedPaidAt,
          source:item.source,
          totalSumm:Number(item.totalSumm),
          cardOrPhone:item.cardOrPhone,
          fio:item.fio,
          paymentLink:item.paymentLink,
          selfEmployedCheck:item.selfEmployedCheck
        } as any
        return newItem
      }),
      releaseUserId:integrationPageStore.releaseUserId ? integrationPageStore.releaseUserId : userStore?.user?.id?.toString() as any,
      plannedPaidAt:integrationPageStore.plannedPaidAt as any || undefined,
      counteragent:integrationPageStore.counteragent || undefined,
      comment:integrationPageStore.comment || undefined,
    }
    PaymentsService.financePaymentsInsert(option)
      .then(res=>{
        // console.log(res, 'res oplata')

        integrationPageStore.setOplataId(res.data?.id|| null)
        integrationPageStore.setTotalSumm(res.data?.totalSumm || null)
        integrationPageStore.setReleaseUserId(res.data?.releaseUserId?.toString() || null)
        integrationPageStore.setPlannedPaidAt(res.data?.plannedPaidAt || null)
        integrationPageStore.setCounteragent(res.data?.counteragent || null)
        integrationPageStore.setContractBlock(res.data?.contractBlock || null)
        integrationPageStore.setCeatedAt(res.data?.createdAt || null)
        integrationPageStore.setPaymentType(res.data?.paymentType || null)
        integrationPageStore.setStatus(res.data?.status || null)
        integrationPageStore.setPays(transformPaysData(res.data?.pays || []))
        integrationPageStore.setPaidAt(res.data?.paidAt || null)
        integrationPageStore.setPostedComments(res.data?.posted_comments || null)
        integrationPageStore.setHasSelfEmployedCheck(res.data?.hasSelfEmployedCheck)
        integrationPageStore.setSelfEmployedCheckLink(res.data?.selfEmployedCheckLink)
        integrationPageStore.setActLink(res.data?.actLink)          
        integrationPageStore.setHasAct(res.data?.hasAct)
        integrationPageStore.setServiceProvided(res.data?.isServiceProvided)
        integrationPageStore.setHasSelfEmployedCheck(res.data?.hasSelfEmployedCheck)
        integrationPageStore.setSelfEmployedCheckLink(res.data?.selfEmployedCheckLink)
        integrationPageStore.setActLink(res.data?.actLink)          
        integrationPageStore.setHasAct(res.data?.hasAct)
        integrationPageStore.setServiceProvided(res.data?.isServiceProvided)
        integrationPageStore.setOrdInfoBlock(res.data?.ordInfo || null)

        if(integrationPageStore.plannedPaidAt){
          integrationTableStore.updatePaymentsDate(integrationId, res.data?.plannedPaidAt, 'plannedPaymentDate')
        }
        
        integrationTableStore.changeIntegrationPaymentStatus(integrationId, res.data?.status?.code || null)

        closeCreateMode()
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setError(err?.response?.data?.message)
          setLoading(false)
        }
      })
  
  }

  return (
    <>
      <Button 
        variant='contained' 
        onClick={createOplata}
        disabled={isLoading}
        endIcon={isLoading ? <CircularProgress sx={{maxHeight:20, maxWidth:20}}/> : null}
      >
        создать оплату
      </Button>
      <NotificationAlerts
        error={{text:Array.isArray(isError) ? isError?.join(', ') : isError || '', open:Boolean(isError), onClose:()=>setError(null)}}
      />
    </>
  )
}
