import { FC } from 'react'
import { IGoodSampleItemWrapper } from './IGoodSampleItemWrapper'
import { Box, Typography } from '@mui/material'

export const GoodSampleItemWrapper:FC<IGoodSampleItemWrapper> = (props) => {
  const {
    children,
    title,
    required = false
  } = props

  return (
    <Box 
      sx={{
        display:'grid', 
        gridTemplateColumns:{xs:'100%', sm:'50% 50%'}, 
        alignItems:'center'
      }}
    >
      <Typography>{title} {required ? <Typography component={'span'} color={'error'}>*</Typography> : null}</Typography>
      {children}
    </Box>
  )
}
