export const CHANNEL_MODAL_OPEN = 'open'

export const INTEGRATION_CARD_OPEN = 'integration_card'
export const CHANNEL_CARD_OPEN = 'channel_card'
export const CONTRACTOR_CARD_OPEN = 'contractor_card'
export const FINANCE_CARD_OPEN = 'finance_card'
export const PRODUCT_CARD_OPEN = 'product_card'

export const INTEGRATION_CARD_OPEN_REPEAT = 'integration_card_repeat'
export const CHANNEL_CARD_OPEN_REPEAT = 'channel_card_repeat'

export const GOODS_CREATE_PAGE_OPEN = 'goods_create'