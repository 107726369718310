import {FC, useEffect, useState} from 'react'
import { ITableSort } from '../models/ITableSort'
import { TableSortLabel } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

export const TableSort:FC<ITableSort> = (props) => {
  const {
    type,
    currentSortType,
    onChangeCurrentSortType,
    onClick,
    children,
    hideUrlSortCode
  } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const [sortType, setSortType] = useState<'asc' | 'desc'>(type === searchParams.get('sort') ? searchParams.get('direction') as 'asc' | 'desc' || 'desc' : 'desc')

  const changeSort = () => {
    onClick(type, sortType)
    onChangeCurrentSortType(type)
    setSortType(prev=>prev === 'desc' ? 'asc' : 'desc')
  }

  useEffect(()=>{
    if(type === searchParams.get('sort')){
      onClick(type, sortType === 'desc' ? 'asc' : 'desc')
      onChangeCurrentSortType(type)
    }
  },[])

  useEffect(()=>{

    if(type === currentSortType){
      
      searchParams.delete('sort')
      searchParams.delete('direction')

      searchParams.set('sort', type)
      searchParams.set('direction', sortType)

      if(hideUrlSortCode !== true){
        setSearchParams(searchParams)
      }
      
    }
    
  }, [sortType])

  useEffect(()=>{
    if(type !== currentSortType){
      
      if(type === searchParams.get('sort')){
        setSortType('asc')
      } else {
        setSortType('desc')
      }
    }
  }, [currentSortType, searchParams])

  return (
    <TableSortLabel
      active={type === currentSortType}
      direction={sortType}
      onClick={changeSort}
    >
      {children}
    </TableSortLabel>
  )
}