import {FC} from 'react'
import { IMotivationGoodsCategoryItem } from './IMotivationGoodsCategoryItem'
import { Stack, TableCell, TableRow, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import categoriesGoodsStore from '../../../../../../../../store/categoriesGoods.store'
import { priceTransormFormat } from '../../../../../../../../shared/functions/functions'

export const MotivationGoodsCategoryItem:FC<IMotivationGoodsCategoryItem> = observer((props) => {
  const {code, value} = props

  const currentGoodCategory = categoriesGoodsStore?.categories?.find(x=>x?.code === code)

  return (
    <TableRow>
      <TableCell sx={{p:1}}>
        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
          <Typography>{currentGoodCategory?.name}</Typography>
        </Stack>
      </TableCell>
      <TableCell sx={{p:1}}>
        <Typography>{priceTransormFormat(value, true)}</Typography>
      </TableCell>
    </TableRow>
  )
})