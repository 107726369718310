import {FC} from 'react'
import { ILastCommentField } from './ILastCommentField'
import {Box, Tooltip, Typography} from '@mui/material'
import {Chat} from "@mui/icons-material";
import { CommentItem } from './ui/CommentItem/CommentItem';
import { CustomIconButton } from '../../../../UI/Buttons/CustomIconButton/CustomIconButton';

export const LastCommentField:FC<ILastCommentField> = (props) => {
  const {
    id,
    text,
    comments,
  } = props

  return (
    <Box sx={{ display: 'flex', alignItems: 'center',gap:1}}>
      <Typography 
        noWrap 
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 150,
          width: 'fit-content'
        }}
      >
        {text}
      </Typography>
      <Tooltip
        slotProps={{tooltip:{sx:{background:'rgb(89,90,91)'}}}}
        placement='bottom-end'
        title={
          <Box>
            {comments.map((item, i, arr)=>
              item.text
                ? <CommentItem 
                    key={item.postedById + id + item.text} 
                    {...item}
                    showDivider={(i < comments?.length-1 && arr[i+1]?.text) ? true : false}
                  />
                : null
            )}
          </Box>
        }
      >
        <span>
          <CustomIconButton size='small'>
            <Chat fontSize='small'/>
          </CustomIconButton>
        </span>
      </Tooltip>
    </Box>
  )
}
