import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { AddCategoryForm } from './ui/AddCategoryForm/AddCategoryForm'
import { GoodsAndCategotyTable } from './ui/GoodsAndCategotyTable/GoodsAndCategotyTable'
import GoodsCategoriesService from '../../../../../../services/goodsCategories/goodsCategories.service'
import { AxiosError } from 'axios'
import rulesStore from '../../../../../../store/rules.store'

export const GoodsAndCategoryBlock = () => {

  const [isLoading, setLoading] = useState(true)

  const getAllCategoryGoods = () => {
    setLoading(true)

    GoodsCategoriesService.getAllGoodsCategories()
      .then(res=>{
        rulesStore.setGoodsCategories(res.data)
      })
      .catch(err=>{
        if(err instanceof AxiosError){

        }
      })
      .finally(()=>{
        setLoading(false)
      })
  }

  useEffect(()=>{
    getAllCategoryGoods()
  },[])

  return (
    <Box
      sx={{
        display: 'grid',
        height: '-webkit-fill-available',
        gridTemplateRows: isLoading ? 'repeat(3, min-content)' : 'repeat(3, auto)',
        width:{xs:'100%', md:'51%'}
      }}
    >
      <Typography variant='h6' textTransform={'uppercase'}>
        Добавление и редактирование товаров
      </Typography>
      <Box sx={{mt:2, mb:4}}>
        <AddCategoryForm/>
      </Box>
      {isLoading
        ? <Typography variant='h6'>Идёт загрузка таблицы...</Typography>
        : <GoodsAndCategotyTable/> 
      }
    </Box>
  )
}
