import { makeAutoObservable } from "mobx";
import { IChannelCreateAccount, IChannelCreateComment } from "../services/channel/IChannelRequest";
import { IChannelAudienceRequest } from "../pages/ChannelCardPage/ui/ChannelAudience/shared/dataList";
import { getAudienceDataOnChannelType } from "../pages/ChannelCardPage/ui/ChannelAudience/shared/functions";
import dayjs from "dayjs";

class CreateChannelStore {

  name:string = ''
  contact:string | null = null
  agency:string | null = null
  type:string = ''
  theme:string | null = null
  foundBy:number | null = null
  dateFound:string | null = dayjs().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
  dateGiven:string | null = null
  statisticDate:string | null = null

  advertiser:string | null = null
  searchSystem:string | null = null

  link:string = ''
  initialPrice:number | null = null
  commission:number | null = null
  viewsPredict:number | null = null
  audience:number | null = null
  ctrPredict:number | null = null
  crSite:number | null = null

  man:number | null = null
  woman:number | null = null
  targetAudience:number | null = null
  "13-17":number | null = null
  "18-24":number | null = null
  "25-34":number | null = null
  "35-44":number | null = null
  "45-54":number | null = null
  "55-64":number | null = null
  "65_plus":number | null = null

  ru:number | null = null
  ua:number | null = null
  kz:number | null = null
  by:number | null = null
  subs:number | null = null
  notSubs:number | null = null
  fromPhone:number | null = null
  fromPC:number | null = null

  er:number | null = null
  err:number | null = null

  accounts:IChannelCreateAccount[] = []

  posted_comments:IChannelCreateComment[] = []

  isSaveError:string[] | null = null

  editAudienceData:IChannelAudienceRequest | null = null

  editAllAge:number | null = null

  constructor(){
    makeAutoObservable(this)
  }

  changeFieldEditAudience = (code:string, value:string) => {
    if(this.editAudienceData){
      
      this.editAudienceData = {
        ...this.editAudienceData,
        [code]:value
      }

      if(code==='13-17' || code==='18-24' || code==='25-34' || code==='35-44' || code==='45-54' || code==='55-64' || code==='65_plus'){

        this.editAllAge = (Number(this.editAudienceData?.["13-17"]) || 0) + (Number(this.editAudienceData['18-24']) || 0) +  (Number(this.editAudienceData['25-34']) || 0) +  (Number(this.editAudienceData['35-44']) || 0) +  (Number(this.editAudienceData['45-54']) || 0) + (Number(this.editAudienceData['55-64']) || 0) +  (Number(this.editAudienceData['65_plus']) || 0)
        
      }

    }
  }

  setName = (value:string) =>{
    this.name = value
  }

  setContact = (value:string) =>{
    this.contact = value
  }

  setAgency = (value:string) =>{
    this.agency = value
  }

  setType = (value:string) =>{
    this.type = value

    this.targetAudience = null
    this.editAudienceData = null
    this.editAudienceData = getAudienceDataOnChannelType(null, true, value)
  }

  setTheme = (value:string) =>{
    this.theme = value
  }

  setFoundBy = (id:string)=> {
    this.foundBy = +id
  }

  setDateFound = (value:string) =>{
    this.dateFound = value
  }

  setDateGiven = (value:string) =>{
    this.dateGiven = value
  }
  setStatisticDate = (value:string) =>{
    this.statisticDate = value
  }

  setLink = (value:string) =>{
    this.link = value
  }

  setInitialPrice = (value:string) =>{
    this.initialPrice = +value
  }

  setCommission = (value:string) =>{
    this.commission = +value
  }

  setViewsPredict = (value:string) =>{
    this.viewsPredict = +value
  }

  setMan = (value:string) =>{
    this.man = +value
  }

  setWoman = (value:string) =>{
    this.woman = +value
  }

  setTargetAudience = (value:string) =>{
    this.targetAudience = +value
  }

  setAudience = (value:string) =>{
    this.audience = +value
  }

  setCtrPredict = (value:string) =>{
    this.ctrPredict = +value
  }

  setCrSite = (value:string) =>{
    this.crSite = +value
  }

  set13to17 = (value:string) =>{
    this["13-17"] = +value
  }

  set18to24 = (value:string) =>{
    this["18-24"] = +value
  }

  set25to34 = (value:string) =>{
    this["25-34"] = +value
  }

  set35to44 = (value:string) =>{
    this["35-44"] = +value
  }

  set45to54 = (value:string) =>{
    this["45-54"] = +value
  }

  set55to64 = (value:string) =>{
    this["55-64"] = +value
  }

  set65Plus = (value:string) =>{
    this["65_plus"] = +value
  }

  setRu = (value:string) =>{
    this.ru = +value
  }

  setUa = (value:string) =>{
    this.ua = +value
  }

  setKz = (value:string) =>{
    this.kz = +value
  }

  setBy = (value:string) =>{
    this.by = +value
  }

  setSubs = (value:string) =>{
    this.subs = +value
  }
  setErr = (value:string) =>{
    this.err = +value
  }
  setEr = (value:string) =>{
    this.er = +value
  }

  setNotSubs = (value:string) =>{
    this.notSubs = +value
  }

  setFromPhone = (value:string) =>{
    this.fromPhone = +value
  }

  setFromPC = (value:string) =>{
    this.fromPC = +value
  }

  setAccounts = (item:IChannelCreateAccount) =>{
    this.accounts.push(item)
  }

  setRemoveAccounts = (value:string) =>{
    this.accounts = this.accounts?.filter(item=>item.value !== value)
  }

  setPostedComment = (item:IChannelCreateComment) =>{
    this.posted_comments.push(item)
  }

  setPostedRemoveComment = (value:string) =>{
    this.posted_comments = this.posted_comments?.filter(item=>item.text !== value)
  }

  setAdvertiser = (value:string) => {
    this.advertiser = value
  }

  setSearchSystem = (value:string) => {
    this.searchSystem = value
  }

  setSaveError = (value:string[] | null) => {
    this.isSaveError = value
  }

  clearStore = () => {
    this.name = ''
    this.contact = null
    this.agency = null
    this.type = ''
    this.theme = null
    this.foundBy = null
    this.dateFound = dayjs().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    this.dateGiven = null

    this.advertiser = null
    this.searchSystem = null

    this.link = ''
    this.initialPrice = null
    this.commission = null
    this.viewsPredict = null
    this.audience = null
    this.ctrPredict = null
    this.crSite = null

    this.man = null
    this.woman = null
    this.targetAudience = null
    this["13-17"] = null
    this["18-24"] = null
    this["25-34"] = null
    this["35-44"] = null
    this["45-54"] = null
    this["55-64"] = null
    this["65_plus"] = null

    this.ru = null
    this.ua = null
    this.kz = null
    this.by = null
    this.subs = null
    this.err = null
    this.er = null
    this.notSubs = null
    this.fromPhone = null
    this.fromPC = null

    this.er = null
    this.err = null

    this.accounts = []
    this.posted_comments = []

    this.isSaveError = null

    this.editAudienceData = null
    this.editAllAge = null

  }
}

export default new CreateChannelStore()