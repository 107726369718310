import { makeAutoObservable } from "mobx"
import { IChannelAudienceRequest } from "../pages/ChannelCardPage/ui/ChannelAudience/shared/dataList"

class ChannelCardStore {
  chatOpen:boolean = true

  initialPrice:number | null = null

  price:number | null = null

  crSiteState:number | null = null

  minRequests:number | null = null

  minOrders:number | null = null

  roiPredict:number | null = null

  viewsPredict:number | null = null

  cpvPredict:number | null = null

  ctrPredict:number | null = null

  isBanned:boolean | null = null

  cpvNewPredict:number | null = null

  roiNewPredict:number | null = null

  trafficPredict:number | null = null

  targetAudience:number | null = null

  audienceData:IChannelAudienceRequest | null = null

  editAudienceData:IChannelAudienceRequest | null = null

  allAge:number | null = null
  
  editAllAge:number | null = null

  statisticDate:string | null = null

  channelSize:string | null = null

  isPaymentOnCard:boolean | null = null

  constructor(){
    makeAutoObservable(this)
  }

  setPaymentOnCard = (isPaymentOnCard:boolean | null) => {
    this.isPaymentOnCard = isPaymentOnCard
  }

  setChatOpen = (chatOpen:boolean) => {
    this.chatOpen = chatOpen
  }

  setStatisticDate = (statisticDate:string | null) => {
    this.statisticDate = statisticDate
  }

  setChannelSize = (channelSize:string | null) => {
    this.channelSize = channelSize
  }

  setAllAge = (allAge:number | null) => {
    this.allAge = allAge
  }

  setAudienceData = (data:IChannelAudienceRequest | null) => {
    this.audienceData = data 
  }

  setTrafficPredict = (value:number | null) => {
    this.trafficPredict = value
  }

  setTargetAudience = (targetAudience:number | null) => {
    this.targetAudience = targetAudience
  }

  setPrice = (value:number | null) => {
    this.price = value
  }

  setCpvNewPredict = (value:number | null) => {
    this.cpvNewPredict = value
  }

  setRoiNewPredict = (value:number | null) => {
    this.roiNewPredict = value
  }

  setBanned = (value:boolean | null) => {
    this.isBanned = value
  }

  setInitialPrice = (value:number | null) => {
    this.initialPrice = value
  }

  setCrSite = (value:number | null) => {
    this.crSiteState = value
  }
  setMinRequests = (value:number | null) => {
    this.minRequests = value
  }
  setMinOrders = (value:number | null) => {
    this.minOrders = value
  }
  setRoiPredict = (value:number | null) => {
    this.roiPredict = value
  }
  setViewsPredict = (value:number | null) => {
    this.viewsPredict = value
  }
  setCpvPredict = (value:number | null) => {
    this.cpvPredict = value
  }
  setCtrPredict = (value:number | null) => {
    this.ctrPredict = value
  }

  startEditAudience = () => {
    if(this.audienceData){
      this.editAudienceData = {...this.audienceData}
    }
    this.editAllAge = this.allAge
  }

  closeEditAudience = () => {
    this.editAudienceData = null
    this.editAllAge = null
  }

  changeFieldEditAudience = (code:string, value:string) => {
    if(this.editAudienceData){
      
      this.editAudienceData = {
        ...this.editAudienceData,
        [code]:value
      }

      if(code==='13-17' || code==='18-24' || code==='25-34' || code==='35-44' || code==='45-54' || code==='55-64' || code==='65_plus'){

        this.editAllAge = (Number(this.editAudienceData?.["13-17"]) || 0) + (Number(this.editAudienceData['18-24']) || 0) +  (Number(this.editAudienceData['25-34']) || 0) +  (Number(this.editAudienceData['35-44']) || 0) +  (Number(this.editAudienceData['45-54']) || 0) + (Number(this.editAudienceData['55-64']) || 0) +  (Number(this.editAudienceData['65_plus']) || 0)
        
      }

    }
  }

  onResetStore = () => {
    this.initialPrice = null
    this.crSiteState = null
    this.minRequests = null
    this.minOrders = null
    this.roiPredict = null
    this.viewsPredict = null
    this.cpvPredict = null
    this.ctrPredict = null
    this.isBanned = null
    this.cpvNewPredict = null
    this.roiNewPredict = null
    this.price = null
    this.trafficPredict = null
    this.audienceData = null
    this.editAudienceData = null
    this.targetAudience = null
    this.allAge = null
    this.editAllAge = null
    this.statisticDate = null
    this.channelSize = null
    this.isPaymentOnCard = null
  }

}

export default new ChannelCardStore()