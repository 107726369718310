import {FC, useState} from 'react'
import { IIntegrationLink } from './IIntegrationLink'
import {Box, Link, Stack, TextField, Tooltip, Typography} from '@mui/material'
import { NotificationAlerts } from '../../../../../../components/NotificationAlerts/NotificationAlerts'
import {Check, Clear} from '@mui/icons-material'
import IntegrationsService from '../../../../../../services/integration/integrations.service'
import { IUpdateStatusesRequest } from '../../../../../../services/integration/IntegrationsRequest'
import { AxiosError } from 'axios'
import { AlertModal } from '../../../../../../components/AlertModal/AlertModal'
import integrationTableStore from '../../../../../../store/integrationTable.store'
import {RemoveItemButton} from "../../../../../../components/UI/Buttons/RemoveItemButton/RemoveItemButton";
import {EmptyText} from "../../../../../../components/EmptyText/EmptyText";
import { CustomIconButton } from '../../../../../../components/UI/Buttons/CustomIconButton/CustomIconButton'
import { EditIconButton } from '../../../../../../components/UI/Buttons/EditIconButton/EditIconButton'
import { CopyIconButton } from '../../../../../../components/UI/Buttons/CopyIconButton/CopyIconButton'

export const  IntegrationLink:FC<IIntegrationLink> = (props) => {
  const {
    link, 
    title, 
    tooltip = false,
    field,
    id,
    update_id,
    setUpdateId,
    setLink,
    deleteModalTitle,
    isEditMode
  } = props

  const [isDeleteShowModal, setDeleteShowModal] = useState(false)
  const [isDeleteLoading, setDeletedLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isEdit, setEdit] = useState(false)
  const [value, setValue] = useState<string | null>('')
  const [isDeleteError, setDeletedError] = useState<null | string>(null)

  const onDelete = () => {
    setDeletedLoading(true)
    setDeletedError(null)

    const options: IUpdateStatusesRequest = {
      id,
      field,
      new_value:null!,
      update_id
    }
    IntegrationsService.updateSimpleField(options)
      .then(res=>{
        setLink(null)
        setUpdateId(res.data.update_id)
        integrationTableStore.updateSimpleField(id, res.data.update_id, field, null)
        setDeleteShowModal(false)
        setDeletedLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setDeletedError(err?.response?.data?.message)
          setDeletedLoading(false)
        }
      })
  }

  const addLink = () => {
    setIsLoading(true)
    const options: IUpdateStatusesRequest = {
      id,
      field,
      new_value:value ? value : '',
      update_id
    }
    IntegrationsService.updateSimpleField(options)
      .then(res=>{
        setLink(res.data.qrCodeLink)
        setUpdateId(res.data.update_id)
        setEdit(false)
        setValue('')
        integrationTableStore.updateSimpleField(id, res.data.update_id, field, value)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setDeletedError(err?.response?.data?.message)
        }
      })
    .finally(()=>{
      setIsLoading(false)
    })
  }

  const closeEdit = () => {
    setEdit(false)
    setValue('')
  }

  return (
    <>
      {link &&
        <Stack
          direction={'row'}
          alignItems={'center'}
          sx={{display:'grid', gridTemplateColumns:'min-content 1fr'}}
        >
          <Typography noWrap>{title}</Typography>
          <Stack
            direction={'row'} 
            alignItems={'center'}
          >
            <CopyIconButton copyText={link}/>
            <Box sx={{display:'grid', gridTemplateColumns:'1fr'}}>
              {tooltip
                ? <Tooltip title={<Typography>{link}</Typography>}>
                    <Link
                      color={'secondary'} 
                      href={link} 
                      target='_blank' 
                      sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', }}
                    >
                      {link}
                    </Link>
                  </Tooltip>
                : <Link
                    color={'secondary'} 
                    href={link} 
                    target='_blank' 
                    sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', }}
                  >
                    {link}
                  </Link>
              }
            </Box>
            <RemoveItemButton onClick={()=>setDeleteShowModal(true)}/>
          </Stack>
        </Stack>
      }
      {!link &&
        <Stack direction={'row'} alignItems={'center'}>
          {!link && <Typography display={'flex'} gap={1}>{title}: {!isEdit && <EmptyText/>}</Typography>}
          {!link && isEditMode &&
            <Stack ml={1} direction={'row'} alignItems={'center'}>
              {!isEdit &&
                <EditIconButton size='small' onClick={()=>setEdit(true)}/>
              }
              {isEdit &&
                <Stack direction={'row'} alignItems={'center'} >
                  <TextField
                    placeholder={'Введите значение'}
                    size='small'
                    fullWidth
                    autoFocus
                    value={value}
                    onChange={(event)=>setValue(event.currentTarget.value)}
                  />
                  <Stack direction={'row'} alignItems={'center'} sx={{ml:0.5}}>
                    <CustomIconButton
                      disabled={isLoading}
                      size='small'
                      onClick={closeEdit}
                    >
                      <Clear fontSize='small'/>
                    </CustomIconButton>
                    <CustomIconButton disabled={isLoading} onClick={()=> value && addLink()} showBackground>
                      <Check fontSize='small'/>
                    </CustomIconButton>
                  </Stack>
                </Stack>
              }
          </Stack>
          }
        </Stack>
      }
      <NotificationAlerts
        error={{open:Boolean(isDeleteError), text:`${isDeleteError}`, onClose:()=>setDeletedError(null)}}
      />
      {isDeleteShowModal &&
        <AlertModal
          title='Внимание'
          subtext={deleteModalTitle}
          open={isDeleteShowModal}
          onClose={()=>setDeleteShowModal(false)}
          onSubmit={onDelete}
          loading={isDeleteLoading}
          disabled={isDeleteLoading}
          buttonCancelName='Отмена'
          buttonSubmitName='Удалить'
          colorSubmit='error'
        />
      }
    </>
  )
}