import {FC, useState} from 'react'
import { IBlocked } from './IBlocked'
import { AlertModal } from '../../../../components/AlertModal/AlertModal'
import { IconButton, Stack, TextField, Tooltip, Typography, } from '@mui/material'
import ChannelService from '../../../../services/channel/channel.service'
import { IChannelBlockRequest } from '../../../../services/channel/IChannelRequest'
import { AxiosError } from 'axios'
import channelTableStore from '../../../../store/channelTable.store'
import { IUpdateStatusesRequest } from '../../../../services/integration/IntegrationsRequest'
import { InfoOutlined } from '@mui/icons-material'
import { ModalInfo } from './ui/ModalInfo/ModalInfo'
import { CustomButton } from '../../../../components/UI/Buttons/CustomButton/CustomButton'

export const Blocked:FC<IBlocked> = (props) => {
  const {
    channelId,
    update_id,
    isBanned,
    onChangeUpdateId,
    readOnly,
    changeBanned,
  } = props

  const [banReasonValue, setBanReasonValue] = useState('')

  const [isShowAlert, setShowAlert] = useState(false)

  const [openModalInfo, setOpenModalInfo] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const submit = () => {
    setLoading(true)
    setError(null)

    const option: IChannelBlockRequest = {
      banReason:banReasonValue?.trim(),
      channelId,
      isBanned:isBanned ? false : true,
      updateId:update_id
    }
    ChannelService.channelBlock(option)
      .then(res=>{
        // console.log(res.data, 'change block channel')
        const option: IUpdateStatusesRequest = {
          field:'isBanned',
          id:channelId,
          new_value:res.data?.isBanned as any,
          update_id:res.data?.update_id
        }

        channelTableStore.changeField(option)

        changeBanned(res.data?.isBanned)
        onChangeUpdateId(res.data?.update_id)
        
        setBanReasonValue('')
        setShowAlert(false)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err change block')
          setError(err.response?.data?.message)
          setLoading(false)
        }
      })
  }

  const close = () => {
    setShowAlert(false)
    setBanReasonValue('')
  }

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
        <CustomButton
          variant='contained'
          size='small'
          onClick={()=>setShowAlert(true)}
          disabled={readOnly}
        >
          {!isBanned ? 'Добавить в ЧС' : 'Убрать из ЧС'}
        </CustomButton>
        {isBanned &&
          <Tooltip title='Информация'>
            <IconButton size='small' onClick={()=>setOpenModalInfo(true)}>
              <InfoOutlined fontSize='small'/>
            </IconButton>
          </Tooltip>
        }
      </Stack>
      {isShowAlert &&
        <AlertModal
          title={!isBanned ? `Заблокировать канал №${channelId}?` : `Разблокировать канал №${channelId}?`}
          open={isShowAlert}
          onClose={close}
          onSubmit={submit}
          buttonCancelName='закрыть'
          buttonSubmitName={!isBanned ? `Заблокировать` : `Разблокировать`}
          loading={isLoading}
          error={isError ? true : false}
          disabled={!isBanned ? banReasonValue.trim()==='' : false}
          subContent={
            <>
              {!isBanned
                ? <TextField
                    value={banReasonValue}
                    onChange={e=>setBanReasonValue(e.target.value)}
                    size='small'
                    fullWidth
                    placeholder='Введите причину блокировки'
                    autoFocus
                  />
                : <Typography variant='h6' sx={{fontWeight:'normal'}}>Это действие разблокирует канал</Typography>
              }
            </>
          }
        />
      }
      {openModalInfo &&
        <ModalInfo 
          open={openModalInfo} 
          onClose={()=>setOpenModalInfo(false)} 
          channelId={channelId}
        />
      }
    </>
  )
}
