import { useState } from "react";
import { Card, Stack, TextField, Typography, Box, CircularProgress, InputAdornment, IconButton } from '@mui/material';
import { AuthService } from "../../services";
import userStore from "../../store/user.store";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../shared/routes/routes";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CustomButton } from "../UI/Buttons/CustomButton/CustomButton";

export function LoginForm(){
  const navigate = useNavigate()
    
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
    
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setLoading] = useState(false)

  const handelSubmit =  async () => {
    setLoading(true)
    setError(null)

    try {
      const res = await AuthService.login(email, password)
      const data = res.data

      localStorage.setItem('token', data.token)
      userStore.setAuth(true)

      navigate(ROUTES.HOME)
      setLoading(false)
    } catch (err) {
      if (err instanceof AxiosError) {
        setError(err.response?.data.msg)
        setLoading(false)
      }
      // console.log(err, 'err asdpasd')
      setLoading(false)
    }    
  }

  const onKeyDownSend = (e:React.KeyboardEvent) => {
    if(e.code === "Enter" || e.code === "NumpadEnter"){
      handelSubmit()
    }
  }
    
  return (
    <Card variant="outlined" sx={{width:{xs:'300px', sm:'400px'}, p:4, boxShadow:'0px 0px 8px 0px rgba(34, 60, 80, 0.2)'}}>
      <Typography variant='h3' sx={{fontSize:{xs:'30px', sm:'2.5rem'}}}>Войти в систему</Typography>
      <Stack direction={'column'} sx={{mt:4,mb:3}} spacing={3}>
        <TextField
          value={email}
          onChange={e=>setEmail(e.target.value)}
          disabled={isLoading}
          placeholder="E-mail Адрес"
          onKeyDown={e=>onKeyDownSend(e)}
        />
        <TextField
          value={password}
          onChange={e=>setPassword(e.target.value)}
          disabled={isLoading}
          placeholder="Пароль"
          type={showPassword ? 'text' : "password"}
          onKeyDown={e=>onKeyDownSend(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={()=>setShowPassword(prev=>!prev)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Stack>
      <CustomButton
        variant="contained"
        onClick={handelSubmit}
        disabled={isLoading}
        fullWidth
        sx={{height:50}}
        endIcon={isLoading && <CircularProgress sx={{maxHeight:25, maxWidth:25}} />}
      >
        Войти
      </CustomButton>
      <Box sx={{mt:2, mb:-1}}>
        {error && <Typography color={'error'} alignItems={'center'}>{error}</Typography>}
      </Box>
    </Card>
  )
}