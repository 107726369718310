import {Box} from "@mui/material";
import {ResumePrePaymentCard} from "./ui/ResumePrePaymentCard/ResumePrePaymentCard";
import {PrePaymentTable} from "./ui/PrePaymentTable/PrePaymentTable";
import {INTEGRATION_CARD_OPEN} from "../../../../shared/constants/searchParams";
import {useSearchParams} from "react-router-dom";
import {TableAsidePage} from "../../../TableAsidePage/TableAsidePage";
import {PageNotFound} from "../../../PageNotFound/PageNotFound";
import prePaymentStore from "../../../../store/prePayment.store";

export const PrePaymentPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const integrationChannel = () => {
    searchParams.delete(INTEGRATION_CARD_OPEN)
    setSearchParams(searchParams)
  }

  return (
    <>
    {prePaymentStore.isPageNotFound ? <PageNotFound/> :
      <Box sx={{display:'grid', overflow:'hidden', gridTemplateRows: 'min-content 1fr', gridAutoRows:' min-content'}}>
        <ResumePrePaymentCard/>
        <PrePaymentTable/>
        {searchParams.get(INTEGRATION_CARD_OPEN) &&
				 <TableAsidePage integrationId={searchParams.get(INTEGRATION_CARD_OPEN)!} onClose={integrationChannel}/>
        }
      </Box>
    }
    </>
  )
}