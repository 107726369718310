import axios from 'axios'
import { URLS } from '../config'
import { AuthService } from '../../services'

const API_URL = URLS.gateway

const $users_api = axios.create({
  withCredentials: false,
  baseURL: API_URL,
})

$users_api.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  return config
}, (error) => Promise.reject(error))

$users_api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const result = await AuthService.refreshToken();
      if (result?.data.token) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${result?.data.token}`,
        };
        localStorage.setItem('token', result.data.token)
      }

      return axios(config);
    }
    return Promise.reject(error);
  }
)

export default $users_api