import { makeAutoObservable } from "mobx";
import { AxiosError } from "axios";
import ReferencesService from "../services/references/references.service";
import { IChannelCreateRequestSelect } from "../services/channel/IChannelRequest";

class SearchSystemStore {
  searchSystem:IChannelCreateRequestSelect[] | null = null

  constructor(){
    makeAutoObservable(this)
  }

  getSearchSystems(){
    ReferencesService.getSearchSystems()
      .then(res=>{
        // console.log(res, 'res all searchSystem')
        this.searchSystem = res.data
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err all searchSystem')
        }
      })
  }

}

export default new SearchSystemStore()