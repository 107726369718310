import { FC } from 'react'
import { IRenderReferenceFilter } from './IRenderReferenceFilter'
import { observer } from 'mobx-react-lite'
import { FilterSelectChip } from '../../../../UI/FilterSelectChip/FilterSelectChip'
import { FilterSkeleton } from '../../../../UI/FilterSkeleton/FilterSkeleton'

export const RenderReferenceFilter:FC<IRenderReferenceFilter> = observer((props) => {
  const {
    content,
    label,
    placeholder,
    referenceContentFn,
    referenceStore,
    searchParamName,
    limitTags = 3,
    showOptionLabelValue = false,
    isGoodsSelect
  } = props
  
  return (
    <>
      {referenceStore ? (
        <FilterSelectChip
          showOptionLabelValue={showOptionLabelValue}
          label={label}
          content={referenceContentFn(referenceStore)}
          searchParamName={searchParamName}
          placeholder={placeholder}
          limitTags={limitTags}
          isGoodsSelect={isGoodsSelect}
        />
      ) : (
        <FilterSkeleton />
      )}
    </>
  )
})