import {
  Autocomplete,
  Checkbox,
  Chip,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { purple_color } from "../../app/styles/variables";
import { IAutocompleteSelectCheckboxField } from "./IAutocompleteSelectCheckboxField";

export const AutocompleteSelectCheckboxField: FC<IAutocompleteSelectCheckboxField> = (props) => {
  const {
    list,
    label,
    onChange,
    placeholder,
    limitTags,
    error,
    loading,
    fullWidth,
    maxWidth,
    isTzSelect,
    value,
  } = props;

  const options = list.map((option) => ({
    ...option,
    category: option.category.name,
  }));

  const sortedList = (a: (typeof options)[number], b: (typeof options)[number]) => {
    if (a.category === b.category) {
      const isALatin = /^[A-Za-z]/.test(a.name);
      const isBLatin = /^[A-Za-z]/.test(b.name);

      if (isALatin && !isBLatin) return -1;
      if (!isALatin && isBLatin) return 1;

      return a.name.localeCompare(b.name);
    }
    return a.category.localeCompare(b.category);
  };

  const mappedValue = isTzSelect
    ? options.filter((option) => (value as string[])?.includes(option.article))
    : undefined;

  return (
    <Autocomplete
      fullWidth={fullWidth}
      onChange={(event, value) => onChange(value?.map((item) => item?.article))}
      limitTags={limitTags}
      multiple
      disableCloseOnSelect
      options={options.sort(sortedList)}
      groupBy={(option) => option.category}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.article === value.article}
      ChipProps={{ size: "small", variant: "outlined" }}
      value={mappedValue}
      renderTags={(selectedOptions, getTagProps) => {
        if (isTzSelect) {
          // Рендер строк через запятую, если isTzSelect === true
          const selectedNames = selectedOptions.map((option) => option.name).join(", ");
          return (
            <Typography
              variant="body2"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "150px",
              }}>
              {selectedNames}
            </Typography>
          );
        } else {
          // Стандартные чипы, если isTzSelect === false
          console.log("xbg");
          return selectedOptions.map((option, index) => (
            <Chip
              size="small"
              variant="outlined"
              label={option.name}
              {...getTagProps({ index })}
              key={option.article}
            />
          ));
        }
      }}
      renderGroup={(params) => (
        <>
          <Typography fontWeight={"bold"} pl={"24px"}>
            {params.group}
          </Typography>
          <Stack>{params.children}</Stack>
        </>
      )}
      renderOption={(props, option, { selected }) => {
        const isChecked = isTzSelect ? (value as string[])?.includes(option.article) : selected;
        return (
          <MenuItem {...props} value={option.article}>
            <Checkbox checked={isChecked} style={{ marginRight: 8 }} />
            <Typography sx={{ "&:hover": { color: purple_color }, textWrap: "wrap" }}>
              {option.name}
            </Typography>
          </MenuItem>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          variant="outlined"
          placeholder={placeholder}
          label={label}
          size="small"
          sx={{ maxWidth: maxWidth, minWidth: "200px" }}
        />
      )}
      loading={loading}
      sx={{
        "& .MuiAutocomplete-popper": {
          width: "auto", // Автоматическая ширина
          minWidth: "300px", // Минимальная ширина, если нужно
        },
        "& .MuiAutocomplete-listbox": {
          width: "auto", // Автоматическая ширина для элементов списка
        },
      }}
    />
  );
};
