import {FC, useState} from 'react'
import { IEndDocs } from './IEndDocs'
import { observer } from 'mobx-react-lite'
import integrationPageStore from '../../../../../../../../store/integrationPage.store'
import { IFinancePaymentEditRequest } from '../../../../../../../../services/payments/IPaymentsRequest'
import PaymentsService from '../../../../../../../../services/payments/payments.service'
import { AxiosError } from 'axios'
import { AsideCardLayout } from '../../../../../../../../components/AsideCardLayout/AsideCardLayout'
import { EditModeButton } from '../../../../../../../../components/UI/Buttons/EditModeButton/EditModeButton'
import {Box, Link, Stack} from '@mui/material'
import { ContractCheckbox } from '../../../../../../../FinanceCardPage/ui/ContractBlock/ui/ContractCheckbox/ContractCheckbox'
import { Link as RouterLink } from "react-router-dom"
import { ContractInput } from '../../../../../../../FinanceCardPage/ui/ContractBlock/ui/ContractInput/ContractInput'
import {checkNumberBoolean} from '../../../../../../../../shared/functions/functions'
import { NotificationAlerts } from '../../../../../../../../components/NotificationAlerts/NotificationAlerts'
import integrationTableStore from '../../../../../../../../store/integrationTable.store'
import {EmptyText} from "../../../../../../../../components/EmptyText/EmptyText";
import {TemplateProcessor} from "../../../../../../../FinanceCardPage/ui/ClosingDocuments/ui/TemplateProcessor/TemplateProcessor";

const PAYMENT_ACT_OF_COMPLETED_WORKS = 'payment_act_of_completed_works'

export const EndDocs:FC<IEndDocs> = observer((props) => {
  const {readOnly, integrationId, integrationStatusCode} = props

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)
  const [isEdit, setEdit] = useState(false)

  const startEdit = () => {
    integrationPageStore.startEditClosingDocs()
    setEdit(true)
  }

  const closeEdit = () => {
    integrationPageStore.closeEditClosingDocs()
    setEdit(false)
  }

  const submit = () => {
    if(integrationPageStore.oplataId && checkNumberBoolean(integrationPageStore.paymentUpdateId)){
      setLoading(true)
      setError(null)

      const option: IFinancePaymentEditRequest = {
        update_id:integrationPageStore.paymentUpdateId!,
        id:integrationPageStore.oplataId,
        hasAct:Boolean(integrationPageStore.edithasAct),
        actLink:integrationPageStore.editActLink || '',
        hasSelfEmployedCheck:Boolean(integrationPageStore.editHasSelfEmployedCheck),
        selfEmployedCheckLink:integrationPageStore.editSelfEmployedCheckLink || '',
        isServiceProvided:Boolean(integrationPageStore.editIsServiceProvided),
      }
      PaymentsService.financePaymentEdit(option)
        .then(res=>{
          // console.log(res, 'res')
          integrationPageStore.setHasSelfEmployedCheck(res.data?.hasSelfEmployedCheck)
          integrationPageStore.setSelfEmployedCheckLink(res.data?.selfEmployedCheckLink)
          integrationPageStore.setActLink(res.data?.actLink)          
          integrationPageStore.setHasAct(res.data?.hasAct)
          integrationPageStore.setServiceProvided(res.data?.isServiceProvided)
          integrationPageStore.setPaymentUpdateId(res.data?.update_id || null)

          integrationTableStore.changePaymentSimpleField(integrationId, 'isServiceProvided', res.data.isServiceProvided, res.data?.update_id)

          closeEdit()
          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            setError(err?.response?.data?.message)
            setLoading(false)
          }
        })
    }
  }

  const template = integrationPageStore?.template?.find(el => el.code = PAYMENT_ACT_OF_COMPLETED_WORKS)


  return (
    <>
      <AsideCardLayout
        headerTitle="Закрывающие документы"
        rightContent={
          <Stack direction={'row'} alignItems={'center'} gap={2}>
            {template &&
						 <TemplateProcessor
							template={template}
							text={'Запрос акта'}
							titleModal={'Запрос акта выполненных работ'}
						 />
            }
            <EditModeButton
              isEdit={isEdit}
              onClose={closeEdit}
              onOpenEdit={startEdit}
              onSubmit={submit}
              loading={isLoading}
              disabledEdit={readOnly}            />
          </Stack>
        }
      >
        <Box sx={{mt:1}}>
          {!isEdit &&
            <Box
              sx={{
                display:'grid', 
                gridTemplateColumns:{xs:'1fr', sm:'1fr 1fr'},
                columnGap:1,
                rowGap:1, 
                alignItems:'center'
              }}
            >
              <Box sx={{gridColumn: {xs:'1', sm:'1/3'}}}>
                <ContractCheckbox
                  initValue={integrationPageStore.isServiceProvided || false}
                  onChange={()=>''}
                  readOnly
                  text="Услуга оказана"
                />
              </Box>
              <Box>
                <ContractCheckbox
                  initValue={integrationPageStore.hasAct || false}
                  onChange={()=>''}
                  readOnly
                  text="Акт"
                />
              </Box>
              <Box>
                {integrationPageStore?.actLink
                  ? <Link
                      color={'secondary'} 
                      component={RouterLink} 
                      to={integrationPageStore?.actLink} 
                      target="_blank"
                      sx={{display:'inline-block', maxWidth:200}}
                      noWrap
                    >
                      {integrationPageStore?.actLink}
                    </Link>
                  : <EmptyText>ссылка отсутствует</EmptyText>
                }
              </Box>
              {integrationPageStore?.counteragent?.type?.code === 'self_employed' &&
                <>
                  <Box>
                    <ContractCheckbox
                      initValue={integrationPageStore.hasSelfEmployedCheck || false}
                      onChange={()=>''}
                      readOnly
                      text="Чек от самозанятого"
                    />
                  </Box>
                  <Box>
                    {integrationPageStore.selfEmployedCheckLink
                      ? <Link 
                          color={'secondary'} 
                          component={RouterLink} 
                          to={integrationPageStore.selfEmployedCheckLink} 
                          target="_blank"
                          sx={{display:'inline-block', maxWidth:200}}
                          noWrap
                        >
                          {integrationPageStore.selfEmployedCheckLink}
                        </Link>
                      : <EmptyText>ссылка отсутствует</EmptyText>
                    }
                  </Box>
                </>
              }
            </Box>
          }
          {isEdit &&
            <Box
              sx={{display:'grid', gridTemplateColumns:'1fr 1fr', columnGap:1, rowGap:1}}
            >
              <Box sx={{gridColumn: '1/3'}}>
                <ContractCheckbox
                  initValue={integrationPageStore.editIsServiceProvided || false}
                  onChange={integrationPageStore.changeEditServiceProvided}
                  readOnly={false}
                  disabled={isLoading || !(integrationPageStore?.isCheckGuaranteeCompleteAll)}
                  text="Услуга оказана"
                />
              </Box>
              <Box>
                <ContractCheckbox
                  initValue={Boolean(integrationPageStore.edithasAct)}
                  onChange={integrationPageStore.changeEditHasAct}
                  readOnly={false}
                  disabled={isLoading}
                  text="Акт"
                />
              </Box>
              <ContractInput
                field=""
                onChange={integrationPageStore.changeEditActLink as any}
                value={integrationPageStore.editActLink}
                disabled={isLoading}
                placeholder="Ссылка на акт"
              />
              {integrationPageStore?.counteragent?.type?.code === 'self_employed' &&
                <>
                  <Box>
                    <ContractCheckbox
                      initValue={Boolean(integrationPageStore.editHasSelfEmployedCheck)}
                      onChange={integrationPageStore.changeEditHasSelfEmployedCheck}
                      readOnly={false}
                      disabled={isLoading}
                      text="Чек от самозанятого"
                    />
                  </Box>
                  <ContractInput
                    field=""
                    onChange={integrationPageStore.changeEditSelfEmployedCheckLink as any}
                    value={integrationPageStore.editSelfEmployedCheckLink}
                    disabled={isLoading}
                    placeholder="Ссылка на чек от самозанятого"
                  />
                </>
              }
            </Box>
          }
        </Box>
      </AsideCardLayout>
      <NotificationAlerts
        error={{text:isError ? isError : '', onClose:()=>setError(null), open:Boolean(isError), autoHideDuration:5000}}
      />
    </>
  )
})