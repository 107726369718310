import {FC, useState} from 'react'
import { DeleteButton } from '../../../../../../components/UI/DeleteButton/DeleteButton'
import { IChannelDeleteBtn } from './IChannelDeleteBtn'
import { useSearchParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import channelTableStore from '../../../../../../store/channelTable.store'
import { CHANNEL_CARD_OPEN } from '../../../../../../shared/constants/searchParams'
import { Avatar, Box, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import channelStore from '../../../../../../store/channel.store'
import ChannelService from '../../../../../../services/channel/channel.service'
import { IRemoveChannelRequest } from '../../../../../../services/channel/IChannelRequest'
import { NotificationAlerts } from '../../../../../../components/NotificationAlerts/NotificationAlerts'

export const ChannelDeleteBtn:FC<IChannelDeleteBtn> = observer((props) => {
  const {
    id,
    update_id,
    data
  } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const [isShowAlert, setShowAlert] = useState(false)

  const channelType = channelStore?.channels?.[data.type.code]

  const deleteIntegration = () => {
    setLoading(true)
    setError(null)
    
    const option: IRemoveChannelRequest = {
      id,
      update_id
    }
    ChannelService.removeChannel(option)
      .then(res=>{

        channelTableStore.deleteChannel(id)

        searchParams.delete(CHANNEL_CARD_OPEN)
        setSearchParams(searchParams)
    
        setShowAlert(false)
        channelTableStore.showSuccsecDeletedChannel(true)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setError(err?.response?.data?.message)
        }
      })
      .finally(()=>{
        setLoading(false)
      })
  }

  return (
    <DeleteButton
      deleteButtonLabel={'удалить канал'} 
      confirmationTitle={'Внимание!'} 
      subContent={
        <>
          <Stack spacing={1}>
            <Typography>Вы действительно хотите удалить канал?</Typography>
            <Stack spacing={1} direction={'row'} alignItems={'start'}>
              <Avatar src={channelType?.icon} alt={channelType?.name} sx={{height:20, width:20}}/>
              <Typography>{data.name}</Typography>
            </Stack>
          </Stack>
          <NotificationAlerts
            error={{text:error || '', open:Boolean(error), onClose:()=>setError(null)}}
          />
        </>
      }
      onDelete={deleteIntegration}
      isLoading={isLoading}
      onCloseAlert={()=>setShowAlert(false)}
      showAlert={isShowAlert}
      onOpenAlert={()=>setShowAlert(true)}
    />
  )
})