import { FC, memo } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { AsideCardLayout } from '../../../../../../../../components/AsideCardLayout/AsideCardLayout'
import { IIntegrationPaymentTotalSumm } from './IIntegrationPaymentTotalSumm'
import { priceTransormFormat } from '../../../../../../../../shared/functions/functions'
import {EmptyText} from "../../../../../../../../components/EmptyText/EmptyText";

export const IntegrationPaymentTotalSumm:FC<IIntegrationPaymentTotalSumm> = memo((props) => {
  const {
    integrationId,
    integrations,
    totalPrice,
    errorPayments
  } = props

  return (
    <AsideCardLayout headerTitle={`интеграция`}>
      <Stack sx={{mt:'6px'}} spacing={'6px'}>
        {integrations?.map(x=>
          <Typography key={x.id} sx={{whiteSpace:'nowrap'}}>
            Интеграция №{x.id} стоимость: {x?.price ? priceTransormFormat(x?.price, true) : <EmptyText>нет данных</EmptyText>}
          </Typography>
        )}
        <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between', flexWrap:'wrap'}}>
          <Typography fontWeight={'bold'} sx={{whiteSpace:'nowrap'}}>
            Сумма общая (с комиссией): {totalPrice !== null ? priceTransormFormat(totalPrice, true) : <EmptyText/>}
          </Typography>
          {errorPayments && <Typography color={'error'} fontWeight={'bold'}>{errorPayments}</Typography>}
        </Box>
      </Stack>
    </AsideCardLayout>
  )
})