import { ICrmOrderByPromoResponse } from "../../../../../services/crm/ICrmRespone"
import { IIntegrationsFullResponse } from "../../../../../services/integration/IIntegrationsResponse"
import { IGoodCategoriesItem } from "../../../../../services/references/IReferencesResponse"
import { IStrategyField } from "../../../../../strategy/interface/IStrategyField"
import { IAsideTableStatisticField } from "../../../ui/AsideTableStatistic/models/IAsideTableStatisticField"
import {changeTableColor} from "../../../../../components/Table/shared/functions";
import IntegrationPageStore from "../../../../../store/integrationPage.store";

export const  getStatisticsMain = (data:IIntegrationsFullResponse):IStrategyField[] => {

  const statisticsRow:IStrategyField[] = [
    {title:'Просмотры', code:'views', immutable:false, isSimple:false, isSimpleView:true},
    {title:'Трафик', code:'traffic', immutable:false, isSimple:true,},
    {title:'CPV', code:'cpv', immutable:false, isSimple:false, isCtrls:true, ctrlsIcon:'₽', isShowRemains:true},
    {title:'CTR', code:'ctr', immutable:false, isSimple:false, isCtrls:true, ctrlsIcon:'%', isShowRemains:true,backgroundColor:changeTableColor('CTR',data.ctr)},
    {title:'CR', code:'cr', immutable:false, isSimple:false, isCtrls:true, ctrlsIcon:'%', isShowRemains:true,backgroundColor:changeTableColor('CR',data.cr)},
    {title: 'Скв.CR', code: 'crThrough', immutable: false, isSimple: false, isCtrls: true, ctrlsIcon: '%', isShowRemains: true},
    {title:'ROI', code:'roi', immutable:false, isSimple:true, isPercent:true},
    {title:'Заказы', code:'orders', immutable:false, isSimple:true},
    {title:'Средний чек', code:'averageCheck', immutable:false, isSimple:false,isCtrls:true, ctrlsIcon:'₽'},
    {title:'Отмены', code:'cancels', immutable:false, isSimple:true},
    {title:'Сумма отмен', code:'totalCancelledSumm', immutable:false, isSimple:true, isMoneySimple:true,},
    {title:'Сумма заказов', code:'totalSumm', immutable:false, isSimple:true, isMoneySimple:true,},
    {title:'Стоимость заказа', code:'cac', immutable:false, isSimple:true, isMoneySimple:true,},
  ]
  return statisticsRow
}

export const getFieldStatisticsMain = (data:IIntegrationsFullResponse, cpvValue:string, views:string,dataOrder: ICrmOrderByPromoResponse | null, cac:number | null):IAsideTableStatisticField[] => {
  const fields:IAsideTableStatisticField[] = [
    {code:'views', value:views},
    {code:'traffic', value:`${data.traffic}`,},
    {code:'cpv', value:`${cpvValue}`},
    {code:'ctr', value:`${data.ctr}`},
    {code:'cr', value:`${data.cr}`},
    {code: 'crThrough', value: `${data.crThrough}`},
    {code:'roi', value:IntegrationPageStore.roi || data.roi,},
    {code:'orders', value:dataOrder?.totalValid,},
    {code:'averageCheck', value:dataOrder?.averageCheck,},
    {code:'cancels', value:dataOrder?.cancels},
    {code:'totalCancelledSumm', value:dataOrder?.totalCancelledSumm},
    {code:'totalSumm', value:dataOrder?.totalSumm},
    {code:'cac', value:cac ? cac?.toFixed(2) : null}

  ]

  return fields
}

export const getStatisticsOrders = ():IStrategyField[] => {
  const orders:IStrategyField[] = [
    {title:'Заказы', code:'totalValid', immutable:false, isSimple:true,},
    {title:'Отмены', code:'cancels', immutable:false, isSimple:true},
    {title:'Сумма отмен', code:'totalCancelledSumm', immutable:false, isSimple:true, isMoneySimple:true,},
    {title:'Сумма заказов', code:'totalSumm', immutable:false, isSimple:true, isMoneySimple:true,},
    {title:'Средний чек', code:'averageOrderSumm', immutable:false, isSimple:true, isMoneySimple:true,},
    {title:'Стоимость заказа', code:'cac', immutable:false, isSimple:true, isMoneySimple:true,},
  ]

  return orders
}

export const getFieldStatisticsOrders = (dataOrder:ICrmOrderByPromoResponse, cac:number | null):IAsideTableStatisticField[] => {
  const orders:IAsideTableStatisticField[] = [
    {code:'totalValid', value:dataOrder.totalValid},
    {code:'cancels', value:dataOrder.cancels},
    {code:'totalCancelledSumm', value:dataOrder.totalCancelledSumm},
    {code:'totalSumm', value:dataOrder.totalSumm},
    {code:'averageOrderSumm', value:(dataOrder.totalSumm / dataOrder.totalValid)?.toFixed(2)},
    {code:'cac', value:cac ? cac?.toFixed(2) : null}
  ]

  return orders
}

export const getStatisticsCategories = (categories:IGoodCategoriesItem[]):IStrategyField[] => {
  const totalCategories:IStrategyField[] = categories.map(item=>{
    const strategyField:IStrategyField = {
      code:item.code,
      immutable:false,
      isSimple:true,
      title:item.name,
    }
    return strategyField
  })

  return totalCategories
}

export const getFieldStatisticsCategories = (categoriesJSON:string):IAsideTableStatisticField[] => {
  const categoriesObject = JSON.parse(categoriesJSON)

  const arrayValues:number[] = Object.values(categoriesObject)

  const totalCategories = arrayValues.reduce((prev, current)=>{
    return prev + current
  },0)
  const goodsCategories = Object.keys(categoriesObject).map(item=>{
    const newItem:IAsideTableStatisticField = {
      code:item,
      value:`${categoriesObject[item]} (${((categoriesObject[item] / totalCategories) * 100).toFixed(2)} %)`,
    }

    return newItem
  })

  return goodsCategories
}