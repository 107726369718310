import {useState, useEffect, FC} from 'react'
import { AsideCardLayout } from '../../../../components/AsideCardLayout/AsideCardLayout'
import ReferencesService from '../../../../services/references/references.service'
import { IReferencesBudgetParams, IReferencesPlanGetRequest } from '../../../../services/references/IReferencesRequest'
import { observer } from 'mobx-react-lite'
import dashboardStore from '../../../../store/dashboard.store'
import { AxiosError } from 'axios'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { BudgetTable } from './ui/BudgetTable/BudgetTable'
import dayjs, { Dayjs } from 'dayjs'
import { SearchMontDatePicker } from '../MotivationPage/ui/SearchMontDatePicker/SearchMontDatePicker'
import { AddBudgetTable } from './ui/AddBudgetTable/AddBudgetTable'
import { useSearchParams } from 'react-router-dom'
import { RadioFilter } from './ui/RadioFilter/RadioFilter'
import { AddPlanTable } from './ui/AddPlanTable/AddPlanTable'
import { PlanTable } from './ui/PlanTable/PlanTable'
import userStore from '../../../../store/user.store'
import { ALL_ROLES } from '../../../../shared/rules/interface/EnumRights'
import {IPlanPage} from "./IPlanPage";
import {toJS} from "mobx";
import { CustomButton } from '../../../../components/UI/Buttons/CustomButton/CustomButton'

export const PlanPage: FC<IPlanPage> = observer((props) => {
  const {isLk} = props
  const [searchParams, setSearchParams] = useSearchParams()

  const [showContent, setShowContent] = useState<1 | 2>(userStore?.user?.role === ALL_ROLES.SEARCH_HEAD_MANAGER ? 2 : 1)

  const [dateMonth, setDateMonth] = useState<number>(searchParams.get('date') ? +searchParams.get('date')?.split('-')[1]!-1 : new Date().getMonth())
  const [date, setDate] = useState<Dayjs | null>(searchParams.get('date') ? dayjs(searchParams.get('date')) : dayjs(new Date()))

  const [isLoadingBudget, setLoadingBudget] = useState(false)
  const [isLoadingPlan, setLoadingPlan] = useState(false)

  const getAllPlans = (params:IReferencesPlanGetRequest) => {
    setLoadingPlan(true)

    ReferencesService.planGet(params)
      .then(res=>{
        // console.log(res.data, 'res plan get')
        dashboardStore.setPlanAll(res.data)
        setLoadingPlan(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err plan get')
          setLoadingPlan(false)
        }
      })
  }
  

  const getAllBudget = (params:IReferencesBudgetParams) => {
    setLoadingBudget(true)

    ReferencesService.budgetGet(params)
      .then(res=>{
        dashboardStore.setBudgetAll(res.data)
        setLoadingBudget(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setLoadingBudget(false)
        }
      })
  }

  const planMonth = () => {
    if(date){
      getAllPlans({month:date.month()+1, year:date.year()})
      setDateMonth(date.month())

      searchParams.set('date', `${date.year()}-${date.month()+1}`)
      setSearchParams(searchParams)
    } else if (date === null) {
      
      searchParams.delete('date')
      setSearchParams(searchParams)
    }
  }

  const budgetMonth = () => {
    if(date){
      getAllBudget({month:date.month()+1, year:date.year()})
      setDateMonth(date.month())

      searchParams.set('date', `${date.year()}-${date.month()+1}`)
      setSearchParams(searchParams)
    } else if (date === null) {
      
      searchParams.delete('date')
      setSearchParams(searchParams)
    }
  }

  const changeRadio = (type:'release' | 'search') => {
    if(type === 'release'){
      setShowContent(prev=>1)
    } else {
      setShowContent(prev=>2)
      planMonth()
    }
  }

  useEffect(()=>{
    if(showContent === 1){
      getAllBudget(date ? {month:date.month()+1, year:date.year()} : {month:new Date().getMonth()+1, year:new Date().getFullYear()})
    } else {
      getAllPlans(date ? {month:date.month()+1, year:date.year()} : {month:new Date().getMonth()+1, year:new Date().getFullYear()})
    }
  }, [])

  useEffect(()=>{
    if(isLk){
      if(!!userStore.user?.rights.filter(item => toJS(item.code) === 'search'))
        setShowContent(2)
      else
        setShowContent(1)
    }
  },[isLk])
  
  return (
    <AsideCardLayout fullHeight sx={{overflow:'hidden'}}>
      {isLoadingBudget &&
        <Stack direction={'row'} justifyContent={'center'} spacing={1}>
          <Typography variant='h5'>Идёт загрузка...</Typography>
          <CircularProgress sx={{minHeight:35, maxHeight:35, minWidth:35, maxWidth:35}}/>
        </Stack>
      }
      {isLoadingPlan &&
        <Stack direction={'row'} justifyContent={'center'} spacing={1}>
          <Typography variant='h5'>Идёт загрузка...</Typography>
          <CircularProgress sx={{minHeight:35, maxHeight:35, minWidth:35, maxWidth:35}}/>
        </Stack>
      }
      <Stack
        sx={{
          height:'100%',
          display: 'grid',
          gridTemplateRows: 'min-content 1fr',
        }} 
        spacing={1}
      >
        <Stack
          direction={{xs:'column-reverse', md:'row'}}
          alignItems={'flex-start'}
          justifyContent={'space-between'}
        >
          {showContent === 1 && !isLoadingBudget && dashboardStore.budgetAll !== null &&
            <AddBudgetTable
              budget={dashboardStore.budgetAll?.filter(x=>x.isGeneral === true)?.[0]}
              month={dateMonth}
              year={dashboardStore.budgetAll?.filter(x=>x.isGeneral === true)?.[0]?.year}
              isEdit={isLk}
            />
          }
          {!isLoadingPlan && showContent === 2 && dashboardStore.planAll !== null && dashboardStore.planAll?.find(x=>x.isGeneral === true) &&
            <AddPlanTable {...dashboardStore.planAll?.find(x=>x?.isGeneral === true)!}/>
          }
          {(!isLoadingPlan && !isLoadingBudget) &&
            <Stack
              sx={{
                mt:0,
                ml:{sm:'auto', md:0},
                mb:{xs:1, md:0},
                width:{xs:'100%', md:'auto'}
              }} 
              direction={'column'}
              spacing={1}
            >
              {!isLk &&
                <RadioFilter
                  initValue={showContent === 1 ? 'release' : 'search'}
                  changeFilter={changeRadio}
                  removeRelease={userStore?.user?.role === ALL_ROLES.SEARCH_HEAD_MANAGER}
                />
              }
              {isLk && !!userStore.user?.rights.filter(item => toJS(item.code) === 'search')
                  && !!userStore.user?.rights.filter(item => toJS(item.code) === 'release') &&
                  <RadioFilter
                    initValue={showContent === 1 ? 'release' : 'search'}
                    changeFilter={changeRadio}
                  />
              }
              <SearchMontDatePicker
                initValue={date}
                onChange={setDate}
              />
              <Box sx={{display:'inline-flex'}}>
                <CustomButton
                  variant='contained'
                  sx={{ml:'auto', width:{xs:'100%', md:'auto'},}}
                  disabled={isNaN(date?.month()!) && isNaN(date?.year()!)}
                  onClick={showContent === 1 ? budgetMonth : planMonth}
                >
                  применить
                </CustomButton>
              </Box>
            </Stack>
          }
        </Stack>
        <Box 
          sx={{
            width:{xl: isLk ? '85%' : '50%', lg:'75%'},
            overflow:'hidden',
            display:'grid',
            gridTemplateRows:'min-content 100%', 
            height:'initial'
          }}
        >
          {showContent === 1 && !isLoadingBudget && dashboardStore.budgetAll !== null &&
            <>
              <Typography sx={{mb:0.5, typography: {xs:'body1', sm:'h6'}}}>Таблица расходов менеджеров</Typography>
              {isLk
                ? <BudgetTable 
                    budgetItems={dashboardStore.budgetAll.filter(x=>x.isGeneral === false && x.userId===userStore.user?.id)}
                    noEdit={isLk} 
                  />
                : <BudgetTable 
                    budgetItems={dashboardStore.budgetAll.filter(x=>x.isGeneral === false )} 
                    noEdit={isLk} 
                  />
              }
            </>
          }
          {showContent === 2 && dashboardStore.planAll !== null && !isLoadingPlan &&
            <>
              <Typography sx={{mb:0.5, typography: {xs:'body1', sm:'h6'}}}>План поисковых менеджеров</Typography>
              {isLk
                ? <PlanTable 
                    list={dashboardStore.planAll?.filter(x=>x.isGeneral === false && x.userId===userStore.user?.id)} 
                    noEdit={isLk}
                  />
                : <PlanTable 
                    list={dashboardStore.planAll?.filter(x=>x.isGeneral === false)} 
                    noEdit={isLk}
                  />
                }
            </>
          }
        </Box>
      </Stack>
    </AsideCardLayout>
  )
})
