import { DatePickerSlotsComponentsProps } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { purple_color, purple_light_color, purple_middle_color } from "../../../../app/styles/variables";

export const getDatePickerIconsStyle = (): DatePickerSlotsComponentsProps<Dayjs> => {

  const styles:DatePickerSlotsComponentsProps<Dayjs> = {
    openPickerIcon: {
      sx: {
        fontSize: '1.0714285714285714rem',
      },
    },
    openPickerButton:{
      sx:{
        '&:hover':{
          color:purple_middle_color,
          background:purple_light_color
        },
        '&:active':{
          color:purple_color,
          background:purple_light_color
        },
        marginRight:'-10px'
      },
      size:'small'
    },
    nextIconButton:{
      sx:{
        '&:hover':{
          color:purple_middle_color,
          background:purple_light_color
        },
        '&:active':{
          color:purple_color,
          background:purple_light_color
        },
        height:'25.42px',
        width:'25.42px',
        p:'5px',
      }
    },
    previousIconButton:{
      sx:{
        '&:hover':{
          color:purple_middle_color,
          background:purple_light_color
        },
        '&:active':{
          color:purple_color,
          background:purple_light_color
        },
        height:'25.42px',
        width:'25.42px',
        p:'5px',
      }
    },
    switchViewButton:{
      sx:{
        '&:hover':{
          color:purple_middle_color,
          background:purple_light_color
        },
        '&:active':{
          color:purple_color,
          background:purple_light_color
        },
        height:'25.42px',
        width:'25.42px',
        p:'5px',
      }
    }
  }

  return styles
}