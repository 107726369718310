const GATEWAY = window.location.origin.includes('localhost')
	? process.env.REACT_APP_API_URL_GATEWAY
	: window.location.origin + '/agw'

const AUTH = window.location.origin.includes('localhost')
	? process.env.REACT_APP_API_URL_AUTH
	: window.location.origin + '/agw'

export const URLS = {
	gateway: GATEWAY,
	auth: AUTH,
}
