import {FC, useState, useEffect} from 'react'
import { IGoodListInputItem } from './IGoodListInputItem'
import { TextField } from '@mui/material'

export const GoodListInputItem:FC<IGoodListInputItem> = (props) => {
  const {
    showDeleteButton,
    onChangeGood,
    disabled,
    id,
    defaultValue
  } = props

  const [value, setValue] = useState('')

  useEffect(() => {
    const delay = setTimeout(()=> onChangeGood(id, 'color', value), 450)
    return ()=>clearTimeout(delay)
  },[value])

  return (
    <TextField
      size='small'
      placeholder='Введите цвет товара' 
      sx={{pr:showDeleteButton ? '35px' : 0}}
      onChange={e=>setValue(e.target.value)}
      disabled={disabled}
      defaultValue={defaultValue}
    />
  )
}
