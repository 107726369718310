import {FC, useState} from 'react'
import { IChannelRequisiteModal } from './IChannelRequisiteModal'
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import requisiteStore from '../../../../store/requisite.store'
import { CustomIconButton } from '../../../../components/UI/Buttons/CustomIconButton/CustomIconButton'
import { CancelButton } from '../../../../components/UI/Buttons/CancelButton/CancelButton'
import { CustomButton } from '../../../../components/UI/Buttons/CustomButton/CustomButton'

export const ChannelRequisiteModal:FC<IChannelRequisiteModal> = (props) => {
  const {
    open,
    onClose,
    title,
    initFio,
    initType,
    initValue,
    error,
    loading,
    onSubmit
  } = props

  const [fioValue, setFioValue] = useState(initFio ? initFio : '')
  const [valueRequisite, setValueRequisite] = useState(initValue ? initValue : '')
  const [type, setType] = useState(initType ? initType : '')

  const selectItems = requisiteStore.requisiteInfo?.externalAccountTypes

  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle sx={{display:'flex', alignItems:'center', justifyContent:'space-between', p:{xs:1, sm:2}, pr:1}}>
        <Typography variant='h5'>{title}</Typography>
        <CustomIconButton disabled={loading} onClick={onClose}>
          <Cancel/>
        </CustomIconButton>
      </DialogTitle>
      <Divider/>
      <DialogContent sx={{p:{xs:1, sm:2}}}>
        <Stack spacing={1}>
          <Stack spacing={1}>
            <Typography>Заполните ФИО</Typography>
            <TextField
              value={fioValue}
              onChange={e=>setFioValue(e.target.value)}
              size='small'
              placeholder='Введите полное ФИО'
            />
          </Stack>
          <Stack spacing={1}>
            <Typography>Выберите тип реквизита</Typography>
            <FormControl>
              <InputLabel id="demo-simple-select-label" size='small'>Тип</InputLabel>
              <Select
                value={type}
                onChange={handleChange}
                labelId="demo-simple-select-label"
                size='small'
                label={'Тип'}
              >
                {selectItems?.filter(select=>select.isActive === true).map(select=>
                  <MenuItem key={select.code} value={select.code}>{select.name}</MenuItem>)
                }
              </Select>
            </FormControl>
          </Stack>
          <Stack spacing={1}>
            <Typography>Значение реквизита</Typography>
            <TextField
              value={valueRequisite}
              onChange={e=>setValueRequisite(e.target.value)}
              size='small'
              placeholder='Значение'
            />
          </Stack>
        </Stack>
        {error && <Typography color='error'>{error}</Typography>}
      </DialogContent>
      <Divider/>
      <DialogActions sx={{p:{xs:1, sm:2}}}>
        <CancelButton variant='outlined' disabled={loading} onClick={onClose}>Отмена</CancelButton>
        <CustomButton 
          variant='contained'
          disabled={loading}
          onClick={()=>onSubmit(fioValue, valueRequisite, type)}
          endIcon={loading && <CircularProgress sx={{maxHeight:20, maxWidth:20}}/>}
        >
          Сохранить
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}
