import { AxiosResponse } from "axios";
import $gateway_api from "../../shared/api/gatewayAxios";
import { IMetricsAllSourceRequest, IMetricsOnSourceRequest } from "./IMetricsRequest";
import { IMetricsAllSourceResponse, IMetricsOnSourceResponse } from "./IMetricsResponse";

export default class MetricsService {

  static getMetricsAllSource(option:IMetricsAllSourceRequest):Promise<AxiosResponse<IMetricsAllSourceResponse>>{
    return $gateway_api.post('/api/metrics/all-sources', option)
  }

  static getMetricsOnSource(option:IMetricsOnSourceRequest):Promise<AxiosResponse<IMetricsOnSourceResponse>>{
    return $gateway_api.post('/api/metrics/metrics-on-source', option)
  }

}