import { AxiosResponse } from "axios";
import $gateway_api from "../../shared/api/gatewayAxios";
import { IAllGoodsCategoriesResponse, IGoodsCategoriesResponse, IGoodsCategoriesResponseProduct } from "./IGoodsCategoriesResponse";
import { IEditGoodResponse, ISaveGoodsCategoriesResponse } from "./IGoodsCategoriesRequest";
import { IGoodItem } from "../../components/Table/ui/Fields/GoodsField/ui/GoodItem/IGoodItem";

export default class GoodsCategoriesService {

  static getProductsGroupsCRM(categoryId?:number): Promise<AxiosResponse<IGoodsCategoriesResponse[]>>{
    const url = categoryId !== undefined ? `/api/goodsCategories/getProductsGroupsCRM/${categoryId}` : `/api/goodsCategories/getProductsGroupsCRM/`
    
    return $gateway_api.get(url)
  }
  
  static getAllGoodsCategories(): Promise<AxiosResponse<IAllGoodsCategoriesResponse[]>>{
    return $gateway_api.get('/api/goodsCategories/all')
  }

  static saveGoodsCategories(options:ISaveGoodsCategoriesResponse): Promise<AxiosResponse<IGoodsCategoriesResponseProduct>>{
    return $gateway_api.post('/api/goodsCategories/save', options)
  }

  static removeGoods(options:Pick<IGoodItem, 'article'>): Promise<AxiosResponse<Pick<IGoodItem, 'article'>>>{
    return $gateway_api.post('/api/goodsCategories/removeGoods', options)
  }

  static editGoods(options:IEditGoodResponse): Promise<AxiosResponse<IGoodsCategoriesResponseProduct>>{
    return $gateway_api.post('/api/goodsCategories/editGoods', options)
  }
  
}