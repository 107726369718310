import {FC, useState} from 'react'
import { useSearchParams } from "react-router-dom"
import { CircularProgress, SelectChangeEvent, Skeleton, Stack, Typography } from "@mui/material"
import { IFilterMotivation } from './IFilterMotivation'
import { CategorySelect } from '../CategorySelect/CategorySelect'
import { SearchManagerSelect } from '../SearchManagerSelect/SearchManagerSelect'
import { SearchMontDatePicker } from '../SearchMontDatePicker/SearchMontDatePicker'
import { getCategotyManager, getManagersOnType } from './shared/dataList'
import AllUsersStore from '../../../../../../store/AllUsers.store'
import { observer } from 'mobx-react-lite'
import dayjs, { Dayjs } from 'dayjs'
import userStore from '../../../../../../store/user.store'
import { ALL_ROLES } from '../../../../../../shared/rules/interface/EnumRights'
import { CancelButton } from '../../../../../../components/UI/Buttons/CancelButton/CancelButton'
import { CustomButton } from '../../../../../../components/UI/Buttons/CustomButton/CustomButton'

export const FilterMotivation:FC<IFilterMotivation> = observer((props) => {
  const {
    loading,
    onSubmitRelease,
    onSubmitSearch,
    onSubmitReleaseByUser,
    onSubmitAllSearch,
    disabledTypeManager
  } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const [dateValue, setDateValue] = useState<Dayjs | null>(searchParams.get('date') ? dayjs(searchParams.get('date')) : null)

  const managerType:'search' | 'release' | null = 
    userStore.user?.role === ALL_ROLES.SEARCH_HEAD_MANAGER ? 'search' : searchParams.get('managerType') as 'search' | 'release' | null

  const params = {
    month:dateValue?.month()!+1,
    year:dateValue?.year()!,
    userId:+searchParams.get('manager')! || 0
  }

  const submitRelease = () => {
    onSubmitRelease(dateValue !== null && searchParams.get('manager') ? params : undefined)

    if(dateValue){
      onSubmitReleaseByUser(params)
    }

  }

  const submitSearch = () => {
    onSubmitAllSearch()

    if(dateValue){
      onSubmitSearch(params)
    }

  }

  const changeManager = (event: SelectChangeEvent) => {
    searchParams.set('manager', event.target.value)
    setSearchParams(searchParams)
  }

  const changeDate = (newValue:Dayjs | null) => {
    if(newValue && newValue.isValid()){
      setDateValue(newValue)

      searchParams.set('date', newValue?.format('YYYY-MM'))
      setSearchParams(searchParams)
    } else {
      searchParams.delete('date')
      setSearchParams(searchParams)
      setDateValue(newValue)
    }
  }

  const resetFilter = () => {
    searchParams.delete('manager')
    searchParams.delete('date')

    setSearchParams(searchParams)
    setDateValue(null)
  }

  return (
      <Stack sx={{display:'grid', gridAutoRows:'min-content'}} spacing={1}>
        <Typography sx={{fontWeight:'bold'}}>Данные по таблице мотивации</Typography>
        <Stack direction={'column'} spacing={2}>
          <Stack spacing={1}>
            <Stack spacing={1}>
              <Typography>Тип менеджера</Typography>
              <CategorySelect
                content={getCategotyManager()}
                initValue={managerType || 'release'}
                placeholder='Тип менеджера'
                searchQueryName='managerType'
                removeQueryName='manager'
                removeQueryDate='date'
                disabled={loading || disabledTypeManager}
                removeState={()=>setDateValue(null)}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography>Выбор менеджера</Typography>
              {AllUsersStore.users
                ? <SearchManagerSelect
                    isAllManagers
                    content={getManagersOnType({allUsers:AllUsersStore.users, managerType:managerType as any || 'release'})}
                    handleChange={changeManager}
                    initValue={searchParams.get('manager') || '0'}
                    label='Текущий менеджер'
                    disabled={loading}
                />
                : <Skeleton variant='rounded' sx={{minHeight:'40px', width:'100%'}}/>}
            </Stack>
            <Stack spacing={1}>
              <Typography>Период (месяц, год)</Typography>
              <SearchMontDatePicker
                initValue={dateValue}
                onChange={changeDate}
                disabled={loading}
              />
            </Stack>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            {searchParams.get('manager') || searchParams.get('date') ?
              <CancelButton variant='outlined' onClick={resetFilter} disabled={loading}>Очистить</CancelButton>
              : null
            }
            <CustomButton
              variant='contained'
              endIcon={loading && <CircularProgress sx={{maxHeight:20, maxWidth:20}}/>}
              disabled={!dateValue?.isValid() || loading}
              onClick={
                managerType === 'release'
                    ? submitRelease
                    : managerType === 'search'
                        ? submitSearch
                        : submitRelease
              }
              sx={{ml:"auto"}}
            >
              Применить
            </CustomButton>
          </Stack>
        </Stack>
      </Stack>
  )
})