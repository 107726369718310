import {FC} from 'react'
import { IGoodCheckbox } from './IGoodCheckbox'
import { Box, Checkbox } from '@mui/material'

export const GoodCheckbox:FC<IGoodCheckbox> = (props) => {
  const {
    value, 
    onChange, 
    disabled = false,
    isEdit
  } = props

  return (
    <Box>
      <Checkbox checked={value} onChange={isEdit ? e=>onChange(e.target.checked) : ()=>''} disabled={disabled}/>
    </Box>
  )
}
