import {FC} from 'react'
import { IGoodCategory } from './IGoodCategory'
import { observer } from 'mobx-react-lite'
import { Typography } from '@mui/material'
import categoriesGoodsStore from '../../../../../store/categoriesGoods.store'

export const GoodCategory:FC<IGoodCategory> = observer((props) => {
  const {code} = props

  const currentCategoty = categoriesGoodsStore.categories?.find(category=>category.code === code)

  return (
    <Typography component={'span'} sx={{whiteSpace:'nowrap'}}>{currentCategoty?.name}</Typography>
  )
})