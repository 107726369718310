import {FC} from 'react'
import { ICustomButton } from '../CustomButton/ICustomButton'
import { Button, ThemeProvider, createTheme } from '@mui/material'
import { theme } from '../../../../app/theme'
import { purple_color, purple_light_color, purple_middle_color } from '../../../../app/styles/variables'

export const CancelButton:FC<ICustomButton> = (props) => {
  const {
    children,
    variant = 'outlined',
    size = 'medium'
  } = props

  const buttonTheme = createTheme({
    components: {
      MuiButton:{
        variants:[
          {
            props:{
              variant:'contained',
            },
            style:{
              color:'#fff',
              background:theme.palette.primary.main,
              borderColor:theme.palette.primary.main,

              '&:hover':{
                color:'#fff',
                background:purple_middle_color,
                borderColor:purple_middle_color,
              },
              '&:active':{
                color:'#fff',
                background:purple_color,
                borderColor:purple_color,
              },
            }
          },
          {
            props:{
              variant:'outlined',
            },
            style:{
              color:theme.palette.primary.main,
              borderColor:theme.palette.primary.main,

              '&:hover':{
                color:purple_color,
                background:purple_light_color,
                borderColor:purple_color,
              },
              '&:active':{
                color:purple_color,
                background:purple_light_color,
                borderColor:purple_color,
              },
            }
          },
          {
            props:{
              variant:'text',
            },
            style:{
              color:theme.palette.primary.main,
              '&:hover':{
                background:'rgba(124, 100, 217, 0.04)',
              },
              '&:active':{
                background:'rgba(80, 61, 157, 0.04)',
              },
            }
          }
        ]
      }
    },
    typography:theme.typography
  })

  return (
    <ThemeProvider theme={buttonTheme}>
      <Button
        {...props}
        variant={variant}
        size={size}
      >
        {children}
      </Button>
    </ThemeProvider>
  )
}
