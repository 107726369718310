import { Autocomplete, Avatar, Box, CircularProgress, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { useState, FC, useEffect } from 'react'
import ReferencesService from '../../../../../../services/references/references.service'
import { IIntegrationsFullResponse } from '../../../../../../services/integration/IIntegrationsResponse'
import { IGoodSearchIntegration } from './IGoodSearchIntegration'

export const GoodSearchIntegration:FC<IGoodSearchIntegration> = (props) => {
  const {
    onSubmit,
    disabled,
    integration
  } = props

  const [isLoadingSearch, setLoadingSearch] = useState(false)
  const [errorSearch, setErrorSearch] = useState<string | null>(null)

  const [value, setValue] = useState<IIntegrationsFullResponse | null>(integration ? integration : null)
  const [integrationList, setIntegrationList] = useState<IIntegrationsFullResponse[] | null>(null)
  const [inputValue, setInputValue] = useState<string>('')

  const getIntegrationList = (value:string) => {
    setLoadingSearch(true)
    setIntegrationList(null)
    setErrorSearch(null)

    ReferencesService.hintsAllIntegrations({target:value})
      .then(res=>{
        // console.log(res, 'res')

        setIntegrationList(res.data)
        
        setLoadingSearch(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setErrorSearch(err?.response?.data?.message)
          setLoadingSearch(false)
        }
      })
  }

  useEffect(() => {
    if(inputValue.trim() !== ''){

      if(inputValue !== value?.promokod?.code
        ? `${value?.id?.toString()} [${value?.promokod?.code}] ${value?.channel?.name || ''}`
        : `${value?.id?.toString()} ${value?.channel?.name || ''}`
      ){
        const delay = setTimeout(()=> getIntegrationList(inputValue), 500)
        return ()=>clearTimeout(delay)
      }

    }
  },[inputValue])

  return (
    <Autocomplete
      fullWidth
      value={value}
      onChange={(event: any, newValue: IIntegrationsFullResponse | null) => {
        setValue(newValue)
        onSubmit(newValue)
      }}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue)
      }}
      options={integrationList || []}
      getOptionLabel={(option) =>
        option?.promokod?.code ? `${option.id?.toString()} [${option?.promokod?.code}] ${option?.channel?.name || ''}` : `${option.id?.toString()} ${option?.channel?.name || ''}`
      }
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <Avatar src={option?.channel?.type?.icon} alt={option?.channel?.name} sx={{height:20, width:20, mr:1}}/>
          {option?.promokod?.code
            ? <Typography>{option?.id} [{option?.promokod?.code}] {option?.channel?.name}</Typography>
            : <Typography>{option?.id} {option?.channel?.name}</Typography>
          }
        </Box>
      )}
      noOptionsText='Ничего не найдено'
      loading={isLoadingSearch}
      disabled={disabled}
      loadingText={'Загрузка...'}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={'Введите ID интеграции, промокод, название канала'}
          size='small'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoadingSearch ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      size='small'
    />
  )
}
