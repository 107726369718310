import {FC, useState} from 'react'
import { IFinancePaymentItemStatus } from './IFinancePaymentItemStatus'
import { StatusesList } from '../../../../../../../../components/Statuses/StatusesList'
import { observer } from 'mobx-react-lite'
import statusesStore from '../../../../../../../../store/statuses.store'
import { Skeleton } from '@mui/material'
import PaymentsService from '../../../../../../../../services/payments/payments.service'
import { IPaymentUpdateStatusRequest } from '../../../../../../../../services/payments/IPaymentsRequest'
import { AxiosError } from 'axios'
import financeCardPageStore from '../../../../../../../../store/financeCardPage.store'
import dayjs from 'dayjs'
import paymentsTableStore from '../../../../../../../../store/paymentsTable.store'
import integrationPageStore from '../../../../../../../../store/integrationPage.store'
import integrationTableStore from '../../../../../../../../store/integrationTable.store'
import { checkNumberBoolean } from '../../../../../../../../shared/functions/functions'

export const FinancePaymentItemStatus:FC<IFinancePaymentItemStatus> = observer((props) => {
  const {
    paymentStatusCode, 
    id, 
    onChangeStatus, 
    isEditMode,
    paymentType,
    plannedPaidAt,
    totalSumm,
    readOnlyStatus,
    update_id
  } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<null | string>(null)

  const changeStatus = (type:string) => {
    if(checkNumberBoolean(update_id)){

      setLoading(true)
      setError(null)

      const option: IPaymentUpdateStatusRequest = {
        id:id,
        update_id:update_id!,
        entity:'pay',
        newStatus:type
      }
      PaymentsService.updateStatus(option)
        .then(res=>{
          onChangeStatus(id, type, type === 'paid' ? dayjs()?.toISOString() : null, res.data?.paidAt)
          financeCardPageStore.setStatus(res.data?.status)
          integrationPageStore.setStatus(res.data?.status)

          financeCardPageStore.setUpdateId(res.data?.update_id)
          integrationPageStore.setPaymentUpdateId(res.data?.update_id)

          if(financeCardPageStore.id){
            paymentsTableStore.changePayment(financeCardPageStore.id, res.data)
          }
          
          if(integrationPageStore.integrationId){
            integrationTableStore.updatePaymentsDate(integrationPageStore.integrationId, res.data?.paidAt, 'paidAt')
            integrationTableStore.changeIntegrationPaymentStatus(integrationPageStore.integrationId, res.data?.status?.code || null)
          }

          financeCardPageStore.setPlannedPaidAt(res.data?.plannedPaidAt)
          integrationPageStore.setPlannedPaidAt(res.data?.plannedPaidAt)

          handleClose()
          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            setError(err.response?.data?.message)
            setLoading(false)
            handleClose()
          }
        })
    }
  }

  const editChangeStatus = (type:string) => {
    onChangeStatus(id, type, type === 'paid' ? dayjs()?.toISOString(): null)
    handleClose()
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  
  const handleClose = () => {
    setAnchorEl(null)
  }

  const closeAlertError = (isShow:boolean) => {
    if(!isShow){
      setError(null)
    }
  }

  return (
    <>
      {statusesStore.pay
        ? <StatusesList
            type={paymentStatusCode}
            statusStore={statusesStore.pay}
            anchorEl={anchorEl}
            changeStatus={isEditMode ? editChangeStatus : changeStatus}
            onOpenList={handleClick}
            onCloseList={handleClose}
            loading={isLoading}
            payAlertModal
            isShowAlerError={isError}
            closeAlertError={()=>closeAlertError(false)}
            readOnly={readOnlyStatus}
            paymentType={paymentType?paymentType:''}
            payContentData={{
              paymentType:(paymentType === 'bank' ? 'На счёт' : 'На карту') || 'не выбрано',
              plannedPaidAt:plannedPaidAt === null ? dayjs()?.toISOString() : plannedPaidAt,
              totalSumm:totalSumm || 0,
            }}
          />
        : <Skeleton variant='rounded' sx={{minHeight:40, maxHeight:40, minWidth:100, maxWidth:100}}/>
      }
    </>
  )
})