import {FC} from 'react'
import { IAsideCardChart } from './IAsideCardChart'
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { getOrdersFilterType } from './shared/functions'
import { getDatePlusCustomTime } from '../../../../shared/functions/functions'

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend)

export const AsideCardChart:FC<IAsideCardChart> = (props) => {
  const {data} = props

  const options = {
    responsive:true,
    scaleShowLabels:false,
    elements:{
      point:{radius:3}
    },
    plugins:{
      legend:{display:false},
      tooltip:{
        enabled:true,
        displayColors:false,
        titleAlign:'center' as "center",
      }
    },
  }

  const allOrders = getOrdersFilterType(data, ["cancel", "ne-zakaz"])
    .map(x=>getDatePlusCustomTime({value:x.createdAt, format:'DD.MM.YY'}))
  const dataOrder = allOrders.reduce((acc:any, n) => (acc[n] = (acc[n] || 0) + 1, acc), {})
 
  const chartData = {
    labels:Object.keys(dataOrder),
    datasets:[
      {
        fill:false,
        label:'Заказов в день',
        data:Object.values(dataOrder),
        borderColor:'1px solid #e0e0e0',
        
      }
    ]
  }

  return (
    <Line style={{maxWidth:'100%', maxHeight:'300px'}} options={options} data={chartData}/>
  )
}