import {FC, useState} from 'react'
import {IChannelAsideSimpleEdit} from './IChannelAsideSimpleEdit'
import {ChannelTitleEdit} from '../../../ChannelAsidePage/ui/ChannelTitleEdit/ChannelTitleEdit'
import ChannelService from '../../../../services/channel/channel.service'
import {IUpdateStatusesRequest} from '../../../../services/integration/IntegrationsRequest'
import {AxiosError} from 'axios'
import channelTableStore from '../../../../store/channelTable.store'
import {NotificationAlerts} from '../../../../components/NotificationAlerts/NotificationAlerts'

export const ChannelAsideSimpleEdit: FC<IChannelAsideSimpleEdit> = (props) => {
  const {
    isPercent,
    link,
    title,
    value,
    icon,
    notFoundTitle,
    placeholder,
    setValue,
    field,
    id,
    update_id,
    onChangeUpdateId,
    isCopy = false,
    tooltipPlacement = 'bottom',
    readOnly
  } = props

  const [isEditOpen, setEditOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const submit = (value: string) => {
    setLoading(true)
    setError(null)

    const option: IUpdateStatusesRequest = {
      id,
      field,
      new_value:value.replace(',', '.'),
      update_id
    }
    ChannelService.updateSimpleField(option)
      .then(res => {
        // console.log(res, 'res update simple cahnnel field edit')

        channelTableStore.changeField({id, field, new_value: value, update_id: res.data.update_id})

        if (onChangeUpdateId) {
          onChangeUpdateId(res.data.update_id)
        }
        setValue(value)
        setEditOpen(false)
        setLoading(false)
      })
      .catch(err => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err channel simple fiel edit')
          setLoading(false)
          setError(err?.response?.data?.message)
        }
      })
  }

  return (
    <>
      <ChannelTitleEdit
        isPercent={isPercent}
        link={link}
        title={title}
        value={value}
        notFoundTitle={notFoundTitle}
        onSubmit={submit}
        placeholder={placeholder}
        isEdit={isEditOpen}
        onCloseEdit={() => setEditOpen(false)}
        onOpenEdit={() => setEditOpen(true)}
        isLoading={isLoading}
        icon={icon}
        tooltipPlacement={tooltipPlacement}
        required={false}
        isCopy={isCopy}
        readOnly={readOnly}
      />
      <NotificationAlerts
        error={{text: isError ? isError : '', open: isError ? true : false, onClose: () => setError(null)}}
      />
    </>
  )
}