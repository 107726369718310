import {FC, memo, useEffect, useState} from 'react';
import { FinanceSelect } from '../../../FinancecTable/ui/FinanceFilter/FinanceSelect';
import { IFilterSelectItem } from '../../../Filters/UI/FilterSelectChip/IFilterSelectChip';
import ReferencesService from '../../../../services/references/references.service';
import { AxiosError } from 'axios';
import { FilterSkeleton } from '../../../Filters/UI/FilterSkeleton/FilterSkeleton';

interface ISelectFilterField {
  label:string;
  searchParamName:string;
  placeholder:string;
  isNoLabel?: boolean
  field:string;
}

export const SelectFilterField:FC<ISelectFilterField> = memo((props) => {
  const {
    label,
    placeholder,
    searchParamName,
    isNoLabel,
    field
  } = props

  const [selectItems, setSelectItems] = useState<null | IFilterSelectItem[]>(null)
  const [isLoading, setLoading] = useState(false)

  const getAllSelectItems = () => {
    if(field === 'edo') {
      setSelectItems(
        [
          {
            value: 'yes',
            name: 'Есть',
          },
          {
            value: 'no',
            name: 'Нет',
          }
        ]
      )
    }else{
      setLoading(true)

      ReferencesService.referencesContrActor({field})
        .then(res => {

          const newArray:IFilterSelectItem[] = res.data?.map(x=>{

            const newItem:IFilterSelectItem = {
              name:x.name,
              value:x.code,
            }
            return newItem
          })
          
          setSelectItems(newArray)
          setLoading(false)
        })
        .catch(err => {
          if (err instanceof AxiosError) {
             setLoading(false)
          }
        })
    }
  }

  useEffect(()=>{
    getAllSelectItems()
  }, [])

  return (
    <>
      {selectItems
        ? <FinanceSelect
            label={label}
            placeholder={placeholder}
            searchParamName={searchParamName}
            content={selectItems}
            isNoLabel={isNoLabel}
          />
        : <FilterSkeleton/>
      }
    </>
  )
})
