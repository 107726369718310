import {FC, useEffect, useState} from 'react'
import { IAgreementComment } from './IAgreementComment'
import { InputEditField } from '../InputEditField/InputEditField'
import { NotificationAlerts } from '../../../../NotificationAlerts/NotificationAlerts'
import AgreementsService from '../../../../../services/agreements/agreements.service'
import { IAgreementsRequestPutComment } from '../../../../../services/agreements/IAgreementsRequest'
import { AxiosError } from 'axios'
import { Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import userStore from '../../../../../store/user.store'
import dashboardStore from '../../../../../store/dashboard.store'
import {EmptyText} from "../../../../EmptyText/EmptyText";
import { EditIconButton } from '../../../../UI/Buttons/EditIconButton/EditIconButton'

export const AgreementComment:FC<IAgreementComment> = observer((props) => {
  const {agreementId, userId, value} = props
  
  const [isEdit, setEdit] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const [commentValue, setCommentValue] = useState<string | null>(value)

  const submit = (value:string) => {
    setLoading(true)
    setError(null)

    const option:IAgreementsRequestPutComment = {
      agreementId,
      comment:value,
      userId
    }
    AgreementsService.putComment(option)
      .then(res=>{
        // console.log(res,'put comment agree')

        if(dashboardStore.agreementsAll){
          dashboardStore.setComment(value, agreementId, userId)
        }

        setCommentValue(value)

        setEdit(false)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err,'put comment agree')
          setLoading(false)
          setError(err?.response?.data?.message)
        }
      })
  }

  useEffect(()=>{
    setCommentValue(value)
  },[value])

  return (
    <>
      {!isEdit &&
        <Stack direction={'row'} alignItems={'center'}>
          {commentValue === null || commentValue.trim() === '' && <EmptyText>Нет комментария</EmptyText>}
          {commentValue !== null && commentValue?.trim() !== '' && <Typography sx={{flexWrap:'wrap', lineBreak: 'auto'}}>{commentValue}</Typography>}
          {userStore.user && userStore.user?.id === userId &&
            <EditIconButton size='small' onClick={()=>setEdit(true)}/>
          }
        </Stack>
      }
      {isEdit &&
        <InputEditField
          initValue={commentValue ? commentValue : ''}
          inputType='text'
          placeholder='Введите комментарий'
          onClose={()=>setEdit(false)}
          onSubmit={submit}
          loading={isLoading}
          error={isError}
        />
      }
      {isError &&
        <NotificationAlerts
          error={{text:isError, open:Boolean(isError), onClose:()=>setError(null)}}
        />
      }
    </>
  )
})