export const disableAuedienceOnType = (type:string, field:string):boolean => {

  const allAudience:string[] = [
    'by', 
    'kz', 
    'ru', 
    'ua', 
    'audience',
    'targetAudience',
    '13-17', 
    '18-24', 
    '25-34', 
    '35-44', 
    '45-54',
    '55-64', 
    '65_plus', 
    'woman', 
    'man', 
    "subs", 
    "notSubs", 
    "fromPC", 
    "fromPhone",
  ]
  
  const telegrammAudience:string[] = allAudience.filter(x=>x === 'audience')

  const vkAudience:string[] = allAudience.filter(x=>x !== 'subs' && x !== 'notSubs' && x !== '55-64' && x !== '65_plus')

  const instAndYoutubeAudience:string[] = allAudience.filter(x=>x !== 'fromPC' && x !== 'fromPhone')

  if(type === 'telegram'){

    return telegrammAudience.includes(field) ? false : true

  } else if(type === 'vk') {

    return vkAudience.includes(field) ? false : true

  } else if(type === 'instagram' || type === 'youtube') {

    return instAndYoutubeAudience.includes(field) ? false : true

  } else return allAudience.includes(field) ? false : true

}
