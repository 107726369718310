import {FC} from 'react'
import { IStatusAddEmpty } from './IStatusAddEmpty'
import { StatusEmptyField } from '../../../../../../components/Table/ui/Fields/StatusEmptyField/StatusEmptyField'
import { observer } from 'mobx-react-lite'
import statusesStore from '../../../../../../store/statuses.store'
import integrationTableStore from '../../../../../../store/integrationTable.store'
import IntegrationsService from '../../../../../../services/integration/integrations.service'

export const StatusAddEmpty:FC<IStatusAddEmpty> = observer((props) => {
  const {
    id,
    update_id,
    onChangeUpdateId,
    setStatus,
    readOnly = false
  } = props

  const changeStatus = (id:number, update_id:number, value:string) => {
    setStatus(value)
    onChangeUpdateId(update_id)
    integrationTableStore.changeUpdateIdItem(id, update_id, value)
  }

  return (
    <StatusEmptyField
      id={id}
      update_id={update_id}
      statusStore={statusesStore.statuses}
      onChangeStatus={changeStatus}
      onUpdateStatus={IntegrationsService.updateIntegrationStatuses}
      readOnly={readOnly}
    />
  )
})