import {FC, useCallback, useMemo, useState} from 'react'
import { observer } from 'mobx-react-lite'
import { IIntegrationTableList } from '../../IntegrationTable/models/IIntegrationTableList'
import channelTableStore from '../../../store/channelTable.store'
import { TableItem } from '../../Table/ui/TableItem'
import ChannelService from '../../../services/channel/channel.service'
import statusesStore from '../../../store/statuses.store'
import { IBurgerItem } from '../../Table/models/ITableItem'
import { useNavigate } from 'react-router-dom'
import { CHANNELS_ROUTES } from '../../../shared/routes/routes'
import userStore from '../../../store/user.store'
import { RULES } from '../../../shared/rules/rules'
import { ALL_RULES } from '../../../shared/rules/interface/EnumRights'
import { WrapperRouteAgree } from '../../../pages/DashboardPage/pages/AgreementPage/ui/AgreementTableItem/ui/WrapperRouteAgree/WrapperRouteAgree'
import { CHANNEL_CARD_OPEN } from '../../../shared/constants/searchParams'
import {OpenInNew, PostAdd} from "@mui/icons-material";

interface IChannelTableListArr extends IIntegrationTableList {
  navigateChannel:(id:string | number)=>void;
}

export const ChannelTableListArr:FC<IChannelTableListArr> = observer((props) => {
  const {tableHead, navigateChannel} = props
  
  const navigate = useNavigate()

  const navigateAside = useCallback((id:string | number) => {
    navigate(`${id}/${CHANNELS_ROUTES.INTEGRATION}`)
  }, [])

  const [controlButtonPanel] = useState<IBurgerItem[]>([
    {
      title:'Открыть и посмотреть',
      onNavigate:navigateChannel,
      isShow:true,
      icon: <OpenInNew/>
    },
    {
      title:'Создать интеграцию',
      onNavigate:navigateAside,
      isShow:Boolean(userStore.user?.rights?.find(x=>x?.code === ALL_RULES.ADMIN)) || Boolean(userStore.user?.rights?.find(x=>x?.code === ALL_RULES.create_integration)),
      icon: <PostAdd/>
    },
  ])

  const checkUserOtherEdit = useCallback((channelFoundById:number):boolean => {
    if(userStore?.user?.rights?.find(el=>el.code === RULES.channels.edit) || userStore.user?.id === channelFoundById) {
      return true
    } else {
      return false
    }
  }, [])

  const checkUserEdit = useCallback((channelFoundById:number, isBanned:boolean):boolean => {
    if(userStore.user?.rights?.find(x=>x?.code === ALL_RULES.ADMIN)){
      return true
    } else if (isBanned) {
      return false
    } else if(checkUserOtherEdit(channelFoundById)) {
      return true
    } else return false
  }, [])

  const isHiddenEdit = useCallback(():boolean=> {
    if(userStore.user?.rights?.find(x=>x?.code === ALL_RULES.ADMIN || x?.code === ALL_RULES.edit_date_given || x?.code === ALL_RULES.release)) {
      return false
    } else return true
  }, [])

  const isHiddenEditDateFound = useCallback(():boolean=> {
    if(userStore.user?.rights?.find(x=>x?.code === ALL_RULES.ADMIN || x.code?.includes(ALL_RULES.search))) {
      return false
    } else return true
  }, [])

  const changeCallbackNumEdit = useCallback((currentId:number, field:string, newValue:string, update_id:number) => {
    channelTableStore.changeSimpleNumEdit(currentId, field, +newValue, update_id)
  },[])

  const getStatusAllowedMoves = useMemo(():string[] => {
    return Object.keys(statusesStore.channel)
  },[])

  return (
    <>
      {channelTableStore.tableItems.map(item=>
        <WrapperRouteAgree searchParamsKey={CHANNEL_CARD_OPEN} id={item.id} key={item.id}>
          <TableItem
            {...item}
            cells={tableHead}
            controlButtonPanel={controlButtonPanel}
            showSettingMenu
            onChangeStatus={channelTableStore.changeUpdateIdItem}
            onUpdateStatus={ChannelService.updateStatus}
            statusStore={statusesStore.channel}
            hover
            changeCallbackNumEdit={changeCallbackNumEdit}
            readonly={!checkUserEdit(item?.foundBy?.id, item?.isBanned)}
            showCheckboxCell
            allAllowedMoves={getStatusAllowedMoves}
            readOnlyDateGiven={item?.isBanned === true ? true : false}
            hiddenDateFoundEdit={isHiddenEditDateFound()}
            hiddenDateGivenEdit={isHiddenEdit()}
            hiddenDateGivenNotValueText={isHiddenEdit()}
            isBanned={item?.isBanned}
            backgroundColor={item.isBanned ? '#fff0f5' : '#fff'}
          />
        </WrapperRouteAgree>)
      }
    </>
  )
})