import {FC} from 'react'
import { IResizeButton } from './IResizeButton'
import { Box } from '@mui/material'

export const ResizeButton:FC<IResizeButton> = (props) => {
  const { onMouseDown, onMouseUp, sx} = props

  return (
    <Box
      sx={{
        cursor:'e-resize', 
        width:'4px', 
        background:'inherit', 
        height:'100%', 
        position:'absolute', 
        top:0, 
        left:0,
        '&:hover':{background:'#ffb904'},
        ...sx,
      }}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    />
  )
}
