import { FC, useState, useEffect } from 'react'
import { Box, CircularProgress, FormControl, IconButton, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { IIntegrationCategoryField } from './IIntegrationCategoryField'
import { observer } from 'mobx-react-lite'
import categoryStore from '../../../../../store/category.store'
import { Add, Close, Edit } from '@mui/icons-material'
import { IIntegrationCategory } from '../../../../../services/layout/layoutResponse'
import IntegrationsService from '../../../../../services/integration/integrations.service'
import { IUpdateStatusesRequest } from '../../../../../services/integration/IntegrationsRequest'
import { AxiosError } from 'axios'
import integrationTableStore from '../../../../../store/integrationTable.store'

export const IntegrationCategoryField:FC<IIntegrationCategoryField> = observer((props) => {
  const {category, id, update_id, readOnly, fullWidth = false} = props
  
  const [value, setValue] = useState<IIntegrationCategory | null>(category ? category : null)
  const [isEdit, setEdit] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const handleChange = (event: SelectChangeEvent<IIntegrationCategory>) => {
    setLoading(true)
    setError(null)

    const newCategory = event.target.value as IIntegrationCategory

    const option:IUpdateStatusesRequest = {
      field:'category',
      id,
      new_value:newCategory.code,
      update_id
    }

    IntegrationsService.updateSimpleField(option)
      .then(res=>{
        // console.log(res, 'res category')

        integrationTableStore.updateCategory(id, res.data.update_id, newCategory)
        setValue(newCategory)

        setEdit(false)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err change category')
          setError(err.response?.data.message)
          setLoading(false)
        }
      })
  }

  const openEdit = () => {
    setEdit(true)
  }

  const closeEdit = () => {
    setError(null)
    setEdit(false)
  }
  
  useEffect(()=>{
    setValue(category)
  },[category])

  return (
    <>
      {value === null && !isEdit &&
        <IconButton onClick={openEdit}>
          <Add/>
        </IconButton>
      }
      {!isEdit && value &&
        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
          <Typography sx={{whiteSpace:'nowrap'}}>{value ? value.name : 'Добавить категорию'}</Typography>
          {readOnly === false &&
            <IconButton size='small' onClick={openEdit}>
              <Edit fontSize='small'/>
            </IconButton>
          }
        </Stack>
      }
      {isEdit &&
        <>
          <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
            <FormControl size='small' sx={{minWidth:200}} disabled={isLoading} fullWidth={fullWidth}>
              <Select
                value={value ? value : ''}
                onChange={handleChange}
                size='small'
                renderValue={(val:IIntegrationCategory)=>
                  <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    <Typography>{val.name}</Typography>
                    {isLoading && <CircularProgress sx={{maxWidth:15, maxHeight:15}} />}
                  </Box>
                }
                error={isError ? true : false}
              >
                {categoryStore?.category &&
                  categoryStore.category.map(item=><MenuItem key={item.code} value={item as any}>{item.name}</MenuItem>)
                }
              </Select>
            </FormControl>
            <IconButton size='small' onClick={closeEdit} disabled={isLoading}>
              <Close fontSize='small'/>
            </IconButton>
          </Stack>
          <Typography color={'error'} variant='body2'>{isError}</Typography>
        </>
      }
    </>
  )
})