import {FC} from 'react'
import { ICommentField } from './ICommentField'
import { Tooltip, Typography } from '@mui/material'

export const CommentField:FC<ICommentField> = (props) => {
  const {text, subtext} = props

  return (
    <>
      {text.trim() !== ''
        ? <Tooltip title={<Typography>{text}</Typography>}>
            <Typography noWrap sx={{whiteSpace:'nowrap'}}>{text} {subtext ? subtext : null}</Typography>
          </Tooltip>
        : <Typography>—</Typography>
      }
    </>
  )
}