export const LS_TAKE_REQUEST_INTEGRATION = 'ls_take_request_integration'

export const LS_TAKE_REQUEST_CHANNEL = 'ls_take_request_channel'

export const LS_TAKE_REQUEST_FINANCES = 'ls_take_request_finances'

export const LS_TAKE_REQUEST_TZ = 'ls_take_request_tz'

export const LS_TAKE_REQUEST_AGREEMENTS = 'ls_take_request_tz'

export const LS_TAKE_REQUEST_GOODS = 'ls_take_request_goods'

export const LS_TAKE_REQUEST_PREPAYMENT = 'ls_take_request_prepayment'

// resize page

export const LS_SAVE_WIDTH_INTEGRATION = 'ls_save_width_integration'
export const LS_SAVE_WIDTH_USER = 'ls_save_width_user'
export const LS_SAVE_WIDTH_CHANNEL = 'ls_save_width_channel'
export const LS_SAVE_WIDTH_FINANCE = 'ls_save_width_finance'

// filters

export const LS_FILTERS_INTEGRATION = 'ls_filters_integration'
export const LS_FILTERS_ALL_INTEGRATION = 'ls_filters_all_integration'

export const LS_FILTERS_CHANNEL = 'ls_filters_channel'
export const LS_FILTERS_ALL_CHANNEL = 'ls_filters_all_channel'

export const LS_FILTERS_FINANCE = 'ls_filters_finance'
export const LS_FILTERS_ALL_FINANCE = 'ls_filters_all_finance'