import { FC, useState } from 'react'
import { IChannelAudienceEdit } from './IChannelAudienceEdit'
import { observer } from 'mobx-react-lite'
import { pink } from '@mui/material/colors'
import { ReactComponent as RuFlag } from '../../../../images/flags/ru.svg'
import { ReactComponent as ByFlag } from '../../../../images/flags/by.svg'
import { ReactComponent as UaFlag } from '../../../../images/flags/ua.svg'
import { ReactComponent as KzFlag } from '../../../../images/flags/kz.svg'
import { ChannelAudience } from '../ChannelAudience/ChannelAudience'
import channelCardStore from '../../../../store/channelCard.store'
import { Man, Woman } from '@mui/icons-material'
import ChannelService from '../../../../services/channel/channel.service'
import { IChannelUpdateStatisticRequest } from '../../../../services/channel/IChannelRequest'
import { AxiosError } from 'axios'
import channelTableStore from '../../../../store/channelTable.store'
import { getAudienceDataOnChannelType } from '../ChannelAudience/shared/functions'

export const ChannelAudienceEdit:FC<IChannelAudienceEdit> = observer((props) => {
  const {
    channelId,
    onChangeUpdateId,
    readOnly,
    type,
    update_id
  } = props

  const [isEditMode, setEditMode] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<null | string>(null)

  const onOpenEdit = () => {
    channelCardStore.startEditAudience()
    setEditMode(prev=>true)
  }
  const onCloseEdit = () => {
    channelCardStore.closeEditAudience()
    setEditMode(prev=>false)
  } 

  const submit = () => {
    setLoading(true)
    setError(null)

    const option: IChannelUpdateStatisticRequest = {
      channelId,
      update_id,
      "13-17":channelCardStore.editAudienceData?.['13-17'] as any !=='' && channelCardStore.editAudienceData?.['13-17'] !== null ? Number(channelCardStore.editAudienceData?.['13-17']) : null,
      "18-24":channelCardStore.editAudienceData?.['18-24'] as any !=='' && channelCardStore.editAudienceData?.['18-24'] !== null ? Number(channelCardStore.editAudienceData?.['18-24']) : null,
      "25-34":channelCardStore.editAudienceData?.['25-34'] as any !=='' && channelCardStore.editAudienceData?.['25-34'] !== null ? Number(channelCardStore.editAudienceData?.['25-34']) : null,
      "35-44":channelCardStore.editAudienceData?.['35-44'] as any !=='' && channelCardStore.editAudienceData?.['35-44'] !== null ? Number(channelCardStore.editAudienceData?.['35-44']) : null,
      "45-54":channelCardStore.editAudienceData?.['45-54'] as any !=='' && channelCardStore.editAudienceData?.['45-54'] !== null ? Number(channelCardStore.editAudienceData?.['45-54']) : null,
      "55-64":channelCardStore.editAudienceData?.['55-64'] as any !=='' && channelCardStore.editAudienceData?.['55-64'] !== null ? Number(channelCardStore.editAudienceData?.['55-64']) : null,
      "65_plus":channelCardStore.editAudienceData?.['65_plus'] as any !=='' && channelCardStore.editAudienceData?.['65_plus'] !== null ? Number(channelCardStore.editAudienceData?.['65_plus']) : null,
      audience:channelCardStore.editAudienceData?.audience as any !=='' && channelCardStore.editAudienceData?.audience !== null? Number(channelCardStore.editAudienceData?.audience) : null,
      by:channelCardStore.editAudienceData?.by as any !=='' && channelCardStore.editAudienceData?.by !== null ? Number(channelCardStore.editAudienceData?.by) : null,
      er:channelCardStore.editAudienceData?.er as any !=='' && channelCardStore.editAudienceData?.er !== null ? Number(channelCardStore.editAudienceData?.er) : null,
      err:channelCardStore.editAudienceData?.err as any !=='' && channelCardStore.editAudienceData?.err !== null ? Number(channelCardStore.editAudienceData?.err) : null,
      fromPC:channelCardStore.editAudienceData?.fromPC as any !=='' && channelCardStore.editAudienceData?.fromPC !== null ? Number(channelCardStore.editAudienceData?.fromPC) : null,
      fromPhone:channelCardStore.editAudienceData?.fromPhone as any !=='' && channelCardStore.editAudienceData?.fromPhone !== null ? Number(channelCardStore.editAudienceData?.fromPhone) : null,
      kz:channelCardStore.editAudienceData?.kz as any !=='' && channelCardStore.editAudienceData?.kz !== null ? Number(channelCardStore.editAudienceData?.kz) : null,
      man:channelCardStore.editAudienceData?.man as any !=='' && channelCardStore.editAudienceData?.man !== null ? Number(channelCardStore.editAudienceData?.man) : null,
      notSubs:channelCardStore.editAudienceData?.notSubs as any !=='' && channelCardStore.editAudienceData?.notSubs !== null ? Number(channelCardStore.editAudienceData?.notSubs) : null,
      ru:channelCardStore.editAudienceData?.ru as any !=='' && channelCardStore.editAudienceData?.ru !== null ? Number(channelCardStore.editAudienceData?.ru) : null,
      subs:channelCardStore.editAudienceData?.subs as any !=='' && channelCardStore.editAudienceData?.subs !== null ? Number(channelCardStore.editAudienceData?.subs) : null,
      ua:channelCardStore.editAudienceData?.ua as any !=='' && channelCardStore.editAudienceData?.ua !== null ? Number(channelCardStore.editAudienceData?.ua) : null,
      woman:channelCardStore.editAudienceData?.woman as any !=='' && channelCardStore.editAudienceData?.woman !== null ? Number(channelCardStore.editAudienceData?.woman) : null,
    }

    ChannelService.updateStatisticData(option)
      .then(res=>{

        channelCardStore.setAudienceData(getAudienceDataOnChannelType(res.data))

        channelCardStore.setStatisticDate(res?.data?.statisticDate)
        channelCardStore.setTargetAudience(res.data?.targetAudience)
        channelCardStore.setAllAge(res.data?.allAge)

        onChangeUpdateId(res.data?.update_id)

        channelTableStore.updateAudience(res.data?.id, getAudienceDataOnChannelType(res.data), res.data?.update_id)
        channelTableStore.changeField({id:res.data?.id, field:'targetAudience', new_value:res.data?.targetAudience as any, update_id:res.data?.update_id})
        channelTableStore.changeField({id:res.data?.id, field:'statisticDate', new_value:res?.data?.statisticDate as any, update_id:res.data?.update_id})

        onCloseEdit()
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setError(err?.response?.data?.message)
          setLoading(false)
        }
      })
  }

  return (
    <>
      {channelCardStore.audienceData &&
        <ChannelAudience
          isEdit={isEditMode}
          onOpenEdit={onOpenEdit}
          onCloseEdit={onCloseEdit}
          loadingEdit={isLoading}
          data={channelCardStore.audienceData}
          editData={channelCardStore.editAudienceData}
          targetAudience={channelCardStore.targetAudience}
          disabledEdit={readOnly}
          allAge={
            isEditMode
              ? channelCardStore.editAllAge
              : channelCardStore.allAge
          }
          statisticDate={channelCardStore.statisticDate}
          type={type}
          changeField={channelCardStore.changeFieldEditAudience}
          submit={submit}
          disabledInputs={isLoading}
          icons={{
            byIcon:<ByFlag height={30} width={30}/>,
            kzIcon:<KzFlag height={30} width={30}/>,
            maleIcon:<Man color='secondary' sx={{height:30, width:30}}/>,
            ruIcon:<RuFlag height={30} width={30}/>,
            uaIcon:<UaFlag height={30} width={30}/>,
            womanIcon:<Woman sx={{height:30, width:30, color:pink[200]}}/>,
          }}
        />  
      } 
    </>    
  )
})