import {FC} from 'react'
import { Box, Checkbox,ListItemText, Typography } from '@mui/material'
import { ITableSettingsItem } from '../models/ITableSettingsItem'
import {DragIndicator } from '@mui/icons-material'
import {RemoveItemButton} from "../../UI/Buttons/RemoveItemButton/RemoveItemButton";
import { purple_color } from '../../../app/styles/variables';

export const TableSettingsItem:FC<ITableSettingsItem> = (props) => {
  const {
    code,
    name,
    ordering,
    isSelected,
    onChangeCheckbox,
    onDelete,
    onClick,
    isConfirmItem = false,
    isDraggable = false,
    isShowDelete = false,
    disabled = false,
    onDragOver,
    onDragStart,
    onDrop,
  } = props

  return (
    <Box
      sx={{
        cursor:isDraggable ? 'move' : 'pointer',
        minHeight:'35px',
        pl:isDraggable ? 0 : 1,
        display:'flex',
        alignItems:'center',
        '&:hover':{
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          color:isDraggable ? 'auto' : purple_color
        },
        transition:'all ease .3s'
      }}
      onClick={disabled ? undefined : onClick}
      draggable={isDraggable}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      {isDraggable &&
        <DragIndicator sx={{mr:1, opacity:'.5'}}/>
      }
      {!isConfirmItem &&
        <Checkbox
          edge="start"
          checked={Boolean(isSelected)}
          sx={{mr:1}}
          disabled={disabled}
          size='small'
        />
      }
      <ListItemText
        primary={<Typography>{name}</Typography>}
      />
      {isShowDelete &&
        <RemoveItemButton onClick={onDelete}/>
      }
    </Box>
  )
}
