import { makeAutoObservable } from "mobx";
import { IFinancesPaymentItem } from "../pages/FinancesCreatePage/ui/FinancesPayments/ui/FinancesPaymentItem/IFinancesPaymentItem";
import { IContractorCreateResponse } from "../services/contractor/IContractorIResponse";
import { ILastPayInfo } from "../services/channel/ICnannelResponse";

class CreatePaymentStore {
  
  releaseUserId:string | null = null

  plannedPaidAt:string | null = null

  comment:string | null = null

  allSumm:number | null = null

  integrationIds:number[] = []

  integrationContacts:string[] = []

  pays:IFinancesPaymentItem[] = [
    // {
    //   id:1,
    //   paymentType:'bank',
    //   plannedPaidAt:null,
    //   source:'ip-bokova',
    //   direction:null,
    //   totalSumm:null,
    //   cardOrPhone:null,
    //   fio:null,
    //   paymentLink:null,
    //   selfEmployedCheck:null
    // }
  ]

  counteragent:IContractorCreateResponse | null = null

  lastPayInfo:null | ILastPayInfo = null

  constructor(){
    makeAutoObservable(this)
  }

  changeComment = (comment:string | null) => {
    this.comment = comment
  }

  setLastPayInfo = (lastPayInfo:ILastPayInfo | null) => {
    this.lastPayInfo = lastPayInfo

    if(this.pays?.length>0){
      this.pays = this.pays.map(item=>{
        if(item.paymentType === 'card'){
          return {
            ...item, 
            cardOrPhone:this.lastPayInfo?.phoneOrCard?.replaceAll(' ','') || null, 
            fio:this.lastPayInfo?.fio || null,
          }
        } else return item
      })
    }
  }

  setIntegrationContacts = (integrationContact:string, isDeleteContacts:boolean) => {
    if(isDeleteContacts === false){
      this.integrationContacts = [...this.integrationContacts, integrationContact]
    } else {
      
      this.integrationContacts = [...this.integrationContacts.filter(x=>x !== integrationContact)]
    }
  }

  setReleaseUserId = (releaseUserId:string | null) => {
    this.releaseUserId = releaseUserId
  }

  setPlannedPaidAt = (plannedPaidAt:string | null) => {
    this.plannedPaidAt = plannedPaidAt
  }
  
  setAllSumm = (allSumm:number | null) => {
    this.allSumm = allSumm

    if(this.pays.length <= 1){
      this.pays = this.pays.map(item=>{
        return {...item, totalSumm:allSumm}
      })
    }
  }

  addIntegrationsIds = (id:number) => {
    this.integrationIds = [...this.integrationIds, id]
  }

  deleteIntegrationsIds = (id:number) => {
    const deltedArray = this.integrationIds.filter(x=>x !== id)

    this.integrationIds = deltedArray
    
    if(deltedArray.length === 0){
      this.allSumm = null
      
      this.pays = this.pays.map(item=>{
        return {...item, totalSumm:null}
      })
    }

    if(this.pays?.length>0){
      this.pays = this.pays.map(item=>{
        if(item.paymentType === 'card'){
          return {
            ...item, 
            cardOrPhone:this.lastPayInfo?.phoneOrCard?.replaceAll(' ','') || null, 
            fio:this.lastPayInfo?.fio || null,
          }
        } else return item
      })
    }
  }

  addPays = (pay:IFinancesPaymentItem, isFirstItem:boolean) => {
    if(isFirstItem){
      this.pays = [pay]
    } else {
      const newArr = [...this.pays, pay]

      this.pays = newArr.map(item=>{
        return {...item, totalSumm:newArr?.length <= 1 ? this.allSumm : null}
      })
    }
  }

  changePaymentType = (id:number, field:string, typeValue:string) => {
    this.pays = this.pays.map(item=>{
      if(item.id === id){

        if(typeValue === "card"){

          const pay:IFinancesPaymentItem = {
            id:Number(new Date()),
            direction:null,
            paymentType:'card',
            plannedPaidAt:item.plannedPaidAt,
            source:null,
            totalSumm:item.totalSumm,
            cardOrPhone:this.lastPayInfo?.phoneOrCard?.replaceAll(' ','') || null,
            fio:this.lastPayInfo?.fio || null,
            paymentLink:null,
            selfEmployedCheck:null
          }

          return pay

        } else {

          const pay:IFinancesPaymentItem = {
            id:Number(new Date()),
            direction: this.counteragent?.id?.toString() || null,
            paymentType:typeValue,
            plannedPaidAt:item.plannedPaidAt,
            source:'ip-bokova',
            totalSumm:item.totalSumm,
            cardOrPhone:null,
            fio:null,
            paymentLink:null,
            selfEmployedCheck:null
          }

          return pay
        }

      } else return item
    })
  }

  changeFieldPaysItem = (id:number, field:string, newValue:string) => {
    this.pays = this.pays.map(item=>{
      if(item.id === id){
        return {...item, [field]:newValue}
      } else return item
    })
  }

  deletePays = (id:number) => {
    const filterArr = this.pays.filter(x=>x.id !== id)

    this.pays = filterArr.map(item=>{
      return {...item, totalSumm:filterArr?.length <= 1 ? this.allSumm : item.totalSumm}
    })
  }

  setCounteragent = (counteragent:IContractorCreateResponse | null) => {
    this.counteragent = counteragent

    this.pays = this.pays.map(item=>{
      if(item.paymentType === 'bank'){
        return {...item, direction:`${counteragent?.id}`}
      } else return item
    })
  }

  clearCreateStore = () => {
    this.releaseUserId = null
    this.plannedPaidAt = null
    this.allSumm = null
    this.counteragent = null
    this.comment = null
    this.integrationIds = []
    this.integrationContacts = []
    this.pays = [
      // {
      //   id:1,
      //   paymentType:'bank',
      //   plannedPaidAt:null,
      //   source:'ip-bokova',
      //   direction:null,
      //   totalSumm:null,
      //   cardOrPhone:null,
      //   fio:null,
      //   paymentLink:null,
      //   selfEmployedCheck:null
      // }
    ]
    this.lastPayInfo = null
  }

}

export default new CreatePaymentStore()