import {FC} from 'react'
import { IAsideSelectManager } from './IAsideSelectManager'
import {Box, MenuItem, Select, SelectChangeEvent, Skeleton, Stack, Tooltip} from '@mui/material'
import { observer } from 'mobx-react-lite'
import AllUsersStore from '../../../../store/AllUsers.store'
import { IFilterSelectItem } from '../../../../components/Filters/UI/FilterSelectChip/IFilterSelectChip'
import { getManagerFilterItems } from '../../../../components/IntegrationTable/shared/dataList'
import {RemoveItemButton} from "../../../../components/UI/Buttons/RemoveItemButton/RemoveItemButton";

export const AsideSelectManager:FC<IAsideSelectManager> = observer((props) => {
  const {
    initValue, 
    onChange, 
    isLoading, 
    isError,
    readOnly = false,
    onDelete,
    minWidth,
    maxWidth,
    width,
    isDeleteIcon = true,
    placeholder,
  } = props

  const manager:IFilterSelectItem[] | undefined = AllUsersStore?.users ? getManagerFilterItems(AllUsersStore?.users) : undefined

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value)
  }
  
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={0.5} minWidth={minWidth} width={width} maxWidth={maxWidth}>
      {manager
        ? <Select
            displayEmpty
            value={initValue ? initValue : ''}
            onChange={handleChange}
            disabled={isLoading || readOnly}
            fullWidth
            size='small'
            sx={{minWidth:'100px'}}
            renderValue={selected => selected ? manager?.find(x => x.value === selected)?.name : <Box sx={{ color: 'grey.500' }}>{placeholder || ''}</Box>}
          >
            {manager.map(item=>
              <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)
            }
          </Select>
        : <Skeleton variant='rounded' sx={{minHeight:40, width:'100%'}}/>
      }
      {manager && isDeleteIcon &&
        <Tooltip title='Удалить'>
          <Box>
            <RemoveItemButton isDisableColor={isLoading|| readOnly} onClick={onDelete} disabled={isLoading || readOnly}/>
          </Box>
        </Tooltip>
      }
    </Stack>
  )
})