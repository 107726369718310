import { FC, useState } from "react";
import { IIntegrationTargetAudience } from "./IIntegrationTargetAudience";
import { Box, ClickAwayListener, Stack, Tooltip, Typography } from "@mui/material";
import { getAudiencePercentColor } from "../../../../../ChannelCardPage/ui/ChannelAudience/shared/functions";
import { ErrorOutline, Man, Woman } from "@mui/icons-material";
import { IntegrationAudienceCard } from "./ui/IntegrationAudienceCard/IntegrationAudienceCard";
import { CustomIconButton } from "../../../../../../components/UI/Buttons/CustomIconButton/CustomIconButton";
import { pink } from "@mui/material/colors";

export const IntegrationTargetAudience: FC<IIntegrationTargetAudience> = (props) => {
  const { targetAudience, audienceData } = props;

  const [isShowAudience, setShowAudience] = useState(false);

  const handleTooltipClose = () => {
    setShowAudience(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <Typography>ЦА:</Typography>
        <Stack direction={"row"}>
          <Typography
            sx={{
              backgroundColor: getAudiencePercentColor(+targetAudience),
              padding: "4px 8px",
              borderRadius: 1,
            }}
            fontWeight={"bold"}>
            {targetAudience}%
          </Typography>
          <Stack direction={'row'}>
            <Stack direction={'row'} alignItems={'center'}>
              <Man color="secondary" sx={{ height: 30, width: 30 }} />
              <Typography fontWeight={'bold'}>{audienceData.man}%</Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'}>
              <Woman sx={{ height: 30, width: 30, color: pink[200] }} />
              <Typography fontWeight={'bold'}>{audienceData.woman}%</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Tooltip
          enterTouchDelay={0}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClose={handleTooltipClose}
          open={isShowAudience}
          placement={"right-end"}
          slotProps={{
            tooltip: {
              sx: { background: "#383e4c", minWidth: { xs: 230, sm: 400 }, p: "8px 16px" },
            },
          }}
          PopperProps={{
            disablePortal: true,
            placement: "auto",
          }}
          title={<IntegrationAudienceCard data={audienceData} />}>
          <Box>
            <CustomIconButton size="small" onClick={() => setShowAudience((prev) => !prev)}>
              <ErrorOutline fontSize="small" />
            </CustomIconButton>
          </Box>
        </Tooltip>
      </Stack>
    </ClickAwayListener>
  );
};
