import {FC, useState} from 'react'
import { IAsideSettingsItem } from '../models/IAsideSettingsItem'
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

export const AsideSettingsItem:FC<IAsideSettingsItem> = (props) => {
  const {title, group, icon} = props

  const [open, setOpen] = useState(false)

  return (
    <>
      <ListItemButton onClick={()=>setOpen(prev=>!prev)}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {group.map(item=>
            <ListItemButton key={item.name} sx={{ pl: 4 }}>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>)
          }
        </List>
      </Collapse>
    </>
  )
}
