import {FC, useState} from 'react'
import { AsideCardLayout } from '../../../../components/AsideCardLayout/AsideCardLayout'
import { Box } from '@mui/material'
import { getFieldStatisticsChannel, getStatisticsChannel } from '../../shared/dataList'
import { IChannelAsideStatistic } from './IChannelAsideStatistic'
import { TableDefault } from '../../../../components/Table/TableDefault'
import { TableItem } from '../../../../components/Table/ui/TableItem'
import { IAsideTableStatisticField } from '../../../TableAsidePage/ui/AsideTableStatistic/models/IAsideTableStatisticField'
import channelTableStore from '../../../../store/channelTable.store'
import { IUpdateStatusesRequest } from '../../../../services/integration/IntegrationsRequest'

export const ChannelAsideStatistic:FC<IChannelAsideStatistic> = (props) => {
  const {
    data, 
    id, 
    update_id, 
    onChangeUpdateId, 
    readOnly
  } = props

  const [ctrValue, setCtrValue] = useState<number>(data.ctr)

  const createItem = (fields:IAsideTableStatisticField[]):Object => {
    const item:any = {
      id,
      update_id,
    }

    for(let property of fields) {
      item[property.code] = property.value
    }

    return item
  }

  const changeCallbackNumEdit = (currentId:number,field:string, newValue:string, update_id:number) => {

    setCtrValue(+newValue)

    const option:IUpdateStatusesRequest = {
      field,
      id:currentId,
      new_value:+newValue as any,
      update_id
    }

    channelTableStore.changeField(option)
    
    if(onChangeUpdateId) {
      onChangeUpdateId(update_id)
    }
  }

  return (
    <AsideCardLayout headerTitle='Статистика' showDivider={false}>
      <Box sx={{mt:1}}>
        <TableDefault tableRow={getStatisticsChannel()} showBorderRadius={false} maxWidth='100%'>
          <TableItem 
            cells={getStatisticsChannel()}
            {...createItem(getFieldStatisticsChannel(data, ctrValue))} 
            changeCallbackNumEdit={changeCallbackNumEdit} 
            onChangeUpdateId={onChangeUpdateId}
            readonly={readOnly}
          />
        </TableDefault>
      </Box>
    </AsideCardLayout>
  )
}