import {FC} from 'react'
import { IPlanTableItem } from './IPlanTableItem'
import { observer } from 'mobx-react-lite'
import AllUsersStore from '../../../../../../../../store/AllUsers.store'
import { IUser } from '../../../../../../../../entities/user/models/IUser'
import { Skeleton, Stack, TableCell, TableRow, Typography } from '@mui/material'
import { PlanInputItem } from '../PlanInputItem/PlanInputItem'

export const PlanTableItem:FC<IPlanTableItem> = observer((props) => {
  const {
    crFound,
    crGiven,
    factFound,
    factGiven,
    id,
    isGeneral,
    month,
    planFound,
    planGiven,
    userId,
    year,
      noEdit
  } = props

  const currentUser:IUser | undefined = AllUsersStore.users ? AllUsersStore.users?.find(user=>user.id === userId) : undefined

  return (
    <TableRow>
      <TableCell sx={{p:1}}>
        {currentUser
          ? <Typography>{`${currentUser?.firstName} ${currentUser?.lastName}`}</Typography>
          : <Skeleton variant='rounded' sx={{height:30, width:150}}/>
        }
      </TableCell>
      <TableCell sx={{p:0, border:'none'}}>
        <Stack>
          <TableCell sx={{p:2, minHeight:'55px', ml:'-1px'}}>
            <Typography>Найден</Typography>
          </TableCell>
          <TableCell sx={{p:2, minHeight:'55px', ml:'-1px'}}>
            <Typography>Передан</Typography>
          </TableCell>
          <TableCell sx={{p:2, minHeight:'55px', ml:'-1px'}}>
            <Typography noWrap>Конверсия (CR)</Typography>
          </TableCell>
        </Stack>
      </TableCell>
      <TableCell sx={{p:0, border:'none'}}>
        <Stack>
          <TableCell sx={{p:1, minHeight:'55px', ml:'-1px'}}>
            <Typography sx={{height: '38px', display: 'flex', alignItems: 'center'}}>
              {userId !== null && !noEdit &&
                <PlanInputItem
                  field='planFound'
                  month={month}
                  planType='found_channels'
                  userId={userId}
                  value={planFound}
                  year={year}
                />
              }
              {userId !== null && noEdit &&
                  <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                    <Typography>{(planFound)}</Typography>
                  </Stack>
              }
            </Typography>
          </TableCell>
          <TableCell sx={{p:1, minHeight:'55px', ml:'-1px'}}>
            <Typography sx={{height: '38px', display: 'flex', alignItems: 'center'}}>
              {userId !== null && !noEdit &&
                <PlanInputItem
                  field='planGiven'
                  month={month}
                  planType='given_channels'
                  userId={userId}
                  value={planGiven}
                  year={year}
                />
              }
              {userId !== null && noEdit &&
                <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                  <Typography>{(planGiven)}</Typography>
                </Stack>
              }
            </Typography>
          </TableCell>
          <TableCell sx={{p:1, minHeight:'55px', ml:'-1px'}}>
            <Typography sx={{height: '38px', display: 'flex', alignItems: 'center'}}>
              {crFound || crFound === 0 ? crFound : 0}%
            </Typography>
          </TableCell>
        </Stack>
      </TableCell>
      <TableCell sx={{p:0, border:'none'}}>
        <Stack>
          <TableCell sx={{p:1, minHeight:'55px', ml:'-1px'}}>
            <Typography sx={{height: '38px', display: 'flex', alignItems: 'center'}}>
              {factFound || factFound === 0 ? factFound : 0}
            </Typography>
          </TableCell>
          <TableCell sx={{p:1, minHeight:'55px', ml:'-1px'}}>
            <Typography sx={{height: '38px', display: 'flex', alignItems: 'center'}}>
              {factGiven || factGiven === 0 ? factGiven : 0}
            </Typography>
          </TableCell>
          <TableCell sx={{p:1, minHeight:'55px', ml:'-1px'}}>
            <Typography sx={{height: '38px', display: 'flex', alignItems: 'center'}}>
              {crGiven || crGiven === 0 ? crGiven : 0} %
            </Typography>
          </TableCell>
        </Stack> 
      </TableCell>
    </TableRow>
  )
})
