import { FC, useState } from 'react'
import { IFilterPresetItem } from './IFilterPresetItem'
import { Box, CircularProgress, Tooltip, Typography } from '@mui/material'
import ReferencesService from '../../../../services/references/references.service'
import { AxiosError } from 'axios'
import { useSearchParams } from 'react-router-dom'
import { AlertModal } from '../../../AlertModal/AlertModal'
import {RemoveItemButton} from "../../../UI/Buttons/RemoveItemButton/RemoveItemButton";
import {CancelButton} from "../../../UI/Buttons/CancelButton/CancelButton";

export const FilterPresetItem:FC<IFilterPresetItem> = (props) => {
  const {
    id, 
    name, 
    forAll, 
    entity, 
    preset,
    user, 
    onClick, 
    onDelete, 
    readOnly
  } = props

  const [searchParams, setSearchParams] = useSearchParams(preset)
  
  const [isShowDeleteModal, setShowDeleteModal] = useState(false)
  const [isRemoveLoading, setRemoveLoading] = useState(false)

  const deleteCurrentPreset = () => {
    setRemoveLoading(true)

    const option = {
      presetId:id
    }
    ReferencesService.deletePresetFilter(option)
      .then(res=>{
        // console.log(res, 'res remove preset')
        onDelete(id)
        setShowDeleteModal(false)
        setRemoveLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err remove preset')
          setRemoveLoading(false)
        }
      })
  }

  const handelClick = () => {
    setSearchParams(preset)

    const params = new URLSearchParams(preset)
    onClick(params)
  }

  return (
    <>
      <Tooltip title={<Typography>{name}</Typography>}>
        <Box>
          <CancelButton
            variant="outlined"
            sx={{pt:0.5,pb:0.5,pr:0.5,pl:1, borderRadius:'4px', display:'flex', alignItems:'center', justifyContent:'space-between',textTransform:'none', width:'100%'}}
            onClick={handelClick}
          >
            <Typography
              sx={{cursor:'pointer', maxWidth:'125px'}}
              tabIndex={0}
              noWrap
            >
              {name}
            </Typography>
            {!isRemoveLoading
              ? <Box onClick={e=>e.stopPropagation()}>
                  {!readOnly &&
                    <RemoveItemButton onClick={()=>setShowDeleteModal(true)}/>
                  }
                </Box>
              : <CircularProgress sx={{maxHeight:27, maxWidth:27, ml:1}} />
            }
          </CancelButton>
        </Box>
      </Tooltip>
      <AlertModal
        title='Подтверждение'
        subtext='Вы действительно хотите удалить фильтры?'
        open={isShowDeleteModal}
        onClose={()=>setShowDeleteModal(false)}
        onSubmit={deleteCurrentPreset}
        loading={isRemoveLoading}
        buttonCancelName='отмена'
        buttonSubmitName='удалить'
        colorSubmit='error'
      />
    </>
  )
}