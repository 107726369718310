import {
  IAllManagerSummaryResponse,
  IManagerSummaryAllStatsResponse,
  IManagerSummaryStatsResponse
} from "../../../../../../../services/statistics/IStatisticsResponse"
import {ILayoutResponse} from "../../../../../../../services/layout/layoutResponse";

export const getMangerRowStaistics = (manager:IAllManagerSummaryResponse, channelData:ILayoutResponse['channelTypes']) => {

  const basicStats = [
    { key: 'released', title: 'Выпущено всего' },
    { key: 'all', title: 'В графике' }
  ];
  const channelStats = manager?.stats?.channelTypeStats ? Object.keys(manager.stats.channelTypeStats).map(key => ({
    key: key,
    title: channelData[key]?.name,
  })) : [];
  const allStats = [...basicStats, ...channelStats];
  return allStats.map(({ key, title }) => ({
    type:{
      type:{code:key},
      name:title
    },
    ...manager?.stats[key as keyof IManagerSummaryAllStatsResponse],
    ...manager?.stats?.channelTypeStats[key]
  }));

}