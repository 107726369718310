import {FC, memo, useState} from "react";
import {useSearchParams} from "react-router-dom";
import { IFilterSelectItem } from "../../UI/FilterSelectChip/IFilterSelectChip";
import { getFilterPaymentsQuery } from "../../../FinancecTable/shared/dataList";
import ReferencesService from "../../../../services/references/references.service";
import {AxiosError} from "axios";
import { FIlterFetchSelectChip } from "../../UI/FIlterFetchSelectChip/FIlterFetchSelectChip";
import {IFilterSearchPaymentDirection} from "./IFilterSearchPaymentDirection";

export const FilterSearchPaymentDirection:FC<IFilterSearchPaymentDirection> = memo((props) => {
  const {searchParamsName, limit} = props
  const [searchParams, setSearchParams] = useSearchParams()

  const queryValue:IFilterSelectItem[] = JSON.parse(searchParams.get(searchParamsName) || '[]')

  const [channels, setChannels] = useState<IFilterSelectItem[]>([])
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<null | string>(null)
  const search = (value:string) =>{
    setLoading(true)
    setError(null)

    const option:{ target: string | number,paymentType:string[]} = {
      target:value,
      paymentType: getFilterPaymentsQuery(searchParams).paymentType || []
    }
    ReferencesService.getRecipientName(option)
      .then(res=>{
        const items = res.data.map(el=>{
          const key = el.id || el?.code;
          const item:IFilterSelectItem = {
            name:el.name,
            value:String(key),
          }
          return item
        })
        setChannels(items)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setError(err.response?.data?.message)
          setLoading(false)
        }
      })
  }

  const changeUrlValue = (items:IFilterSelectItem[]) => {
    if (items.length === 0 ){
      searchParams.delete(searchParamsName)
      setSearchParams(searchParams)
    } else {
      searchParams.set(searchParamsName, JSON.stringify(items))
      setSearchParams(searchParams)
    }
  }

  const resetErr = () => setError(null)

  return (
    <FIlterFetchSelectChip
      label="Куда оплачиваем"
      content={channels}
      searchParamName={searchParamsName}
      placeholder="Выберите направление оплаты"
      loading={isLoading}
      onSearch={search}
      error={!!isError}
      onResetError={resetErr}
      initValue={queryValue}
      changeUrlValue={changeUrlValue}
      limitTags={limit}
    />
  )
})