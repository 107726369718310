import {FC, useEffect, useState} from 'react'
import { ITheameField } from './ITheameField'
import { Skeleton } from '@mui/material'
import { AsideCardThemeList } from '../../../../../pages/TableAsidePage/ui/AsideCardThemeList/AsideCardThemeList'
import channelTheame from '../../../../../store/channelTheame'
import { observer } from 'mobx-react-lite'
import { IChannelChangeThemeRequest } from '../../../../../services/channel/IChannelRequest'
import channelTableStore from '../../../../../store/channelTable.store'
import ChannelService from '../../../../../services/channel/channel.service'
import { AxiosError } from 'axios'

export const TheameField:FC<ITheameField> = observer((props) => {
  const {
    shouldHideSelect,
    code, 
    isActive, 
    name, 
    update_id, 
    id, 
    onClose, 
    minWidth,
    readOnly
  } = props

  const [isLoading, setLoading] = useState(false)

  const [themeValue, setThemeValue] = useState({code, isActive, name})

  const changeTheme = (value:string, code:string, isActive:boolean) => {
    if(update_id){
      setLoading(true)

      const theme:IChannelChangeThemeRequest = {
        id:+id!,
        new_theme:{
          code,
          isActive,
          name:value
        },
        update_id
      }
      ChannelService.changeTheme(theme)
        .then(res=>{
          // console.log(res, 'res change theme')
          channelTableStore.changeTheme(+id!, {code, isActive, name:value}, res.data.update_id)

          if(onClose){
            onClose()
          }

          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError) {
            // console.log(err, 'change theme err')
            setLoading(false)
          }
        })
    }
  }

  useEffect(()=>{
    setThemeValue({code, isActive, name})
  },[code])

  return (
    <>
      {channelTheame.theame === null && <Skeleton variant='rounded' sx={{minHeight:30, minWidth:'150px'}}/>}
      {channelTheame.theame !== null &&
        <AsideCardThemeList
				  shouldHideSelect={shouldHideSelect}
          value={themeValue}
          onChange={changeTheme}
          lists={[...channelTheame?.theame]?.sort((a,b)=>a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1)?.filter(x=>x.isActive === true)}
          loading={isLoading} 
          minWidth={minWidth ? minWidth : 200}
          label={false}
          readOnly={readOnly}
          channelId={id}
          update_id={update_id}
          maxWidth={260}
        />
      }
    </>
  )
})