import {useState, memo} from 'react'
import { FIlterFetchSelectChip } from '../../../Filters/UI/FIlterFetchSelectChip/FIlterFetchSelectChip'
import ReferencesService from '../../../../services/references/references.service'
import { AxiosError } from 'axios'
import { IFilterSelectItem } from '../../../Filters/UI/FilterSelectChip/IFilterSelectChip'
import { useSearchParams } from 'react-router-dom'

export const FilterSeacrhAgency = memo(() => {

  const [searchParams, setSearchParams] = useSearchParams()

  const queryValue:string[] = searchParams.get('agency')?.split(',') || []

  const queryItems:any[] = queryValue.map(val=>{
    return {name:val, value:val}
  })

  const [channels, setChannels] = useState<IFilterSelectItem[]>([])
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<null | string>(null)

  const search = (value:string) =>{
    setLoading(true)
    setError(null)

    const option:{target: string} = {
      target:value
    }
    ReferencesService.getHintsAgencylName(option)
      .then(res=>{
        // console.log(res, 'res seact channel')

        const items = res.data.map(x=>{
          const item:IFilterSelectItem = {name:x, value:x}
          return item
        })

        setChannels(items)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err search channel')
          setError(err.response?.data?.message)
          setLoading(false)
        }
      })
  }

  const changeUrlValue = (items:IFilterSelectItem[]) => {
    if (items.length === 0 ){
      searchParams.delete('agency')
      setSearchParams(searchParams)
    } else {
      searchParams.set('agency', `${items.map(x=>x.value)}`)
      setSearchParams(searchParams)
    }
  }

  const resetErr = () => setError(null)

  return (
    <FIlterFetchSelectChip
      label="Агентство"
      placeholder="Выберите агентство"
      searchParamName="agency"
      content={channels}
      loading={isLoading}
      onSearch={search}
      error={isError ? true : false}
      onResetError={resetErr}
      initValue={queryItems}
      changeUrlValue={changeUrlValue}
      limitTags={3}
    />
  )
})