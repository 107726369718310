import { ISettingsFilters, IStatusesChannel, IStatusesPayments } from "../../../../../Statuses/models/ISettingsFilters";
import { IStatusEmptyItem } from "../IStatusEmptyField";

export const getAllStatus = (statusStore:ISettingsFilters | IStatusesChannel | IStatusesPayments):IStatusEmptyItem[] => {

  const allStatuses:IStatusEmptyItem[] = Object.keys(statusStore).map(key=>{

    const item:IStatusEmptyItem = {
      code:key,
      hexColor:statusStore[key]?.hexColor,
      name:statusStore[key]?.name
    }

    return item
  })

  return allStatuses
}