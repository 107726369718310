export const black_color = '#2E2F40'

export const purple_color = '#503D9D'

export const purple_middle_color = '#7C64D9'

export const purple_light_color = '#E2DBFF'

export const red_color = '#FF5252'

export const red_middle_color = '#FFC7C7'

export const red_light_color = '#FFDBDB'