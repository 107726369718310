import {useEffect, useRef} from 'react'
import { AsideCardLayout } from '../../../../components/AsideCardLayout/AsideCardLayout'
import { Box, Stack, Typography, } from '@mui/material'
import { observer } from 'mobx-react-lite'
import createChannelStore from '../../../../store/createChannel.store'
import { CreateAsideInputField } from './ui/CreateAsideInputField/CreateAsideInputField'
import { CreateAsideCommentItem } from './ui/CreateAsideCommentItem/CreateAsideCommentItem'
import userStore from '../../../../store/user.store'
import { toJS } from 'mobx'
import {EmptyText} from "../../../../components/EmptyText/EmptyText";

export const CreateAsideComments = observer(() => {

  const contentRef = useRef<HTMLDivElement | null>(null)

  const scrollDown = () => {
    contentRef?.current?.scrollTo({top: contentRef?.current.scrollHeight, behavior:'smooth'})
  }

  useEffect(()=>{
    scrollDown()
  },[toJS(createChannelStore).posted_comments])

  return (
    <AsideCardLayout headerTitle='Комментарии к каналу'>
      <Stack spacing={2}>
        {userStore?.user &&
          <Stack ref={contentRef} spacing={1} sx={{pt:1, maxHeight:170, overflow:'auto'}}>
            {createChannelStore.posted_comments.length === 0 &&
              <EmptyText>Здесь будут ваши комментарии</EmptyText>
            }
            {createChannelStore.posted_comments.map(item=>
              <CreateAsideCommentItem
                key={item.text} 
                name={`${userStore?.user?.firstName} ${userStore?.user?.lastName}`}
                imageUrl={userStore?.user?.imageUrl!}
                text={item.text}
              />)
            }
          </Stack>
        }
        <Box sx={{mt:'auto'}}>
          <CreateAsideInputField/>
        </Box>
      </Stack>
    </AsideCardLayout>
  )
})