import {FC, useState} from 'react'
import { IAsideCardDate } from './IAsideCardDate'
import { Stack, Typography } from '@mui/material'
import { DateInputEdit } from '../../../../components/Table/ui/Fields/DateInputEdit/DateInputEdit'
import dayjs, { Dayjs } from 'dayjs'
import { IUpdateStatusesRequest } from '../../../../services/integration/IntegrationsRequest'
import IntegrationsService from '../../../../services/integration/integrations.service'
import integrationTableStore from '../../../../store/integrationTable.store'
import { AxiosError } from 'axios'
import { NotificationAlerts } from '../../../../components/NotificationAlerts/NotificationAlerts'
import integrationPageStore from '../../../../store/integrationPage.store'
import { sendDatePlusCustomTime } from '../../../../shared/functions/functions'

export const AsideCardDate:FC<IAsideCardDate> = (props) => {
  const {
    title,
    date,
    field,
    id,
    readonly,
    update_id,
    fullWidth = false,
    setValue,
    onChangeUpdateId
  } = props

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<null | string>(null)
  const [isOpenEdit, setOpenEdit] = useState(false)
  const [dateValue, setDateValue] = useState<Dayjs | null>(date ? dayjs(date) : null)

  const submit = (dateValue:Dayjs | null) => {
    if(dateValue) {
      setLoading(true)
      setError(null)

      const currentDate:string = sendDatePlusCustomTime({value:dateValue?.toISOString()})

      const option:IUpdateStatusesRequest = {
        field,
        id,
        new_value:currentDate,
        update_id
      }
      IntegrationsService.updateSimpleField(option)
        .then(res=>{
          // console.log(res, 'res upd table date')
          integrationTableStore.updateDate(id, res.data.update_id, currentDate, field)
          integrationTableStore.changeGurange(id, res.data?.guarantee || null, res.data.update_id)
          integrationPageStore.setGuarantee(res.data.guarantee)

          if(onChangeUpdateId){
            onChangeUpdateId(res.data.update_id)
          }

          setOpenEdit(false)
          setLoading(false)

          setDateValue(dayjs(currentDate))
          setValue(dayjs(currentDate)?.toISOString())
        })
        .catch(err=>{
          if(err instanceof AxiosError) {
            // console.log(err, 'err update date in table')
            setLoading(false)
            setError(err?.response?.data?.message)
          }
        })
    }
  }

  const handelEdit = () => {
    setOpenEdit(true)
  }

  const handelClose = () => {
    setOpenEdit(false)
    setDateValue(dayjs(date))
  }

  return (
    <>
      <Stack spacing={0.5} direction={{sm:'row'}} alignItems={{sm:'center'}} >
        <Typography mr={1} >{title}</Typography>
        <DateInputEdit
          minWidth={'20vw'}
          maxWidth={'350px'}
          width={'100%'}
          date={date}
          value={dateValue as any || ''}
          onChange={setDateValue}
          open={isOpenEdit}
          onOpen={handelEdit}
          onClose={handelClose}
          onSubmit={submit}
          fullWidth={fullWidth}
          loading={isLoading}
          readOnly={readonly}
        />
      </Stack>
      <NotificationAlerts
        error={{text:isError ? isError : '', open:isError ? true : false, onClose:()=>setError(null)}}
      />
    </>
  )
}
