import { FC } from 'react'
import { IUsetAnalyticsContent } from './IUsetAnalyticsContent'
import { priceTransormFormat } from '../../../../../../../../shared/functions/functions'
import { IUserAnalyticsItem } from '../UserAnalyticsItem/IUserAnalyticsItem'
import { Stack } from '@mui/material'
import { UserAnalyticsItem } from '../UserAnalyticsItem/UserAnalyticsItem'

export const UsetAnalyticsContent:FC<IUsetAnalyticsContent> = (props) => {
  const {data} = props

  const analitics:IUserAnalyticsItem[] = [
    {
      title:"Всего запущено интеграций" ,
      textData:data?.toScheduleIntegrationsTotal?.value?.toString(),
    },
    {
      title:"Всего выпущено интеграций" ,
      textData:data?.integrationsTotal.value?.toString(),
    },
    {
      title:"Всего выпущено релизов" ,
      textData:data?.releasesTotal?.value?.toString(),
    },
    {
      title:"Стоимость выпущенных интеграций" ,
      textData:priceTransormFormat(data.releasedIntegrationsSumm.value, true),
    },
    {
      title:"Стоимость всех интеграций в графике" ,
      textData:priceTransormFormat(data.toScheduleIntegrationsSumm.value, true),
    },
    {
      title:"Оплаченная сумма по интеграциям в графике" ,
      textData:priceTransormFormat(data?.budget.value),
    },
    {
      title: "Осталось оплатить по интеграциям в графике",
      textData: priceTransormFormat(data.remainsToPay.value, true),
    },
    {
      title:"Оплаченная сумма по платежам",
      textData:priceTransormFormat(data.pays.value, true),
    },
    {
      title:"ROI",
      textData:data?.roi?.value?.toString() + ' %',
    },
    {
      title:"▼ С выбранной даты по сегодняшнее число",
      isOnlyText:true
    },
    {
      title:"Общая сумма продаж",
      textData:priceTransormFormat(data?.totalOrdersSumm.value),
    },
    {
      title:"Всего валидных заказов",
      textData:data?.totalOrders?.value?.toString(),
    },
    {
      title:"Всего отменных заказов",
      textData:data?.totalCancel?.value?.toString(),
    },
    {
      title:"Средняя стоимость заказа" ,
      textData:priceTransormFormat(data?.averageOrderPrice.value),
    },
    {
      title:"Сумма отмен",
      textData:priceTransormFormat(data?.cancelSumm.value),
    },
    {
      title:"Средний чек",
      textData:priceTransormFormat(data?.averageCheck.value),
    },
  ]

  return (
    <Stack spacing={1}>
      {analitics.map(item=><UserAnalyticsItem key={item.title} {...item}/>)}
    </Stack>
  )
}
