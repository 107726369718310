import { FC } from 'react'
import { IMotivationGoodsCategoryTable } from './IMotivationGoodsCategoryTable'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { MotivationGoodsCategoryItem } from './ui/MotivationGoodsCategoryItem/MotivationGoodsCategoryItem'

export const MotivationGoodsCategoryTable:FC<IMotivationGoodsCategoryTable> = (props) => {
  const {forReleaseByGoodsCategory, talbeHeaderColor} = props

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{border:'1px solid #e0e0e0',borderBottom:'none', maxHeight:225}}
    >
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell sx={{p:1, pb:0, background:talbeHeaderColor}}>
            <Typography sx={{fontWeight:'bold', display:'inline-flex', verticalAlign:'super', whiteSpace:'nowrap'}}>Товарная категория</Typography>
          </TableCell>
          <TableCell sx={{p:1, pb:0, background:talbeHeaderColor}}>
            <Typography sx={{fontWeight:'bold', display:'inline-flex', verticalAlign:'super', whiteSpace:'nowrap'}}>Мотивация</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(forReleaseByGoodsCategory).map(key=>
          <MotivationGoodsCategoryItem key={key} value={forReleaseByGoodsCategory[key]} code={key}/>)
        }
      </TableBody>
    </Table>
  </TableContainer>
  )
}
