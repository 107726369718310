import { useState } from "react"

export const useResizeHook = (elementId:string, localStorageKey:string):any[] => {

  if(localStorage.getItem(localStorageKey)){

    const num = Number(localStorage.getItem(localStorageKey))

    if(num > 110){
      localStorage.removeItem(localStorageKey)
    }
  }

  const maxResizeWidth:number = 70 /* width left panel */

  const [start, setStart] = useState(false)

  const [initialPos, setInitialPos] = useState<null | number>(null)
  const [initialSize, setInitialSize] = useState<null | number>(null)
  const [initialPercent, setInitialPercent] = useState<null | number>(null)

  const onMouseDown = (e:React.MouseEvent) => {
    e.preventDefault()
    let resizable:HTMLElement | null = document.getElementById(elementId)

    if(resizable){
      const currentPercentResize = (resizable.offsetWidth / (window.innerWidth - maxResizeWidth)) * 100
      setInitialPercent(currentPercentResize || null)
    }

    setInitialPos(e?.clientX || null)
    setInitialSize(resizable?.offsetWidth || null)
    setStart(true)
  }
  
  const onMouseMove = (e:React.MouseEvent) => {
    e.preventDefault()

    if(start && initialPos !== null && initialSize !== null && e?.clientX > maxResizeWidth){
      let resizable:HTMLElement | null = document.getElementById(elementId)

      if(resizable){
        resizable.style.width = `${initialSize + parseInt(initialPos - e.clientX as any)}px`
      }
    }
  }

  const onMouseUp = (e:React.MouseEvent) => {
    e.preventDefault()

    let resizable = document.getElementById(elementId)

    if(resizable?.offsetWidth !== undefined){

      const currentPercentResize = (resizable.offsetWidth / (window.innerWidth - maxResizeWidth)) * 100

      resizable.style.width = `calc(${currentPercentResize}vw - ${maxResizeWidth}px`

      localStorage.setItem(localStorageKey, `${currentPercentResize}`)
      setStart(false)
      
    }
  }

  return [onMouseDown, onMouseMove, onMouseUp, start]
}