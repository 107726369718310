import {FC} from 'react'
import { ICustomButton } from '../CustomButton/ICustomButton'
import { CustomButton } from '../CustomButton/CustomButton'
import { Add } from '@mui/icons-material'

export const AddedButton:FC<ICustomButton> = (props) => {
  const {
    children,
    size = 'medium',
  } = props

  return (
    <CustomButton
      endIcon={
        <Add 
          fontSize={size} 
          sx={{mt:'-1px'}}
        />
      }
      sx={{alignItems:'flex-start'}}
      {...props}
    >
      {children}
    </CustomButton>
  )
}
