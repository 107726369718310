import { FC } from "react"
import { Stack, Typography } from "@mui/material"
import { AsideCardLayout } from "../../../../../AsideCardLayout/AsideCardLayout"
import { IUserSubDetails } from "./IUserSubDetails"

export const UserSubDetails:FC<IUserSubDetails> = (props) => {
  const {company, roleName} = props

  return (
    <AsideCardLayout headerTitle="детали">
      <Stack spacing={'6px'} sx={{pt:'6px'}}>
        <Typography>Компания: <Typography component={'span'}>{company}</Typography></Typography>
        <Typography>Роль: <Typography component={'span'}>{roleName}</Typography></Typography>
      </Stack>
    </AsideCardLayout>
  )
}