import {FC, useState} from 'react'
import { IStatusEmptyField } from './IStatusEmptyField'
import { Box, CircularProgress, IconButton, Menu, Stack, Typography} from '@mui/material'
import { getAllStatus } from './shared/dataList'
import { Add } from '@mui/icons-material'
import { IUpdateStatusesRequest } from '../../../../../services/integration/IntegrationsRequest'
import { AxiosError } from 'axios'
import { NotificationAlerts } from '../../../../NotificationAlerts/NotificationAlerts'

export const StatusEmptyField:FC<IStatusEmptyField> = (props) => {
  const {
    id,
    update_id,
    statusStore,
    readOnly,
    onChangeStatus,
    onUpdateStatus,
    onChangePayments
  } = props

  const [statusValue, setStatusValue] = useState('')
  const [statusTypePick, setStatusTypePick] = useState('')

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeStatus = (value:string) => {
    setStatusTypePick(value)

    if(onChangePayments) {
      onChangePayments(value)
    }

    if(onUpdateStatus && onChangeStatus && id && update_id){

      setLoading(true)
      setError(null)

      const option:IUpdateStatusesRequest = {
        id,
        field:'status',
        new_value:value,
        update_id
      }
  
      onUpdateStatus(option)
        .then(res=>{
          // console.log(res, 'res change sttuas')
          onChangeStatus(id, res.data.update_id, value)
          
          setLoading(false)
          setStatusValue(value)
          handleClose()
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            // console.log(err, 'err change stuts')
            setLoading(false)
            setError(err?.response?.data?.message)
          }
        })
    }
  }

  const getContrastTextColor = (backgroundColor: string) => {
    if (backgroundColor?.length === 0){
      return
    }
    const red = parseInt(backgroundColor?.substring(1, 3), 16);
    const green = parseInt(backgroundColor?.substring(3, 5), 16);
    const blue = parseInt(backgroundColor?.substring(5, 7), 16);

    const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
    return brightness > 150 ? '#000000' : '#FFFFFF';
  }

  return (
    <>
      <IconButton onClick={handleClick} disabled={readOnly}>
        <Add/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={!isLoading ? handleClose : undefined}
      >
        <Stack direction={'column'} spacing={1} sx={{pl:1, pr:1}}>
          {getAllStatus(statusStore).map(status => 
            <Box
              key={status.code}
              sx={{
                background:status.hexColor,
                "&:hover":{opacity:'0.8'},
                color:getContrastTextColor(status.hexColor),
                borderRadius:'4px', 
                p:1,
                cursor:'pointer',
                display:'flex',
                alignItems:'center',
                justifyContent:'space-between'
              }}
              onClick={!isLoading ? ()=>changeStatus(status.code) : undefined}
            >
              <Typography sx={{mr:isLoading && statusTypePick === status.code ? 1 : 0}}>{status.name}</Typography>
              {isLoading && statusTypePick === status.code
                ? <CircularProgress sx={{maxHeight:20, minHeight:20, maxWidth:20, minWidth:20}}/> : null
              }
            </Box>)
          }
        </Stack>
      </Menu>
      <NotificationAlerts
        error={{text:isError ? isError : '', open:isError ? true : false, onClose:()=>setError(null)}}
      />
    </>
  )
}