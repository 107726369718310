import { AxiosResponse } from "axios";
import $gateway_api from "../../shared/api/gatewayAxios";
import { IReleasesRequestEdit } from "./IReleasesRequest";
import { IReleasesResponseEdit } from "./IReleasesResponse";

export default class ReleasesService {

  static removeReleaes(id:number): Promise<AxiosResponse>{
    return $gateway_api.post('/api/releases/remove', {id})
  }

  static releaesEdit(option:IReleasesRequestEdit): Promise<AxiosResponse<IReleasesResponseEdit>>{
    return $gateway_api.post('/api/releases/edit', option)
  }

}