import {FC} from 'react'
import {Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select} from '@mui/material'
import { ISelectCheckboxField } from './ISelectCheckboxField'
import {purple_color} from "../../../../../app/styles/variables";

export const SelectCheckboxField:FC<ISelectCheckboxField> = (props) => {
  const {
    list, 
    label,
    onChange,
    value,
    onOpen,
    loading,
    minWidth,
    maxWidth,
    placeholder,
  } = props

  return (
    <FormControl sx={{width: '100%', minWidth, maxWidth}} disabled={loading}>
      <InputLabel id="demo-multiple-checkbox-label" size='small'>{label}</InputLabel>
      <Select
        displayEmpty
        labelId="demo-multiple-checkbox-label"
        input={<OutlinedInput label={label} />}
        size='small'
        multiple
        value={value}
        onChange={onChange}
        onOpen={onOpen}
        renderValue={(selected)=>{
          return selected.length > 0 ? list.filter(x=>selected.includes(x.value)).map(x=>x.name).join(', ') : <Box sx={{color:'grey.500'}}>{placeholder}</Box>
        }}
        MenuProps={{PaperProps:{sx:{maxHeight:320}}}}
      >
        {list.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <Checkbox checked={value.indexOf(item.value) > -1} />
            <ListItemText sx={{'&:hover':{color:purple_color}}} primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
