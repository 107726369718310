import React, {FC} from 'react'
import { IIntegrationTableList } from '../../../../../../components/IntegrationTable/models/IIntegrationTableList'
import { AgreemenTableListArr } from './AgreemenTableListArr'
import { useSearchParams } from 'react-router-dom'
import { INTEGRATION_CARD_OPEN } from '../../../../../../shared/constants/searchParams'

export const AgreemenTableList:FC<IIntegrationTableList> = React.memo((props) => {
  const {tableHead} = props

  const [searchParams, setSearchParams] = useSearchParams()

  const navigateAside = (id:string) => {
    const params = new URLSearchParams(window.location.search)
    params.set(INTEGRATION_CARD_OPEN, id)
    
    searchParams.set(INTEGRATION_CARD_OPEN, id)

    setSearchParams(params)
  }

  return (
    <AgreemenTableListArr tableHead={tableHead} navigateAside={navigateAside}/>
  )
})