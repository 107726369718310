import { Paper, Skeleton } from "@mui/material"

export const SkeletonChart = () => {

  return (
    <Paper elevation={0} sx={{p:1, border:'1px solid #e0e0e0', height:'200px'}}>
      <Skeleton variant="rectangular" height={'100%'} width={'100%'}/>
    </Paper>
  )
}
