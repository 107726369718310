import { FC } from 'react'
import { IAsideCardStatusEmpty } from './IAsideCardStatusEmpty'
import { StatusEmptyField } from '../../../../components/Table/ui/Fields/StatusEmptyField/StatusEmptyField'
import { observer } from 'mobx-react-lite'
import statusesStore from '../../../../store/statuses.store'
import channelTableStore from '../../../../store/channelTable.store'
import ChannelService from '../../../../services/channel/channel.service'

export const AsideCardStatusEmpty:FC<IAsideCardStatusEmpty> = observer((props) => {
  const {
    id,
    onChangeUpdateId,
    setStatus,
    update_id,
    readOnly = false
  } = props

  const changeStatus = (id:number, update_id:number, value:string) => {
    setStatus(value)
    onChangeUpdateId(update_id)
    channelTableStore.changeUpdateIdItem(id, update_id, value)
  }

  return (
    <StatusEmptyField
      id={id}
      update_id={update_id}
      onChangeStatus={changeStatus}
      onUpdateStatus={ChannelService.updateStatus}
      statusStore={statusesStore.channel}
      readOnly={readOnly}
    />
  )
})