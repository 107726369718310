import { FC } from "react";
import { ICreateSelectField } from "./ICreateSelectField";
import {
  Avatar,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";

export const CreateSelectField: FC<ICreateSelectField> = observer((props) => {
  const {
    title,
    label,
    content,
    onChange,
    value,
    required = false,
    error,
    errorText,
    sxPropsSelect,
    sxPropsTitle,
    sxPropsWrapper,
    placeholder,
  } = props;

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  const renderValue = (selected: string) => {
    if (!selected && placeholder) {
      return (
        <Typography
          sx={{
            color: "rgba(187, 187, 187, 1)",
            fontSize: 14,
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}>
          {placeholder}
        </Typography>
      );
    }

    const selectedItem = content.find((item) => item.value === selected);
    return (
      <Box display={"flex"} alignItems={"center"}>
        {selectedItem?.iconUrl && (
          <Avatar src={selectedItem.iconUrl} sx={{ height: 20, width: 20, mr: 0.5 }} />
        )}
        <Typography>{selectedItem?.name}</Typography>
      </Box>
    );
  };

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems={{ sm: "center" }}
      sx={{ ...sxPropsWrapper }}>
      <Typography sx={{ mb: 1, width: { sm: "170px" }, ...sxPropsTitle }}>
        {title}{" "}
        {required && (
          <Typography component={"span"} color={"error"}>
            *
          </Typography>
        )}
      </Typography>
      <Box sx={{ width: { xs: "100%", sm: "70%" }, ...sxPropsSelect }}>
        <FormControl size="small" fullWidth>
          <InputLabel id="select-label" size="small">
            {label}
          </InputLabel>
          <Select
            labelId="select-label"
            value={value.toString()}
            label={label}
            onChange={handleChange}
            size="small"
            required={required}
            error={error}
            fullWidth
            displayEmpty
            renderValue={renderValue}>
            {content.map((item) => {
              return (
                <MenuItem
                  key={item.value}
                  value={item.value}
                  sx={{ display: "flex", alignItems: "center" }}>
                  {item.iconUrl ? (
                    <Avatar src={item.iconUrl} sx={{ height: 20, width: 20, mr: 0.5 }} />
                  ) : null}
                  <Typography>{item.name}</Typography>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {error && (
          <Typography sx={{ mt: 0.5 }} variant="body2" color={"error"}>
            {errorText}
          </Typography>
        )}
      </Box>
    </Stack>
  );
});
