import {FC} from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import {IUserPlanTable} from "./IUserPlanTable";
import {UserPlanTableItem} from "./ui/UserPlanTableItem/UserPlanTableItem";

export const UserPlanTable:FC<IUserPlanTable> = (props) => {
    const {list, noEdit} = props
    
    return (
        <TableContainer
            component={Paper}
            elevation={0}
            sx={{
                border:'1px solid #e0e0e0',
                borderBottom:'none',
                borderRadius:0,
                borderLeft:'none',
                height:'min-content',
                maxHeight:'calc(100% - 32px)',
            }}
        >
            <Table
                stickyHeader
                sx={{
                    "& .MuiTableCell-root": {borderLeft: '1px solid #e0e0e0'},
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell align="center" sx={{p:1, width:120, background:' #f2f3f5'}}>
                            <Typography variant="body1" fontWeight={'bold'} whiteSpace='nowrap'>Канал</Typography>
                        </TableCell>
                        <TableCell align="center" sx={{p:1, minWidth:250, background:' #f2f3f5'}}>
                            <Typography variant="body1" fontWeight={'bold'}>План</Typography>
                        </TableCell>
                        <TableCell align="center" sx={{p:1, minWidth:150, background:' #f2f3f5'}}>
                            <Typography variant="body1" fontWeight={'bold'}>Факт</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map(item=><UserPlanTableItem key={item.userId} noEdit={noEdit} {...item}/>)}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
