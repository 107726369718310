import {FC, useState, useEffect} from 'react'
import { IModalFilter } from './IModalFilter'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, InputAdornment, List, Stack, TextField, Typography } from '@mui/material'
import { CustomIconButton } from '../../../UI/Buttons/CustomIconButton/CustomIconButton'
import { Cancel, Clear, Search } from '@mui/icons-material'
import { CancelButton } from '../../../UI/Buttons/CancelButton/CancelButton'
import { CustomButton } from '../../../UI/Buttons/CustomButton/CustomButton'
import { AlertModal } from '../../../AlertModal/AlertModal'
import { TableSettingsItem } from '../../../IntegrationTable/ui/TableSettingsItem'
import { IRenderFilterLists } from '../../interfaces/IRenderFilterItem'
import { IDragParams } from '../../../../store/integrationTable.store'

export const ModalFilter:FC<IModalFilter> = (props) => {
  const {
    onClose,
    open,
    list,
    onSubmit
  } = props

  const [isShowAlertReset, setShowAlertReset] = useState(false)

  const [searchValue, setSearchValue] = useState('')

  const [curDragItemParams, setCurDragItemParams] = useState<null | IDragParams>(null)

  const [arrayConfirmFilters, setArrayConfirmFilters] = useState<IRenderFilterLists[]>(list.filter(x=>x.isSelected).sort((a,b)=>a.ordering!-b.ordering!))
  const [selectedCheckboxFilters, setSelectedCheckboxFilters] = useState<IRenderFilterLists[]>(list)

  const [searchCheckboxFilters, setSearchCheckboxFilters] = useState<IRenderFilterLists[]>(list)

  const handelSearchItem = (value:string) => {
    const searchArray:IRenderFilterLists[] = [...selectedCheckboxFilters]

    const newItems:any[] = searchArray.map(item=>{

      if(item.label.toLowerCase().includes(value.toLowerCase())){
        return item
      } else {
        return undefined
      }

    }).filter(x=>x!==undefined)

    setSearchCheckboxFilters(newItems)
  }

  const resetSettings = () => {
    setArrayConfirmFilters(list.filter(x=>x.isSelected).sort((a,b)=>a.ordering!-b.ordering!))
    setSelectedCheckboxFilters(list)

    setShowAlertReset(false)
  }

  const saveSettings = () => {
    const objKeys:any = {}

    for (let key in arrayConfirmFilters) {
      objKeys[arrayConfirmFilters[key].code] = arrayConfirmFilters[key].code
    }

    const totalAllArrFilters:any[] = selectedCheckboxFilters.map(item=>{
      if(item.code === objKeys[item.code]){
        return arrayConfirmFilters.find(x=>x.code === item.code)
      } else return {...item, isSelected:false, ordering:null}
    })

    onSubmit(arrayConfirmFilters, totalAllArrFilters)
    closeModal()
  }

  const closeModal = () => {
    resetSettings()
    onClose()
  }

  const onConfirmDelete = (code:string) =>{
    setSelectedCheckboxFilters((prev:any[])=>{
      return prev.map(item=>{
        if(item.code === code){
          return {...item, isSelected:false}
        } else return item
      })
    })

    setSearchCheckboxFilters((prev:any[])=>{
      return prev.map(item=>{
        if(item.code === code){
          return {...item, isSelected:false}
        } else return item
      })
    })
  }

  const deleteConfirmItem = (code:string) => {
    onConfirmDelete(code)
    removeConfirmList(code)
  }

  const setSelectedChecked = (code:string) =>{
    
    setSelectedCheckboxFilters((prev:any[])=>{
      return prev.map(item=>{
        if(item.code === code){
          return {...item, isSelected: !item.isSelected}
        } else return item
      })
    })

    setSearchCheckboxFilters((prev:any[])=>{
      return prev.map(item=>{
        if(item.code === code){
          return {...item, isSelected: !item.isSelected}
        } else return item
      })
    })
    
  }

  const addToConfirmList = (item:IRenderFilterLists) =>{
    setArrayConfirmFilters((prev:any[])=>{
      return [...prev, {...item, isSelected:true, ordering:prev.length + 1}]
    })
  }

  const removeConfirmList = (code:string) =>{
    setArrayConfirmFilters((prev:any[])=>{
      return prev.filter(item=>item.code !== code).map((item, i)=>{
        return {...item, ordering:i+1}
      })
    })
  }

  const onChangeCheckboxStore = (item:IRenderFilterLists) => {
    setSelectedChecked(item.code)

    if(!item.isSelected){
      addToConfirmList(item)
    } else {
      removeConfirmList(item.code)
    }
  }

  const onDragStart = (params:IDragParams) => {
    setCurDragItemParams(params)
  }

  const onDragOver = (e:React.DragEvent) => {
    e.preventDefault()
  }

  const onDrop = (e:React.DragEvent, params:IDragParams) => {
    e.preventDefault()

    const {code, ordering} = params

    if(code && ordering && curDragItemParams?.code && curDragItemParams?.ordering && code !== curDragItemParams.code){

      const dragItem = {code:curDragItemParams.code, ordering:ordering}
      const downItem = {code:code, ordering:curDragItemParams.ordering < ordering ? ordering-1 : ordering+1}

      const afterDragArr:any[] = arrayConfirmFilters.map((item:any)=>{
        if(item.code === dragItem.code) {
          return {...item, ...dragItem}
        } else if (item.code === downItem.code) {
          return {...item, ...downItem}
        } else  {
          return {
            ...item,
            ordering:item.ordering >= dragItem.ordering ? item.ordering + 1 : item.ordering - 1
          }
        }
      })

      setArrayConfirmFilters(
        afterDragArr.sort((a, b)=>a.ordering > b.ordering ? 1 : -1).map((x,i)=>{
          return {...x, ordering:i+1}
        })
      )
    }
  }

  useEffect(()=>{
    if(searchValue.trim()===''){
      setSelectedCheckboxFilters(list)
    }

    const delay = setTimeout(()=>handelSearchItem(searchValue), 400)
    return ()=>clearTimeout(delay)

  },[searchValue])

  useEffect(()=>{
    setArrayConfirmFilters(list.filter(x=>x.isSelected).sort((a,b)=>a.ordering!-b.ordering!))
  },[list])

  return (
    <>
      <Dialog
        open={open}
        onClose={closeModal}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle sx={{display:'flex', alignItems:'center', justifyContent:'space-between', pr:1}}>
          <Typography fontSize={'1.07rem'} fontWeight={'bold'} sx={{display:{xs:'none', sm:'inline-block'}}}>Настройка фильтров на странице</Typography>
          <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{width:{xs:'100%', sm:'auto'}}}>
            <TextField
              value={searchValue}
              onChange={e=>setSearchValue(e.target.value)}
              size='small'
              placeholder='Быстрый поиск...'
              sx={{width:{xs:'100%', sm:'200px'}}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchValue.trim() !=='' ? 
                      <CustomIconButton size='small' onClick={()=>setSearchValue('')}>
                        <Clear fontSize='small'/>
                      </CustomIconButton>
                    : null}
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize='small' />
                  </InputAdornment>
                ),
              }}
            />
            <CustomIconButton onClick={closeModal}>
              <Cancel/>
            </CustomIconButton>
          </Stack>
        </DialogTitle>
        <Divider/>
        <DialogContent sx={{display:'grid', gridTemplateColumns: {xs:'1fr', sm:'min-content min-content 1fr'}, p:0, columnGap:{xs:0, sm:1}}}>
          <List dense sx={{maxWidth:'200px', minWidth:'200px', mt:0.5, pl:1, display:{xs:'none', sm:'block'}}}>
            {arrayConfirmFilters.map((item)=>
              <TableSettingsItem
                key={item.code}
                name={item.label}
                ordering={Number(item.ordering)}
                code={item.code}
                isConfirmItem
                isDraggable
                isShowDelete={arrayConfirmFilters.length > 1}
                onDelete={()=>deleteConfirmItem(item.code)}
                onDragStart={()=>onDragStart({code:item.code, ordering:Number(item.ordering)})}
                onDragOver={(e)=>onDragOver(e)}
                onDrop={(e)=>onDrop(e, {code:item.code, ordering:Number(item.ordering)})}
              />)
            }
          </List>
          <Divider orientation='vertical' sx={{display:{xs:'none', sm:'block', borderStyle:'dashed'}}}/>
          <List dense sx={{pl:{xs:1, sm:0}, pt:'2px'}}>
            <Box
              sx={{
                display:"grid", 
                gridTemplateColumns:{xs:'1fr', sm:'1fr 1fr', md:'repeat(3, 1fr)'}, 
                gridAutoColumns:'min-content',
                columnGap:{xs:0, md:1}
              }}
            >
              {searchCheckboxFilters.map((item)=>
                <TableSettingsItem
                  key={item.code}
                  name={item.label}
                  code={item.code}
                  ordering={Number(item.ordering)}
                  isSelected={Boolean(item.isSelected)}
                  onClick={()=>onChangeCheckboxStore(item)}
                  disabled={(Boolean(item.isSelected) && arrayConfirmFilters.length === 1)}
                />
                )
              }
            </Box>
            {searchCheckboxFilters.length === 0 &&
              <Typography variant='h5' sx={{display:'flex', alignItems:'center', justifyContent:'center', flexWrap:'wrap'}}>
                По запросу <span style={{margin:'0px 4px'}}>"<b>{searchValue}</b>"</span> ничего не найдено
              </Typography>
            }
          </List>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <CancelButton variant='outlined' onClick={()=>setShowAlertReset(true)}>сбросить</CancelButton>
          <CustomButton
            variant='contained'
            onClick={saveSettings}
          >
            Сохранить
          </CustomButton>
        </DialogActions>
      </Dialog>
      {isShowAlertReset &&
        <AlertModal
          open={isShowAlertReset}
          title='Подтвердите действие'
          subtext='Вы действительно хотите сбросить настройки фильтров?'
          onClose={()=>setShowAlertReset(false)}
          onSubmit={resetSettings}
          buttonCancelName='Отмена'
          buttonSubmitName='Ок'
        />
      }
    </>
  )
}
