import { FC } from 'react'
import { IPostedCommentComponent } from './models/IPostedCommentItem'
import { observer } from 'mobx-react-lite'
import companyUsersStore from '../../store/companyUsers.store'
import { Avatar, Box, Divider, Stack, Typography } from '@mui/material'
import { getDatePlusCustomTime } from '../../shared/functions/functions'
import {RemoveItemButton} from "../UI/Buttons/RemoveItemButton/RemoveItemButton";
import { EditIconButton } from '../UI/Buttons/EditIconButton/EditIconButton'

export const PostedCommentItem:FC<IPostedCommentComponent> = observer((props) => {
  const {
    channelId,
    id,
    postedAt,
    postedById,
    text,
    type,
    newDay,
    onDelete,
    onEdit
  } = props

  const userPost = companyUsersStore.users.find(x=>x.id === postedById)

  return (
    <>
      {newDay && <Divider><Typography variant='body2'>{getDatePlusCustomTime({value:postedAt, format:'DD.MM.YY'})}</Typography></Divider>}
      <Box sx={{display:'grid', gridTemplateColumns:'min-content 1fr', columnGap:'8px'}}>
        <Avatar src={userPost?.imageUrl} alt={'user ' + postedById} sx={{height:30, width:30}} />
        <Stack direction={'column'}>
          <Stack direction={'row'} alignItems={'center'}>
            <Typography color={'secondary'}>{`${userPost?.firstName} ${userPost?.lastName}`}</Typography>
            <EditIconButton size='small' onClick={()=>onEdit(id, text)}/>
            <RemoveItemButton onClick={()=>onDelete(id)}/>
          </Stack>
          <Typography sx={{lineBreak:'anywhere'}}>
            {text}
            <Typography variant='body2' sx={{float:'right'}}>{getDatePlusCustomTime({value:postedAt, format:'HH:mm'})}</Typography>
          </Typography>
        </Stack>
      </Box>
    </>
  )
})