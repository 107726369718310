import {FC, useEffect} from 'react'
import { IintegrationAllSumm } from './IintegrationAllSumm'
import { InputEditField } from '../../../../../../components/Table/ui/Fields/InputEditField/InputEditField'
import { priceTransormFormat } from '../../../../../../shared/functions/functions'
import { IconButton, Stack, Typography } from '@mui/material'
import { Edit } from '@mui/icons-material'

export const IntegrationAllSumm:FC<IintegrationAllSumm> = (props) => {
  const {
    integrations,
    changeSumm,
    isEdit,
    setEdit,
    changeTotalSummOnIntegration,
    allSumm,
    loading = false,
    readOnly = false,
    text = 'Сумма общая (с комиссией):'
  } = props

  useEffect(() => {
    if(changeTotalSummOnIntegration){
      changeTotalSummOnIntegration()
    }
  }, [integrations])
  
  return (
    <>
        <Stack direction={{xs:!isEdit ? 'row' : 'column', sm:'row'}} alignItems={{xs:!isEdit ? 'center' : 'start', sm:'center'}} spacing={0.5}>
            <Typography fontWeight={'bold'}>
              {text} {!isEdit && priceTransormFormat(allSumm)}
            </Typography>
          {!isEdit && readOnly !== true &&
            <IconButton size='small' onClick={()=>setEdit(true)}>
              <Edit fontSize='small'/>
            </IconButton>
          }
          {isEdit &&
            <InputEditField
              initValue={allSumm?.toString()}
              onClose={()=>setEdit(false)}
              onSubmit={changeSumm}
              inputType='number'
              placeholder='Введите общую сумму (с комиссией)'
              loading={loading}
            />
          }
        </Stack>
    </>
  )
}