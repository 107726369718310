import {FC, useState, useEffect} from 'react'
import { IModalInfo } from './IModalInfo'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import ChannelService from '../../../../../../services/channel/channel.service'
import { AxiosError } from 'axios'
import { IChannelBannedResponse } from '../../../../../../services/channel/ICnannelResponse'
import dayjs from 'dayjs'

export const ModalInfo:FC<IModalInfo> = (props) => {
  const {channelId, onClose, open} = props

  const [data, setDate] = useState<IChannelBannedResponse | null>(null)

  const [isLoading, setLoading] = useState(true)
  const [isError, setError] = useState<string | null>(null)

  const getBlockedChannelInfo = () => {
    setLoading(true)
    setError(null)

    ChannelService.getChannelBanned(channelId)
      .then(res=>{
        console.log(res, 'res get banned')
        setDate(res.data)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          console.log(err, 'err get banned')
          setLoading(false)
          setError(err?.response?.data?.message)
        }
      })
  }

  useEffect(()=>{
    getBlockedChannelInfo()
  },[])

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle
          sx={{
            display:'flex', 
            alignItems:'center', 
            justifyContent:'space-between', 
            p:{xs:1, sm:2}, 
            pr:{xs:1, sm:0.5}, 
            fontSize:{xs:'14px', sm:'1.07rem'}
          }}
        >
          Информация о блокировки канала №{channelId}
          <IconButton disabled={isLoading} onClick={onClose}>
            <Cancel/>
          </IconButton>
        </DialogTitle>
        <Divider/>
        <DialogContent sx={{p:{xs:1, sm:2}, minHeight:80}}>
          {isLoading &&
            <Stack justifyContent={'center'} alignItems={'center'} >
              <CircularProgress sx={{minHeight:50, minWidth:50}}/>
            </Stack>
          }
          {!isLoading && data &&
            <Stack spacing={1}>
              <Typography>Кем заблокирован: <Typography component={'span'} fontWeight={'bold'}>{data?.userName}</Typography></Typography>
              <Stack spacing={1} sx={{width:'100%'}}>
                <Typography>Причина блокировки:</Typography>
                <Box>
                  <Typography sx={{lineBreak:'anywhere'}}>{data?.banReason}</Typography>
                </Box>
              </Stack>
              <Typography>Дата блокировки: <Typography component={'span'} fontWeight={'bold'}>{dayjs(data?.bannedDate).format('DD.MM.YYYY')}</Typography></Typography>           
            </Stack>
          }
        </DialogContent>
        <Divider/>
        <DialogActions sx={{p:{xs:1, sm:2},}}>
          <Button
            variant='outlined' 
            disabled={isLoading}
            onClick={onClose}
          >
            закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}