import {FC, useState} from 'react'
import { IAsidePriceEdit } from './IAsidePriceEdit'
import { Box, CircularProgress, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { Check, Clear } from '@mui/icons-material'
import { priceTransormFormat } from '../../../../shared/functions/functions'
import { EditIconButton } from '../../../../components/UI/Buttons/EditIconButton/EditIconButton'
import { CustomIconButton } from '../../../../components/UI/Buttons/CustomIconButton/CustomIconButton'

export const AsidePriceEdit:FC<IAsidePriceEdit> = (props) => {
  const {
    price,
    title,
    isEditMode,
    isLoading,
    submitPrice,
    initValue,
    open,
    onClose,
    onOpen,
    tooltipPlacement,
    isPercent = true,
    placeholder,
    readOnly = false
  } = props

  const [value, setValue] = useState(initValue ? initValue.toString() : '')

  const closeEdit = () => {
    setValue(initValue ? initValue.toString() : '')
    onClose()
  }

  const onKeyDownSend = (e:React.KeyboardEvent) => {
    if(e.code === "Enter" || e.code === "NumpadEnter"){
      submitPrice(value)
    }
  }

  return (
    <>
     {!open &&
        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
          {title && <Typography>{title}</Typography>}
          {price !== null ? <Typography variant={'body1'} noWrap>{isPercent ? priceTransormFormat(price) : price}</Typography> : null}
          {price === null && <Typography variant={'body1'} noWrap>Введите значение</Typography>}
          {isEditMode && !readOnly &&
            <Tooltip title='Изменить' placement={tooltipPlacement}>
              <Box>
                <EditIconButton size='small' onClick={onOpen}/>
              </Box>
            </Tooltip>
          }
        </Stack>
     }
     {open && 
       <>
          {title && <Typography>{title}</Typography>}
          <Stack direction={'row'}>
            <TextField
              value={value}
              onChange={e=>setValue(e.target.value)} 
              placeholder={placeholder ? placeholder : 'Введите новую цену'}
              size='small'
              type='number'
              disabled={isLoading}
              sx={{width:'100%'}}
              InputProps={{
                endAdornment:isLoading && <CircularProgress sx={{maxHeight:20, maxWidth:20}} /> 
              }}
              autoFocus
              onKeyDown={onKeyDownSend}
            />
            <Stack direction={'row'} alignItems={'center'} sx={{ml:0.5}}>
              <Tooltip title='Отмена' onClick={closeEdit}>
                <Box>
                  <CustomIconButton disabled={isLoading} size='small'>
                    <Clear fontSize='small'/>
                  </CustomIconButton>
                </Box>
              </Tooltip>
              <Tooltip title='Сохранить'>
                <Box>
                  <CustomIconButton disabled={isLoading || value.trim()==='' || value.trim() === price?.toString()} size='small' onClick={()=>submitPrice(value)} showBackground>
                    <Check fontSize='small'/>
                  </CustomIconButton>
                </Box>
              </Tooltip>
            </Stack>
          </Stack>
       </>
      }
    </>
  )
}
