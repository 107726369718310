import {Box, Typography} from "@mui/material";
import {IEmptyTextProps} from "./IEmptyText";

export const EmptyText = (props:IEmptyTextProps) => {

  const {  children, icon, iconPosition = 'start', sx, ...typographyProps} = props

  const defaultTextStyles = {
    fontStyle: 'italic',
    color: '#9DA8AA',
    ...sx
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {icon && iconPosition === 'start' && icon}
      <Typography {...typographyProps} sx={defaultTextStyles}>
        {children ? children : 'отсутствует'}
      </Typography>
      {icon && iconPosition === 'end' && icon}
    </Box>
  );
};