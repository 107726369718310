import {useSearchParams} from "react-router-dom";
import {useCallback} from "react";
import {CHANNEL_CARD_OPEN} from "../shared/constants/searchParams";

export const useChannelNavigation = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigateChannel = useCallback((id: string | number) => {
    const params = new URLSearchParams(window.location.search);

    params.set(CHANNEL_CARD_OPEN, id?.toString());
    searchParams.set(CHANNEL_CARD_OPEN, id?.toString());
    
    setSearchParams(params);
  }, []);

  return navigateChannel;
};