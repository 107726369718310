import { useState, useEffect } from "react"
import { AddCircleOutline, ExpandMore, Save, Settings } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Divider, Stack, Typography,} from "@mui/material"
import { IChannelMinMaxResponse } from "../../../../services/channel/ICnannelResponse"
import ChannelService from "../../../../services/channel/channel.service"
import { AxiosError } from "axios"
import { getFilterChannelQuery,} from "./shared/dataList"
import { LS_FILTERS_ALL_CHANNEL, LS_FILTERS_CHANNEL, LS_TAKE_REQUEST_CHANNEL } from "../../../../shared/constants/localstorage"
import userStore from "../../../../store/user.store"
import channelTableStore from "../../../../store/channelTable.store"
import { useNavigate, useSearchParams } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { IChannelRequest, allChannelTableCol,} from "../../../../services/channel/IChannelRequest"
import { ChannelPresetList } from "../ChannelPresetList/ChannelPresetList"
import { SaveModalFilter } from "../../../SaveModalFilter/SaveModalFilter"
import { IReferencesPresetResponse } from "../../../../services/references/IReferencesResponse"
import presetFiltersStore from "../../../../store/presetFilters.store"
import { ALL_ROLES } from "../../../../shared/rules/interface/EnumRights"
import { ACCESS_RULES } from "../../../../shared/rules/rules"
import { CHANNELS_ROUTES } from "../../../../shared/routes/routes"
import { CustomIconButton } from "../../../UI/Buttons/CustomIconButton/CustomIconButton"
import { CustomButton } from "../../../UI/Buttons/CustomButton/CustomButton"
import { CancelButton } from "../../../UI/Buttons/CancelButton/CancelButton"
import { IRenderFilterLists } from "../../../Filters/interfaces/IRenderFilterItem"
import { ModalFilter } from "../../../Filters/components/ModalFilter/ModalFilter"
import { RenderFiltersList } from "../../../Filters/components/RenderFiltersList/RenderFiltersItem"
import { IStrategyFiltersField } from "../../../../strategy/Filters/interface/IStrategyFiltersField"

export const ChannelFilter = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()

  const [isOpenModalFilter, setOpenModalFilter] = useState(false)

  const [isSavePresetOpen, setSavePresetOpen] = useState(false)

  const [isFiltersOpen, setFilterOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [dataMinMax, setDataMinMax] = useState<IChannelMinMaxResponse | null>(null)

  const getChannelFiltersTableItems = (isClear: boolean) => {
    channelTableStore.setLoading(true)

    const params = new URLSearchParams(searchParams)

    if(!isClear){
      const currentFiltersArr:IStrategyFiltersField[] = channelTableStore.channelFilters

      const allSearchParamsUrl:string[] = []

      for (let value of searchParams.keys() as any) {
        allSearchParamsUrl.push(value)
      }

      const allCodeFilters:string[] = currentFiltersArr.map(x=>x.searchUrl)

      const diff:{isActive:boolean, searchUrl:string}[] = allSearchParamsUrl
        .map(x=>allCodeFilters.includes(x) ? {isActive:true, searchUrl:x} : {isActive:false, searchUrl:x})
        .filter(x=>x.isActive === false)

      diff.map(x=>params.delete(x.searchUrl))
      setSearchParams(params)
    }

    const take = localStorage.getItem(LS_TAKE_REQUEST_CHANNEL) ? localStorage.getItem(LS_TAKE_REQUEST_CHANNEL) : 100

    const option: IChannelRequest = {
      company: userStore.currentCompany,
      filterQuery: isClear ? {} : getFilterChannelQuery(params),
      includeInSelect: channelTableStore.allFilter as allChannelTableCol[],
      page: 1,
      take: +`${take}`,
    }

    ChannelService.getChannelItems(option)
      .then((res) => {
        // console.log(res, "res confirm filters")
        channelTableStore.setPage(1)

        channelTableStore.setTableItems(res.data.items)
        channelTableStore.setTotalCount(res.data.totalCount)
        channelTableStore.setLoading(false)
        setFilterOpen(false)
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, "err filter confirm")
        }
      })
  }

  const clearFilter = () => {
    setSearchParams({})
    getChannelFiltersTableItems(true)
    channelTableStore.setChannelPresetFilters([])
    channelTableStore.setChanneAllPresetFilters([])
  }

  const getMinMaxData = () => {
    setLoading(true)

    ChannelService.getChannelMinMaxFilter()
      .then((res) => {
        // console.log(res, "res min max filter")
        setDataMinMax(res.data)
        setLoading(false)
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, "err filter")
          setLoading(false)
        }
      })
  }

  const checkUserOtherEdit = ():boolean => {
    return ACCESS_RULES.create_channels.map(x=>{
      if(userStore?.user?.rights?.find(right=>right.code === x)){
        return true
      } else return false
    }).filter(x=>x===true)[0]
    
  }

  const checkUserEdit = ():boolean => {
    if(userStore.user?.role?.includes(ALL_ROLES.ADMIN)){
      return true
    } else if(checkUserOtherEdit()) {
      return true
    } else return false
  }

  const openSavePresetModal = () => setSavePresetOpen(true)
  const closeSavePresetModal = () => setSavePresetOpen(false)
  const addPresetStore = (preset:IReferencesPresetResponse) => presetFiltersStore.addPresetChannels(preset)

  const saveFilters = (list:IRenderFilterLists[], allFilterLists:IRenderFilterLists[]) => {

    if(channelTableStore.channelPresetFilters.length>0){
      
      channelTableStore.setChannelPresetFilters(list)
      channelTableStore.setChanneAllPresetFilters(allFilterLists)

    } else {

      localStorage.setItem(LS_FILTERS_CHANNEL, JSON.stringify(list))
      channelTableStore.setChannelFilters(list)
  
      localStorage.setItem(LS_FILTERS_ALL_CHANNEL, JSON.stringify(allFilterLists))
      channelTableStore.setChannelAllFilters(allFilterLists)
    }
  }

  useEffect(() => {
    getMinMaxData()
  }, [])

  return (
    <>
      {channelTableStore.isLoading === false && (
        <Accordion
          elevation={0}
          expanded={isFiltersOpen}
          disableGutters
          TransitionProps={{timeout:0}}
          sx={{
            mb: 0, 
            border: "none",
            borderRadius: "16px",
            "&.MuiAccordion-root": { borderRadius: "16px" }, 
            boxShadow:'0px 0px 8px 0px rgba(34, 60, 80, 0.2)', 
            '&::before': {
              display: 'none',
            },
          }}
        >
          <Stack direction={{xs:'column', sm:'row'}} alignItems={{xs:'start', sm:'center'}} justifyContent={'space-between'} sx={{pl:{xs:1, sm:2}, pr:{xs:1, sm:2}}}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <CustomIconButton sx={{position:'absolute', top:'-15px', left:'-4px'}} onClick={()=>setOpenModalFilter(true)}>
                <Settings fontSize="medium"/>
              </CustomIconButton>
              <Typography variant="h5" sx={{display:{xs:"none", sm:'inline-block'}}}>Каналы</Typography>
              <AccordionSummary
                expandIcon={<CustomIconButton><ExpandMore fontSize="medium" /></CustomIconButton>}
                onClick={() => setFilterOpen((prev) => !prev)}
                sx={{pr:1, pl:1, width:'180px', "&:hover":{color:'#7C64D9'}}}
              >
                <Typography>
                  {isFiltersOpen ? "Свернуть фильтр" : "Развернуть фильтр"}
                </Typography>
              </AccordionSummary>
            </Stack>
            {checkUserEdit() &&
              <CustomButton
                variant="contained"
                startIcon={<AddCircleOutline/>}
                onClick={()=>navigate(CHANNELS_ROUTES.CREATE)}
                sx={{mb:{xs:2, sm:0}, width:{xs:'100%', sm:'fit-content'}}}
              >
                Новый канал
              </CustomButton>
            }
          </Stack>
          <Divider />
          <AccordionDetails sx={{ p: 1, pb: 2 }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns:{xl:"repeat(5, 1fr)", lg:'repeat(4,1fr)', md:'repeat(3,1fr)', sm:'repeat(2,1fr)', xs:'repeat(1,1fr)'},
                rowGap:1, 
                columnGap:2,
                pl:1,
                pr:1,
                overflow:'auto',
                maxHeight:{xs:'300px', md:'450px'},
                pb:1
              }}
            >
              <RenderFiltersList
                filters={
                  channelTableStore.channelPresetFilters?.length===0
                    ? localStorage.getItem(LS_FILTERS_CHANNEL)
                      ? channelTableStore.channelFilters
                      : channelTableStore.channelFilters.filter(x=>x.isSelected)
                    : channelTableStore.channelPresetFilters
                }
                dataMinMax={dataMinMax}
                minMaxLoading={isLoading}
              />
            </Box>
            <Divider sx={{margin:'0 -8px'}}/>
            <Box
              sx={{
                mt:2,
                display:"grid",
                gridTemplateColumns:{xs:'1fr', sm:"min-content 1fr min-content"},
                columnGap:1,
                rowGap:{xs:1, sm:0},
                alignItems:"start",
                overflow:'auto',
                maxHeight:82,
                pr:1
              }}
            >
              <Stack direction={{xs:'column', md:"row"}} spacing={1}>
                <CustomButton
                  variant="contained"
                  onClick={() => getChannelFiltersTableItems(false)}
                  endIcon={channelTableStore.isLoading && <CircularProgress sx={{ maxHeight: 20, maxWidth: 20 }} />}
                >
                  Применить
                </CustomButton>
                <CancelButton
                  variant="outlined"
                  disabled={channelTableStore.channelPresetFilters.length>0 ? false : searchParams.toString() === ""}
                  onClick={clearFilter}
                >
                  Сбросить
                </CancelButton>
              </Stack>
              <ChannelPresetList onFilterClose={()=>setFilterOpen(false)}/>
              <CustomButton
                variant="outlined"
                disabled={searchParams.toString() === ""}
                endIcon={<Save />}
                onClick={openSavePresetModal}
                sx={{gridRow:{xs:2, sm:'auto'}}}
              >
                Сохранить
              </CustomButton>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      {isSavePresetOpen && (
        <SaveModalFilter
          open={isSavePresetOpen}
          onClose={closeSavePresetModal}
          addPresetStore={addPresetStore}
          entity="channel"
        />
      )}
      {isOpenModalFilter &&
        <ModalFilter
          list={
            channelTableStore.channelPresetFilters.length>0
              ? channelTableStore.channelAllPresetFilters 
              : channelTableStore.channelAllFilters
          }
          open={isOpenModalFilter}
          onClose={()=>setOpenModalFilter(false)}
          onSubmit={saveFilters}
        />
      }
    </>
  )
})