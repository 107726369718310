import { FC } from "react";
import { IUserAnalyticsItem } from "./IUserAnalyticsItem";
import { Box, Typography } from "@mui/material";
import {EmptyText} from "../../../../../../../EmptyText/EmptyText";

export const UserAnalyticsItem: FC<IUserAnalyticsItem> = (props) => {
  const { title, textData, isOnlyText } = props;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: isOnlyText ? "1fr" : "1fr max-content",
        p: 1,
        border: "1px solid #e4e4e4",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontWeight: isOnlyText ? "bold" : "normal",
          fontStyle: isOnlyText ? "italic" : "normal",
        }}
      >
        {title}
      </Typography>
      {!isOnlyText && (
        <Typography sx={{ ml: { xs: "auto", md: "inherit" } }}>
          {textData ? textData : <EmptyText>Нет данных</EmptyText>}
        </Typography>
      )}
    </Box>
  );
};
