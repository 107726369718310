import { makeAutoObservable } from "mobx";
import { IAllManagerSummaryResponse, IGoodsCategoriesSummaryResponse } from "../services/statistics/IStatisticsResponse";

class PlashkiStore {

  managers:null | IAllManagerSummaryResponse[] = null

  goods:null | IGoodsCategoriesSummaryResponse[] = null
    

  constructor(){
    makeAutoObservable(this)
  }

  setManager = (managers:null | IAllManagerSummaryResponse[]) => {
    this.managers = managers
  }

  setGoods = (goods:null | IGoodsCategoriesSummaryResponse[]) => {
    this.goods = goods
  }

}

export default new PlashkiStore()