import {useState, FC} from 'react'
import { IPaymentDeleteButton } from './IPaymentDeleteButton'
import { DeleteButton } from '../../../../../../../../components/UI/DeleteButton/DeleteButton'

export const PaymentDeleteButton:FC<IPaymentDeleteButton> = (props) => {
  const {
    id,
    onDelete
  } = props

  const [isShowAlert, setShowAlert] = useState(false)

  const handleDelete = () => {
    onDelete(id)
    setShowAlert(false)
  }

  return (
    <DeleteButton
      onDelete={handleDelete}
      deleteButtonLabel={'удалить'}
      subtext={'Вы действительно хотите удалить платёж?'}
      confirmationTitle={'Внимание!'}
      showAlert={isShowAlert}
      onOpenAlert={()=>setShowAlert(true)}
      onCloseAlert={()=>setShowAlert(false)}
    />
  )
}
