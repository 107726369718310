import { FC, useState } from 'react'
import { IPaymentIntegrations } from './IPaymentIntegrations'
import { observer } from 'mobx-react-lite'
import { FinancesPayments } from '../../../../../../../FinancesCreatePage/ui/FinancesPayments/FinancesPayments'
import integrationPageStore from '../../../../../../../../store/integrationPage.store'
import { IPaysInsertOrUpdateRequest } from '../../../../../../../../services/payments/IPaymentsRequest'
import { IFinancesPaymentItem } from '../../../../../../../FinancesCreatePage/ui/FinancesPayments/ui/FinancesPaymentItem/IFinancesPaymentItem'
import PaymentsService from '../../../../../../../../services/payments/payments.service'
import { transformPaysData } from '../../../../../../../FinanceCardPage/shared/dataList'
import { AxiosError } from 'axios'
import integrationTableStore from '../../../../../../../../store/integrationTable.store'
import { NotificationAlerts } from '../../../../../../../../components/NotificationAlerts/NotificationAlerts'
import { checkNumberBoolean } from '../../../../../../../../shared/functions/functions'
import {editPaymentsPermission} from "../../../../../../../../shared/rules/permission";
import IntegrationPageStore from "../../../../../../../../store/integrationPage.store";


export const PaymentIntegrations:FC<IPaymentIntegrations> = observer((props) => {
  const {readOnly, integrationId} = props

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<null | string[]>(null)
  const [isShowError, setShowError] = useState(false)

  const [isEditMode, setEditMode] = useState(false)

  const openEditMode = () => {
    integrationPageStore.syncEditPaysWithPays()
    setEditMode(true)
  }

  const closeEditMode = () => {
    integrationPageStore.clearEditPays()
    setEditMode(false)
  }

  const addPay = () => {
    setEditMode(true)
    integrationPageStore.addPayItemInEditPays()
  }

  const submit = () => {
    if(integrationPageStore.oplataId && checkNumberBoolean(integrationPageStore.paymentUpdateId)){
      setLoading(true)
      setError(null)
      setShowError(false)

      const option:IPaysInsertOrUpdateRequest = {
        paymentId:integrationPageStore.oplataId,
        update_id:integrationPageStore.paymentUpdateId!,
        pays:integrationPageStore.editPays?.map(x=>{

          const item:IFinancesPaymentItem = {
            ...x,
            id:x?.id <= -99999999 ? undefined : x?.id,
            status:x?.status?.code
          } as any

          return item
        }) || [],
      }

      PaymentsService.paysInsertOrUpdate(option)
        .then(res=>{
          integrationPageStore.setStatus(res.data?.status)
          integrationPageStore.setPays(transformPaysData(res.data?.pays || []))
          integrationPageStore.setPaidAt(res.data?.paidAt)
          integrationPageStore.setPlannedPaidAt(res.data?.plannedPaidAt)
          integrationPageStore.setPaymentUpdateId(res.data?.update_id)

          integrationTableStore.updatePaymentsDate(integrationId, res.data?.paidAt, 'paidAt')
          integrationTableStore.changeIntegrationPaymentStatus(integrationId, res.data?.status?.code || null)

          if(res.data.totalSumm !== res.data.paysAmount && res.data.pays && res.data.pays?.length>0){
            integrationPageStore.setErrorPayments(`общая сумма платежей не соответствует общей сумме оплаты`)
          } else integrationPageStore.setErrorPayments(null)
          
          closeEditMode()
          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            setLoading(false)
            setError(err?.response?.data?.message)
            setShowError(true)
          }
        })

    }
  }

  const { code } = integrationPageStore?.status || {}
  const paysToCalculate = isEditMode ? integrationPageStore?.editPays : integrationPageStore.pays;
  const totalSumm = paysToCalculate?.reduce((acc, pay) => acc + Number(pay.totalSumm), 0) ?? 0;

  return (
    <>
      <FinancesPayments
        errorMessages={Array.isArray(error) ? error: null}
        update_id={integrationPageStore.paymentUpdateId!}
        isErrorTitle={Boolean(integrationPageStore.errorPayments)}
        isEditMode={isEditMode}
        isShowEditIcon={
          !isEditMode
            ? integrationPageStore?.pays && integrationPageStore?.pays?.length > 0 ? true : false
            : true
        }
        pays={
          !isEditMode
            ? integrationPageStore.pays || []
            : integrationPageStore.editPays || []
        }
        addPayment={addPay}
        changeFieldPaysItem={integrationPageStore?.changeSimpleFielEditPay}
        counteragent={integrationPageStore?.counteragent || undefined}
        changePaymentType={integrationPageStore?.changeEditPaymentType}
        onDeletePayment={integrationPageStore?.deleteEditPaymentType}
        isShowPaymentStatus
        onOpenEditButton={openEditMode}
        onCloseEditButton={closeEditMode}
        onSubmitEditButton={submit}
        loadingEditButton={isLoading}
        showDelete={(code==='paperwork' || code==='to_work' || code==='payment_generation' || code==='to_pay') && isEditMode}
        disabledAddBtn={readOnly || isLoading}
        onChangeStatus={!isEditMode ? integrationPageStore.onChangePayStatus : integrationPageStore.onChangeEditStatus}
        readOnlyStatus={
          ((readOnly === false && (integrationPageStore.status?.code === 'to_pay' || integrationPageStore.status?.code === 'partially_paid')) || !editPaymentsPermission())
            ? false
            : true
        }
        disabledEdit={readOnly}
        isTotalSummError={IntegrationPageStore.totalSumm!==totalSumm}
      />
      <NotificationAlerts
        error={{text: (Array.isArray(error) ? 'Пожалуйста, обратите внимание, на странице есть ошибки.' : error) || '' , onClose:()=>setShowError(false), open:isShowError}}
      />
    </>
  )
})