import {useState} from "react";
import ReferencesService from "../../../../../services/references/references.service";
import {Add} from "@mui/icons-material";
import { CustomIconButton } from "../../../../UI/Buttons/CustomIconButton/CustomIconButton";
import {AlertModal} from "../../../../AlertModal/AlertModal";

export const PositioningAddButtonDialog = () => {

  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const addPositioning = async (positioningValue: string) => {
    setLoading(true)
    await ReferencesService.createPositioning({name:positioningValue})
    setLoading(false)
    setOpen(false)
  }

  return (
    <>
      <CustomIconButton
        onClick={(e) => {
        setOpen(true)
        e.stopPropagation()
        }}
        sx={{ml: 1}} size='small' showBackground>
        <Add fontSize='small'/>
      </CustomIconButton>
      <AlertModal
        loading={isLoading}
        buttonCancelName={'Отмена'}
        buttonSubmitName={'Добавить'}
        showTextField
        placeholder={'Введите значение'} 
        subInputText={'Позиционирование:'} 
        title={'Добавление позиционирования'} 
        onClose={() => {setOpen(false)}} 
        open={open} 
        onSubmit={addPositioning}
      />
    </>
  )
}