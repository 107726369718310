import {FC, useState, useEffect} from 'react'
import { IAgreementsPage } from './IAgreementsPage'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { AgreementsTable } from '../../../../components/AgreementsTable/AgreementsTable'
import AgreementsService from '../../../../services/agreements/agreements.service'
import { AxiosError } from 'axios'
import { IAgreementsResponseCreate, IAgreementsResponseUpdateStatus, IUserApprovments } from '../../../../services/agreements/IAgreementsResponse'
import { observer } from 'mobx-react-lite'
import userStore from '../../../../store/user.store'
import { DASHBOARD_ROUTES } from '../../../../shared/routes/routes'
import { useLocation } from 'react-router-dom'
import statusesStore from '../../../../store/statuses.store'
import dashboardStore from '../../../../store/dashboard.store'
import { IAgreementsFiltered } from '../../../../services/agreements/IAgreementsRequest'

export const AgreementsPage:FC<IAgreementsPage> = observer((props) => {
  const {
    integrationId, 
    userApprovments, 
    setUserApprovments,
    update_id,
    setUpdateId,
    statusValue,
    setStatusValue
  } = props

  const location = useLocation()

  const [data, setData] = useState<null | IAgreementsResponseCreate>(null)
  const [isLoading, setLoading] = useState(false)

  const changeStatus = (userAprov: IAgreementsResponseUpdateStatus) => {
    if(userApprovments){

      const newApprovments:IUserApprovments[] = [...userApprovments.map(item=>{

        if(item.user.id === userAprov.approvments[0].user.id){

          return {...item, decisionDate:userAprov.approvments[0].decisionDate, approvedStatus: {...item.approvedStatus, code: userAprov.approvments[0].approvedStatus.code}}
        } else return item
  
      })]

      setUserApprovments(newApprovments)

      setStatusValue(userAprov.integration?.status?.code)
      setUpdateId(userAprov.integration?.update_id)

      if(statusesStore.agreement){

        const currentStatus = statusesStore?.agreement[userAprov.approvments[0].approvedStatus.code]

        dashboardStore.changeAgreementStatus(
          userAprov.id, userAprov.approvments[0].user.id, currentStatus, userAprov.approvments[0].approvedStatus.code, userAprov.agreementStatus.code, userAprov.approvments[0].decisionDate, userAprov.allDecisionDate
        )
      }
    }
  }

  const changeStatusAndUpdateId = (userAprov: IAgreementsResponseUpdateStatus) => {
    setStatusValue(userAprov.integration?.status?.code)
    setUpdateId(userAprov.integration?.update_id)
  }

  const getAgreementsIntegration = () => {
    setLoading(true)
    
    const option:IAgreementsFiltered = {
      filters:{
        integrationId:integrationId
      },
      page:1,
      take:100
    }
    AgreementsService.getAgreementsFiltered(option)
      .then(res=>{
        // console.log(res, 'res get agreemets integrations')
        setData(res.data.items?.[0])
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err get agreemets integrations')
          setLoading(false)
        }
      })
  }

  useEffect(()=>{
    getAgreementsIntegration()
  }, [])

  return (
    <>
      {isLoading &&
        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={1}>
          <Typography variant='h5'>Загрузка списка согласования...</Typography>
          <CircularProgress sx={{maxHeight:35, maxWidth:35}}/>
        </Stack>
      }
      {!isLoading && data &&
        <Box
          sx={{
            display:'grid',
            gridTemplateColumns:{xs:'1fr', lg:'2fr 1fr'}
          }}
        >
          {location.pathname.includes(DASHBOARD_ROUTES.AGREEMENTS) && userApprovments
            ? <AgreementsTable userApprovments={userApprovments} agreementId={data.id} userId={userStore?.user?.id} callback={changeStatus}/>
            : <AgreementsTable userApprovments={data.approvments} agreementId={data.id} userId={userStore?.user?.id} callback={changeStatusAndUpdateId}/>
          }
        </Box>
      }
      {!isLoading && data?.approvments === undefined &&
        <Typography textAlign={'center'} variant='h5'>Для данной интеграции сейчас нет согласования</Typography>
      }
    </>
  )
})