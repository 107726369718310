import { IRenderFilterLists } from "../../../../../components/Filters/interfaces/IRenderFilterItem";

const channelsFilters: IRenderFilterLists[] = [
  {
    code: "channelStatus",
    label: "Статусы канала",
    searchUrl: "status",
    isSelected: true,
    ordering: 2,
    placeholder: "Выберите статусы",
  },
  {
    code: "dateGiven",
    label: "Дата передачи",
    searchUrl: "dateGiven",
    isSelected: false,
    ordering: null,
  },
  {
    code: "dateFound",
    label: "Дата нахождения",
    searchUrl: "dateFound",
    isSelected: true,
    ordering: 5,
  },
  {
    code: "releaseById",
    label: "Выпускающий менеджер",
    searchUrl: "releaseById",
    isSelected: false,
    ordering: null,
    placeholder: "Выберите менеджера",
  },
  {
    code: "foundById",
    label: "Менеджер поиска",
    searchUrl: "foundById",
    isSelected: true,
    ordering: 6,
    placeholder: "Выберите менеджера",
  },
];

export const processedChannelsFilters: IRenderFilterLists[] = channelsFilters.filter(
  (item) => item.code !== "dateFound"
);
export const transferredChannelsFilters: IRenderFilterLists[] = channelsFilters.filter(
  (item) => item.code !== "releaseById"
);
