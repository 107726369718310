import { IFilterSelectItem } from "../../../../../components/Filters/UI/FilterSelectChip/IFilterSelectChip"
import { IStatusesAgreements } from "../../../../../components/Statuses/models/ISettingsFilters"

export const getAgreementsStatusInSelect = (allStatuses:IStatusesAgreements):IFilterSelectItem[] => {
  const selectStatuses:IFilterSelectItem[] = Object.keys(allStatuses).map(key=>{
    const item:IFilterSelectItem = {
      name:allStatuses[key].name,
      value:key
    }
    return item
  })

  const allStatusItem:IFilterSelectItem = {
    name:'Все статусы',
    value:'all'
  }

  return [allStatusItem, ...selectStatuses]
}