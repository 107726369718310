import { makeAutoObservable } from "mobx";
import { ILayoutResponseUser } from "../services/layout/layoutResponse";

class CompanyUsersStore {
    
  users:ILayoutResponseUser[] = []

  constructor(){
    makeAutoObservable(this)
  }

  setUsers(array:ILayoutResponseUser[]) {
    this.users = array
  }

}

export default new CompanyUsersStore()