import {FC, useState} from 'react'
import { IChannelPredictInput } from './IChannelPredictInput'
import { IUpdateStatusesRequest } from '../../../../../../services/integration/IntegrationsRequest'
import { AxiosError } from 'axios'
import { Box, Stack, Typography } from '@mui/material'
import { InputEditField } from '../../../../../../components/Table/ui/Fields/InputEditField/InputEditField'
import ChannelService from '../../../../../../services/channel/channel.service'
import { NotificationAlerts } from '../../../../../../components/NotificationAlerts/NotificationAlerts'
import {priceTransormFormat} from "../../../../../../shared/functions/functions";
import { EditIconButton } from '../../../../../../components/UI/Buttons/EditIconButton/EditIconButton'

export const ChannelPredictInput:FC<IChannelPredictInput> = (props) => {
  const {
    id, 
    field, 
    update_id, 
    initValue, 
    readOnly,
    symbol,
    changeCallbackNumEdit,
    showRemains
  } = props

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)
  const [isEdit, setEdit] = useState(false)

  const submit = (newValue:string) => {
    setLoading(true)
    setError(null)

    const option:IUpdateStatusesRequest = {
      id,
      update_id,
      field,
      new_value:newValue
    }
    ChannelService.updateSimpleField(option)
      .then(res=>{
        // console.log(res, 'update pridict field', field)
        changeCallbackNumEdit(id, field, newValue, res.data)
        
        setLoading(false)
        setEdit(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err update predicti', field)
          setLoading(false)
          setError(err?.response?.data?.message)
        }
      })
  }

  return (
    <>
      <Box>
        {!isEdit &&
          <Stack direction={'row'} alignItems={'center'}>
            <Typography sx={{whiteSpace:'nowrap'}}>{priceTransormFormat(initValue, false, showRemains, showRemains)} {symbol ? symbol : null}</Typography>
            {!readOnly &&
              <EditIconButton size='small' onClick={()=>setEdit(true)}/>
            }
          </Stack>
        }
        {isEdit && 
          <InputEditField
            inputType='number' 
            initValue={initValue?.toString() || ''} 
            loading={isLoading} 
            onClose={()=>setEdit(false)} 
            onSubmit={submit}
          />
        }
      </Box>
      <NotificationAlerts
        error={{text:isError ? isError : '', open:isError ? true : false, onClose:()=>setError(null)}}
      />
    </>
  )
}

