import {FC, useState} from 'react'
import { ICheckboxFieldPayment } from './ICheckboxFieldPayment'
import { Checkbox } from '@mui/material'
import { AxiosError } from 'axios'
import PaymentsService from '../../../../../services/payments/payments.service'
import { IFinancePaymentEditRequest } from '../../../../../services/payments/IPaymentsRequest'
import integrationTableStore from '../../../../../store/integrationTable.store'

export const CheckboxFieldPayment:FC<ICheckboxFieldPayment> = (props) => {
  const {
    checked,
    field,
    id,
    readOnly,
    update_id,
    integrationId
  } = props

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    setError(null)

    const value = event.target.checked

    const option: IFinancePaymentEditRequest = {
      id,
      update_id,
      [field]:value
    }
    PaymentsService.financePaymentEdit(option)
      .then(res=>{
        integrationTableStore.changePaymentSimpleField(integrationId, field, res.data[field], res.data.update_id)
        
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setError(err?.response?.data?.message)
          setLoading(false)
        }
      })
    
  }

  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      disabled={isLoading || readOnly}
    />
  )
}
