import { AxiosResponse } from "axios";
import $gateway_api from "../../shared/api/gatewayAxios";
import { ICrmOrderByPromoRequest } from "./ICrmRequest";
import { ICrmOrderByPromoResponse } from "./ICrmRespone";

export default class CrmService {

  static getOrders(option:ICrmOrderByPromoRequest): Promise<AxiosResponse<ICrmOrderByPromoResponse>>{
    return $gateway_api.post('/api/crm/orders-by-promo', option)
  }
    
}