import {FC, useState} from 'react'
import { IRepeatModalField } from './IRepeatModalField'
import { ModalsRepeat } from './ui/ModalsRepeat/ModalsRepeat'
import { CustomButton } from '../../../../UI/Buttons/CustomButton/CustomButton'

export const RepeatModalField:FC<IRepeatModalField> = (props) => {
  const {id} = props

  const [isShowRepeat, setShowRepeat] = useState(false)

  return (
    <>
      <CustomButton variant='contained' onClick={()=>setShowRepeat(true)}>
        Повторить
      </CustomButton>
      {isShowRepeat && <ModalsRepeat id={id} open={isShowRepeat} onClose={()=>setShowRepeat(false)}/>}
    </>
  )
}
