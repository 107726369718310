import {FC} from 'react'
import { IMotivationKpiPercentChannel } from './IMotivationKpiPercentChannel'
import { AccordionWrapper } from '../../../../../../components/AccordionWrapper/AccordionWrapper'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { KpiPriceChannelInput } from './ui/KpiPriceChannelInput/KpiPriceChannelInput'

export const MotivationKpiPercentChannel:FC<IMotivationKpiPercentChannel> = (props) => {
  const { 
    data, 
    isInitOpen = false,
    readOnly = false,
    talbeHeaderColor = '#f2f3f5'
  } = props

  return (
    <AccordionWrapper
      title='Показатели расчёта KPI за выпущенные интеграции по новым каналам'
      isOpen={isInitOpen}
    >
      <TableContainer 
        component={Paper} 
        elevation={0}
        sx={{border:'1px solid #e0e0e0',borderBottom:'none'}}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{p:1, background:talbeHeaderColor}}><Typography noWrap fontWeight={'bold'}>Товарная категория</Typography></TableCell>
              {data.map(item=>
                <TableCell key={item.goodsCategory?.code} sx={{p:1, background:talbeHeaderColor}}>
                  <Typography noWrap fontWeight={'bold'}>{item.goodsCategory?.name}</Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{p:1}}><Typography noWrap>Комиссия %</Typography></TableCell>
              {data.map(item=>
                <TableCell key={item.id} sx={{p:1}}>
                  <KpiPriceChannelInput id={item.id} value={item.commission} readOnly={readOnly}/>
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </AccordionWrapper>
  )
}
