import {FC, useState} from 'react'
import { ITzCheckbox } from './ITzCheckbox'
import { Checkbox } from '@mui/material'
import TzService from '../../../../../services/tz/tz.service'
import { ITzUpdateSimpleFieldRequest } from '../../../../../services/tz/ITzRequest'
import { AxiosError } from 'axios'
import { observer } from 'mobx-react-lite'
import tzTableStore from '../../../../../store/tzTable.store'
import { NotificationAlerts } from '../../../../NotificationAlerts/NotificationAlerts'

export const TzCheckbox:FC<ITzCheckbox> = observer((props) => {
  const {
    checked, 
    readOnly = false, 
    update_id, 
    field,
    id
  } = props

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<null | string>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    setError(null)

    const option: ITzUpdateSimpleFieldRequest = {
      field,
      id,
      new_value:event.target.checked as any,
      update_id
    }
    TzService.updateSimpleField(option)
      .then(res=>{
        tzTableStore.updateSimpleField(id, field, res.data[field], res.data.update_id)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setError(err?.response?.data?.message)
        }
      })
      .finally(()=>{
        setLoading(false)
      })
  }

  return (
    <>
      <Checkbox checked={checked} onChange={handleChange} disabled={isLoading || readOnly}/>
      <NotificationAlerts
        error={{text:error ? error : '', open:Boolean(error), onClose:()=>setError(null)}}
      />
    </>
  )
})