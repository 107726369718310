import { AsideCardLayout } from '../../../../components/AsideCardLayout/AsideCardLayout'
import { GoodsAndCategoryBlock } from './ui/GoodsAndCategoryBlock/GoodsAndCategoryTable'

export const RulesPage = () => {
  return (
    <AsideCardLayout sx={{overflow:'auto'}}>
      <GoodsAndCategoryBlock/>
    </AsideCardLayout>
  )
}
