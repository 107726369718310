import { FC } from "react";
import { Paper,  Table,  TableBody,  TableCell,  TableContainer,  TableHead,  TableRow,  Typography,} from "@mui/material";
import { IMotivationTableSection, } from "../../models/IMotivationTableSection";
import { initDataForTable } from "../../shared/function";
import { observer } from "mobx-react-lite";
import { AccordionWrapper } from "../../../AccordionWrapper/AccordionWrapper";
import AllUsersStore from "../../../../store/AllUsers.store";
import { IUser } from "../../../../entities/user/models/IUser";
import { priceTransormFormat } from "../../../../shared/functions/functions";
import {staticColumnsName} from "../../models/tableModels";
import { MotivationTableKpiItem } from "./ui/MotivationTableKpiItem/MotivationTableKpiItem";

const MotivationTable: FC<Omit<IMotivationTableSection, "name">> = observer((props) => {
  const {
    userId,
    summaryTableData,
    readOnly,
    talbeHeaderColor,
    onChangeCell
  } = props

  const currentUser: undefined | IUser = userId
    ? AllUsersStore?.users?.find((user) => user?.id === +userId)
    : undefined

  return (
    <AccordionWrapper
      title={
        currentUser
          ? `${currentUser?.roleName ? currentUser?.roleName + ':'  : 'Менеджер:'} ${currentUser?.firstName} ${currentUser?.lastName}`
          : 'Загрузка менеджера...'
      }
      isOpen
      leftText={summaryTableData ? `Итого KPI: ${priceTransormFormat(summaryTableData?.summaryTable?.totalSumm, true)}` : undefined}
    >
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          border: "1px solid #e0e0e0",
          borderBottom: "none",
          borderRadius: 0,
          borderLeft: "none",
        }}
      >
        {summaryTableData && (
          <Table
            sx={{
              "& .MuiTableCell-root": { borderLeft: "1px solid #e0e0e0" },
            }}
          >
            <TableHead>
              <TableRow>
                {staticColumnsName.columnsName.map((item) => (
                  <TableCell sx={{p:1, background:talbeHeaderColor}} key={item}>
                    <Typography noWrap sx={{fontWeight:'bold'}}>{item}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userId && currentUser && initDataForTable(currentUser?.role, summaryTableData).rows.map(item => (
                <MotivationTableKpiItem
                  key={item?.name}
                  name={item?.name}
                  readOnly={readOnly ? readOnly : false}
                  userId={userId}
                  value={item.value}
                  fontWeight={item.fontWeight}
                  isAccordion={item.isAccordion}
                  isEdit={item.isEdit}
                  lastTextAccordion={item.lastTextAccordion}
                  subContent={item.subContent}
                  type={item.type}
                  onChangeCell={onChangeCell}
                  prevMonthMotivationReleases={summaryTableData?.forRelease?.prevMonthMotivationReleases || []}
                />
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </AccordionWrapper>
  )
})

export default MotivationTable
