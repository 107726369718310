import { makeAutoObservable } from "mobx";

class TableRowsStore {
  checkedRows = new Set<number>();
  checkedRowsCounter: number | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setCheckedRows(id: number) {
    this.checkedRows.has(id) ? this.checkedRows.delete(id) : this.checkedRows.add(id);
    this.checkedRowsCounter = this.checkedRows.size || null;
  }

  resetCheckedRows() {
    this.checkedRows.clear();
    this.checkedRowsCounter = null;
  }
}

export default new TableRowsStore();
