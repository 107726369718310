import {FC, useState} from 'react'
import { IPaymentStatusIntegrationField } from './IPaymentStatusIntegrationField'
import { StatusesList } from '../../../../Statuses/StatusesList'
import statusesStore from '../../../../../store/statuses.store'
import { IPaymentUpdateStatusRequest } from '../../../../../services/payments/IPaymentsRequest'
import PaymentsService from '../../../../../services/payments/payments.service'
import { AxiosError } from 'axios'
import integrationTableStore from '../../../../../store/integrationTable.store'

export const PaymentStatusIntegrationField:FC<IPaymentStatusIntegrationField> = (props) => {
  const {
    id,
    integrationId,
    type, 
    update_id, 
    readOnly = true,
    changePaymentStatusInIntegrationTable
  } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [isShowAlert, setShowAlert] = useState(false)
  
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<null | string>(null)

  const changeStatus = (value:string) => {
    setLoading(true)

    const option: IPaymentUpdateStatusRequest = {
      id,
      update_id,
      newStatus:value,
      entity:'payment'
    }
    PaymentsService.updateStatus(option)
      .then(res=>{
        handleClose()
        setLoading(false)
        changePaymentStatusInIntegrationTable && changePaymentStatusInIntegrationTable(integrationId, res.data?.status?.code || null, res.data.update_id)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setError(err?.response?.data?.message)
          setLoading(false)
        }
      })

  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <StatusesList
      type={type}
      anchorEl={anchorEl}
      onOpenList={handleClick}
      onCloseList={handleClose}
      changeStatus={changeStatus}
      statusStore={statusesStore.payments}
      loading={isLoading}
      showAlertSuccess={isShowAlert}
      closeAlertSuccess={()=>setShowAlert(false)}
      isShowAlerError={isError}
      closeAlertError={()=>setError(null)}
      readOnly={readOnly}
      wrap
    />
  )
}
