import { makeAutoObservable } from "mobx"
import { IDragParams, setCheckedParams } from "./integrationTable.store"
import { ISettingsSelected } from "../components/IntegrationTable/models/ISettingsSelected"
import { ISettingsSelectedGroup } from "../components/IntegrationTable/models/ISettingsSelectedGroup"
import { ITzFullItemResponse } from "../services/tz/ITzResponse"
import allTzStore from "./allTz.store"

class TzTableStore {
  page:number = 1

  tableItems:any[] = []

  allFilter:string[] = []

  isLoading:boolean = true

  isLoadingSort:boolean = true

  totalCount:number = 0

  isPageNotFound:boolean = false

  initSettingsConfirm:ISettingsSelected[] = []
  initSettingsSelect:ISettingsSelectedGroup[] = []
    
  selectLists:ISettingsSelectedGroup[] = []
  confirmLists:ISettingsSelected[] = []

  curDragItemParams:IDragParams | null = null

  constructor(){
    makeAutoObservable(this)
  }

  setLoading(value:boolean) {
    this.isLoading = value
  }

  setTotalCount(count:number) {
    this.totalCount = count
  }

  setPage(page:number) {
    this.page = page
  }
    
  setAllFilter(array:string[]){
    this.allFilter = array
  }

  setTableItems(items:any[]){
    this.tableItems = items
  }

  setSelectedList(array:ISettingsSelectedGroup[]){
    this.selectLists = array
  }

  setConfirmList(array:ISettingsSelected[]) {
    this.confirmLists = array
  }

  setInitSettingsSelect(array:ISettingsSelectedGroup[]){
    this.initSettingsSelect = array
  }

  setInitSettingsConfirm(array:ISettingsSelected[]){
    this.initSettingsConfirm = array
  }

  updateSimpleField = (id:number, field:string, new_value:any, update_id:number) => {
    
    this.tableItems = this.tableItems.map(item=>{
      if(item.id === id){
        return {...item, [field]:new_value, update_id}
      } else return item
    })

    allTzStore.changeTzInStore(id, field, new_value, update_id)
  }

  addTzInTable = (tz:ITzFullItemResponse) => {
    this.tableItems.unshift(tz)
  }

  onConfirmDelete(id:string){
    this.selectLists.map(groupArr=>
        groupArr.fields.map(item=>item.code === id ? item.isSelected = !item.isSelected : item)
    )
  }

  setIsPageNotFound(value:boolean){
    this.isPageNotFound = value
  }

  addToConfirmList(item:ISettingsSelected){
    const newArr:any[] = [...this.confirmLists, {...item, ordering:this.confirmLists.length + 1}]

    this.confirmLists = newArr
  }

  setSelectedChecked(params:setCheckedParams){
    const {code, group} = params

    this.selectLists.map(groupArr=>groupArr.group === group ? 
        groupArr.fields.map(item=>item.code === code ? item.isSelected = !item.isSelected : item)
     : groupArr)
  }

  removeConfirmList(id:string){
    const newArr:any[] = [...this.confirmLists.filter(item=>item.code !== id).map((item, i)=>{
        return {...item, ordering:i+1}
    })]

    this.confirmLists = newArr
  }

  onDragStart = (params:IDragParams) => {
    this.curDragItemParams = params
  }

  onDragOver = (e:React.DragEvent) => {
    e.preventDefault()
  }

  onDrop = (e:React.DragEvent, params:IDragParams) => {
    e.preventDefault()

    if(params.code && params.ordering && this.curDragItemParams?.code && this.curDragItemParams?.ordering && params.code !== this.curDragItemParams.code){

      const dragItem = {code:this.curDragItemParams.code, ordering:params.ordering}
      const downItem = {params,code:params.code, ordering:this.curDragItemParams.ordering < params.ordering ? params.ordering-1 : params.ordering+1}

      const afterDragArr:any[] = this.confirmLists.map(item=>{
        if(item.code === dragItem.code) {
          return {...item, ...dragItem}
        } else if (item.code === downItem.code) {
          return {...item, ...downItem}
        } else  {
          return {
            ...item,
            ordering:item.ordering >= dragItem.ordering ? item.ordering + 1 : item.ordering
          }
        }
      })

      this.confirmLists = afterDragArr.sort((a, b)=>a.ordering > b.ordering ? 1 : -1).map((x,i)=>{
        return {...x, ordering:i+1}
      })
    }
  }

  sortTableItem(type:string, sortType:'asc' | 'desc'){
    this.isLoadingSort = true

    setTimeout(()=>{
      sortType === 'desc'
        ? this.tableItems = [].concat(this.tableItems as []).sort((a, b)=>{
            if (a[type] === null) {
              return 1;
            }
          
            if (b[type] === null) {
              return -1;
            }
          
            if (a[type] === b[type]) {
              return 0;
            }
          
            return a[type] < b[type] ? 1 : -1;
          })
        : this.tableItems = [].concat(this.tableItems as []).sort((a, b)=>{
            if (a[type] === null) {
              return 1;
            }
          
            if (b[type] === null) {
              return -1;
            }
          
            if (a[type] === b[type]) {
              return 0;
            }
          
            return a[type] > b[type] ? 1 : -1;
          })

        setTimeout(()=>{
          this.isLoadingSort = false
        },0)

    }, 0)
  }
  
}

export default new TzTableStore()