import { FC, useState } from 'react'
import { IAsideReleaseManager } from './IAsideReleaseManager'
import { AsideSelectManager } from '../../../ChannelCardPage/ui/AsideSelectManager/AsideSelectManager'
import { AxiosError } from 'axios'
import IntegrationsService from '../../../../services/integration/integrations.service'
import { IUpdateStatusesRequest } from '../../../../services/integration/IntegrationsRequest'
import integrationTableStore from '../../../../store/integrationTable.store'
import integrationPageStore from '../../../../store/integrationPage.store'

export const AsideReleaseManager:FC<IAsideReleaseManager> = (props) => {
  const {
    id,
    field,
    initValue,
    update_id,
    onChangeUpdateId,
    readOnly,
    fieldNotCode
  } = props
  
  const [value, setValue] = useState<{value:string}>(initValue ? {value:`${initValue}`} : {value:''})
  
  const [isLoadingDelete, setLoadingDelete] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const onChangeReleseManager = (value:string) => {
    setLoading(true)

    const option:IUpdateStatusesRequest = {
      id,
      field:fieldNotCode ? fieldNotCode : field,
      new_value:value,
      update_id:update_id
    }
    IntegrationsService.updateSimpleField(option)
      .then(res=>{
        // console.log(res, 'res update releseBy')
        integrationPageStore.setReleaseUserId(value ? value : null)
        integrationPageStore.setAsiedeReleaseUserId(value ? value : null)
        
        integrationTableStore.addReleaseByManager(id, field, res.data.update_id, +value)

        onChangeUpdateId&&onChangeUpdateId(res.data.update_id)

        setValue({value:value})
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err releaseBy')
          setLoading(false)
        }
      })
  }

  const onDelete = () => {
    setLoadingDelete(true)

    const option:IUpdateStatusesRequest = {
      id,
      field:fieldNotCode ? fieldNotCode : field,
      new_value:null!,
      update_id:update_id
    }
    IntegrationsService.updateSimpleField(option)
      .then(res=>{
        // console.log(res, 'res update releseBy')
        integrationPageStore.setReleaseUserId(null)
        integrationPageStore.setAsiedeReleaseUserId(null)
        integrationTableStore.removeReleaseByManager(id, field, update_id,)
        setValue({value:''})
        onChangeUpdateId && onChangeUpdateId(res.data.update_id)

        setLoadingDelete(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err releaseBy')
          setLoadingDelete(false)
        }
      })
  }

  return (
    <AsideSelectManager
      maxWidth={'350px'}
      width={'100%'}
      initValue={value.value}
      onChange={onChangeReleseManager}
      isLoading={isLoading || isLoadingDelete}
      isError={isError}
      readOnly={readOnly}
      onDelete={onDelete}
    />
  )
}