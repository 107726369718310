import { IStatisticsRequestGeneral } from "../../../services/statistics/IStatisticsRequest";

export const getAnalyticsParams = (searchParams:URLSearchParams, dateFrom:string, dateTo:string):IStatisticsRequestGeneral => {

  const option:IStatisticsRequestGeneral = {
    id:searchParams.get('id') ? +searchParams.get('id')! : null!,
    dateOptions:{
      from:searchParams.get('date') ? searchParams.get('date')?.split('_')[0]! : dateFrom,
      to:searchParams.get('date') ? searchParams.get('date')?.split('_')[1]! : dateTo
    }
  }

  const params:IStatisticsRequestGeneral = {} as IStatisticsRequestGeneral

  for (let key in option) {
    if(option[key]){
      params[key] = option[key]
    }
  }

  return params
}