import { FC } from "react"
import { IMotivationRoiTable } from "./IMotivationRoiTable"
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { MotivationRoiTableItem } from "./ui/MotivationRoiTableItem/MotivationRoiTableItem"
import { AccordionWrapper } from "../../../../../../components/AccordionWrapper/AccordionWrapper"
import { AddedCategorySelect } from "./ui/AddedCategorySelect/AddedCategorySelect"
import { MotivationChannelViews } from "./ui/MotivationChannelViews/MotivationChannelViews"

export const MotivationRoiTable:FC<IMotivationRoiTable> = (props) => {
  const {
    tableItems, 
    readOnly = false,
    isInitShowTable = false,
    talbeHeaderColor,
    showDeleteIcon,
    month,
    year,
    userId,
    showAddedSelect,
    sizeAudience
  } = props

  return (
    <AccordionWrapper
      isOpen={isInitShowTable}
      title={'Показатели для расчёта KPI за выпущенные интеграции'}
    >
      <Box sx={{display:'grid', gridAutoRows: 'min-content'}}>
        <TableContainer
          component={Paper} 
          elevation={0}
          sx={{
            border:'1px solid #e0e0e0',
            borderBottom:'none', 
            borderRadius:0,
            borderLeft:'none',
          }}
        >
          <Table
            stickyHeader
            sx={{
              "& .MuiTableCell-root": {borderLeft: '1px solid #e0e0e0'},
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{p:1, background:talbeHeaderColor}}>
                  <Typography variant="h6">Категория</Typography>
                </TableCell>
                <TableCell align="center" colSpan={sizeAudience ? 3 : 2} sx={{p:1, background:talbeHeaderColor}}>
                  <Typography variant="h6">Пороги ROI</Typography>
                </TableCell>
                <TableCell align="center" colSpan={sizeAudience ? 6 : 4} sx={{p:1, pl:0, pr:0, pb:0, background:talbeHeaderColor}}>
                  <Typography variant="h6">Мотивация</Typography>
                  <Box
                    sx={{display:'flex', borderTop:'1px solid #e0e0e0', p:1, mt:1,pt:0, pb:0,}}
                  >
                    <Box sx={{width:'50%', borderRight:'1px solid #e0e0e0', p:1}}>
                      <Typography sx={{fontWeight:'bold',}}>Бюджет выработан</Typography>
                    </Box>
                    <Box sx={{width:'50%', p:1}}>
                      <Typography sx={{fontWeight:'bold'}}>Бюджет НЕ выработан</Typography>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{p:1}}>
                  <Typography fontWeight={'bold'} whiteSpace={'nowrap'}>Тип</Typography>
                </TableCell>
                <TableCell align="center" sx={{p:1}}>
                  <Typography fontWeight={'bold'} whiteSpace={'nowrap'}>Старый</Typography>
                </TableCell>
                <TableCell align="center" sx={{p:1}}>
                  <Typography fontWeight={'bold'} whiteSpace={'nowrap'}>Новый</Typography>
                </TableCell>
                {sizeAudience &&
                  <TableCell align="center" sx={{p:1, maxWidth:'100px'}}>
                    <Typography fontWeight={'bold'}>Новый с просмотрами</Typography>
                  </TableCell>
                }
                <TableCell align="center" sx={{p:1}}>
                  <Typography fontWeight={'bold'}>Старый</Typography>
                </TableCell>
                <TableCell align="center" sx={{p:1}}>
                  <Typography fontWeight={'bold'}>Новый</Typography>
                </TableCell>
                {sizeAudience &&
                  <TableCell align="center" sx={{p:1, maxWidth:'100px'}}>
                    <Typography fontWeight={'bold'}>Новый с просмотрами</Typography>
                  </TableCell>
                }
                <TableCell align="center" sx={{p:1}}>
                  <Typography fontWeight={'bold'}>Старый</Typography>
                </TableCell>
                <TableCell align="center" sx={{p:1}}>
                  <Typography fontWeight={'bold'} whiteSpace={'nowrap'}>Новый</Typography>
                </TableCell>
                {sizeAudience &&
                  <TableCell align="center" sx={{p:1, maxWidth:'100px'}}>
                    <Typography fontWeight={'bold'}>Новый с просмотрами</Typography>
                  </TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {tableItems.map(item=>
                <MotivationRoiTableItem 
                  key={item.id} 
                  readOnly={readOnly} 
                  {...item} 
                  showDeleteIcon={showDeleteIcon}
                  month={month || 0}
                  year={year || 0}
                />)
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{display:'flex', alignItems:'center', flexWrap:'wrap', mt:2}}>
        {showAddedSelect &&
          <Box sx={{mr:sizeAudience ? 1 : 0}}>
            <AddedCategorySelect
              month={month || 0}
              year={year || 0}
              userId={userId}
              readOnly={readOnly}
            />
          </Box>
        }
        {sizeAudience &&
          <Box sx={{display:'flex', alignItems:'center', flexWrap:'wrap'}}>
            <Typography sx={{mr:1}}>Новый с просмотрами</Typography>
            {sizeAudience.map((x,i)=>
              <Box key={x?.id} sx={{mr:i===0 ? 1 : 0}}>
                <MotivationChannelViews id={x?.id} initValue={x?.value} readOnly={readOnly} channel={x?.channelType}/>
              </Box>
            )}
          </Box>
        }
      </Box>
    </AccordionWrapper>
  )
}